import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { CHECK_RESPONSE, VALIDATION_ERROR } from 'utils/enums';
import { checkIsAmountServiceField, getServiceInputConfig } from 'utils/helpers';
import { IAddServiceFieldValueRequest, IServiceFieldResponse, IUpdateServiceFieldValueRequest } from 'utils/types';
import { ServiceFieldItem } from '../ServiceFieldItem';
import styles from './index.module.scss';

interface ProviderServiceFieldsListProps {
	fieldsList: IServiceFieldResponse[];
	serviceId?: string;
	setIsValid: (value: boolean) => void;
	onChange: (values: IUpdateServiceFieldValueRequest[] | IAddServiceFieldValueRequest[]) => void;
	values: IUpdateServiceFieldValueRequest[] | IAddServiceFieldValueRequest[];
	providerAccountIdFieldName?: string;
	isFieldsDisabled?: boolean;
	onSubmit: () => void;
}

export const ProviderServiceFieldsList: FC<ProviderServiceFieldsListProps> = ({
	setIsValid,
	onChange,
	values,
	fieldsList = [],
	isFieldsDisabled,
	onSubmit,
}) => {
	const [validationError, setValidationError] = useState({});
	const [touched, setTouched] = useState({});

	useEffect(() => {
		const isErrorsNotExist = Object.values(validationError).every((item) => !item);
		setIsValid(isErrorsNotExist);
	}, [validationError, values, fieldsList.length]);

	useEffect(() => {
		setValidationError({});
	}, [fieldsList.length]);

	useEffect(() => {
		if (!Object.keys(validationError).length) {
			values.forEach((item) => handleValidate(item.name, item.value));
		}
	}, [values, Object.keys(validationError).length]);

	const handleValidate = async (name: string, value: string): Promise<void> => {
		try {
			const currentConfig = fieldsList.find((item) => item.name === name);
			if (currentConfig) {
				let schema = yup.string();
				if (currentConfig.required === CHECK_RESPONSE.YES) {
					schema = schema.concat(yup.string().required(VALIDATION_ERROR.REQUIRED));
				}
				if (currentConfig.minAmount) {
					schema = schema.concat(
						yup
							.string()
							.test('minAmount', `Мінімальне значення ${currentConfig.minAmount}`, (value: string | undefined) => {
								return +(value as string) >= (currentConfig.minAmount as number);
							})
					);
				}
				if (currentConfig.maxAmount) {
					schema = schema.concat(
						yup
							.string()
							.test(
								'maxAmount',
								`Максимальне значення ${currentConfig.maxAmount}`,
								(value: string | undefined) => +(value as string) <= (currentConfig.maxAmount as number)
							)
					);
				}
				if (currentConfig?.maxLength) {
					schema = schema.concat(
						yup.string().max(currentConfig.maxLength, `Максимальна кількість символів ${currentConfig.maxLength}`)
					);
				}
				await schema.validate(value);
				setValidationError({ ...validationError, [name]: '' });
			}
		} catch (error) {
			setValidationError({ ...validationError, [name]: error.message });
		}
	};

	const handleChange = (data: IAddServiceFieldValueRequest | IUpdateServiceFieldValueRequest): void => {
		const payload: (IUpdateServiceFieldValueRequest | IAddServiceFieldValueRequest)[] = values.map((item) => {
			if (data.name !== item.name) return item;
			return {
				...item,
				value: data.value,
			};
		});
		onChange(payload as IUpdateServiceFieldValueRequest[] | IAddServiceFieldValueRequest[]);
	};

	return (
		<div className={styles.list}>
			{values.map((item: IAddServiceFieldValueRequest | IUpdateServiceFieldValueRequest) => (
				<ServiceFieldItem
					onSubmit={onSubmit}
					{...getServiceInputConfig(item, fieldsList)}
					value={item.value}
					key={item.name}
					data={item}
					onChange={handleChange}
					onValidate={handleValidate}
					onBlur={(name: string) => setTouched({ ...touched, [name]: true })}
					errors={validationError}
					touched={touched}
					fieldConfig={fieldsList.find((fieldConfig) => item.name === fieldConfig.name)}
					disabled={!checkIsAmountServiceField(item) && isFieldsDisabled}
				/>
			))}
		</div>
	);
};
