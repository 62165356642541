import { FC } from 'react';
import { useHistory } from 'react-router';
import { getRadabanlInternalClientSelector, useAppSelector } from 'store';
import { CardBanner } from 'components/Home';
import { Navbar } from 'components/Navbar';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { FirebaseAnalytics } from 'utils/services';

export const RadabankCreateCardBannerPage: FC = () => {
	const history = useHistory();

	const radabankInternalClient = useAppSelector(getRadabanlInternalClientSelector);

	const redirectUrl =
		radabankInternalClient?.username && radabankInternalClient?.clientcode
			? ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD
			: ROUTER_URL.RADABANK_CARD_AUTH_INFO_BEGIN;

	const handleClickCard = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE_CARD_RADABANK
		);
		history.replace(redirectUrl);
	};

	return (
		<>
			<div className="page page-scrollable">
				<CardBanner onClick={handleClickCard} />
			</div>
			<Navbar />
		</>
	);
};
