import { ChangeEvent, FC, useState } from 'react';
import { useHistory } from 'react-router';
import { AuthContentHeader } from 'components/Auth/AuthContentHeader';
import { ArrowBackButton, CustomButton, PasswordInput } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON } from 'utils/constants';
import { FirebaseAnalytics } from 'utils/services';
import { passwordValidationSchema } from 'utils/validation';

// TODO should rework
interface SetPasswordWebProps {
	value: string;
	isButtonBackVisible?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onSubmit: () => void;
	nameInput?: string;
	label: string;
	description: string;
	pageName: string;
}

export const SetPasswordWeb: FC<SetPasswordWebProps> = ({
	value,
	nameInput,
	isButtonBackVisible = true,
	onChange,
	onSubmit,
	label,
	description,
	pageName,
}) => {
	const history = useHistory();

	const [error, setError] = useState('');
	const [touched, setTouched] = useState(false);

	const isFormDisabled = !!error || !value.length;

	const validatePassword = async (value: string): Promise<void> => {
		try {
			await passwordValidationSchema.validate(value, { abortEarly: false });
			setError('');
		} catch (error) {
			setError(error.inner[0]?.message);
		}
	};

	const handleBlur = (): void => {
		if (!touched) {
			setTouched(true);
			validatePassword(value);
		}
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		onChange(event);
		validatePassword(event.target.value);
	};

	const handleClickBack = () => {
		FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK);
		history.goBack();
	};

	const handleSubmitPassword = () => {
		if (isFormDisabled) return;
		onSubmit();
	};

	return (
		<>
			{isButtonBackVisible && <ArrowBackButton onClick={handleClickBack} />}
			<AuthContentHeader title={label} description={description} />
			<PasswordInput
				onSubmit={handleSubmitPassword}
				value={value}
				nameInput={nameInput}
				onChange={handleChangePassword}
				onBlur={handleBlur}
				isError={!!error && touched}
			/>
			<CustomButton
				className="auth-button-submit"
				size="large"
				disabled={isFormDisabled}
				label="Продовжити"
				onClick={onSubmit}
			/>
		</>
	);
};
