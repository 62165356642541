import { FC, HTMLProps, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { MapMarker, StaticGoogleMap } from 'components/shared';
import { ERROR_MESSAGE } from 'utils/enums';
import { encryptData, getReceiptRoute } from 'utils/helpers';
import { useMatchMedia } from 'utils/hooks';
import { IApartmentResponse } from 'utils/types';
import { ApartmentMapContent } from '../ApartmentMapContent';
import styles from './index.module.scss';

interface ApartmentMapProps extends Omit<HTMLProps<HTMLDivElement>, 'data' | 'ref'> {
	data: IApartmentResponse;
}

export const ApartmentMap: FC<ApartmentMapProps> = ({ data, children, className, onClick, ...props }) => {
	const isMobile = useMatchMedia();

	const history = useHistory();

	const dispatch = useAppDispatch();

	const mapRef = useRef<HTMLDivElement>(null);

	const apartments = useAppSelector(getApartmentsSelector);

	const [apartmentIndex, setApartmentIndex] = useState<number | null>(null);

	useEffect(() => {
		if (apartments.list.length) {
			const currentApartmentIndex = apartments.list.findIndex(
				(item) => item.apartmentAccountId === data.apartmentAccountId
			);
			setApartmentIndex(currentApartmentIndex);
		}
	}, [data, apartments.list.length]);

	const handleClickCard = (): void => {
		if (data.singleReceipt) {
			history.push(getReceiptRoute(encryptData(data.apartmentAccountId.toString())));
		} else {
			dispatch(showToast({ message: ERROR_MESSAGE.RECEIPT_NOT_FOUND }));
		}
	};

	return (
		<div
			{...props}
			ref={mapRef}
			onClick={onClick ?? handleClickCard}
			className={classNames(styles.wrapper, className, isMobile ? styles.mobile_narrow : styles.web_narrow)}
		>
			{!!mapRef.current && (
				<StaticGoogleMap
					width={mapRef.current.offsetWidth}
					height={mapRef.current.offsetHeight}
					lat={+data.lat}
					lng={+data.lon}
				/>
			)}
			{apartmentIndex !== null && <MapMarker className={styles.marker} index={apartmentIndex} />}
			{children || <ApartmentMapContent data={data} />}
		</div>
	);
};
