import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
	getUserProfileSelector,
	setAppLoading,
	showToast,
	updateInfoStatusIdRadabankReducer,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_IMAGE_TYPE_CODE, ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FaceDetectorService, FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankInfoStatusResponse, IUserProfileResponse } from 'utils/types';

export const RadabankAuthInfoImageSetPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const profile = useAppSelector(getUserProfileSelector);

	const [isLoading, setIsLoading] = useState(false);

	const handleClickStartDetect = async (): Promise<void> => {
		setIsLoading(true);
		try {
			dispatch(setAppLoading(true));
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SET_PHOTO,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			const imageBase64 = await FaceDetectorService.getPhoto();
			if (imageBase64 && profile) {
				await RadabankService.setInfoImage({
					userId: profile.userId,
					imagecontext: [imageBase64],
					typecode: RADABANK_IMAGE_TYPE_CODE.SELFIE,
				});
				await RadabankService.commitInfoImage(profile.userId);
				history.replace(ROUTER_URL.RADABANK_CARD_AUTH_INFO_SET_ANKETA);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			await checkStatus((profile as IUserProfileResponse).userId);
		} finally {
			dispatch(setAppLoading(false));
			setIsLoading(false);
		}
	};

	const checkStatus = async (userId: string): Promise<void> => {
		const response: AxiosResponse<IRadabankInfoStatusResponse> = await RadabankService.checkInfoStatus(userId);
		dispatch(updateInfoStatusIdRadabankReducer(response.data.statusCode));
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SET_PHOTO,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} />
			<RadabankOnboardingContent
				title="Підтвердження особи"
				description={[
					'Тепер я маю вас побачити. Оберіть місце з гарним освітленням та приготуйтеся усміхатися)',
					'Прохання не використовувати аксесуари, які закривають обличчя',
				]}
			/>
			<CustomButton isLoading={isLoading} label="Продовжити" color="secondary" onClick={handleClickStartDetect} />
		</div>
	);
};
