import { ChangeEvent, FC } from 'react';
import { Typography } from '@mui/material';
import DotStepper from 'components/Auth/PasswordSetup/DotStepper';
import { NumKeyboard } from 'components/Auth/PasswordSetup/NumKeyboard';
import styles from './index.module.scss';

interface RadabankPinCodeProps {
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	error?: string;
	description?: string;
	maxLength?: number;
}

export const RadabankPinCode: FC<RadabankPinCodeProps> = ({ value, onChange, error, description, maxLength = 0 }) => {
	const makeEvent = (value: string) =>
		({
			target: {
				value,
				name: 'name',
			},
		} as ChangeEvent<HTMLInputElement>);

	const onNumClick = (num: number) => {
		if (value.length !== maxLength && num >= 0) {
			onChange(makeEvent(value + num));
		} else if (num < 0) {
			onChange(makeEvent(value.substring(0, value.length - 1)));
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.info}>
				{(error || description) && (
					<Typography
						variant="body2"
						color={error ? 'var(--color-error-500)' : 'var(--color-primary-500)'}
						textAlign="center"
					>
						{error || description}
					</Typography>
				)}
				<DotStepper steps={maxLength} currentStep={value.length} color="primary" />
			</div>
			<NumKeyboard onButtonClick={(num: number) => onNumClick(num)} color="primary" />
		</div>
	);
};
