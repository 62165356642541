import { FC, memo } from 'react';
import { Typography } from '@mui/material';
import { IRadabankInternalOperation } from 'utils/types';
import { OperationItem } from '../OperationsList/OperationItem';

interface RadabankOperationsDayItemProps {
	date: string;
	operations: IRadabankInternalOperation[];
}

export const RadabankOperationsDayItem: FC<RadabankOperationsDayItemProps> = memo(({ date, operations }) => {
	return (
		<div>
			<Typography fontSize="12px" color="var(--color-primary-200)" textAlign="center">
				{date}
			</Typography>
			{operations.map((item) => (
				<OperationItem key={item.id} data={item} />
			))}
		</div>
	);
});
