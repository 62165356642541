import { FC } from 'react';
import { getRadabankInfoSprDataLoadingSelector, getRadabankInfoSprDataSelector, useAppSelector } from 'store';
import { SurveyAdditionalIncome } from 'components/RadabankCard/SurveyAdditionalIncome';
import { SurveyAssetsAndProperty } from 'components/RadabankCard/SurveyAssetsAndProperty';

export const PropertyStatusStep: FC = () => {
	const isLoading = useAppSelector(getRadabankInfoSprDataLoadingSelector);
	const infoSprData = useAppSelector(getRadabankInfoSprDataSelector);

	return (
		<>
			<SurveyAssetsAndProperty />
			<SurveyAdditionalIncome
				isLoading={isLoading}
				sourceIncomeOptions={infoSprData?.sourceIncome}
				scopeEnterpriseOptions={infoSprData?.scopeEnterprise}
			/>
		</>
	);
};
