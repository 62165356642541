import { FC } from 'react';
import { Download } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { ArrowBackButton } from 'components/shared';
import { useOpen } from 'utils/hooks';
import { IDateRange } from 'utils/types';
import { RadabankDownloadStatementModal } from '../DownloadStatementModal';
import styles from './index.module.scss';

interface RadabankOperationsStatementHeaderProps {
	onGoBack: () => void;
	dateRange: IDateRange;
}

export const RadabankOperationsStatementHeader: FC<RadabankOperationsStatementHeaderProps> = ({
	onGoBack,
	dateRange,
}) => {
	const { isOpen, handleClose, handleOpen } = useOpen();

	return (
		<>
			<div className={styles.header}>
				<div className={styles.header__text}>
					<ArrowBackButton onClick={onGoBack} />
					<Typography variant="h6">Виписка по картці</Typography>
				</div>
				<IconButton onClick={handleOpen}>
					<Download />
				</IconButton>
			</div>
			<RadabankDownloadStatementModal dateRange={dateRange} isOpen={isOpen} onClose={handleClose} />
		</>
	);
};
