import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Typography } from '@mui/material';
import {
	getRadabanlInternalClientSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { ConfirmOTPCode } from 'components/Auth';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_OTP_LENGTH,
	RADABANK_OTP_TITLE,
} from 'utils/constants';
import { RADABANK_BOOLEAN_VALUE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { decryptData, getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService, StorageService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const RadabankInternalCreateCardPage: FC = () => {
	const history = useHistory();

	const { search } = useLocation();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const profile = useAppSelector(getUserProfileSelector);
	const radabankInternalClient = useAppSelector(getRadabanlInternalClientSelector);

	const isSkipBanner = new URLSearchParams(search).get('skipBanner') === RADABANK_BOOLEAN_VALUE.TRUE;

	const [otp, setOtp] = useState('');
	const [isValid, setIsValid] = useState(true);
	const [isShowBanner, setIsShowBanner] = useState(!isSkipBanner);

	const isClientExistInRB = !!radabankInternalClient && radabankInternalClient.activate === RADABANK_BOOLEAN_VALUE.TRUE;

	useEffect(() => {
		return () => {
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);
		};
	}, []);

	useEffect(() => {
		if (isSkipBanner) {
			handleAuthorize();
		}
	}, [isSkipBanner]);

	const handleChangeOTP = (value: string): void => {
		setIsValid(true);
		setOtp(value);
	};

	const handleNavigateToActivationPage = () => {
		history.replace(ROUTER_URL.RADABANK_CARD_INTERNAL_ACTIVATE);
	};

	const handleCloseBanner = () => {
		if (isClientExistInRB && !isSkipBanner) {
			handleNavigateToActivationPage();
			return;
		}
		handleAuthorize();
	};

	const handleAuthorize = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CREATE_CARD,
				isShowBanner ? FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE : FIREBASE_EVENT_ANALYTICS_BUTTON.RESEND_OTP
			);
			dispatch(setAppLoading(true));
			setIsShowBanner(false);
			const password = await StorageService.get<string>(STORAGE_KEY.USER_PASSWORD);
			const decryptedPasswordData = decryptData(password ?? '');

			if (!decryptedPasswordData || !password || !profile || !radabankInternalClient) {
				return history.replace(ROUTER_URL.ERROR);
			}

			if (radabankInternalClient.changepassword === RADABANK_BOOLEAN_VALUE.TRUE && !isSkipBanner) {
				await RadabankService.activateInternal({
					userId: profile.userId,
					passnew: decryptedPasswordData,
					passrenew: decryptedPasswordData,
					username: radabankInternalClient.username,
				});
			}
			await RadabankService.authorizeInternal({
				userId: profile.userId,
				username: radabankInternalClient.username,
				userpass: decryptedPasswordData,
			});
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CREATE_CARD,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			dispatch(setAppLoading(true));

			if (!profile) {
				return history.replace(ROUTER_URL.ERROR);
			}

			await RadabankService.verifyInternalSms({
				userId: (profile as IUserProfileResponse).userId,
				smsdata: otp,
			});
			await RadabankService.createCoBrandCard((profile as IUserProfileResponse).userId);
			history.replace(ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD_SUBMITION);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CREATE_CARD,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return isShowBanner ? (
		<div className="host-card-page-color-primary">
			<ArrowBackButton />
			<RadabankOnboardingContent
				title="Бачимо, що ви вже є клієнтом Банку"
				description={['Тоді вам буде дуже легко відкрити картку: просто натисніть кнопку внизу']}
			/>
			<CustomButton className={styles.button_next} label="Продовжити" color="secondary" onClick={handleCloseBanner} />
			<Typography id={styles.caption} color="var(--color-neutral-white)" textAlign="center" variant="caption">
				Натискаючи на кнопку &#34;Продовжити&#34;, ви погоджуєтесь з{' '}
				<Typography
					variant="caption"
					className={styles.link}
					component="a"
					target="_blank"
					rel="noreferrer"
					href={process.env.REACT_APP_RB_PUBLIC_AGREEMENT}
				>
					умовами Публічного договору
				</Typography>{' '}
				та підписуєте{' '}
				<Typography
					variant="caption"
					className={styles.link}
					component="a"
					target="_blank"
					rel="noreferrer"
					href={process.env.REACT_APP_RB_PUBLIC_AGREEMENT}
				>
					Заяву про відкриття рахунку
				</Typography>
			</Typography>
		</div>
	) : (
		<div className="host-card-page-color-white-auth">
			<ArrowBackButton onClick={handleGoBack} />
			<div className="auth-content">
				<ConfirmOTPCode
					title={RADABANK_OTP_TITLE}
					phone={profile?.phone ?? ''}
					value={otp}
					onChange={handleChangeOTP}
					isValid={isValid}
					onResendCode={handleAuthorize}
					length={RADABANK_OTP_LENGTH}
					onSubmit={handleSubmit}
				/>
			</div>
			<CustomButton label="Продовжити" onClick={handleSubmit} disabled={otp.length !== RADABANK_OTP_LENGTH} />
		</div>
	);
};
