import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import classNames from 'classnames';
import { useOpen } from 'utils/hooks';
import { InfoAnchorModal } from '../Modal';
import styles from './index.module.scss';

interface InfoAnchorProps {
	title: string;
	className?: string;
	titleClassName?: string;
}

export const InfoAnchor: FC<PropsWithChildren<InfoAnchorProps>> = ({ title, className, titleClassName, children }) => {
	const { isOpen, handleClose, handleOpen } = useOpen();

	const isWithModal = !!children;

	const handleOpenModal = () => {
		if (isWithModal) {
			handleOpen();
		}
	};

	return (
		<>
			<div className={classNames(styles.wrapper, className)}>
				<Typography className={titleClassName}>{title}</Typography>
				<InfoIcon onClick={handleOpenModal} />
			</div>
			{isWithModal && (
				<InfoAnchorModal isOpen={isOpen} handleClose={handleClose}>
					{children}
				</InfoAnchorModal>
			)}
		</>
	);
};
