// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddApartmentById_header__hgmou {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.AddApartmentById_header__hgmou .AddApartmentById_icon__CXYiL {
  margin-left: var(--spacing-1);
  margin-bottom: -5px;
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/components/Apartment/AddApartmentById/index.module.scss"],"names":[],"mappings":"AAEA;EACI,oBAAA;EACA,mBAAA;EACA,WAAA;AADJ;AAGI;EACI,6BAAA;EACA,mBAAA;EACA,eAAA;AADR","sourcesContent":["@import 'assets/styles/mixins.scss';\n\n.header {\n    display: inline-flex;\n    align-items: center;\n    width: 100%;\n\n    .icon {\n        margin-left: var(--spacing-1);\n        margin-bottom: -5px;\n        display: inline;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `AddApartmentById_header__hgmou`,
	"icon": `AddApartmentById_icon__CXYiL`
};
export default ___CSS_LOADER_EXPORT___;
