import { ComponentProps, FC } from 'react';
import { IonToggle, ToggleChangeEventDetail, ToggleCustomEvent } from '@ionic/react';
// Styles
import './styles.scss';

export interface CustomSwitchProps extends Omit<ComponentProps<typeof IonToggle>, 'onChange'> {
	onChange?: (e: ToggleCustomEvent<ToggleChangeEventDetail<boolean>>) => void;
}

const Switch: FC<CustomSwitchProps> = ({ onChange, ...props }) => (
	<IonToggle onIonChange={onChange} {...props} mode="md" />
);

export default Switch;
