import { FC, useEffect } from 'react';
import classNames from 'classnames';
import { getApartmentList } from 'store/bill/actions';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetMetersList, setMetersApartmentAccountId } from 'store/meters/reducer';
import { getMetersStateSelector } from 'store/meters/selectors';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { ChooseMetersApartment, MetersList } from 'components/Meters';
import { Navbar } from 'components/Navbar';
import { Loader } from 'components/shared';
import { getErrorMessage } from 'utils/helpers';
import styles from './index.module.scss';

export const MetersPage: FC = () => {
	const dispatch = useAppDispatch();

	const apartments = useAppSelector(getApartmentsSelector);
	const meters = useAppSelector(getMetersStateSelector);
	const profile = useAppSelector(getUserProfileSelector);

	useEffect(() => {
		return () => {
			dispatch(resetMetersList());
		};
	}, []);

	useEffect(() => {
		if (!apartments.list.length && profile) {
			getApartmentsList(profile.userId);
		} else {
			dispatch(setMetersApartmentAccountId(meters.apartmentAccountId ?? apartments.list[0].apartmentAccountId));
		}
	}, [apartments.list.length, profile]);

	const getApartmentsList = async (userId: string): Promise<void> => {
		try {
			await dispatch(getApartmentList(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return (
		<>
			<div className={classNames('page', 'page-scrollable')}>
				<div className={styles.content}>
					<ChooseMetersApartment />
					{!!meters.apartmentAccountId && <MetersList />}
				</div>
			</div>
			<Navbar />
			{(apartments.isLoading || meters.isLoading) && <Loader />}
		</>
	);
};
