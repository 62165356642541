import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import { ReactComponent as AddCircleSVG } from 'assets/icons/add-circle.svg';
import { ReactComponent as ReloadSVG } from 'assets/icons/reload.svg';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setMetersApartmentAccountId } from 'store/meters/reducer';
import { getMetersStateSelector } from 'store/meters/selectors';
import { CustomButton, MapMarker } from 'components/shared';
import { ROUTER_URL } from 'utils/enums';
import { getApartmentTitle } from 'utils/helpers';
import { useOpen } from 'utils/hooks';
import { SelectApartmentModal } from './SelectApartmentModal';
import styles from './index.module.scss';

export const ChooseMetersApartment: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const { isOpen, handleClose, handleOpen } = useOpen();

	const apartments = useAppSelector(getApartmentsSelector);
	const meters = useAppSelector(getMetersStateSelector);

	const [isSingleApartment, setIsSingleApartment] = useState(true);
	const [apartmentIndex, setApartmentIndex] = useState<number | null>(null);

	useEffect(() => {
		setIsSingleApartment(apartments.list.length <= 1);

		if (meters.apartmentAccountId) {
			setApartmentIndex(apartments.list.findIndex((item) => item.apartmentAccountId === meters.apartmentAccountId));
		}
	}, [apartments.list.length, meters.apartmentAccountId]);

	const handleClickButton = (): void => {
		if (isSingleApartment) {
			history.push(ROUTER_URL.ADD_ADDRESS);
		} else {
			handleOpen();
		}
	};

	const handleSetMetersApartmentAccountId = (apartmentAccountId: number) => {
		dispatch(setMetersApartmentAccountId(apartmentAccountId));
	};

	return (
		<div className={styles.wrapper}>
			{apartmentIndex !== null && !!apartments.list[apartmentIndex] && (
				<div className={styles.apartment}>
					<MapMarker index={apartmentIndex} isOnlyIcon />
					<Typography color="var(--color-neutral-500)" variant="body2">
						{getApartmentTitle(apartments.list[apartmentIndex])}
					</Typography>
				</div>
			)}
			<CustomButton
				fill="clear"
				label={isSingleApartment ? 'Додати помешкання' : 'Обрати інше помешкання'}
				startIcon={isSingleApartment ? <AddCircleSVG /> : <ReloadSVG />}
				onClick={handleClickButton}
			/>
			{!!meters.apartmentAccountId && (
				<SelectApartmentModal
					isOpen={isOpen}
					onClose={handleClose}
					onSubmit={handleSetMetersApartmentAccountId}
					apartmentAccountId={meters.apartmentAccountId}
				/>
			)}
		</div>
	);
};
