import { ComponentProps, FC } from 'react';
import { IonModal } from '@ionic/react';
import classNames from 'classnames';
import { useDevice } from 'utils/hooks';
import { IDefaultModalProps } from 'utils/types';
import { ModalHeader } from '../ModalHeader';

interface CustomModalProps extends ComponentProps<typeof IonModal>, IDefaultModalProps {
	isMobileView?: boolean;
	classNameHeader?: string;
	isFullHeight?: boolean;
	isCloseHeader?: boolean;
	title?: string;
}

export const CustomModal: FC<CustomModalProps> = ({
	children,
	onClose,
	title,
	breakpoints,
	initialBreakpoint,
	className,
	classNameHeader = '',
	isMobileView = true,
	isFullHeight = false,
	isCloseHeader = true,
	...props
}) => {
	const { isMobile } = useDevice();

	return (
		<IonModal
			{...props}
			onDidDismiss={onClose}
			// If we need to close a full height modal by swipe to bottom, we need change undefined to [1]
			// eslint-disable-next-line no-nested-ternary
			breakpoints={isMobile && isMobileView ? (isFullHeight ? undefined : breakpoints || [0, 1]) : undefined}
			initialBreakpoint={isMobile && isMobileView ? initialBreakpoint || 1 : undefined}
			className={classNames(className, {
				'full-height-modal': isFullHeight,
			})}
		>
			{!isCloseHeader ? (
				children
			) : (
				<div>
					<ModalHeader
						className={classNameHeader}
						title={title}
						isFullHeight={isMobile && isFullHeight}
						onClose={onClose}
					/>
					{children}
				</div>
			)}
		</IonModal>
	);
};
