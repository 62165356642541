import { Capacitor } from '@capacitor/core';
/* eslint-disable no-param-reassign */
import { Share } from '@capacitor/share';
import { PayloadAction } from '@reduxjs/toolkit';
import store from 'store/root';
import { EMPTY_CONFIRM_MODAL, MODAL_DATA } from 'utils/constants';
import { PLATFORMS } from 'utils/enums/ionic-platforms';
import { getErrorResponse } from 'utils/helpers';
import { FilesystemService } from 'utils/services';
import { IConfirmModal, IDocumentViewer, IModal, ISystemStore } from 'utils/types';
import { setAppLoading } from './reducer';

export const setAppLoadingOperation = (state: ISystemStore, { payload }: PayloadAction<boolean>) => {
	return {
		...state,
		isLoading: payload,
	};
};

export const openConfirmModalOperation = (state: ISystemStore, { payload }: PayloadAction<Partial<IConfirmModal>>) => {
	return {
		...state,
		confirmModal: { ...EMPTY_CONFIRM_MODAL, ...payload, isOpen: true },
	};
};

export const closeConfirmModalOperation = (state: ISystemStore) => {
	state.confirmModal.isOpen = false;
	if (state.confirmModal.onClose) {
		state.confirmModal.onClose();
	}
};

export const updateUserPlatformOperation = (state: ISystemStore, { payload }: PayloadAction<PLATFORMS>): void => {
	state.platform = payload;
};

export const updateIsAppUrlListenerInProgressOperation = (
	state: ISystemStore,
	{ payload }: PayloadAction<boolean>
): void => {
	state.isAppUrlListenerInProgress = payload;
};

export const updateIsNetworkConnectedOperation = (state: ISystemStore, { payload }: PayloadAction<boolean>): void => {
	state.isNetworkConnected = payload;
};

export const openModalOperation = (state: ISystemStore, { payload }: PayloadAction<Partial<IModal>>) => {
	return {
		...state,
		modal: { ...MODAL_DATA, ...payload, isOpen: true },
	};
};

export const updateModalIsPresentedOperation = (state: ISystemStore, { payload }: PayloadAction<boolean>) => {
	return {
		...state,
		modal: { ...state.modal, isPresented: payload },
	};
};

export const closeModalOperation = (state: ISystemStore) => {
	state.modal.isOpen = false;
	if (state.modal.onClose) {
		state.modal.onClose();
	}
};

export const openDocumentViewerOperation = (
	state: ISystemStore,
	{ payload }: PayloadAction<IDocumentViewer | undefined>
) => {
	const statePayload = {
		...state,
		documentViewer: {
			...state.documentViewer,
			...payload,
			isOpen: true,
			url: payload?.url ? encodeURI(payload.url) : payload?.url,
		},
	};
	if (payload?.isShareEnabled) {
		statePayload.documentViewer.handleShare = async () => {
			try {
				store.dispatch(setAppLoading(true));
				const { onClose, isShareEnabled, handleShare, fileName, isOpen, ...shareOptions } = statePayload.documentViewer;
				const isCanShare = await Share.canShare();
				if (Capacitor.isNativePlatform() && isCanShare.value) {
					await Share.share(shareOptions);
				} else {
					await FilesystemService.saveWeb(fileName, shareOptions.url as string);
				}
			} catch (error) {
				const errorResponse = getErrorResponse(error);
				if (errorResponse?.message === 'Share canceled') return;
				throw new Error(error);
			} finally {
				store.dispatch(setAppLoading(false));
			}
		};
	}
	return statePayload;
};

export const closeDocumentViewerOperation = (state: ISystemStore) => {
	state.documentViewer.isOpen = false;
	state.documentViewer.openAsUrl = false;
	state.documentViewer.url = '';
	state.documentViewer.fileName = '';
	if (state.documentViewer.onClose) {
		state.documentViewer.onClose();
	}
};

export const resetDocumentViewerOperation = (state: ISystemStore) => {
	state.documentViewer = { isOpen: false, fileName: '' };
};
