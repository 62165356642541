import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import {
	RadabankCardCountryRuleForm,
	RadabankCardCurrencyRuleForm,
	RadabankCardInternetRuleForm,
	RadabankCardSecureSettingsRulesList,
	RadabankCardSecureSettingsTabs,
	RadabankOnboardingContent,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_ADD_RULE_INITIAL_VALUES,
} from 'utils/constants';
import { RADABANK_SECURE_SETTINGS_TABS, ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankInternalAddRuleRequest } from 'utils/types';
import styles from './index.module.scss';

export const RadabankCardSecureSettingsPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [activeTab, setActiveTab] = useState(RADABANK_SECURE_SETTINGS_TABS.LIMITS);
	const [initialValues, setInitialValues] = useState(RADABANK_ADD_RULE_INITIAL_VALUES);
	const [isShowBanner, setIsShowBanner] = useState(false);
	const [bannerTitle, setBannerTitle] = useState('');

	useEffect(() => {
		if (currentCard && profile) {
			setInitialValues({ ...RADABANK_ADD_RULE_INITIAL_VALUES, userId: profile.userId, cardid: currentCard.id });
		}
	}, [currentCard, profile, activeTab]);

	const handleAddRule = async (values: IRadabankInternalAddRuleRequest) => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SECURE_SETTINGS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.ADD_RULE
			);
			await RadabankService.addCurrentRule({
				...values,
				datestart: dayjs(values.datestart).format('DD.MM.YYYY'),
				datefinish: dayjs(values.datefinish).format('DD.MM.YYYY'),
			});
			setIsShowBanner(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const content = useMemo(() => {
		let component = null;
		let title = '';
		let bannerTitle = '';
		switch (activeTab) {
			case RADABANK_SECURE_SETTINGS_TABS.LIMITS:
				title = 'Діючі дозволи для картки';
				component = <RadabankCardSecureSettingsRulesList />;
				break;

			case RADABANK_SECURE_SETTINGS_TABS.INTERNET:
				title = 'Платежі в інтернеті';
				bannerTitle = 'Заявку на виконання платежів в інтернеті надіслано';
				component = <RadabankCardInternetRuleForm initialValues={initialValues} onSubmit={handleAddRule} />;
				break;

			case RADABANK_SECURE_SETTINGS_TABS.COUNTRY:
				title = 'Платежі в країні';
				bannerTitle = 'Заявку на виконання платежів в країні надіслано';
				component = <RadabankCardCountryRuleForm initialValues={initialValues} onSubmit={handleAddRule} />;
				break;

			case RADABANK_SECURE_SETTINGS_TABS.CURRENCY:
				title = 'Платежі в валюті';
				bannerTitle = 'Заявку на виконання платежів в валюті надіслано';
				component = <RadabankCardCurrencyRuleForm initialValues={initialValues} onSubmit={handleAddRule} />;
				break;

			default:
				break;
		}
		setBannerTitle(bannerTitle);
		return (
			<div className={styles.content}>
				<Typography variant="h5" margin="var(--spacing-6) 0">
					{title}
				</Typography>
				{component}
			</div>
		);
	}, [activeTab, initialValues]);

	const handleClickRedirectMain = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SECURE_SETTINGS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_HOME
		);
		history.push(ROUTER_URL.RADABANK_CARD);
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SECURE_SETTINGS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	if (!currentCard) return <Navbar />;
	if (isShowBanner) {
		return (
			<div className="host-card-page-color-primary">
				<ArrowBackButton onClick={handleGoBack} color="white" />
				<RadabankOnboardingContent title={bannerTitle} />
				<CustomButton label="На головну" color="secondary" onClick={handleClickRedirectMain} />
			</div>
		);
	}
	return (
		<div className={classNames('page', styles.page)}>
			<RadabankSectionHeader title="Налаштування безпеки" onClickBack={handleGoBack} />
			<RadabankCardSecureSettingsTabs value={activeTab} onChange={(value) => setActiveTab(value)} />
			{content}
		</div>
	);
};
