import { FC } from 'react';
import { useHistory } from 'react-router';
import {
	createRadabankTransferPayment,
	getRadabankCurrentCardSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	RadabankOperationDetailInfo,
	RadabankOperationDetailRow,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { RadabankOperationFeeDetails } from 'components/RadabankCard/OperationFeeDetails';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { checkIsRadabankIban, convertUAHToCoins, getAmountTitle, getErrorMessage } from 'utils/helpers';
import { useAndroidBackButton } from 'utils/hooks/use-close-on-back-button';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankTransferCreatePaymentRequest, IRadabankTransferFeeResponse } from 'utils/types';
import styles from './index.module.scss';

interface OperationDetailsProps {
	feeData: IRadabankTransferFeeResponse;
	transferPaymentData: IRadabankTransferCreatePaymentRequest;
	onClickBack: () => void;
}

export const OperationDetails: FC<OperationDetailsProps> = ({ feeData, transferPaymentData, onClickBack }) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const handleCreatePayment = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_ACCOUNT_DETAILS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE_PAYMENT
			);
			dispatch(setAppLoading(true));
			await dispatch(
				createRadabankTransferPayment({
					...transferPaymentData,
					amount: `${convertUAHToCoins(transferPaymentData.amount)}`,
				})
			).unwrap();
			history.push(ROUTER_URL.RADABANK_CARD_OPERATION_BANNER);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_ACCOUNT_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		onClickBack();
	};

	useAndroidBackButton(handleGoBack);

	const isRadabankClient = checkIsRadabankIban(transferPaymentData.ibanrec);

	return (
		<div className="host-card-page-color-white">
			<RadabankSectionHeader title="На інші картки або рахунки" onClickBack={handleGoBack} />
			<div className={styles.content}>
				<RadabankOperationDetailRow title="IBAN отримувача" description={transferPaymentData.ibanrec} />
				{!!transferPaymentData.innrec && (
					<RadabankOperationDetailRow title="ЄДРПОУ або ІПН" description={transferPaymentData.innrec} />
				)}
				{!!transferPaymentData.docnumberrec && (
					<RadabankOperationDetailRow
						title={`${transferPaymentData.docseriarec ? 'Серія та номер' : 'Номер'} паспорта`}
						description={`${transferPaymentData.docseriarec}${transferPaymentData.docnumberrec}`}
					/>
				)}
				<RadabankOperationDetailRow title="Отримувач" description={feeData.message1 || transferPaymentData.namerec} />
				{!!transferPaymentData.comment && (
					<RadabankOperationDetailRow title="Коментар" description={transferPaymentData.comment} />
				)}
				{cardData && (
					<RadabankOperationDetailRow
						title="Сума"
						description={getAmountTitle(transferPaymentData.amount, cardData.curriso)}
					/>
				)}
				<RadabankOperationDetailRow title="Призначення" description={transferPaymentData.destination} />
				{cardData && (
					<RadabankOperationFeeDetails
						fee={feeData.clientfee}
						currency={cardData.curriso}
						isShowInfo={!!feeData.message2}
					/>
				)}
			</div>
			{!!feeData.message2 && !isRadabankClient && <RadabankOperationDetailInfo description={feeData.message2} />}
			<CustomButton label="Сплатити" onClick={handleCreatePayment} />
		</div>
	);
};
