import { AxiosResponse } from 'axios';
import { USER_API } from 'core/api';
import { CHECK_RESPONSE, USER_API_URL } from 'utils/enums';
import {
	ICardDeleteRequest,
	ICardListRequest,
	ICardListResponse,
	ICardRequest,
	ICardRequestResponse,
} from 'utils/types';

class CardService {
	static createRequest = async (reqBody: ICardRequest): Promise<AxiosResponse<ICardRequestResponse>> => {
		return USER_API.post(USER_API_URL.CARD_REQUEST, {
			...reqBody,
			verification: CHECK_RESPONSE.YES,
			VTS: CHECK_RESPONSE.NO,
			createUserIfNotExists: CHECK_RESPONSE.YES,
		});
	};

	static getList = async (reqBody: ICardListRequest): Promise<AxiosResponse<ICardListResponse>> => {
		return USER_API.post(USER_API_URL.CARD_LIST, reqBody);
	};

	static deleteCard = async (reqBody: ICardDeleteRequest): Promise<AxiosResponse<ICardListResponse>> => {
		return USER_API.post(USER_API_URL.CARD_DELETE, reqBody);
	};
}

export { CardService };
