import { IBillsListItemResponse, IBillsListRequest, IPaginatedRequest, IPaginatedResponse } from 'utils/types';

export const DEFAULT_BILLS_LIST_DATA = {
	listData: {
		showedFrom: 0,
		totalRows: 0,
		showedTo: null,
	},
};

export const DEFAULT_PAGINATED_BILLS_LIST_DATA: IPaginatedResponse<IBillsListItemResponse> = {
	...DEFAULT_BILLS_LIST_DATA,
	tableData: [],
};

export const DEFAULT_PAGINATED_BILLS_LIST_REQUEST_BODY: IPaginatedRequest<IBillsListRequest> = {
	offset: 0,
	limit: 12,
	options: {},
};
