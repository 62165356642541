// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.PaymentStatusPublic_wrapper__IRszR {
  max-width: 1326px;
  width: 100%;
  display: grid;
  grid-template-columns: 43.5% 1fr;
  grid-template-rows: 100%;
  gap: var(--spacing-9);
  align-items: flex-start;
  overflow: hidden;
  padding-bottom: var(--spacing-9);
}
@media (max-width: 1024px) {
  div.PaymentStatusPublic_wrapper__IRszR {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: var(--spacing-7);
  }
  div.PaymentStatusPublic_wrapper__IRszR .PaymentStatusPublic_banner__yOpUR {
    display: grid;
  }
}
@media screen and (max-width: 1024px) and (max-height: 620px) {
  div.PaymentStatusPublic_wrapper__IRszR .PaymentStatusPublic_banner__yOpUR {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/PaymentStatusPublic/index.module.scss","webpack://./src/assets/styles/media.scss"],"names":[],"mappings":"AAGA;EACC,iBAAA;EACA,WAAA;EACA,aAAA;EACA,gCAAA;EACA,wBAAA;EACA,qBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gCAAA;AAFD;ACGC;EDVD;IAYE,0BAAA;IACA,4BAAA;IACA,qBAAA;EADA;EAGA;IACC,aAAA;EADD;AACF;AAGE;EACC;IACC,aAAA;EADF;AACF","sourcesContent":["@import 'assets/styles/mixins.scss';\n@import 'assets/styles/media.scss';\n\ndiv.wrapper {\n\tmax-width: 1326px;\n\twidth: 100%;\n\tdisplay: grid;\n\tgrid-template-columns: 43.5% 1fr;\n\tgrid-template-rows: 100%;\n\tgap: var(--spacing-9);\n\talign-items: flex-start;\n\toverflow: hidden;\n\tpadding-bottom: var(--spacing-9);\n\n\t@include mobile {\n\t\tgrid-template-columns: 1fr;\n\t\tgrid-template-rows: 1fr auto;\n\t\tgap: var(--spacing-7);\n\n\t\t.banner {\n\t\t\tdisplay: grid;\n\t\t}\n\n\t\t@media screen and (max-height: 620px) {\n\t\t\t.banner {\n\t\t\t\tdisplay: none;\n\t\t\t}\n\t\t}\n\t}\n}\n","@mixin tablet {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin desktop {\n\t@media (min-width: 1025px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-md {\n\t@media (max-width:374px) {\n\t\t@content\n\t}\n}\n\n@mixin mobile-small {\n\t@media (max-width: 319px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-small-height {\n\t@media (max-height: 668px) {\n\t\t@content;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PaymentStatusPublic_wrapper__IRszR`,
	"banner": `PaymentStatusPublic_banner__yOpUR`
};
export default ___CSS_LOADER_EXPORT___;
