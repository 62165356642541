import { FC, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { Typography } from '@mui/material';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import classNames from 'classnames';
import { PasswordSetupPage } from 'pages/Auth';
import { getRadabankCardExistsSelector } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { updateUserProfile } from 'store/user-service/actions';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { UpdatePasswordNative } from 'components/Auth';
import { CustomButton, CustomModal } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import {
	CHECK_RESPONSE,
	EDIT_USER_FIRST_NAME,
	EDIT_USER_LAST_NAME,
	STORAGE_KEY,
	USER_PROFILE_FIELD_NAME,
} from 'utils/enums';
import { getErrorMessage, getMaskedPhone } from 'utils/helpers';
import { useDevice, useOpen } from 'utils/hooks';
import { FirebaseAnalytics, StorageService } from 'utils/services';
import { IUserUpdateProfileRequest, TypeOrNull } from 'utils/types';
import { CardItem } from '../CardItem';
import { ConfirmedDataPreview } from '../ConfirmedDataPreview';
import { EditEmailModalContent } from './EditEmailModalContent';
import { EditModalContent } from './EditModalContent';
import styles from './index.module.scss';

export const UserProfile: FC = () => {
	const dispatch = useAppDispatch();

	const { search, pathname } = useLocation();

	const history = useHistory();

	const initialOpenStep = new URLSearchParams(search).get('updateProfile') as TypeOrNull<USER_PROFILE_FIELD_NAME>;

	const isUserHasRadabankCard = useAppSelector(getRadabankCardExistsSelector);

	const { isOpen, handleOpen, handleClose, handleDidPresent, isPresented } = useOpen(!!initialOpenStep);

	const { isMobile } = useDevice();

	const userProfile = useAppSelector(getUserProfileSelector);

	const [selectedField, setSelectedField] = useState<USER_PROFILE_FIELD_NAME>(() => {
		const updatedFieldsValues = Object.values(USER_PROFILE_FIELD_NAME);

		return initialOpenStep && updatedFieldsValues.includes(initialOpenStep)
			? initialOpenStep
			: USER_PROFILE_FIELD_NAME.NAME;
	});
	const [isLoading, setIsLoading] = useState(false);

	const handleSelectField = (fieldName: USER_PROFILE_FIELD_NAME) => {
		setSelectedField(fieldName);
		handleOpen();
	};

	const handleCloseModal = () => {
		StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);
		if (initialOpenStep) {
			history.replace(pathname);
			return;
		}
		handleClose();
	};

	const onSubmitUpdateProfile = async (data: IUserUpdateProfileRequest) => {
		try {
			setIsLoading(true);
			await dispatch(updateUserProfile(data)).unwrap();
			handleClose();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleClickChangePassword = () => {
		handleSelectField(USER_PROFILE_FIELD_NAME.CHANGE);
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.CHANGE_PASSWORD
		);
	};

	const content = useMemo(() => {
		if (userProfile) {
			switch (selectedField) {
				case USER_PROFILE_FIELD_NAME.NAME:
					return (
						<EditModalContent
							{...EDIT_USER_FIRST_NAME}
							defaultValue={userProfile?.firstName || ''}
							onSubmit={onSubmitUpdateProfile}
							isLoading={isLoading}
							isOpenModal={isPresented}
						/>
					);

				case USER_PROFILE_FIELD_NAME.SURNAME:
					return (
						<EditModalContent
							{...EDIT_USER_LAST_NAME}
							defaultValue={userProfile?.lastName || ''}
							onSubmit={onSubmitUpdateProfile}
							isLoading={isLoading}
							isOpenModal={isPresented}
						/>
					);

				case USER_PROFILE_FIELD_NAME.EMAIL:
					return (
						<EditEmailModalContent
							defaultValue={userProfile.email || ''}
							onClose={handleClose}
							isConfirmed={userProfile.isEmailConfirmed === CHECK_RESPONSE.YES}
							isOpenModal={isPresented}
						/>
					);

				case USER_PROFILE_FIELD_NAME.CHANGE:
					return Capacitor.isNativePlatform() ? (
						<UpdatePasswordNative onClose={handleClose} />
					) : (
						<PasswordSetupPage
							isUpdatePassword
							isShowButton={!isMobile}
							handleClose={handleClose}
							pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS}
						/>
					);

				default:
					break;
			}
		}
	}, [selectedField, userProfile, isLoading, isPresented]);

	const isDisabledPasswordChange = !Capacitor.isNativePlatform() && isUserHasRadabankCard;

	const isChangePasswordModalOpen = Capacitor.isNativePlatform() && selectedField !== USER_PROFILE_FIELD_NAME.CHANGE

	if (!userProfile) return;

	return (
		<>
			<CardItem label="Ім'я" onClick={() => handleSelectField(USER_PROFILE_FIELD_NAME.NAME)}>
				<Typography variant="body2" color="--color-neutral-500" overflow="hidden" textOverflow="ellipsis">
					{userProfile.firstName}
				</Typography>
			</CardItem>
			<CardItem label="Прізвище" onClick={() => handleSelectField(USER_PROFILE_FIELD_NAME.SURNAME)}>
				<Typography variant="body2" color="--color-neutral-500" overflow="hidden" textOverflow="ellipsis">
					{userProfile.lastName}
				</Typography>
			</CardItem>
			<CardItem label="Email" onClick={() => handleSelectField(USER_PROFILE_FIELD_NAME.EMAIL)}>
				{userProfile.email ? (
					<ConfirmedDataPreview title={userProfile.email} data={userProfile.isEmailConfirmed} />
				) : (
					<CustomButton
						label="Додати email адресу"
						fill="clear"
						onClick={() => handleSelectField(USER_PROFILE_FIELD_NAME.EMAIL)}
					/>
				)}
			</CardItem>
			<CardItem label="Телефон">
				<ConfirmedDataPreview title={getMaskedPhone(userProfile.phone)} data={userProfile.isPhoneConfirmed} />
			</CardItem>

			<CardItem
				className={classNames({ [styles.pinWeb]: isDisabledPasswordChange })}
				actionClassName={classNames({ [styles.pinWeb__text]: isDisabledPasswordChange })}
				label="PIN код"
				icon={<Lock />}
			>
				{!isDisabledPasswordChange ? (
					<CustomButton label="Змінити" fill="clear" onClick={handleClickChangePassword} />
				) : (
					'Зміна пароля з метою безпеки доступна лише у мобільному додатку'
				)}
			</CardItem>

			<CustomModal
				isOpen={isOpen}
				onClose={handleCloseModal}
				onDidPresent={handleDidPresent}
				isFullHeight
				isCloseHeader={isChangePasswordModalOpen}
				animated={initialOpenStep ? !isOpen : true}
			>
				<div className={styles.content}>{content}</div>
			</CustomModal>
		</>
	);
};
