import { Camera, PermissionStatus } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { FaceDetectorAndroid } from 'face-detector-android';

class FaceDetectorService {
	static getPhoto = async () => {
		if (Capacitor.getPlatform() !== 'web') {
			const result: PermissionStatus = await Camera.requestPermissions({ permissions: ['camera'] });
			if (result.camera === 'granted') {
				const result = await FaceDetectorAndroid.echo({ value: '' });
				return result.value;
			}
		}
	};
}

export { FaceDetectorService };
