import { FC, useMemo } from 'react';
import { TextField } from '@mui/material';
import { getServiceInputConfig } from 'utils/helpers';
import {
	IBillsSubBillItem,
	IGetServiceDataResponse,
	IProviderServiceResponse,
	IReceiptOtherServiceResponse,
} from 'utils/types';
import { ServiceFieldItem } from '../ServiceFieldItem';
import styles from './index.module.scss';

interface ProviderServiceDetailsProps {
	data: IReceiptOtherServiceResponse | IProviderServiceResponse | IBillsSubBillItem | IGetServiceDataResponse;
	providerAccountId?: string;
	amount?: string;
}

export const ProviderServiceDetails: FC<ProviderServiceDetailsProps> = ({ data, providerAccountId, amount }) => {
	const content = useMemo(() => {
		const regDataObject =
			(data as IReceiptOtherServiceResponse)?.regDataObject ?? (data as IBillsSubBillItem)?.service?.regDataObject;
		if (regDataObject) {
			return (
				<>
					{(data as IReceiptOtherServiceResponse).serviceFieldsValues?.length ? (
						(data as IReceiptOtherServiceResponse).serviceFieldsValues.map((item) => (
							<ServiceFieldItem
								{...getServiceInputConfig(item, (data as IReceiptOtherServiceResponse).serviceFields)}
								value={item.value}
								key={item.name}
								data={item}
								fieldConfig={(data as IReceiptOtherServiceResponse).serviceFields?.find(
									(fieldConfig) => item.name === fieldConfig.name
								)}
								size="small"
								InputProps={{ readOnly: true }}
							/>
						))
					) : (
						<>
							{!!amount && <TextField size="small" label="Сума" value={amount} InputProps={{ readOnly: true }} />}
							{!!(providerAccountId || (data as IBillsSubBillItem)?.service?.providerAccountId) && (
								<TextField
									size="small"
									label="Номер договору"
									value={providerAccountId || (data as IBillsSubBillItem)?.service?.providerAccountId}
									InputProps={{ readOnly: true }}
								/>
							)}
						</>
					)}
					<TextField size="small" label="ПІБ (повністю)" value={regDataObject.name} InputProps={{ readOnly: true }} />
					<TextField
						size="small"
						label="Розрахунковий рахунок"
						value={regDataObject.account}
						InputProps={{ readOnly: true }}
					/>
					<TextField
						size="small"
						label="ЄДРПОУ отримувача"
						value={regDataObject.zkpo}
						InputProps={{ readOnly: true }}
					/>
					<TextField
						size="small"
						label="Призначення"
						value={regDataObject.description}
						InputProps={{ readOnly: true }}
					/>
				</>
			);
		}

		return null;
	}, [data]);

	return <div className={styles.list}>{content}</div>;
};
