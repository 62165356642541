import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { BarcodeScanner as CapacitorBarcodeScanner, PermissionStatus } from '@capacitor-mlkit/barcode-scanning';
import {
	GoogleBarcodeScannerModuleInstallProgressEvent,
	GoogleBarcodeScannerModuleInstallState,
} from '@capacitor-mlkit/barcode-scanning/dist/esm/definitions';
import { Typography } from '@mui/material';
import { ReactComponent as ScanIcon } from 'assets/icons/scan.svg';
import { useAppDispatch } from 'store/hooks';
import { setAppLoading } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getReceiptPayRedirectUrl } from 'utils/helpers';
import { useDevice } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

export const BarcodeScanner: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const { isAndroid } = useDevice();

	useEffect(() => {
		CapacitorBarcodeScanner.addListener('googleBarcodeScannerModuleInstallProgress', handleInstallProgress);
	}, []);

	const stopScan = async () => {
		await CapacitorBarcodeScanner.stopScan();
		document.body.classList.remove('scanner-active');
	};

	const handleInstallProgress = async (event: GoogleBarcodeScannerModuleInstallProgressEvent): Promise<void> => {
		if (event.state === GoogleBarcodeScannerModuleInstallState.COMPLETED) {
			dispatch(setAppLoading(false));
			await CapacitorBarcodeScanner.removeAllListeners();
			await handleScan();
		}
	};

	const handleClickScanner = async (): Promise<void> => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.HOME,
			FIREBASE_EVENT_ANALYTICS_BUTTON.OPEN_QR,
		)
		const { camera }: PermissionStatus = await CapacitorBarcodeScanner.checkPermissions();
		if (camera === 'granted' || camera === 'limited') {
			if (isAndroid) {
				const isGoogleBarcode = await CapacitorBarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
				if (!isGoogleBarcode.available) {
					dispatch(setAppLoading(true));
					await CapacitorBarcodeScanner.installGoogleBarcodeScannerModule();
					return;
				}
			}

			await handleScan();
		} else if (camera !== 'denied'){
			history.push(ROUTER_URL.CAMERA_ACCESS_BANNER);
		}
	};

	const handleScan = async (): Promise<void> => {
		try {
			document.body.classList.add('scanner-active');
			const { barcodes } = await CapacitorBarcodeScanner.scan();
			const content = barcodes[0]?.displayValue;
			if (content && content.includes(process.env.REACT_APP_URL as string)) {
				const queryParams = content.split('?')[1] as string;
				const url = await getReceiptPayRedirectUrl(queryParams);
				handleRedirect(url, queryParams);
			} else {
				dispatch(showToast({ message: 'Відскануйте код єдиної квитанції' }));
			}
		} finally {
			await stopScan();
		}
	};

	const handleRedirect = (redirectUrl: string, query?: string): void => {
		history.replace({
			pathname: redirectUrl,
			search: query,
		});
	};

	return (
		<div className={styles.wrapper} onClick={handleClickScanner}>
			<ScanIcon />
			<Typography variant="subtitle2" color="var(--color-primary-800)">
				QR Сканер
			</Typography>
		</div>
	);
};
