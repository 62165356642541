/* eslint-disable max-len */
import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { getUserProfileSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { RadabankCardActionButton, RadabankSectionHeader } from 'components/RadabankCard';
import { SearchModalContent } from 'components/shared';
// import { RADABANK_NPOSHTA_CREATE_REQUEST_INITIAL_VALUES } from 'utils/constants';
import { getErrorMessage, getTextFieldValidationProps } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import {
	IRadabankNPoshtaCreateRequest,
	IRadabankNPoshtaInternalCityItem,
	// IRadabankNPoshtaInternalStreetItem,
	ISelectOption,
	IUserProfileResponse,
} from 'utils/types';
import styles from './index.module.scss';

export const RadabankOrderPlasticCardAddressFormContent: FC = () => {
	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);

	const { values, setValues, errors, touched, setTouched } = useFormikContext<IRadabankNPoshtaCreateRequest>();

	const [citiesOptionsList, setCitiesOptionsList] = useState<ISelectOption<IRadabankNPoshtaInternalCityItem>[]>([]);
	// const [streetsOptionsList, setStreetsOptionsList] = useState<ISelectOption<IRadabankNPoshtaInternalStreetItem>[]>([]);
	const [isLoadingOptions, setIsLoadingOptions] = useState(false);
	const [isShowSearchCity, setIsShowSearchCity] = useState(false);
	// const [isShowSearchStreet, setIsShowSearchStreet] = useState(false);

	const handleSelectCity = (value: IRadabankNPoshtaInternalCityItem) => {
		// const { streetref, streetpresent, streetcityref, streetdescription, streettype, streettypedescription } =
		// 	RADABANK_NPOSHTA_CREATE_REQUEST_INITIAL_VALUES;
		setTouched({ ...touched, cityref: true });
		setValues({
			...values,
			cityref: value.ref,
			citydeliveryref: value.deliveryref,
			citypresent: value.present,
			cityarea: value.area,
			cityregion: value.region,
			citytypecode: value.typecode,
			citydescription: value.description,
			divref: '',
		}, true);
		setIsShowSearchCity(false);
		// setStreetsOptionsList([]);
		// setIsShowSearchStreet(true);
	};

	// const handleSelectStreet = async (value: IRadabankNPoshtaInternalStreetItem) => {
	// 	await setValues({
	// 		...values,
	// 		streetref: value.ref,
	// 		streetpresent: value.present,
	// 		streetcityref: value.cityref,
	// 		streetdescription: value.description,
	// 		streettype: value.type,
	// 		streettypedescription: value.description,
	// 	});
	// 	await setTouched({ ...touched, streetref: true });
	// 	setIsShowSearchCity(false);
	// 	setIsShowSearchStreet(false);
	// };

	const handleGetInternalCities = async (value: string) => {
		try {
			setIsLoadingOptions(true);
			const response = await RadabankService.getNPoshtaInternalCity({
				userId: (profile as IUserProfileResponse).userId,
				findtext: value,
			});
			setCitiesOptionsList(response.data.cities.map((item) => ({ label: item.present, value: item })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoadingOptions(false);
		}
	};

	// const handleGetInternalStreets = async (value: string) => {
	// 	try {
	// 		setIsLoadingOptions(true);
	// 		const response = await RadabankService.getNPoshtaInternalStreet({
	// 			userId: (profile as IUserProfileResponse).userId,
	// 			findtext: value,
	// 			city: values.cityref,
	// 		});
	// 		setStreetsOptionsList(response.data.streets.map((item) => ({ label: item.present, value: item })));
	// 	} catch (error) {
	// 		dispatch(showToast({ message: getErrorMessage(error) }));
	// 	} finally {
	// 		setIsLoadingOptions(false);
	// 	}
	// };

	const handleClickBackSearchModal = async () => {
		// if (isShowSearchStreet) {
		// 	await setTouched({ ...touched, streetref: true });
		// 	setIsShowSearchStreet(false);
		// } else {
		// 	setIsShowSearchCity(false);
		// }
		setTouched({ ...touched, cityref: true });
		setIsShowSearchCity(false);
	};

	return (
		<>
			<div className={styles.content}>
				<Typography variant="subtitle1" textAlign="center">
					Адреса
				</Typography>
				<RadabankCardActionButton
					labelDefault="Оберіть місто"
					labelMain={values.citydescription}
					// labelSecondary={values.streetpresent}
					onClick={() => setIsShowSearchCity(true)}
					color={
						getTextFieldValidationProps(errors, touched, 'cityref').error
							?
							// ||
							  // getTextFieldValidationProps(errors, touched, 'streetref').error
							  'error'
							: undefined
					}
				/>
				{/* {!getTextFieldValidationProps(errors, touched, 'cityref').error &&
					getTextFieldValidationProps(errors, touched, 'streetref').error && (
						<FormHelperText error>Оберіть вулицю</FormHelperText>
					)} */}
			</div>
			{isShowSearchCity && (
				<div className={classNames('host-card-page-color-white-auth', styles.search)}>
					<RadabankSectionHeader onClickBack={handleClickBackSearchModal} title="Обрати місто" />
					<SearchModalContent
						onChangeInput={handleGetInternalCities}
						options={citiesOptionsList}
						onSelect={handleSelectCity}
						searchValue={values.citypresent}
						isLoading={isLoadingOptions}
					/>
				</div>
			)}
			{/* {(isShowSearchCity || isShowSearchStreet) && (
				<div className={classNames('host-card-page-color-white-auth', styles.search)}>
					<RadabankSectionHeader
						onClickBack={handleClickBackSearchModal}
						title={isShowSearchStreet ? 'Обрати вулицю' : 'Обрати місто'}
					/>
					{isShowSearchStreet ? (
						<SearchModalContent
							onChangeInput={handleGetInternalStreets}
							options={streetsOptionsList}
							onSelect={handleSelectStreet}
							searchValue={values.streetpresent}
							isLoading={isLoadingOptions}
						/>
					) : (
						<SearchModalContent
							onChangeInput={handleGetInternalCities}
							options={citiesOptionsList}
							onSelect={handleSelectCity}
							searchValue={values.citypresent}
							isLoading={isLoadingOptions}
						/>
					)}
				</div>
			)} */}
		</>
	);
};
