import { StoreType } from 'store/root';
import { ROLE } from 'utils/enums';
import { IUserProfileResponse, IUserReducer } from 'utils/types';

export const getUserProfileSelector = (store: StoreType): IUserProfileResponse | null => {
	return store.userStore.profile;
};

export const getUserIdSelector = (store: StoreType): string | undefined => store.userStore.profile?.userId;

export const getUserIsLoadingSelector = (store: StoreType): boolean => store.userStore.isLoading;

export const getUserReducerSelector = (store: StoreType): IUserReducer => store.userStore;

export const getUserAvatarSelector = (store: StoreType): string | null => store.userStore.avatarUser;

export const getIsUserHasFullAccessSelector = (store: StoreType): boolean =>
	!store.userStore.roles.some((item) => item.name === ROLE.TEST_USER);

export const getIsPumbUserAccessSelector = (store: StoreType): boolean =>
	!store.userStore.roles.some((item) => item.name === ROLE.PUMB);
