import { FormikProps } from 'formik';
import { IUserRequest } from 'utils/types';

export enum PinScreenMobileVariantsType {
	Set = 'set-screen',
	SetNewPassword = 'set-new-password-screen',
	CheckCurrentPassword = 'check-user-password-screen',
	Confirm = 'confirm-screen',
	ConfirmNewPassword = 'confirm-new-password-screen',
	WrongConfirm = 'wrong-confirm-screen',
	RegisteredSet = 'registered-set-screen',
	RegisteredWrong = 'registered-wrong-screen',
	Verify = 'verify-screen',
	VerifyWrong = 'verify-wrong-screen',
	SetRadabankPin = 'set-radabank-pin',
}

export enum TextHandlePassword {
	Confirm = 'Підтвердіть свій пін-код для захисту свого облікового запису',
	Set = 'Додайте PIN код для захисту свого облікового запису',
}

export interface PinScreenMobilePropsType {
	formik: FormikProps<Required<IUserRequest>>;
	variant: PinScreenMobileVariantsType;
	isPINEntered: boolean;
	isError: boolean;
	forgotPINClick?: () => void;
	enterPINEmiter: (value: string) => void;
	confirmPINEmiter: (value: string) => void;
}

export const pinCodeMobileScreenText = {
	[PinScreenMobileVariantsType.Set]: {
		header: 'Встановіть PIN-код',
		text: 'Додайте PIN-код для захисту свого облікового запису',
	},
	[PinScreenMobileVariantsType.Confirm]: {
		header: 'Повторіть PIN-код',
		text: 'Підтвердіть свій пін-код для захисту свого облікового запису',
	},
	[PinScreenMobileVariantsType.WrongConfirm]: {
		header: 'Невірний PIN-код',
		text: 'Підтвердіть свій пін-код для захисту свого облікового запису',
	},
	[PinScreenMobileVariantsType.RegisteredSet]: {
		header: 'Введіть PIN-код',
		text: 'Забули ваш PIN-код?',
	},
	[PinScreenMobileVariantsType.RegisteredWrong]: {
		header: 'Невірний PIN-код',
		text: 'Забули ваш PIN-код?',
	},
	[PinScreenMobileVariantsType.Verify]: {
		header: 'Введіть PIN-код',
		text: 'Забули ваш PIN-код?',
	},
	[PinScreenMobileVariantsType.VerifyWrong]: {
		header: 'Невірний PIN-код',
		text: 'Забули ваш PIN-код?',
	},
	[PinScreenMobileVariantsType.SetNewPassword]: {
		header: 'Введіть новий PIN-код',
		text: 'Додайте PIN-код для захисту свого облікового запису',
	},
	[PinScreenMobileVariantsType.CheckCurrentPassword]: {
		header: 'Введіть поточний PIN-код',
		text: 'Додайте PIN-код для захисту свого облікового запису',
	},
	[PinScreenMobileVariantsType.ConfirmNewPassword]: {
		header: 'Підтвердіть новий PIN-код',
		text: 'Додайте PIN-код для захисту свого облікового запису',
	},
	[PinScreenMobileVariantsType.SetRadabankPin]: {
		header: '',
		text: 'Придумайте новий PIN-код',
	},
};

export const helperTextExceptions = [
	PinScreenMobileVariantsType.RegisteredWrong,
	PinScreenMobileVariantsType.RegisteredSet,
	PinScreenMobileVariantsType.VerifyWrong,
	PinScreenMobileVariantsType.Verify,
];
