import { FC, MouseEvent } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import classNames from 'classnames';
import { useGoBack } from 'utils/hooks';
import styles from './index.module.scss';

interface ArrowBackButtonProps extends Omit<IconButtonProps, 'color'> {
	stepCount?: number;
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
	className?: string;
	color?: 'white' | 'black';
}

export const ArrowBackButton: FC<ArrowBackButtonProps> = ({
	stepCount,
	onClick,
	className,
	color = 'black',
	...rest
}) => {
	const handleCLickBack = useGoBack(stepCount);

	const onButtonClick = (event: MouseEvent<HTMLButtonElement>): void => {
		if (onClick) {
			onClick(event);
		} else {
			handleCLickBack();
		}
	};

	return (
		<IconButton {...rest} className={classNames(className, styles.button)} onClick={onButtonClick}>
			<ArrowLeft
				className={classNames(styles.icon, {
					[styles.icon_white]: color === 'white',
				})}
			/>
		</IconButton>
	);
};
