import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { Capacitor } from '@capacitor/core';
import Glide, { ComponentFunction } from '@glidejs/glide';
import classNames from 'classnames';
import {
	getApartmentsSelector,
	getIsUserHasFullAccessSelector,
	getRadabankCardExistsSelector,
	useAppSelector,
} from 'store';
import { ComingSoonContent } from 'components/ComingSoonContent';
import { ApartmentsList, CardBanner, MainPageSliderProps } from 'components/Home';
import styles from './index.module.scss';

const NAV_SELECTOR = '[data-glide-el="controls[nav]"]';

const SetActiveBulletInstantly: ComponentFunction = (Glide, Components, Events) => {
	let controlNavs: HTMLElement[] = [];

	return {
		mount() {
			controlNavs = Array.from(Components.Html.root.querySelectorAll(NAV_SELECTOR));

			Events.on('run', () => {
				const settings = Glide.settings;
				const activeClass = settings.classes.nav.active;

				controlNavs.forEach((controlNav) => {
					const activeItem = controlNav.children.item(Glide.index) as HTMLElement;

					controlNav.querySelectorAll(`.${activeClass}`).forEach((el) => {
						el.classList.remove(activeClass);
					});

					activeItem.classList.add(activeClass);
				});
			});
		},
	};
};

export const AndroidMainPageSlider: FC<MainPageSliderProps> = ({ onClickCardBanner }) => {
	const location = useLocation<{ initialSlide?: number }>();

	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);
	const isHideRadabankBanner = useAppSelector(getRadabankCardExistsSelector);
	const billApartment = useAppSelector(getApartmentsSelector);

	const sliderContainerRef = useRef<HTMLDivElement>(null);

	const isShowCardBanner = isHasFullAccess && !isHideRadabankBanner;

	useEffect(() => {
		let glide = null;
		if (sliderContainerRef.current) {
			glide = new Glide(sliderContainerRef.current, {
				swipeThreshold: 50,
				dragThreshold: false,
				startAt: location?.state?.initialSlide ?? 0,
				perView: 1,
				animationDuration: 300,
				rewind: false,
				gap: 40,
				focusAt: 'center',
				classes: {
					direction: {
						ltr: 'glide--ltr',
						rtl: 'glide--rtl',
					},
					type: {
						slider: 'glide--slider',
						carousel: 'glide--carousel',
					},
					slide: {
						active: styles['glide__slide--active'],
						clone: 'glide__slide--clone',
					},
					arrow: {
						disabled: 'glide__arrow--disabled',
					},
					swipeable: 'glide--swipeable',
					dragging: 'glide--dragging',
					nav: {
						active: styles['glide__bullet--active'],
					},
				},
			}).mount({ SetActiveBulletInstantly });
		}

		return () => {
			if (glide) {
				glide.destroy();
			}
		};
	}, [isShowCardBanner, billApartment.list.length]);

	return (
		<div className={classNames('glide', styles.glide)} ref={sliderContainerRef}>
			<div className={classNames('glide__track', styles.glide__track)} data-glide-el="track">
				<ul className={classNames('glide__slides', styles.glide__slides)}>
					<li className={classNames('glide__slide', styles.glide__slide)}>
						<ApartmentsList />
					</li>
					{isShowCardBanner && (
						<li className={classNames('glide__slide', styles.glide__slide)}>
							<div className={styles.glide__slide__container}>
								<div className={styles.glide__slide__scroll}>
									<CardBanner onClick={onClickCardBanner} isActionsDisabled={!Capacitor.isNativePlatform()} />
								</div>
							</div>
						</li>
					)}
					<li className={classNames('glide__slide', styles.glide__slide)}>
						<ComingSoonContent />
					</li>
				</ul>
			</div>
			<div className={classNames('glide__bullets', styles.glide__nav)} data-glide-el="controls[nav]">
				<button className={classNames('glide__bullet', styles.glide__bullet)} data-glide-dir="=0" />
				<button className={classNames('glide__bullet', styles.glide__bullet)} data-glide-dir="=1" />
				{isShowCardBanner && (
					<button className={classNames('glide__bullet', styles.glide__bullet)} data-glide-dir="=2" />
				)}
			</div>
		</div>
	);
};
