import { FC, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface DotStepperType {
	steps: number;
	currentStep: number;
	color?: 'primary' | 'secondary';
}

const DotStepper: FC<DotStepperType> = ({ steps, currentStep, color = 'secondary' }) => {
	const [dotsList] = useState(Array.from(Array(steps).keys()));

	return (
		<div className={styles.wrapper}>
			{dotsList.map((dot, i) => (
				<div
					key={dot}
					className={classNames(styles.item, {
						[styles.item_primary]: color === 'primary',
						[styles.item_active]: i < currentStep,
					})}
				/>
			))}
		</div>
	);
};

export default DotStepper;
