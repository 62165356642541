import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { STORAGE_KEY } from 'utils/enums';
import { getDeviceFP } from 'utils/helpers';
import { StorageService } from 'utils/services';
import { IErrorResponse } from 'utils/types';

const axiosConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_PAYMENT_API_URL,
};

const instance: AxiosInstance = axios.create(axiosConfig);

instance.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		// for each request adding a device fingerprint and session id
		let deviceFP: string | null = await StorageService.get<string>(STORAGE_KEY.DEVICE_FP);
		if (!deviceFP) {
			deviceFP = await getDeviceFP();
		}

		const sessionId: string | null = await StorageService.get(STORAGE_KEY.SESSION_ID_PAYMENT);

		// eslint-disable-next-line no-param-reassign
		config.data = config.data || {};
		// eslint-disable-next-line no-param-reassign
		config.data.deviceFP = deviceFP;

		if (sessionId) {
			// eslint-disable-next-line no-param-reassign
			config.data.sessionId = sessionId;
		}

		return config;
	},

	(error: AxiosError<IErrorResponse>) => {
		throw error;
	}
);

export const PAYMENT_API = instance;
