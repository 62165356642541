import { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { updateUserProfile } from 'store/user-service/actions';
import { getUserIsLoadingSelector } from 'store/user-service/selectors';
import { ValidationError } from 'yup';
import { AuthContentHeader } from 'components/Auth';
import { CustomButton } from 'components/shared';
import Input from 'components/shared/Input';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IUserRequest } from 'utils/types';
import { userAuthUpdateProfileValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

interface UpdateProfileDataProps {
	onSubmit: () => void;
}

export const UpdateProfileData: FC<UpdateProfileDataProps> = ({ onSubmit }) => {
	const dispatch = useAppDispatch();

	const isLoadingUser: boolean = useAppSelector(getUserIsLoadingSelector);

	const [profileData, setProfileData] = useState<Partial<IUserRequest>>({ firstName: '', lastName: '' });
	const [touched, setTouched] = useState({ firstName: false, lastName: false });
	const [validationError, setValidationError] = useState({
		firstName: '',
		lastName: '',
	});
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		const isValidPayload =
			!!profileData.firstName && !!profileData.lastName && Object.values(validationError).every((item) => !item);
		setIsValid(isValidPayload);
	}, [validationError, profileData]);

	const validateData = async (value: Partial<IUserRequest>): Promise<void> => {
		try {
			await userAuthUpdateProfileValidationSchema.validate(value, { abortEarly: false });
			setValidationError({
				firstName: '',
				lastName: '',
			});
		} catch (error) {
			const errors: any = {};
			error.inner.forEach((err: ValidationError) => {
				if (errors[err.path as string]) return;
				errors[err.path as string] = err.message ? [err.message] : [];
			});
			setValidationError(errors);
		}
	};

	const handleChangeProfileData = (event: ChangeEvent<HTMLInputElement>): void => {
		const payload: Partial<IUserRequest> = { ...profileData, [event.target.name]: event.target.value };
		setProfileData(payload);
		validateData(payload);
	};

	const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
		if (!touched.firstName || !touched.lastName) {
			setTouched({ ...touched, [event.target.name]: true });
			validateData(profileData);
		}
	};

	const handleSubmit = async (): Promise<void> => {
		if (!isValid) return;
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.UPDATE_PROFILE,
				FIREBASE_EVENT_ANALYTICS_BUTTON.UPDATE_PROFILE
			);
			await dispatch(updateUserProfile(profileData)).unwrap();
			onSubmit();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return (
		<>
			<AuthContentHeader title="Вкажіть ваші ім’я та прізвище" />
			<div className="auth-profile-handlers">
				<Input
					onSubmit={handleSubmit}
					value={profileData.firstName}
					onChange={handleChangeProfileData}
					onBlur={handleBlur}
					name="firstName"
					placeholder="Ім’я"
					helperText={touched.firstName ? validationError.firstName : ''}
					isError={touched.firstName && !!validationError.firstName}
				/>
				<Input
					onSubmit={handleSubmit}
					value={profileData.lastName}
					onChange={handleChangeProfileData}
					onBlur={handleBlur}
					name="lastName"
					placeholder="Прізвище"
					helperText={touched.lastName ? validationError.lastName : ''}
					isError={touched.lastName && !!validationError.lastName}
				/>
			</div>
			<div className={styles.actions}>
				<CustomButton fill="clear" size="large" label="Додати пізніше" onClick={onSubmit} />
				<CustomButton
					className="auth-button-submit"
					size="large"
					disabled={!isValid}
					label="Продовжити"
					onClick={handleSubmit}
					isLoading={isLoadingUser}
				/>
			</div>
		</>
	);
};
