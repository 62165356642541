import { FC, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { getUserProfileSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { FormContentDateRow, RadabankOperationDetailInfo } from 'components/RadabankCard';
import { CustomAutocomplete, CustomButton } from 'components/shared';
import { getErrorMessage, getTextFieldValidationProps } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import { IRadabankInternalAddRuleRequest, ISelectOption } from 'utils/types';
import { radabankAddCurrencyRuleValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

interface RadabankCardCurrencyRuleFormProps {
	onSubmit: (values: IRadabankInternalAddRuleRequest) => void;
	initialValues: IRadabankInternalAddRuleRequest;
}

export const RadabankCardCurrencyRuleForm: FC<RadabankCardCurrencyRuleFormProps> = ({ onSubmit, initialValues }) => {
	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);

	const [currenciesOptionsList, setCurrenciesOptionsList] = useState<ISelectOption<string>[]>([]);
	const [isLoadingCurrenciesList, setIsLoadingCurrenciesList] = useState(false);

	useEffect(() => {
		if (profile) {
			hangleGetCurrenciesList(profile.userId);
		}
	}, [profile]);

	const hangleGetCurrenciesList = async (userId: string): Promise<void> => {
		try {
			setIsLoadingCurrenciesList(true);
			const response = await RadabankService.getCurrencyRule(userId);
			setCurrenciesOptionsList(response.data.currencys.map((item) => ({ value: item.id, label: item.name })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoadingCurrenciesList(false);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={onSubmit}
			validateOnMount
			validationSchema={radabankAddCurrencyRuleValidationSchema}
		>
			{({ isValid, errors, touched }) => (
				<Form className={styles.form}>
					<FormContentDateRow />
					<Field
						name="currid"
						label="Валюта"
						component={CustomAutocomplete}
						options={currenciesOptionsList}
						loading={isLoadingCurrenciesList}
						formatLabel={(label: string) => label.slice(-4, -1)}
						{...getTextFieldValidationProps(errors, touched, 'currid')}
					/>
					<RadabankOperationDetailInfo
						className={styles.info}
						// eslint-disable-next-line max-len
						description="Встановлюючи доступ на виконання платежів в валюті, ви можете розраховуватись цією валютою за товари та послуги"
					/>
					<CustomButton className={styles.form__button} label="Додати дозвіл" type="submit" disabled={!isValid} />
				</Form>
			)}
		</Formik>
	);
};
