import { Capacitor } from '@capacitor/core';
import { customHistory, PUBLIC_URL_LIST } from 'utils/constants';
import { ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { StorageService } from 'utils/services';
import { formatQueryString } from './query-string';

export const redirectNotAuthorizedUser = async (): Promise<void> => {
	let redirectPath = !Capacitor.isNativePlatform() ? ROUTER_URL.AUTH_LOGIN : ROUTER_URL.AUTH_CHECK_PHONE;

	const isWasLoggedIn = await StorageService.get(STORAGE_KEY.IS_WAS_LOGGED_IN);
	const phone = await StorageService.get<string>(STORAGE_KEY.PHONE);

	if (isWasLoggedIn && phone) {
		redirectPath = ROUTER_URL.AUTH_LOGIN;
	}

	if (redirectPath === ROUTER_URL.AUTH_CHECK_PHONE && Capacitor.isNativePlatform() && !isWasLoggedIn) {
		redirectPath = ROUTER_URL.WELCOME;
	}

	const additionalRedirect = !PUBLIC_URL_LIST.some((item) => window.location.pathname.includes(item))
		? `?redirectTo=${window.location.pathname + window.location.search}`
		: '';

	customHistory.replace({ pathname: redirectPath, search: additionalRedirect });
};

export const getWindowLocationData = (url: string | undefined): string[] => {
	return url
		? (url
				.split(process.env.REACT_APP_URL as string)
				.pop()
				?.split('?') as string[])
		: [ROUTER_URL.MAIN];
};

export const getReceiptRoute = (apartmentAccountIdHash: string): string =>
	`${ROUTER_URL.RECEIPT_MAIN}/${apartmentAccountIdHash}`;

export const getReceiptBillsListRoute = (apartmentAccountIdHash: string): string =>
	ROUTER_URL.RECEIPT_ARCHIVE_LIST.replace(':apartmentAccountId', apartmentAccountIdHash);

export const getServicesByCategoryRoute = (categoryId: string): string =>
	`${ROUTER_URL.SERVICES_BY_CATEGORY_ID.replace(':categoryId', categoryId)}`;

export const getServiceByIdRoute = (categoryId: string, serviceId: string): string =>
	`${getServicesByCategoryRoute(categoryId)}/${serviceId}`;

export const getServicePaymentStatusRoute = (
	categoryId: string,
	serviceId: string,
	billId?: string,
	origin?: string
): string => `${origin ?? ''}${ROUTER_URL.PAYMENT_STATUS_MAIN}/${categoryId}/${serviceId}/${billId ?? ''}`;

export const getReceiptDeeplinkRoute = (token: string | null): string =>
	`${process.env.REACT_APP_URL}${ROUTER_URL.RECEIPT_PAY_DEEPLINK}?token=${token}`;

export const getReceiptStatusRoute = (apartmentAccountIdHash: string, billId = '', origin = ''): string =>
	`${origin}${ROUTER_URL.PAYMENT_STATUS_MAIN}/${apartmentAccountIdHash}${billId ? `/${billId}` : ''}`;

export const getPublicConfirmUrl = (redirectUrl: string): string =>
	Capacitor.getPlatform() === 'ios'
		? `${process.env.REACT_APP_PAYMENT_REDIRECT_PUBLIC_PAGE_URL}${formatQueryString({
				redirectUrl,
		  })}`
		: redirectUrl;

export const getReceiptReturnUrl = (apartmentAccountIdHash: string, origin = ''): string => {
	const redirectUrl = getReceiptStatusRoute(apartmentAccountIdHash, '', origin);
	return getPublicConfirmUrl(redirectUrl);
};

export const getPortmoneServiceReturnUrl = (categoryId: string, serviceId: string, origin = ''): string => {
	const redirectUrl = `${origin || process.env.REACT_APP_URL}${
		ROUTER_URL.PAYMENT_STATUS_MAIN
	}/${categoryId}/${serviceId}`;
	return getPublicConfirmUrl(redirectUrl);
};

export const getRadabankMobileTopUpUrl = (): string =>
	`${ROUTER_URL.SERVICES_CATEGORIES}/${process.env.REACT_APP_MOBILE_TOP_UP_SERVICE_ID}`;

export const getRadabankTransactionByIdUrl = (transactionId: string) =>
	`${ROUTER_URL.RADABANK_CARD_TRANSACTION_DETAILS.replace(':transactionId', transactionId)}`;
