import { FC } from 'react';
import { Tab, Tabs } from '@mui/material';
import { ReactComponent as SecureIcon } from 'assets/icons/radabank/card-secure-bold.svg';
import { ReactComponent as CashIcon } from 'assets/icons/radabank/cash.svg';
import { ReactComponent as MapIcon } from 'assets/icons/radabank/map.svg';
import { ReactComponent as WebIcon } from 'assets/icons/radabank/web.svg';
import { RADABANK_SECURE_SETTINGS_TABS } from 'utils/enums';

interface RadabankCardSecureSettingsTabsProps {
	value: RADABANK_SECURE_SETTINGS_TABS;
	onChange: (value: RADABANK_SECURE_SETTINGS_TABS) => void;
}

export const RadabankCardSecureSettingsTabs: FC<RadabankCardSecureSettingsTabsProps> = ({ onChange, value }) => (
	<Tabs value={value} onChange={(_, value) => onChange(value)}>
		<Tab label={<SecureIcon />} value={RADABANK_SECURE_SETTINGS_TABS.LIMITS} />
		<Tab label={<WebIcon />} value={RADABANK_SECURE_SETTINGS_TABS.INTERNET} />
		<Tab label={<MapIcon />} value={RADABANK_SECURE_SETTINGS_TABS.COUNTRY} />
		<Tab label={<CashIcon />} value={RADABANK_SECURE_SETTINGS_TABS.CURRENCY} />
	</Tabs>
);
