import { FC } from 'react';
import dayjs from 'dayjs';
import { CustomDatePicker } from 'components/shared';
import { IDateRange } from 'utils/types';
import styles from './index.module.scss';

interface RadabankOperationsStatementFormProps {
	dateRange: IDateRange;
	onChangeDateRange: (dateRange: IDateRange) => void;
}

export const RadabankOperationsStatementForm: FC<RadabankOperationsStatementFormProps> = ({
	dateRange,
	onChangeDateRange,
}) => {
	const handleChangeStartDate = (value: string) => {
		onChangeDateRange({ ...dateRange, datestart: value });
	};

	const handleChangeFinishDate = (value: string) => {
		onChangeDateRange({ ...dateRange, datefinish: value });
	};

	return (
		<div className={styles.form}>
			<CustomDatePicker
				disableFuture
				maxDate={dayjs(dateRange.datefinish)}
				onChange={handleChangeStartDate}
				value={dateRange.datestart}
			/>
			<CustomDatePicker
				disableFuture
				minDate={dayjs(dateRange.datestart)}
				onChange={handleChangeFinishDate}
				value={dateRange.datefinish}
			/>
		</div>
	);
};
