import { FC, HTMLProps } from 'react';
import { MenuList, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

interface RadabankSettingsBlockProps extends HTMLProps<HTMLDivElement> {
	title: string;
}

export const RadabankSettingsBlock: FC<RadabankSettingsBlockProps> = ({ children, title, className, ...props }) => {
	return (
		<div {...props} className={classNames(styles.content, className)}>
			<Typography variant="subtitle1" color="var(--color-neutral-500)">
				{title}
			</Typography>
			<MenuList className={styles.content__list}>{children}</MenuList>
		</div>
	);
};
