import { PAYMENT_STATUS, PAYMENT_STATUS_TITLE, PROVIDER_TYPE } from 'utils/enums';
import { IBillsReport, IBillsReportRelatedBill, IBillsReportResponse, IBillsSubBillItem } from 'utils/types';
import { getAmountTitle, getProviderServiceTitle } from './receipt';

export const getPaymentStatusTitle = (status: PAYMENT_STATUS | PAYMENT_STATUS[], amount?: string | number): string => {
	let statusTitle = '';
	let statusValue: PAYMENT_STATUS | null = null;

	if (Array.isArray(status)) {
		statusValue = status.some((item) => item === PAYMENT_STATUS.PAID)
			? PAYMENT_STATUS.PAID_PARTIAL
			: PAYMENT_STATUS.UNPAID;
	} else {
		statusValue = status;
	}

	switch (statusValue) {
		case PAYMENT_STATUS.PAID:
			statusTitle = PAYMENT_STATUS_TITLE.PAID;
			break;

		case PAYMENT_STATUS.REJECTED:
			statusTitle = PAYMENT_STATUS_TITLE.REJECTED;
			break;

		case PAYMENT_STATUS.EXPIRED:
			statusTitle = PAYMENT_STATUS_TITLE.EXPIRED;
			break;

		case PAYMENT_STATUS.RETURNED:
			statusTitle = PAYMENT_STATUS_TITLE.RETURNED;
			break;

		case PAYMENT_STATUS.UNPAID:
			statusTitle = PAYMENT_STATUS_TITLE.UNPAID;
			break;

		case PAYMENT_STATUS.PAID_PARTIAL:
			statusTitle = PAYMENT_STATUS_TITLE.PAID_PARTIAL;
			break;

		default:
			statusTitle = PAYMENT_STATUS_TITLE.IN_PROGRESS;
			break;
	}

	return `${statusTitle} ${PAYMENT_STATUS.PAID === status && amount ? getAmountTitle(amount) : ''}`;
};

export const getBillsReportPaymentStatus = (serviceList: IBillsSubBillItem[]): PAYMENT_STATUS => {
	if (serviceList.length > 1 && serviceList[0].provider === PROVIDER_TYPE.PORTMONE) {
		if (serviceList.every((item) => item.status === PAYMENT_STATUS.PAID)) return PAYMENT_STATUS.PAID;
		if (serviceList.some((item) => item.status === PAYMENT_STATUS.PAID)) return PAYMENT_STATUS.PAID_PARTIAL;
		if (
			serviceList.some(
				(item) =>
					item.status !== PAYMENT_STATUS.PAID &&
					item.status !== PAYMENT_STATUS.REJECTED &&
					item.status !== PAYMENT_STATUS.RETURNED &&
					item.status !== PAYMENT_STATUS.EXPIRED
			)
		) {
			return PAYMENT_STATUS.PENDING;
		}

		return PAYMENT_STATUS.UNPAID;
	}
	return serviceList[0].status;
};

export const filterBillsReportListByStatus = <T extends Pick<IBillsReport, 'status'>>(
	list: T[],
	status: PAYMENT_STATUS
): T[] => {
	return list.filter((item) => item?.status === status);
};

export const getOtherServiceSubBillPayedAmount = (data: IBillsSubBillItem): number =>
	Number(data.amount) - Number(data.fee);

export const getSubBillPayedAmount = (data: IBillsSubBillItem): number => {
	if (data.status === PAYMENT_STATUS.PAID) {
		return data.provider === PROVIDER_TYPE.PORTMONE ? getOtherServiceSubBillPayedAmount(data) : +data.amount;
	}
	return 0;
};

export const getSubBillsTotalAmount = (totalAmount: number, subBillItem: IBillsSubBillItem): number =>
	totalAmount + getOtherServiceSubBillPayedAmount(subBillItem);

export const getSubBillsTotalFeeAmount = (totalFeeAmount: number, subBillItem: IBillsSubBillItem): number =>
	totalFeeAmount + Number(subBillItem.fee);

export const getBillsTotalAmount = (data: IBillsReportResponse): number => {
	const relatedBillsTotalAmount = data.relatedBills.reduce((totalAmount: number, item: IBillsReportRelatedBill) => {
		const totalRelatedBillSubBillsAmount = filterBillsReportListByStatus(item.subBills, PAYMENT_STATUS.PAID).reduce(
			getSubBillsTotalAmount,
			0
		);

		return totalAmount + totalRelatedBillSubBillsAmount;
	}, 0);

	const subBilsTotalAmount = filterBillsReportListByStatus(data.subBills, PAYMENT_STATUS.PAID).reduce(
		(acc, item) => acc + getOtherServiceSubBillPayedAmount(item),
		0
	);
	return relatedBillsTotalAmount + subBilsTotalAmount;
};

export const getBillsTotalFee = (data: IBillsReportResponse): number => {
	const relatedBillsFeeAmount = data.relatedBills.reduce((totalFeeAmount: number, item: IBillsReportRelatedBill) => {
		const totalRelatedBillSubBillsFeeAmount = filterBillsReportListByStatus(item.subBills, PAYMENT_STATUS.PAID).reduce(
			getSubBillsTotalFeeAmount,
			0
		);

		return totalFeeAmount + totalRelatedBillSubBillsFeeAmount;
	}, 0);
	const subBils = filterBillsReportListByStatus(data.subBills, PAYMENT_STATUS.PAID);
	const relatedListTotalAmount = relatedBillsFeeAmount + subBils.reduce((acc, item) => acc + Number(item.fee ?? 0), 0);
	return relatedListTotalAmount;
};

export const getBillsReportServiceTitle = (data: IBillsSubBillItem): string =>
	data?.payerData?.isShowServiceName
		? data?.payerData.serviceName
		: data.service?.title ?? getProviderServiceTitle(data) ?? '';
