import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import styles from './index.module.scss';

interface AuthContentHeaderProps {
	title: string;
	description?: string | ReactNode;
}

export const AuthContentHeader: FC<AuthContentHeaderProps> = ({ title, description }) => {
	return (
		<div className={styles.content}>
			<Typography variant="h5">{title}</Typography>

			{!!description && (
				<Typography variant="body2" marginTop="var(--spacing-2)" color="--color-neutral-500">
					{description}
				</Typography>
			)}
		</div>
	);
};
