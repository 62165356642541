import { HostCardSurveyStep } from 'utils/enums';
import {
	radabankSurveyGeneralStepValidationSchema,
	radabankSurveyHomeAddressStepValidationSchema,
	radabankSurveyPropertyStatusStepValidationSchema,
	radabankSurveySocialStatusValidationSchema,
	radabankSurveyTogglersStepValidationSchema,
} from 'utils/validation';
import { SurveyLetterGeneralStep } from './GeneralStep';
import { SurveyLetterHomeAddressStep } from './HomeAddressStep';
import { PropertyStatusStep } from './PropertyStatusStep';
import { SurveyLetterSocialStatusStep } from './SocialStatusStep';
import { SurveyTogglersStep } from './TogglersStep';

const hostCardSurveySteps = {
	[HostCardSurveyStep.General]: {
		title: 'Основна інформація',
		Component: SurveyLetterGeneralStep,
		validationSchema: radabankSurveyGeneralStepValidationSchema,
	},
	[HostCardSurveyStep.PropertyStatus]: {
		title: 'Майновий статус',
		Component: PropertyStatusStep,
		validationSchema: radabankSurveyPropertyStatusStepValidationSchema,
	},
	[HostCardSurveyStep.HomeAddress]: {
		title: 'Адреса фактичного проживання',
		Component: SurveyLetterHomeAddressStep,
		validationSchema: radabankSurveyHomeAddressStepValidationSchema,
	},
	[HostCardSurveyStep.SocialStatus]: {
		title: 'Соціальний статус',
		Component: SurveyLetterSocialStatusStep,
		validationSchema: radabankSurveySocialStatusValidationSchema,
	},
	[HostCardSurveyStep.LastQuestions]: {
		title: 'Останні питання',
		Component: SurveyTogglersStep,
		validationSchema: radabankSurveyTogglersStepValidationSchema,
	},
};

export const getRadabankSurveyStep = (step: HostCardSurveyStep) => hostCardSurveySteps[step];

export const HOST_CARD_SURVEY_STEPS_COUNT = Object.keys(hostCardSurveySteps).length;
