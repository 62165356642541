import { FC, useState } from 'react';
import { Browser } from '@capacitor/browser';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import { RadabankCardDetails, RadabankCardPreview, RadabankSectionHeader } from 'components/RadabankCard';
import { CustomButton } from 'components/shared';
import { NumberInput } from 'components/shared/NumberInput';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT,
} from 'utils/constants';
import { convertUAHToCoins, getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import { radabankTopUpValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

export const RadabankCardOperationTopUpPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [amount, setAmount] = useState('');
	const [isValid, setIsValid] = useState(false);
	const [isTouched, setIsTouched] = useState(false);

	const handleChangeAmountValue = async (value: string): Promise<void> => {
		setAmount(value);

		try {
			await radabankTopUpValidationSchema.validate(value);
			setIsValid(true);
		} catch (error) {
			setIsValid(false);
		}
	};

	const handleBlurInput = () => {
		setIsTouched(true);
	};

	const handleClickCardTopUp = async () => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_TOP_UP,
				FIREBASE_EVENT_ANALYTICS_BUTTON.NEXT
			);
			if (currentCard) {
				dispatch(setAppLoading(true));
				const response = await RadabankService.createP2PData({
					userId: (profile as IUserProfileResponse).userId,
					cardid: currentCard.id,
					amount: `${convertUAHToCoins(amount)}`,
				});
				await Browser.open({ url: response.data.url });
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_TOP_UP,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	if (!currentCard) return <Navbar />;
	return (
		<div className={classNames('page', styles.page)}>
			<RadabankSectionHeader title="Поповнити свою картку" onClickBack={handleGoBack} />
			<div className={styles.content}>
				<Typography variant="caption" color="var(--color-neutral-600)" marginBottom="var(--spacing-1)">
					Отримувач
				</Typography>
				<RadabankCardPreview className={styles.content__card}>
					<RadabankCardDetails />
				</RadabankCardPreview>

				<NumberInput
					label="Сума"
					value={amount}
					onChange={handleChangeAmountValue}
					onBlur={handleBlurInput}
					error={!isValid && isTouched}
					max={RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT}
				/>
			</div>
			<CustomButton onClick={handleClickCardTopUp} label="Далі" disabled={!convertUAHToCoins(amount) || !isValid} />
		</div>
	);
};
