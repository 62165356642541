import { capitalize } from '@mui/material';
import { ADD_APARTMENT_VARIANT, STREET_TYPE } from 'utils/enums';
import { IAddApartmentRequest, IReceiptAnauthorizedResponse, IReceiptResponse, ReceiptApartment } from 'utils/types';
import { decryptBase64 } from './crypt';

export const getApartmentTitle = (apartment: Partial<ReceiptApartment>): string => {
	const streetType = `${
		apartment.streetType?.slice(0, apartment.streetType === STREET_TYPE.STREET ? 3 : 5)?.toLowerCase() ?? ''
	}`;

	const city = apartment.city
		?.split('.')
		.map((item, index) => (!index ? item.toLowerCase() : capitalize(item.trim().toLowerCase())))
		.join('. ');

	const street: string =
		apartment.street
			?.split(' ')
			?.map((item: string) => `${item?.slice(0, 1)?.toUpperCase() ?? ''}${item?.slice(1)?.toLowerCase() ?? ''}`)
			?.join(' ') ?? '';
	return `${city}, ${streetType ?? `${streetType}.`} ${street ?? ''} ${apartment?.house ?? ''}${
		apartment.flat ? `, кв. ${apartment.flat}` : ''
	}`;
};

export const getApartmentTitleFromHash = (apartmentHashedString: string): string => {
	const decryptedApartmentString = decryptBase64(apartmentHashedString);
	const [streetType, street, house, flat] = decryptedApartmentString.split('_');
	const apartment = {
		streetType,
		street,
		house,
		flat,
	};
	return getApartmentTitle(apartment);
};

export const getStreetTitle = (street: string): string =>
	street.charAt(0).toUpperCase() + street.slice(1).toLowerCase();

export const getApartmentDataFromReceipt = (
	receipt: IReceiptResponse | IReceiptAnauthorizedResponse
): ReceiptApartment => {
	const {
 apartmentAccountId, flat, house, street, streetType, lat, lon, city
} = receipt;
	return {
		apartmentAccountId,
		flat,
		house,
		street,
		streetType,
		lat,
		lon,
		city,
	};
};

export const getApartmentInitialValues = (
	userId: string,
	variant: ADD_APARTMENT_VARIANT | null
): IAddApartmentRequest => {
	let payload: IAddApartmentRequest = {
		userId,
	};

	switch (variant) {
		case ADD_APARTMENT_VARIANT.ADDRESS:
			payload = { ...payload, street: '', house: '', flat: null };
			break;

		case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
			payload = { ...payload, providerAccountId: '', providerId: null };
			break;

		case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
			payload = { ...payload, apartmentAccountId: undefined };
			break;

		default:
			break;
	}

	return payload;
};

export const sortApartmentArrayStringResponse = (a: string, b: string): number => {
	// Extract numerical part from the strings
	const regex = /(\d+)/g;
	const aNum = a.match(regex) !== null ? +(a.match(regex) as RegExpMatchArray)[0] : 0;
	const bNum = b.match(regex) !== null ? +(b.match(regex) as RegExpMatchArray)[0] : 0;

	// Compare numerical parts
	if (aNum !== bNum) {
		return aNum - bNum;
	}

	// Extract alphabetic part from the strings
	const aAlpha = a.replace(regex, '').toUpperCase();
	const bAlpha = b.replace(regex, '').toUpperCase();

	// Compare alphabetic parts
	if (aAlpha !== bAlpha) {
		return aAlpha.localeCompare(bAlpha);
	}

	// Compare special characters
	const aSpecial = a.replace(/[0-9A-Z\s]+/g, '');
	const bSpecial = b.replace(/[0-9A-Z\s]+/g, '');
	if (aSpecial !== bSpecial) {
		return aSpecial.localeCompare(bSpecial);
	}

	// If all parts are equal, maintain original order
	return 0;
};
