import { ChangeEvent, FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { SetPasswordNative } from 'components/Auth';
import { PinScreenMobileVariantsType } from 'components/Auth/PasswordSetup/helpers';
import { ArrowBackButton, Loader } from 'components/shared';
import { ERROR_MESSAGE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { decryptData } from 'utils/helpers';
import { StorageService } from 'utils/services';
import { BiometryService } from 'utils/services/biometry.service';
import { LoginProps } from 'utils/types';
import styles from './index.module.scss';

export const LoginNative: FC<LoginProps> = ({
	data,
	onChange,
	onSubmit,
	onClickRestorePassword,
	errors,
	touched,
	isLoading,
}) => {
	const history = useHistory();

	const pinType =
		errors.password === ERROR_MESSAGE.INVALID_PASSWORD_OR_NOT_FOUND
			? PinScreenMobileVariantsType.RegisteredWrong
			: PinScreenMobileVariantsType.RegisteredSet;

	useEffect(() => {
		if (data.phone) {
			handleAuth();
		}
	}, [data.phone]);

	const handleAuth = async () => {
		const password = await StorageService.get<string>(STORAGE_KEY.USER_PASSWORD);
		if (password) {
			const decryptedData = decryptData(password);
			if (
				decryptedData &&
				BiometryService.isAvailable &&
				BiometryService.biometryType === BiometryService.primaryBiometryType
			) {
				await BiometryService.auth();
				onChange({ ...data, password: decryptData(password) });
			}
		}
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		onChange({ ...data, password: event.target.value });
	};

	const handleBackToPhonePage = () => {
		history.replace(ROUTER_URL.AUTH_CHECK_PHONE);
	};

	return (
		<>
			{isLoading && <Loader />}
			<div className={styles.wrapper}>
				<ArrowBackButton onClick={handleBackToPhonePage} />
				<SetPasswordNative
					contentClasses={styles.content}
					value={data.password}
					onChange={handleChangePassword}
					onClickForgotPassword={onClickRestorePassword}
					maxLength={6}
					onSubmit={onSubmit}
					isError={!!errors.password && touched.password}
					type={pinType}
				/>
			</div>
		</>
	);
};
