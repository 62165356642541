import { AxiosResponse } from 'axios';
import { USER_API } from 'core';
import { USER_API_URL } from 'utils/enums';
import { removeEmptyProps } from 'utils/helpers';
import {
	IPaymentResponse,
	IReceiptAnauthorizedResponse,
	IReceiptRequest,
	IReportPaymentReceiptRequest,
	IReportPaymentReceiptResponse,
	IReportSingleReceiptResponse,
	ReportReceiptPayRequest,
} from 'utils/types';

class ReportService {
	static getSingleReceipt = async (reqBody: IReceiptRequest): Promise<AxiosResponse<IReportSingleReceiptResponse>> =>
		USER_API.post(USER_API_URL.REPORT_GET_SINGLE_RECEIPT, removeEmptyProps(reqBody));

	static getReceiptDownloadUrl = async (
		reqBody: IReceiptRequest
	): Promise<AxiosResponse<IReportSingleReceiptResponse>> =>
		USER_API.post(USER_API_URL.REPORT_DOWNLOAD_RECEIPT_PDF, reqBody);

	static getUnathorizedReceiptData = async (token: string): Promise<AxiosResponse<IReceiptAnauthorizedResponse>> =>
		USER_API.post(`${USER_API_URL.REPORT_GET_UNATHORIZED_SINGLE_RECEIPT}`, undefined, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

	static payUnathorizedReceipt = async (
		reqBody: ReportReceiptPayRequest,
		token: string
	): Promise<AxiosResponse<IPaymentResponse[]>> =>
		USER_API.post(USER_API_URL.REPORT_PAY_UNATHORIZED_SINGLE_RECEIPT, reqBody, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

	static getPaymentReceipt = async (
		reqBody: IReportPaymentReceiptRequest
	): Promise<AxiosResponse<IReportPaymentReceiptResponse>> =>
		USER_API.post(USER_API_URL.REPORT_GET_PAYMENT_RECEIPT, removeEmptyProps(reqBody));
}

export { ReportService };
