import { FC, useMemo, useState } from 'react';
import { BiometryType } from '@aparajita/capacitor-biometric-auth';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as FaceID } from 'assets/icons/faceID.svg';
import { ReactComponent as FingerPrint } from 'assets/icons/fingerprint.svg';
import { useAppSelector } from 'store/hooks';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { CardItem } from 'components/Settings/CardItem';
import CustomSwitch from 'components/shared/CustomSwitch';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { BiometryService, FirebaseAnalytics, PushNotificationsService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const AppSettings: FC = () => {
	const userProfile = useAppSelector(getUserProfileSelector);

	const [isEnabledPushNotifications, setIsEnabledPushNotifications] = useState(!!PushNotificationsService.isEnabled);

	const cardItemData = useMemo(() => {
		switch (BiometryService.biometryType) {
			case BiometryType.faceAuthentication:
			case BiometryType.faceId:
				return {
					label: 'Face ID',
					icon: <FaceID />,
				};

			// case BiometryType.fingerprintAuthentication:
			// case BiometryType.touchId:
			default:
				return {
					label: 'Використовувати біометрію',
					icon: <FingerPrint />,
				};
		}
	}, [BiometryService.biometryType]);

	const handleTogglePushNotifications = async () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.PUSH_NOTIFICATIONS}-${!isEnabledPushNotifications}`
		);
		const result = await PushNotificationsService.toggleSubscribe((userProfile as IUserProfileResponse).userId);
		setIsEnabledPushNotifications(!!result);
	};

	const handleToggleBiometry = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SETTINGS,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.BIOMETRY}-${BiometryService.biometryType !== BiometryService.primaryBiometryType}`
		);
		BiometryService.toggleBiometryEnabled();
	};

	return (
		<>
			{!!BiometryService.isAvailable && (
				<CardItem className={styles.switch__content} label={cardItemData.label} icon={cardItemData.icon}>
					<CustomSwitch
						checked={BiometryService.biometryType === BiometryService.primaryBiometryType}
						onIonChange={handleToggleBiometry}
					/>
				</CardItem>
			)}
			<div className={styles.switch} onClick={handleTogglePushNotifications}>
				<CardItem className={styles.switch__content} label="Push сповіщення" icon={<Bell />}>
					<CustomSwitch checked={isEnabledPushNotifications} />
				</CardItem>
			</div>
		</>
	);
};
