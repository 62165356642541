import { useCallback, useState } from 'react';

export const useStepper = (totalSteps: number, initialStep = 0) => {
	const [currentStep, setCurrentStep] = useState<number>(initialStep);

	const handlePreviousStep = useCallback(() => {
		setCurrentStep((prev) => (!prev ? prev : prev - 1));
	}, []);

	const handleNextStep = useCallback(() => {
		setCurrentStep((prev) => (prev === totalSteps - 1 ? prev : prev + 1));
	}, [totalSteps]);

	return {
		currentStep,
		handleNextStep,
		handlePreviousStep,
	};
};
