import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { getApartmentList } from 'store/bill/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfile } from 'store/user-service/actions';
import { getUserIsLoadingSelector, getUserProfileSelector } from 'store/user-service/selectors';
import { Header } from 'components/Header';
import { AndroidMainPageSlider, MainPageSlider } from 'components/Home';
import { Navbar } from 'components/Navbar';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

export const HomePage: FC = () => {
	// useIonicBackButton();
	const history = useHistory();

	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);
	const profileIsLoading = useAppSelector(getUserIsLoadingSelector);

	useEffect(() => {
		if (!profile && !profileIsLoading) {
			handleGetUserProfile();
		}
	}, []);

	useEffect(() => {
		if (profile?.userId) {
			handleGetUserApartments(profile.userId);
		}
	}, [profile]);

	const handleGetUserProfile = async (): Promise<void> => {
		try {
			await dispatch(getUserProfile()).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleGetUserApartments = async (userId: string): Promise<void> => {
		try {
			await dispatch(getApartmentList(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickCardBanner = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.HOME,
			FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE_CARD_RADABANK
		);
		history.push(ROUTER_URL.RADABANK_CARD);
	};

	const isAndroid = Capacitor.getPlatform() === 'android';

	return (
		<div className={styles.wrapper}>
			<Header className={styles.header} />
			{isAndroid ? (
				<section className={classNames(styles.content)}>
					<AndroidMainPageSlider onClickCardBanner={handleClickCardBanner} />
				</section>
			) : (
				<MainPageSlider onClickCardBanner={handleClickCardBanner} />
			)}
			<Navbar />
		</div>
	);
};
