import { FC, MouseEvent, ReactNode } from 'react';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as Close } from 'assets/icons/simple-close.svg';
import cx from 'classnames';
import { useDevice } from 'utils/hooks';
import { ArrowBackButton } from '../ArrowBackButton';
import styles from './index.module.scss';

interface ModalHeaderProps {
	className: string;
	onClose: (() => void) | undefined;
	title?: string | ReactNode;
	variant?: 'bold' | 'normal';
	isFullHeight?: boolean;
}

export const ModalHeader: FC<ModalHeaderProps> = ({ onClose, title, variant = 'normal', isFullHeight, className }) => {
	const { isMobile } = useDevice();

	const handleClose = (event: MouseEvent<HTMLButtonElement>): void => {
		event.stopPropagation();
		if (onClose) {
			onClose();
		}
	};

	return (
		<div className={cx(className, styles.content)}>
			{isFullHeight ? (
				<ArrowBackButton
					className={cx(styles.content__button, {
						[styles.content__left]: isFullHeight,
						[styles.content__right]: isFullHeight && isMobile,
					})}
					onClick={handleClose}
				/>
			) : (
				<IconButton
					onClick={handleClose}
					className={cx(styles.content__button, {
						[styles.content__left]: isFullHeight,
					})}
				>
					<Close
						className={cx(styles.content__button__icon, variant === 'bold' && styles.content__button__icon_small)}
					/>
				</IconButton>
			)}
			{title &&
				(typeof title === 'string' ? (
					<Typography
						className={styles.title}
						variant={variant === 'bold' ? 'h5' : 'h6'}
						textAlign={isMobile ? 'justify' : 'center'}
					>
						{title}
					</Typography>
				) : (
					title
				))}
		</div>
	);
};
