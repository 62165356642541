import { FC } from 'react';
import { Typography } from '@mui/material';
import { getServicesCategoriesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { Observer } from 'components/shared';
import { ERROR_MESSAGE } from 'utils/enums';
import { IServicesCategoriesItemResponse, IServicesCategoriesRequest } from 'utils/types';
import { ServicesCategoryCard } from './ServicesCategoryCard';
import styles from './index.module.scss';

interface ServicesCategoriesListProps {
	onChange: (data: IServicesCategoriesRequest) => void;
	data: IServicesCategoriesRequest;
	onClickCard: (id: string, name: string) => void;
}

export const ServicesCategoriesList: FC<ServicesCategoriesListProps> = ({ onChange, onClickCard, data }) => {
	const dispatch = useAppDispatch();

	const categories = useAppSelector(getServicesCategoriesSelector);

	const handleObserve = (): void => {
		onChange({ ...data, offset: data.offset + data.limit });
	};

	const handleClickCard = (id: string, count: number, name: string): void => {
		if (count) {
			onClickCard(id, name);
		} else {
			dispatch(showToast({ message: ERROR_MESSAGE.SERVICE_CATEGORY_EMPTY, color: 'light' }));
		}
	};

	return (
		<>
			<div className={styles.list}>
				{categories.data.tableData.map((item: IServicesCategoriesItemResponse) => (
					<ServicesCategoryCard
						key={item.id}
						data={item}
						onClick={() => handleClickCard(item.id, item.servicesCount, item.analyticsName || '')}
						isDisabled={!item.servicesCount}
					/>
				))}
			</div>
			{!categories.isLoading && !categories.data.tableData.length && data.options.serviceCategoryTitle && (
				<Typography textAlign="center">
					На жаль, такої категорії не знайдено. Спробуйте пошукати за іншою назвою
				</Typography>
			)}
			{categories.data.tableData.length !== categories.data.listData.totalRows && (
				<Observer onObserve={handleObserve} isLoading={categories.isLoading} />
			)}
		</>
	);
};
