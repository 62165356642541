import { FC, useMemo } from 'react';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import { AddAddressPage } from 'pages/AddAddress';
import { CameraAccessBannerPage } from 'pages/CameraAccessBanner';
import { HomePage } from 'pages/Home';
import { MetersPage } from 'pages/Meters';
import { NotFoundPage } from 'pages/NotFound';
import { PaymentStatusPage } from 'pages/PaymentStatus';
import { PaymentStatusServicePage } from 'pages/PaymentStatusService';
import { PushNotificationsBannerPage } from 'pages/PushNotificationsBanner';
import { ReceiptPage } from 'pages/Receipt';
import { ReceiptBillsListPage } from 'pages/ReceiptBillsList';
import { ReceiptBillsReportPage } from 'pages/ReceiptBillsReport';
import { ServiceProviderPage } from 'pages/ServiceProvider';
import { ServicesPage } from 'pages/Services';
import { ServicesBillsListPage } from 'pages/ServicesBillsList';
import { ServicesBillsReportPage } from 'pages/ServicesBillsReport';
import { ServicesCategoriesPage } from 'pages/ServicesCategories';
import { SettingsPage } from 'pages/Settings';
import { SingleReceiptPage } from 'pages/SingleReceipt';
import { RadabankRouter } from 'router/RadabankCardRouter';
import { useAppSelector } from 'store/hooks';
import {
	getIsPumbUserAccessSelector,
	getIsUserHasFullAccessSelector,
	getUserReducerSelector
} from 'store/user-service/selectors';
import { ROUTER_URL } from 'utils/enums';
import { Rights } from "utils/enums/rights.enum";
import { getIndexRoute } from "utils/helpers";
import { usePermissions } from "utils/hooks";

export const MainRouter: FC = () => {
	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);
	const canI = usePermissions();
	const isUserPUMB = useAppSelector(getIsPumbUserAccessSelector)

	const userProfile = useAppSelector(getUserReducerSelector);
	const indexRoute = useMemo(() => {
		return getIndexRoute(canI);
	}, [canI, userProfile]);

	return (
		<MainLayout>
			<Switch>
				<Route exact path="/"><Redirect to={indexRoute} /></Route>
				{isUserPUMB && <Route exact path={ROUTER_URL.PROFILE} component={HomePage} />}
				<Route exact path={ROUTER_URL.PROFILE_RECEIPT} component={ReceiptPage} />
				<Route exact path={ROUTER_URL.SINGLE_RECEIPT} component={SingleReceiptPage} />
				<Route exact path={ROUTER_URL.RECEIPT_PAYMENT_STATUS} component={PaymentStatusPage} />
				<Route exact path={ROUTER_URL.RECEIPT_ARCHIVE_LIST} component={ReceiptBillsListPage} />
				<Route exact path={ROUTER_URL.RECEIPT_ARCHIVE} component={ReceiptBillsReportPage} />
				<Route exact path={ROUTER_URL.ADD_ADDRESS} component={AddAddressPage} />
				<Route exact path={ROUTER_URL.SETTINGS} component={SettingsPage} />
				{canI(Rights.METERS_READ) && <Route exact path={ROUTER_URL.METERS} component={MetersPage} /> }
				{isHasFullAccess && <Route exact path={ROUTER_URL.SERVICES_CATEGORIES} component={ServicesCategoriesPage} />}
				{isHasFullAccess && <Route exact path={ROUTER_URL.SERVICES_ARCHIVE_LIST} component={ServicesBillsListPage} />}
				{isHasFullAccess && (
					<Route exact path={ROUTER_URL.SERVICES_ARCHIVE_REPORT} component={ServicesBillsReportPage} />
				)}
				{isHasFullAccess && <Route exact path={ROUTER_URL.SERVICES_BY_CATEGORY_ID} component={ServicesPage} />}
				{isHasFullAccess && <Route exact path={ROUTER_URL.SERVICE_PROVIDER} component={ServiceProviderPage} />}
				{isHasFullAccess && (
					<Route exact path={ROUTER_URL.SERVICE_PAYMENT_STATUS} component={PaymentStatusServicePage} />
				)}
				{isHasFullAccess && (
					<Route exact path={ROUTER_URL.PUSH_NOTIFICATIONS_BANNER} component={PushNotificationsBannerPage} />
				)}
				{isHasFullAccess && <Route exact path={ROUTER_URL.CAMERA_ACCESS_BANNER} component={CameraAccessBannerPage} />}
				{isHasFullAccess && <RadabankRouter />}
				<Route path="*" component={NotFoundPage} />
			</Switch>
		</MainLayout>
	);
};
