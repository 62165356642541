// Assets
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxDefault } from 'assets/icons/checkbox-default.svg';

export const MuiCheckbox = {
	styleOverrides: {
		root: {
			width: 'var(--spacing-9)',
			height: 'var(--spacing-9)',
			aspectRation: '1 / 1',
			padding: 'var(--spacing-2)',
			minHeight: 'auto',
		},
	},
	defaultProps: {
		checkedIcon: <CheckboxChecked />,
		icon: <CheckboxDefault />,
	},
};
