// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditServiceFieldValuesModal_content__AgzQE {
  min-height: 100%;
}
.EditServiceFieldValuesModal_content__AgzQE .EditServiceFieldValuesModal_button__submit__pBcvF {
  margin-top: var(--spacing-4);
}`, "",{"version":3,"sources":["webpack://./src/components/Receipt/PaymentOtherProviderItem/EditServiceFieldValuesModal/index.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;AAWE;EACC,4BAAA;AATH","sourcesContent":[".content {\n\tmin-height: 100%;\n\n\t// header,\n\t// &__header {\n\t// \tflex-direction: column;\n\n\t// \tbutton {\n\t// \t\tmargin-right: auto;\n\t// \t}\n\t// }\n\n\t.button {\n\t\t&__submit {\n\t\t\tmargin-top: var(--spacing-4);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `EditServiceFieldValuesModal_content__AgzQE`,
	"button__submit": `EditServiceFieldValuesModal_button__submit__pBcvF`
};
export default ___CSS_LOADER_EXPORT___;
