import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getUserProfileSelector, setAppLoading, showToast, useAppDispatch, useAppSelector } from 'store';
import { ConfirmOTPCode } from 'components/Auth';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE, RADABANK_OTP_LENGTH, RADABANK_OTP_TITLE } from 'utils/constants';
import { ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService, StorageService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';

export const RadabankAuthInfoApproveCodePage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const profile = useAppSelector(getUserProfileSelector);

	const [otp, setOtp] = useState('');
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		return () => {
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);
		};
	}, []);

	const handleChangeOTP = (value: string) => {
		setIsValid(true);
		setOtp(value);
	};

	const handleResendCode = async () => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CONFIRM_PHONE,
				FIREBASE_EVENT_ANALYTICS_BUTTON.RESEND_OTP
			);
			dispatch(setAppLoading(true));
			await RadabankService.sendInfoCode(profile?.userId as string);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CONFIRM_PHONE,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONFIRM_OTP
			);
			dispatch(setAppLoading(true));
			await RadabankService.approveInfoCode({
				userId: (profile as IUserProfileResponse).userId,
				smsdata: otp,
			});
			history.replace(ROUTER_URL.RADABANK_CARD_AUTH_INFO_DIYA_BEGIN);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CONFIRM_PHONE,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="page">
			<ArrowBackButton className="button-arrow-radabank" onClick={handleGoBack} />
			<div className="auth-content">
				<ConfirmOTPCode
					title={RADABANK_OTP_TITLE}
					phone={profile?.phone ?? ''}
					value={otp}
					onChange={handleChangeOTP}
					isValid={isValid}
					onResendCode={handleResendCode}
					length={RADABANK_OTP_LENGTH}
					onSubmit={handleSubmit}
				/>
			</div>
			<CustomButton
				className="margin-top-auto"
				label="Продовжити"
				onClick={handleSubmit}
				disabled={otp.length !== RADABANK_OTP_LENGTH}
			/>
		</div>
	);
};
