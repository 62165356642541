import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { STORAGE_KEY } from 'utils/enums';
import { getErrorResponse } from 'utils/helpers';
import { StorageService, UserService } from 'utils/services';
import {
	IAvatarUrlResponse,
	IDeleteUserAvatarRequest,
	IUpdateUserProfileAvatarRequest,
	IUserProfileResponse,
	IUserResponse,
	IUserUpdateProfileRequest,
	IUserVerifyResponse,
	IVerifyUserDevice,
	UserLoginRequest,
} from 'utils/types';

export const userLogin = createAsyncThunk('userSlice/login', async (reqBody: UserLoginRequest, { rejectWithValue }) => {
	try {
		const userResponse: AxiosResponse<IUserResponse> = await UserService.login(reqBody);
		await StorageService.set(
			STORAGE_KEY.IS_USER_PUMB,
			userResponse.data.roles?.some((role) => role.name === 'testPUMB')
		);
		return userResponse.data;
	} catch (error) {
		return rejectWithValue(getErrorResponse(error));
	}
});

export const getUserProfile = createAsyncThunk('userSlice/getUserProfile', async (_, { rejectWithValue }) => {
	try {
		const userResponse = await UserService.getProfile();
		return userResponse.data;
	} catch (error) {
		return rejectWithValue(getErrorResponse(error));
	}
});

export const updateUserProfile = createAsyncThunk(
	'userSlice/updateProfile',
	async (reqBody: IUserUpdateProfileRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IUserProfileResponse> = await UserService.updateUserProfile(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateUserEmail = createAsyncThunk('userSlice/updateEmail', async (email: string, { rejectWithValue }) => {
	try {
		const response: AxiosResponse<IUserVerifyResponse> = await UserService.updateUserEmail({ email });

		if (response.data.verificationId) {
			await StorageService.set(STORAGE_KEY.VERIFICATION_ID, response.data.verificationId);
		}

		return email;
	} catch (error) {
		return rejectWithValue(getErrorResponse(error));
	}
});

export const updateUserAvatar = createAsyncThunk(
	'userSlice/updateAvatar',
	async ({ userId, file }: IUpdateUserProfileAvatarRequest, { rejectWithValue }) => {
		try {
			const imageUrlResponse: AxiosResponse<IAvatarUrlResponse> = await UserService.generateImageUrl(userId, file.name);
			await UserService.uploadImage(file, imageUrlResponse.data.url);
			const avatarUser: AxiosResponse<IAvatarUrlResponse> = await UserService.getImage(userId);
			return avatarUser.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const deleteUserAvatar = createAsyncThunk(
	'userSlice/deleteUserAvatar',
	async ({ userId, fileName }: IDeleteUserAvatarRequest, { rejectWithValue }) => {
		try {
			await UserService.deleteUserAvatar({ userId, fileName });
			return true;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getUserAvatar = createAsyncThunk(
	'userSlice/getUserAvatar',
	async (userId: string, { rejectWithValue }) => {
		try {
			const avatarUser: AxiosResponse<IAvatarUrlResponse> = await UserService.getImage(userId);
			return avatarUser.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const confirmUserEmail = createAsyncThunk(
	'userSlice/confirmEmail',
	async (reqBody: IVerifyUserDevice, { rejectWithValue }) => {
		try {
			const response = await UserService.confirmEmailUpdate(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
