import { FC } from 'react';
import classNames from 'classnames';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { IApartmentResponse } from 'utils/types';
import { ApartmentMap } from './ApartmentMap';
import { SingleApartmentMap } from './SingleApartmentMap';
import styles from './index.module.scss';

export interface ApartmentCardProps {
	data: IApartmentResponse;
}

export const ApartmentCard: FC<ApartmentCardProps> = ({ data }) => {
	const apartments = useAppSelector(getApartmentsSelector);

	return (
		<div
			className={classNames(styles.address_card, {
				[styles.address_card_single]: apartments.list.length === 1,
				[styles.address_card_inactive]: !data.singleReceipt,
			})}
		>
			{apartments.list.length > 1 ? <ApartmentMap data={data} /> : <SingleApartmentMap data={data} />}
		</div>
	);
};
