import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { USER_API } from 'core/api';
import store from 'store/root';
import { closeConfirmModal, openConfirmModal } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { STORAGE_KEY, USER_API_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { IPushNotificationsRequest } from 'utils/types';
import { StorageService } from './storage.service';

class PushNotificationsService {
	static isEnabled: null | boolean = null;

	static isInProgress = false;

	private static setIsEnabled = async (value: boolean): Promise<void> => {
		this.isEnabled = value;
		await StorageService.set(STORAGE_KEY.IS_PUSH_ENABLED, value);
	};

	private static addListeners = async (userId: string): Promise<void> => {
		return new Promise(async (resolve, reject) => {
			const deviceFP = await StorageService.get<string>(STORAGE_KEY.DEVICE_FP);
			if (deviceFP) {
				PushNotifications.addListener('registration', async (token) => {
					if (token.value) {
						const reqBody: IPushNotificationsRequest = {
							userId,
							fingerprint: deviceFP,
							deviceToken: token.value,
						};
						await USER_API.post(USER_API_URL.USER_PUSH_NOTIFICATIONS_SUBSCRIBE, reqBody);
						await this.setIsEnabled(true);
						await StorageService.set(STORAGE_KEY.LAST_SUBSCRIBED_TO_PUSH_USER_ID, userId);
						resolve();
					}
				});
			}

			PushNotifications.addListener('registrationError', async (err) => {
				console.error('Registration error: ', err.error);
				reject(err.error);
			});

			PushNotifications.addListener('pushNotificationReceived', (notification) => {
				// eslint-disable-next-line no-console
				console.log('Push notification received: ', notification);
			});

			PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
				// eslint-disable-next-line no-console
				console.log('Push notification action performed', notification);
			});
		});
	};

	private static getDeliveredNotifications = async () => {
		await PushNotifications.getDeliveredNotifications();
	};

	static init = async (): Promise<void> => {
		const isEnabled = await StorageService.get<boolean>(STORAGE_KEY.IS_PUSH_ENABLED);
		if (isEnabled !== null) {
			await this.setIsEnabled(isEnabled);
		}
	};

	static subscribe = async (userId: string, isShowPermissionModal = true): Promise<void> => {
		if (this.isEnabled) {
			return;
		}
		let permStatus = await PushNotifications.checkPermissions();
		if (permStatus.receive === 'denied' && isShowPermissionModal) {
			store.dispatch(
				openConfirmModal({
					submitButtonLabel: 'Так',
					title: 'Системний дозвіл не надано',
					subTitle: 'Бажаєте перейти в налаштування та надати дозвіл на отримання сповіщень?',
					onSubmit: () => {
						NativeSettings.open({
							optionAndroid: AndroidSettings.AppNotification,
							optionIOS: IOSSettings.App,
						});
						store.dispatch(closeConfirmModal());
					},
				})
			);
			return;
		}

		permStatus = await PushNotifications.requestPermissions();

		if (permStatus.receive !== 'granted') {
			this.setIsEnabled(false);
		} else if (permStatus.receive === 'granted') {
			await PushNotifications.register();
			await this.addListeners(userId);
			await this.getDeliveredNotifications();
		}
	};

	static unsubscribe = async (userId: string): Promise<void> => {
		const deviceFP = await StorageService.get<string>(STORAGE_KEY.DEVICE_FP);
		if (deviceFP && this.isEnabled) {
			try {
				await USER_API.post(USER_API_URL.USER_PUSH_NOTIFICATIONS_UNSUBSCRIBE, { userId, fingerprint: deviceFP });
				await PushNotifications.removeAllListeners();
				await PushNotifications.unregister();
				await this.setIsEnabled(false);
				await StorageService.remove(STORAGE_KEY.LAST_SUBSCRIBED_TO_PUSH_USER_ID);
			} catch (error) {
				console.error('ERROR IN UNSUBSCRIBE PUSH', error);
			}
		}
	};

	static toggleSubscribe = async (userId: string): Promise<null | boolean> => {
		try {
			if (this.isInProgress) return this.isEnabled;
			this.isInProgress = true;
			const action = this.isEnabled ? this.unsubscribe : this.subscribe;
			await action(userId);
		} catch (error) {
			store.dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			this.isInProgress = false;
			// eslint-disable-next-line no-unsafe-finally
			return this.isEnabled;
		}
	};

	static subscribeNewUserInsteadOfPrevious = async (userId: string) => {
		if (this.isEnabled !== null && Capacitor.isNativePlatform()) {
			const lastSubscribedToPushUserId = await StorageService.get<string>(STORAGE_KEY.LAST_SUBSCRIBED_TO_PUSH_USER_ID);

			if (lastSubscribedToPushUserId && userId !== lastSubscribedToPushUserId) {
				await this.unsubscribe(lastSubscribedToPushUserId);
				await this.subscribe(userId, false);
			} else if (!lastSubscribedToPushUserId) {
				await this.subscribe(userId, false);
			}
		}
	};
}

export { PushNotificationsService };
