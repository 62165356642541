import { FC } from 'react';
import { Typography } from '@mui/material';
import { ArrowBackButton } from 'components/shared';
import styles from './index.module.scss';

interface RadabankSectionHeaderProps {
	title: string;
	onClickBack?: () => void;
}

export const RadabankSectionHeader: FC<RadabankSectionHeaderProps> = ({ title, onClickBack }) => (
	<div className={styles.content}>
		<ArrowBackButton onClick={onClickBack} />
		<Typography variant="h6">{title}</Typography>
	</div>
);
