import { FC, HTMLAttributes } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { ArrowBackButton } from 'components/shared';
import styles from './index.module.scss';

interface ProviderServiceHeaderProps extends HTMLAttributes<HTMLDivElement> {
	onClickBack?: () => void;
	title?: string;
	icon?: string;
}

export const ProviderServiceHeader: FC<ProviderServiceHeaderProps> = ({
	onClickBack,
	title,
	icon,
	className,
	...props
}) => {
	return (
		<header {...props} className={classNames('service-header', styles.wrapper, className)}>
			{onClickBack && <ArrowBackButton className={styles.button_back} onClick={onClickBack} />}
			<div>
				{icon && <img src={icon} alt="service logo" />}
				{title && (
					<Typography className={styles.title} variant="h5">
						{title}
					</Typography>
				)}
			</div>
		</header>
	);
};
