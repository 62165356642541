import { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import { AutocompleteChangeReason, Box, CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material';
import { ReactComponent as Clear } from 'assets/icons/clear.svg';
import { getModalSelector, useAppSelector } from 'store';
import { useAutofocus } from 'utils/hooks/use-autofocus';
import { ISelectOption } from 'utils/types';
import styles from './index.module.scss';

interface NativeViewProps<T> {
	value: T;
	options: ISelectOption<T>[];
	onChange: (value: T, name: string, reason: AutocompleteChangeReason) => void;
	helperText?: string;
	error?: boolean;
	isLoading?: boolean;
	isOpenModal?: boolean;
	name: string;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	label?: string;
	isSearchDisabled: boolean;
}

export const NativeView: FC<NativeViewProps<any>> = ({
	onChange,
	options,
	value,
	isLoading,
	helperText,
	error,
	name,
	label,
	isSearchDisabled,
	...rest
}) => {
	const { isPresented } = useAppSelector(getModalSelector);

	const inputRef = useAutofocus(isPresented);

	const [inputValue, setInputValue] = useState(options.find((item) => value === item.value)?.label ?? '');
	const [optionsList, setOptionsList] = useState(options);

	useEffect(() => {
		setOptionsList(
			options.filter((item: ISelectOption<any>) => {
				return item.label?.toLowerCase().includes(inputValue.toLowerCase());
			})
		);
	}, [inputValue]);

	useEffect(() => {
		setOptionsList(options);
	}, [options]);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};

	const handleSelectItem = (name: string, item: ISelectOption<any>) => {
		setInputValue(item.label);
		onChange(item.value, name, 'selectOption');
	};

	const handleClearInput = () => {
		onChange('', name, 'clear');
		setInputValue('');
	};

	return (
		<div className={styles.wrapper}>
			{!isSearchDisabled && (
				<div className={styles.header}>
					<TextField
						{...rest}
						size="small"
						name={name}
						inputRef={inputRef}
						type="text"
						id={`native-${name}`}
						value={inputValue}
						placeholder={label}
						onChange={handleInputChange}
						helperText={error ? helperText : ''}
						error={error}
						InputProps={{
							inputProps: {
								inputMode: 'url',
							},
							endAdornment: isLoading ? (
								<InputAdornment position="end">
									<CircularProgress color="inherit" size={20} />
								</InputAdornment>
							) : (
								inputValue && <Clear className={styles.clear} onClick={handleClearInput} />
							),
						}}
						sx={{
							'& .MuiFormHelperText-root': {
								position: 'absolute',
								bottom: '-20px',
							},
						}}
					/>
				</div>
			)}
			<Box className={styles.list}>
				{optionsList.map((item) => (
					<MenuItem key={item.value} onClick={() => handleSelectItem(name, item)}>
						{item.label}
					</MenuItem>
				))}
				{!!inputValue && !optionsList.length && !isLoading && (
					<MenuItem className={styles.empty_state}>Результатів не знайдено</MenuItem>
				)}
			</Box>
		</div>
	);
};
