import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AddApartmentVariant } from 'components/Apartment';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ADD_APARTMENT_VARIANT, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { FirebaseAnalytics, StorageService } from 'utils/services';
import styles from './index.module.scss';

interface UserAppartmentVariantProps {
	value: ADD_APARTMENT_VARIANT | null;
	onChange: (value: ADD_APARTMENT_VARIANT) => void;
	pageName: string;
}

export const UserAppartmentVariant: FC<UserAppartmentVariantProps> = ({ onChange, value, pageName }) => {
	const history = useHistory();

	const handleClickSkip = async (): Promise<void> => {
		FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.ADD_APARTMENT_SKIP);
		await StorageService.set(STORAGE_KEY.IS_WAS_LOGGED_IN, true);
		history.push(ROUTER_URL.PROFILE);
	};

	return (
		<>
			<AddApartmentVariant
				value={value}
				onChange={onChange}
				pageName={FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.UPDATE_PROFILE}
			/>
			<div className={styles.actions}>
				<CustomButton fill="clear" size="large" label="Додати пізніше" onClick={handleClickSkip} />
			</div>
		</>
	);
};
