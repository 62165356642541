import { ChangeEvent } from 'react';
import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { getReactHookFormError } from 'utils/helpers';

type OwnProps<T extends FieldValues = FieldValues> = {
	name: FieldPath<T>;
};

type FormTextFieldProps<T extends FieldValues = FieldValues> = TextFieldProps & OwnProps<T>;

export const FormTextField = <T extends FieldValues = FieldValues>({ name, ...props }: FormTextFieldProps<T>) => {
	const { control } = useFormContext<T>();

	const {
		field: { onChange, ...field },
		fieldState: { isTouched, error: fieldError },
	} = useController<T>({ control, name });

	const { isErrorShown, error } = getReactHookFormError(isTouched, fieldError);

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		onChange(value);
	};

	return (
		<TextField
			error={isErrorShown}
			helperText={isErrorShown ? error : ''}
			onChange={handleChangeInput}
			{...field}
			{...props}
		/>
	);
};
