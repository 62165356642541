import { FC, memo, useEffect, useState } from 'react';
import BackgroundImage from 'assets/images/host-card-bg.png';
import classNames from 'classnames';
// import { getStaticMapSrc } from 'utils/helpers';
import { IGoogleMapStaticRequest } from 'utils/types';
import styles from './index.module.scss';

export const StaticGoogleMap: FC<IGoogleMapStaticRequest & { className?: string }> = memo(({ className }) =>
	// { width, height, lat, lng }

	{
		const [imgSrc, setImgSrc] = useState('');
		// const [isLoaded, setIsLoaded] = useState(false);

		useEffect(() => {
			// not fetching google map. HBILL-343
			// setImgSrc(getStaticMapSrc({ width, height, lat, lng }));
			setImgSrc(BackgroundImage);
		}, []);

		if (!imgSrc) return;
		return (
			<div className={styles.wrapper}>
				<img
					className={classNames(styles.map, className, {
						// [styles.map_invisible]: !isLoaded,
					})}
					src={imgSrc}
					// onLoad={() => setIsLoaded(true)}
					alt=""
				/>
			</div>
		);
	}
);
