// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toggle {
  height: 35px;
  margin-left: 5px;
  z-index: 0;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}
ion-toggle::part(track) {
  background-color: rgba(77, 124, 121, 0.38);
}
ion-toggle::part(handle) {
  background: var(--color-neutral-50);
}
ion-toggle:not(.toggle-checked)::part(track) {
  background: var(--color-neutral-300);
}
ion-toggle.toggle-checked::part(handle) {
  background: var(--color-primary-500);
}`, "",{"version":3,"sources":["webpack://./src/components/shared/CustomSwitch/styles.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,gBAAA;EACA,UAAA;EACA,gEAAA;EACA,iBAAA;EACA,aAAA;AACD;AACC;EACC,0CAAA;AACF;AAEC;EACC,mCAAA;AAAF;AAGC;EACC,oCAAA;AADF;AAIC;EACC,oCAAA;AAFF","sourcesContent":["ion-toggle {\n\theight: 35px;\n\tmargin-left: 5px;\n\tz-index: 0;\n\t/* Required for iOS handle to overflow the height of the track */\n\toverflow: visible;\n\tcontain: none;\n\n\t&::part(track) {\n\t\tbackground-color: rgba(77, 124, 121, 0.38);\n\t}\n\n\t&::part(handle) {\n\t\tbackground: var(--color-neutral-50);\n\t}\n\n\t&:not(.toggle-checked)::part(track) {\n\t\tbackground: var(--color-neutral-300);\n\t}\n\n\t&.toggle-checked::part(handle) {\n\t\tbackground: var(--color-primary-500);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
