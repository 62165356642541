import { FC, Fragment, MouseEvent } from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as Download } from 'assets/icons/download-simple.svg';
import {
	PaymentStatusTitle,
	ProviderAccordion,
	ProviderPreview,
	ProviderServiceBalancePreview,
	ProviderServicesGroupTitle,
} from 'components/shared';
import { PAYMENT_STATUS } from 'utils/enums';
import {
	getBillsReportPaymentStatus,
	getBillsReportServiceTitle,
	getGroupedServicesListByType,
	getProvideIconByType,
	getProviderTitle,
	getSubBillPayedAmount,
} from 'utils/helpers';
import { IBillsSubBillItem } from 'utils/types';
import styles from './index.module.scss';

interface BillsReportProviderItemProps {
	serviceList: IBillsSubBillItem[];
	onClickDownload?: (data: IBillsSubBillItem) => Promise<void>;
	id?: string;
}

export const BillsReportProviderItem: FC<BillsReportProviderItemProps> = ({ serviceList, id, onClickDownload }) => {
	const handleClickDownload = (event: MouseEvent): void => {
		if (onClickDownload && serviceList[0]) {
			event.stopPropagation();
			onClickDownload(serviceList[0]);
		}
	};

	if (!serviceList.length) return;
	return (
		<ProviderAccordion
			defaultExpanded
			title={
				<div className={styles.header}>
					<ProviderPreview
						title={serviceList[0]?.providerTitle ?? getProviderTitle(serviceList[0]?.service)}
						icon={getProvideIconByType(serviceList[0].provider, serviceList[0]?.service)}
						description={
							serviceList[0].serviceProviderData?.providerAccountId || serviceList[0].service?.providerAccountId
								? `o/p ${
										serviceList[0].serviceProviderData?.providerAccountId ?? serviceList[0].service?.providerAccountId
								  }`
								: ''
						}
						code={serviceList[0]?.service?.code ?? ''}
						providerType={serviceList[0]?.provider}
						data={serviceList[0]}
					/>
					<div className={styles.header__status}>
						<PaymentStatusTitle status={getBillsReportPaymentStatus(serviceList)} variant="body2" whiteSpace="nowrap" />
						{!!onClickDownload &&
							!!serviceList[0].service &&
							id &&
							serviceList.some((item) => item.status === PAYMENT_STATUS.PAID) && (
								<IconButton onClick={handleClickDownload}>
									<Download />
								</IconButton>
							)}
					</div>
				</div>
			}
		>
			{getGroupedServicesListByType<IBillsSubBillItem>(serviceList, serviceList[0].provider).map((item, index) => (
				<Fragment key={`report-group-${item.list[0].id ?? index}`}>
					{!!item.title && <ProviderServicesGroupTitle title={item.title} />}
					{item.list.map((serviceItem) => (
						<ProviderServiceBalancePreview
							key={serviceItem.id}
							amount={getSubBillPayedAmount(serviceItem)}
							title={getBillsReportServiceTitle(serviceItem)}
						/>
					))}
				</Fragment>
			))}
		</ProviderAccordion>
	);
};
