import { FC } from 'react';
import { getServicesSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { Observer } from 'components/shared';
import { IServicesItemResponse, ServicesRequest } from 'utils/types';
import { ServiceCard } from './ServiceCard';
import styles from './index.module.scss';

interface ServicesListProps {
	onChange: (data: ServicesRequest) => void;
	data: ServicesRequest;
	onClickCard: (id: string, name: string) => void;
	disabledServicesCodeList?: string[];
}

export const ServicesList: FC<ServicesListProps> = ({ onChange, onClickCard, data, disabledServicesCodeList }) => {
	const services = useAppSelector(getServicesSelector);

	const handleObserve = (): void => {
		onChange({ ...data, offset: data.offset + data.limit });
	};

	return (
		<>
			<div className={styles.list}>
				{services.data.tableData.map((item: IServicesItemResponse) => (
					<ServiceCard
						key={item.id}
						data={item}
						onClick={() => onClickCard(item.id, item.name)}
						isDisabled={disabledServicesCodeList?.includes(item.code)}
					/>
				))}
			</div>
			{services.data.tableData.length !== services.data.listData.totalRows && (
				<Observer onObserve={handleObserve} isLoading={services.isLoading} />
			)}
		</>
	);
};
