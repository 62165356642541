import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { Typography } from '@mui/material';
import { ReactComponent as DiyaSvg } from 'assets/icons/diya.svg';
import { ReactComponent as DiyaEmblemUaSvg } from 'assets/icons/diya-emblem-ua.svg';
import { AxiosResponse } from 'axios';
import {
	getUserProfileSelector,
	setAppLoading,
	showToast,
	updateInfoStatusIdRadabankReducer,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { getErrorMessage, getRadabankPageByStatusCode } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankInfoDiyaBeginResponse, IRadabankInfoStatusResponse } from 'utils/types';
import styles from './index.module.scss';

export const RadabankAuthInfoDiyaBeginPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const listenerRef = useRef<PluginListenerHandle | null>(null);

	const profile = useAppSelector(getUserProfileSelector);

	const [isInProgress, setIsInProgress] = useState(false);

	useEffect(() => {
		return () => {
			handleRemoveAppStateChangeListener();
		};
	}, []);

	const handleInfoDiyaBegin = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INFO_DIYA_BEGIN,
				FIREBASE_EVENT_ANALYTICS_BUTTON.GO_TO_DIYA
			);
			dispatch(setAppLoading(true));
			if (!listenerRef.current) {
				await handleAddAppStateChangeListener();
			}
			const response: AxiosResponse<IRadabankInfoDiyaBeginResponse> = await RadabankService.beginInfoDiya(
				profile?.userId as string
			);
			window.location.href = response.data.deeplink;
			setIsInProgress(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const checkStatus = async (userId: string): Promise<void> => {
		try {
			if (timeoutRef.current) {
				clearInterval(timeoutRef.current);
			}
			const response: AxiosResponse<IRadabankInfoStatusResponse> = await RadabankService.checkInfoStatus(userId);
			const { statusCode } = response.data;
			const redirectUrl = await getRadabankPageByStatusCode(statusCode);
			dispatch(updateInfoStatusIdRadabankReducer(statusCode));
			history.replace(redirectUrl);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsInProgress(false);
		}
	};

	const handleRemoveAppStateChangeListener = async () => {
		if (listenerRef.current) {
			await listenerRef.current.remove();
			listenerRef.current = null;
		}
	};

	const handleAddAppStateChangeListener = async () => {
		listenerRef.current = await App.addListener('appStateChange', async (state) => {
			if (state.isActive && profile) {
				try {
					const response = await RadabankService.checkInfoStatus(profile.userId);
					dispatch(updateInfoStatusIdRadabankReducer(response.data.statusCode));
					if (response.data.statusCode === '851') {
						timeoutRef.current = setTimeout(() => {
							if (profile) {
								checkStatus(profile.userId);
							}
						}, 10000);
					} else {
						await handleRemoveAppStateChangeListener();
						setIsInProgress(false);
					}
				} catch (error) {
					dispatch(showToast({ message: getErrorMessage(error) }))
				}
			}
		});
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INFO_DIYA_BEGIN,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-white-auth">
			<ArrowBackButton onClick={handleGoBack} />
			<div className={styles.content}>
				<div className={styles.content__logos}>
					<DiyaSvg />
					<DiyaEmblemUaSvg />
				</div>
				<Typography variant="h5" textAlign="center" marginBottom="var(--spacing-3)">
					Надати документи ще ніколи не було так легко
				</Typography>
				<Typography variant="body2" textAlign="center">
					Якщо ви є власником ID картки чи біометричного закордонного паспорта - просто подайте документи через “Дія”
				</Typography>
			</div>
			<CustomButton
				id={styles.button_submit}
				fill="outline"
				label="Перейти в Дія"
				startIcon={<DiyaSvg width={24} height={24} />}
				onClick={handleInfoDiyaBegin}
				disabled={isInProgress}
				isLoading={isInProgress}
			/>
		</div>
	);
};
