import dayjs from 'dayjs';
import { METERS_FIELD_ENABLED_DATE } from 'utils/constants';
import { METER_TYPE } from 'utils/enums/meters.enum';
import { IMeterDataResponse } from 'utils/types';

export const checkIsMetersDisabled = (): boolean =>
	!dayjs().isBetween(METERS_FIELD_ENABLED_DATE.FROM, METERS_FIELD_ENABLED_DATE.TO, 'day', '[]');

export const getMeterType = (indexFromProvider: string): METER_TYPE => indexFromProvider.slice(0, 2) as METER_TYPE;

export const getMeterNumber = (indexFromProvider: string): string => indexFromProvider.slice(2);

export const getMetersListsByProviderType = (
	data: IMeterDataResponse[]
): { providerTypeId: number; list: IMeterDataResponse[] }[] =>
	data
		.reduce((acc, item) => {
			const isMeterProviderTypeIdInPayload = acc.find((accItem) => accItem.providerTypeId === item.providerType.id);
			return isMeterProviderTypeIdInPayload
				? acc.map((accItem) =>
						accItem.providerTypeId === item.providerType.id
							? {
									...accItem,
									list: [...accItem.list, item].sort((a, b) => (a.indexFromProvider > b.indexFromProvider ? 1 : -1)),
							  }
							: accItem
				  )
				: [...acc, { providerTypeId: item.providerType.id, list: [item] }];
		}, [] as { providerTypeId: number; list: IMeterDataResponse[] }[])
		// eslint-disable-next-line no-nested-ternary
		.sort((a, b) => a.providerTypeId - b.providerTypeId);

export const getMetersListByIndexFromProvider = (
	data: IMeterDataResponse[]
): { meterType: METER_TYPE; list: IMeterDataResponse[] }[] =>
	data
		.reduce((acc, item) => {
			const isMeterTypeInPayload = acc.find((accItem) => accItem.meterType === getMeterType(item.indexFromProvider));
			return isMeterTypeInPayload
				? acc.map((accItem) =>
						accItem.meterType === getMeterType(item.indexFromProvider)
							? {
									...accItem,
									list: [...accItem.list, item].sort((a, b) => (a.indexFromProvider > b.indexFromProvider ? 1 : -1)),
							  }
							: accItem
				  )
				: [...acc, { meterType: getMeterType(item.indexFromProvider), list: [item] }];
		}, [] as { meterType: METER_TYPE; list: IMeterDataResponse[] }[])
		// eslint-disable-next-line no-nested-ternary
		.sort((a, b) => (getMeterTypeTitle(a.meterType) > getMeterTypeTitle(b.meterType) ? 1 : -1));

export const getMeterTypeTitle = (type: METER_TYPE): string => {
	switch (type) {
		case METER_TYPE.HV:
			return 'Холодна вода';

		case METER_TYPE.GV:
			return 'Гаряча вода';

		default:
			return type;
	}
};

export const getMeterNumberTitle = (indexFromProvider: string): string => {
	const typeString = getMeterTypeTitle(getMeterType(indexFromProvider))
		.split(' ')
		.map((item) => item.slice(0, 1).toUpperCase())
		.join('.')
		.concat('.');
	const numberString = getMeterNumber(indexFromProvider);
	return `${typeString} ${numberString} стояк`;
};
