// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesList_list__5srmP {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-4);
}
@media (max-width: 1024px) {
  .ServicesList_list__5srmP {
    grid-template-columns: repeat(2, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/OtherServices/Services/ServicesList/index.module.scss","webpack://./src/assets/styles/media.scss"],"names":[],"mappings":"AAEA;EACC,WAAA;EACA,aAAA;EACA,qCAAA;EACA,qBAAA;AADD;ACQC;EDXD;IAOE,qCAAA;EAAA;AACF","sourcesContent":["@import 'assets/styles/media.scss';\n\n.list {\n\twidth: 100%;\n\tdisplay: grid;\n\tgrid-template-columns: repeat(4, 1fr);\n\tgap: var(--spacing-4);\n\n\t@include mobile {\n\t\tgrid-template-columns: repeat(2, 1fr);\n\t}\n}\n","@mixin tablet {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin desktop {\n\t@media (min-width: 1025px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-md {\n\t@media (max-width:374px) {\n\t\t@content\n\t}\n}\n\n@mixin mobile-small {\n\t@media (max-width: 319px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-small-height {\n\t@media (max-height: 668px) {\n\t\t@content;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `ServicesList_list__5srmP`
};
export default ___CSS_LOADER_EXPORT___;
