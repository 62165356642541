import { FC, MouseEvent, ReactNode } from 'react';
import { MenuItem, MenuItemProps, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

interface CustomMenuItemProps extends MenuItemProps {
	icon?: ReactNode;
	title: string;
	color?: string;
	description?: string;
	onClick: (event: MouseEvent<HTMLLIElement>) => void;
}

export const CustomMenuItem: FC<CustomMenuItemProps> = ({
	icon,
	title,
	description,
	onClick,
	color,
	className,
	...props
}) => {
	return (
		<MenuItem {...props} className={classNames(styles.button, className)} onClick={onClick}>
			{icon}
			<div>
				<Typography variant="subtitle1" color={color}>
					{title}
				</Typography>

				{description && (
					<Typography variant="body1" color="--color-neutral-500" className={styles.button__description}>
						{description}
					</Typography>
				)}
			</div>
		</MenuItem>
	);
};
