import { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import { Box, CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material';
import { ReactComponent as Clear } from 'assets/icons/clear.svg';
import { ADD_ADDRESS_FIELD_NAME } from 'utils/enums';
import { useAutofocus } from 'utils/hooks/use-autofocus';
import styles from './index.module.scss';

interface ApartmentNativeAutocompleteProps {
	value: string;
	options: string[];
	onChange: (value: string, name: string, isShouldCloseModal: boolean) => void;
	helperText?: string;
	isError?: boolean;
	isLoading?: boolean;
	isOpenModal?: boolean;
	name: string;
	onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onClear: () => void;
}

export const ApartmentNativeAutocomplete: FC<ApartmentNativeAutocompleteProps> = ({
	onChange,
	options,
	value,
	isLoading,
	helperText,
	isError,
	name,
	onClear,
	onInputChange,
	isOpenModal,
	...rest
}) => {
	const inputRef = useAutofocus(isOpenModal);

	const [inputValue, setInputValue] = useState(value);
	const [optionsList, setOptionsList] = useState(options);

	useEffect(() => {
		if (name !== ADD_ADDRESS_FIELD_NAME.STREET) {
			setOptionsList(options.filter((item: string) => item.toLowerCase().includes(inputValue.toLowerCase())));
		}
	}, [name, inputValue]);

	useEffect(() => {
		setOptionsList(options);
	}, [options]);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
		onInputChange(event);

		if (!event.target.value) {
			handleClearInput();
		}
	};

	const handleSelectItem = (name: string, item: string, isShouldCloseModal: boolean) => {
		setInputValue(item);
		onChange(item, name, isShouldCloseModal);
	};

	const handleClearInput = () => {
		onClear();
		setInputValue('');
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<TextField
					{...rest}
					name={name}
					inputRef={inputRef}
					type="text"
					id={`native-${name}`}
					value={inputValue}
					onChange={handleInputChange}
					helperText={isError ? helperText : ''}
					error={isError}
					InputProps={{
						inputProps: {
							inputMode: 'url',
						},
						endAdornment: isLoading ? (
							<InputAdornment position="end">
								<CircularProgress color="inherit" size={20} />
							</InputAdornment>
						) : (
							inputValue && <Clear onClick={handleClearInput} />
						),
					}}
					sx={{
						'& .MuiFormHelperText-root': {
							position: 'absolute',
							bottom: '-20px',
						},
					}}
				/>
			</div>
			<Box className={styles.list}>
				{optionsList.map((item) => (
					<MenuItem key={item} onClick={() => handleSelectItem(name, item, false)}>
						{item}
					</MenuItem>
				))}
				{!!inputValue && !optionsList.length && !isLoading && (
					<MenuItem className={styles.empty_state}>Результатів не знайдено</MenuItem>
				)}
			</Box>
		</div>
	);
};
