import { ChangeEvent, FC } from 'react';
import { Link } from 'react-router-dom';
import { VerifyDeviceProps } from 'pages/Auth/VerifyDevice';
import { AuthContentHeader, ConfirmOTPCode } from 'components/Auth';
import { ArrowBackButton, CustomButton, PasswordInput } from 'components/shared';
import { ROUTER_URL } from 'utils/enums';
import styles from '../index.module.scss';

interface VerifyDeviceWebProps extends VerifyDeviceProps {
	isError: boolean;
	onBlur: () => void;
	onChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const VerifyDeviceWeb: FC<VerifyDeviceWebProps> = ({
	password,
	otp,
	phone,
	onChangePassword,
	onBlur,
	onSubmit,
	onResendCode,
	onChangeOTP,
	onClickBack,
	isUserLoginSuccessed,
	isLoading,
	isValidOTP,
	errorToast,
	isError,
	isButtonDisabled,
}) => {
	return (
		<div className={styles.verifyWeb}>
			<ArrowBackButton onClick={onClickBack} />
			{!isUserLoginSuccessed ? (
				<>
					<AuthContentHeader
						title="Введіть PIN код"
						description="Введіть PIN код який Ви вказували для облікового запису"
					/>
					<PasswordInput value={password} onChange={onChangePassword} onBlur={onBlur} isError={isError} />
					<Link to={ROUTER_URL.AUTH_PASSWORD_RESTORE} className={styles.link_restore}>
						Забули ваш PIN-код?
					</Link>
				</>
			) : (
				<ConfirmOTPCode
					value={otp}
					onChange={onChangeOTP}
					isValid={isValidOTP}
					onSubmit={onSubmit}
					onResendCode={onResendCode}
					phone={phone}
				/>
			)}
			<CustomButton
				className="auth-button"
				label="Продовжити"
				onClick={onSubmit}
				disabled={isButtonDisabled}
				isLoading={isLoading}
				size="large"
			/>
			{errorToast}
		</div>
	);
};
