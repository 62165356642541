import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT } from 'utils/enums';

export const formatDateView = (
	date: Dayjs | number | string,
	format: DATE_FORMAT | string = DATE_FORMAT.DATE_VIEW
): string => dayjs(date).format(format);

export const formatUTCDateView = (
	date: Dayjs | number | string,
	format: DATE_FORMAT | string = DATE_FORMAT.DATE_VIEW
): string => dayjs.utc(date).format(format);


export const getDiffTimeString = (milliseconds: number): string => {
	// Convert milliseconds to seconds
	const totalSeconds = Math.floor(milliseconds / 1000);

	// Calculate minutes and remaining seconds
	const minutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;

	// Format minutes and seconds with leading zeros if needed
	const formattedMinutes = String(minutes < 0 ? 0 : minutes).padStart(2, '0');
	const formattedSeconds = String(seconds < 0 ? 0 : seconds).padStart(2, '0');

	// Return the formatted time as 'mm:ss'
	return `${formattedMinutes}:${formattedSeconds}`;
};
