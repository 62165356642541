/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PAGINATED_BILLS_LIST_DATA } from 'utils/constants';
import { DEFAULT_SERVICES_CATEGORIES_PAGINATED_DATA, DEFAULT_SERVICES_PAGINATED_DATA } from 'utils/constants/services';
import { checkIsAmountServiceField } from 'utils/helpers';
import { IBillReducer, IServiceFieldValueResponse, IUpdateServiceFieldValueRequest } from 'utils/types';
import {
	addApartment,
	deleteSingleReceiptOtherService,
	getApartmentList,
	getApartmentListByAccountIds,
	getBillsList,
	getBillsReport,
	getProvidersList,
	getServicesByCategory,
	getServicesCategories,
	getSingleReceipt,
	paySingleReceipt,
	removeApartment,
	updateSingleReceiptOtherServiceFieldsValues,
} from './actions';
import {
	resetBillReducerOperation,
	resetBillsListOperation,
	resetBillsReportOperation,
	resetOtherServiceCategoriesOperation,
	resetOtherServicesListOperation,
	resetPossibleApartmentsListOperation,
	resetReceiptOperation,
	toggleLoadingReceiptOperation,
} from './operations';

export const billInitialState: IBillReducer = {
	apartments: {
		list: [],
		parsingInfo: {
			parsedProviders: [],
			sentToBillProviders: [],
		},
		isLoading: false,
	},
	receipt: {
		data: null,
		isLoading: false,
	},
	otherServices: {
		categories: {
			isLoading: false,
			data: DEFAULT_SERVICES_CATEGORIES_PAGINATED_DATA,
		},
		services: {
			isLoading: false,
			data: DEFAULT_SERVICES_PAGINATED_DATA,
		},
	},
	bills: {
		list: {
			data: DEFAULT_PAGINATED_BILLS_LIST_DATA,
			isLoading: false,
		},
		report: {
			data: null,
			isLoading: false,
		},
	},
	providers: {
		list: [],
		isLoading: false,
	},
	possibleApartments: {
		list: [],
		isLoading: false,
	},
};

// NOTE: prevents recurrsion types error
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const billStore = createSlice({
	name: 'billSlice',
	initialState: billInitialState,
	reducers: {
		resetReceipt: resetReceiptOperation,
		resetBillReducer: resetBillReducerOperation,
		toggleLoadingReceipt: toggleLoadingReceiptOperation,
		resetBillsReport: resetBillsReportOperation,
		resetBillsList: resetBillsListOperation,
		resetOtherServicesList: resetOtherServicesListOperation,
		resetOtherServiceCategories: resetOtherServiceCategoriesOperation,
		resetPossibleApartmentsList: resetPossibleApartmentsListOperation,
	},
	extraReducers: (builder) => {
		builder.addCase(addApartment.pending, (state) => {
			state.apartments.isLoading = true;
		});
		builder.addCase(addApartment.fulfilled, (state) => {
			state.apartments.isLoading = false;
		});
		builder.addCase(addApartment.rejected, (state) => {
			state.apartments.isLoading = false;
		});

		builder.addCase(getApartmentListByAccountIds.pending, (state) => {
			state.possibleApartments.isLoading = true;
		});
		builder.addCase(getApartmentListByAccountIds.fulfilled, (state, { payload }) => {
			if (payload) {
				state.possibleApartments.list = payload;
			}
			state.possibleApartments.isLoading = false;
		});
		builder.addCase(getApartmentListByAccountIds.rejected, (state) => {
			state.possibleApartments.isLoading = false;
		});

		builder.addCase(getApartmentList.pending, (state) => {
			state.apartments.isLoading = true;
		});
		builder.addCase(getApartmentList.fulfilled, (state, { payload }) => {
			state.apartments = { list: payload.listApartment, parsingInfo: payload.parsingInfo, isLoading: false };
		});
		builder.addCase(getApartmentList.rejected, (state) => {
			state.apartments.isLoading = false;
		});

		builder.addCase(removeApartment.pending, (state) => {
			state.apartments.isLoading = true;
		});
		builder.addCase(removeApartment.fulfilled, (state, { payload }) => {
			state.apartments = {
				list: state.apartments.list.filter((item) => item.apartmentAccountId !== payload),
				parsingInfo: state.apartments.parsingInfo,
				isLoading: false,
			};
		});
		builder.addCase(removeApartment.rejected, (state) => {
			state.apartments.isLoading = false;
		});

		builder.addCase(getSingleReceipt.pending, (state) => {
			state.receipt.isLoading = true;
		});
		builder.addCase(getSingleReceipt.fulfilled, (state, { payload }) => {
			if (payload) {
				state.receipt.data = payload;
			}
			state.receipt.isLoading = false;
		});
		builder.addCase(getSingleReceipt.rejected, (state) => {
			state.receipt.isLoading = false;
		});

		builder.addCase(paySingleReceipt.pending, (state) => {
			state.receipt.isLoading = true;
		});
		builder.addCase(paySingleReceipt.fulfilled, (state) => {
			state.receipt.isLoading = false;
		});
		builder.addCase(paySingleReceipt.rejected, (state) => {
			state.receipt.isLoading = false;
		});

		builder.addCase(getServicesCategories.pending, (state) => {
			state.otherServices.categories.isLoading = true;
		});
		builder.addCase(getServicesCategories.fulfilled, (state, { payload }) => {
			state.otherServices.categories.data.listData = payload.listData;
			state.otherServices.categories.data.tableData =
				payload.listData.showedFrom === 0
					? payload.tableData
					: [...state.otherServices.categories.data.tableData, ...payload.tableData];
			state.otherServices.categories.isLoading = false;
		});
		builder.addCase(getServicesCategories.rejected, (state) => {
			state.otherServices.categories.isLoading = false;
		});

		builder.addCase(getServicesByCategory.pending, (state) => {
			state.otherServices.services.isLoading = true;
		});
		builder.addCase(getServicesByCategory.fulfilled, (state, { payload }) => {
			state.otherServices.services.data.listData = payload.listData;
			state.otherServices.services.data.tableData =
				payload.listData.showedFrom === 0
					? payload.tableData
					: [...state.otherServices.services.data.tableData, ...payload.tableData];
			state.otherServices.services.isLoading = false;
		});
		builder.addCase(getServicesByCategory.rejected, (state) => {
			state.otherServices.services.isLoading = false;
		});

		builder.addCase(updateSingleReceiptOtherServiceFieldsValues.pending, (state) => {
			state.receipt.isLoading = true;
		});
		builder.addCase(updateSingleReceiptOtherServiceFieldsValues.fulfilled, (state, { payload }) => {
			if (state.receipt.data) {
				state.receipt.data.otherPaymentServices = state.receipt.data.otherPaymentServices.map((item) => {
					const isServiceUpdated = item.id === payload.serviceRequestData.serviceId;
					if (!isServiceUpdated) return item;
					const updatedFieldsValues = item.serviceFieldsValues.map((serviceValueItem: IServiceFieldValueResponse) => {
						const payloadValue: IUpdateServiceFieldValueRequest | undefined = payload.fieldsValues.find(
							(payloadFieldValue) => payloadFieldValue.name === serviceValueItem.name
						);
						return { ...serviceValueItem, value: payloadValue?.value ?? serviceValueItem.value };
					});
					const newAmount = payload.fieldsValues.find(checkIsAmountServiceField)?.value;
					return {
						...item,
						amount: newAmount !== undefined ? +newAmount : item.amount,
						serviceFieldsValues: updatedFieldsValues,
					};
				});
			}
			state.receipt.isLoading = false;
		});
		builder.addCase(updateSingleReceiptOtherServiceFieldsValues.rejected, (state) => {
			state.receipt.isLoading = true;
		});

		builder.addCase(deleteSingleReceiptOtherService.pending, (state) => {
			state.receipt.isLoading = true;
		});
		builder.addCase(deleteSingleReceiptOtherService.fulfilled, (state, { payload }) => {
			if (state.receipt.data) {
				state.receipt.data = {
					...state.receipt.data,
					otherPaymentServices: state.receipt.data.otherPaymentServices.filter((item) => item.id !== payload) || [],
				};
			}
			state.receipt.isLoading = false;
		});
		builder.addCase(deleteSingleReceiptOtherService.rejected, (state) => {
			state.receipt.isLoading = false;
		});

		builder.addCase(getBillsList.pending, (state) => {
			state.bills.list.isLoading = true;
		});
		builder.addCase(getBillsList.fulfilled, (state, { payload }) => {
			state.bills.list.data.listData = payload.listData;
			state.bills.list.data.tableData =
				payload.listData.showedFrom === 0
					? payload.tableData
					: [...state.bills.list.data.tableData, ...payload.tableData];
			state.bills.list.isLoading = false;
		});
		builder.addCase(getBillsList.rejected, (state) => {
			state.bills.list.isLoading = false;
		});

		builder.addCase(getBillsReport.pending, (state) => {
			state.bills.report.isLoading = true;
		});
		builder.addCase(getBillsReport.fulfilled, (state, { payload }) => {
			state.bills.report.data = payload;
			state.bills.report.isLoading = false;
		});
		builder.addCase(getBillsReport.rejected, (state) => {
			state.bills.report.isLoading = false;
		});

		builder.addCase(getProvidersList.pending, (state) => {
			state.providers.isLoading = true;
		});
		builder.addCase(getProvidersList.fulfilled, (state, { payload }) => {
			state.providers.list = payload;
			state.providers.isLoading = false;
		});
		builder.addCase(getProvidersList.rejected, (state) => {
			state.providers.isLoading = false;
		});
	},
});

export const {
	resetReceipt,
	resetBillReducer,
	toggleLoadingReceipt,
	resetBillsReport,
	resetBillsList,
	resetOtherServicesList,
	resetOtherServiceCategories,
	resetPossibleApartmentsList,
} = billStore.actions;

export default billStore.reducer;
