import { FC, useState } from 'react';
import { MenuList } from '@mui/material';
import classNames from 'classnames';
import {
	getAppLoadingSelector,
	getRadabankCurrentCardSelector,
	getRadabankCurrentCardSmsInfoSelector,
	getUserIdSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import {
	RadabankManualAddPhone,
	RadabankSectionHeader,
	RadabankSmsInfoPhoneAdd,
	RadabankSmsInfoPhonesList,
} from 'components/RadabankCard';
import { CustomMenuItem, CustomModal, Loader } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_SMS_TYPE_ID } from 'utils/enums';
import { getErrorMessage, removeCodeFormPhone } from 'utils/helpers';
import { useGoBackRadabank, useOpen } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import styles from './index.module.scss';

enum SmsInfoScreen {
	Preview = 0,
	Contacts = 1,
	Manual = 2,
}

export const RadabankCardSmsInfoPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const dispatch = useAppDispatch();

	const { isOpen, handleOpen, handleClose, handleDidPresent, isPresented } = useOpen();

	const userId = useAppSelector(getUserIdSelector);
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);
	const cardSmsInfo = useAppSelector(getRadabankCurrentCardSmsInfoSelector);

	const [currentScreen, setCurrentScreen] = useState(SmsInfoScreen.Preview);

	const isScreenWithAddedNumbers = currentScreen === SmsInfoScreen.Preview;

	const handleReturnToAddedNumbersList = () => {
		setCurrentScreen(SmsInfoScreen.Preview);
	};

	const handleLogAnalyticsEvent = (name: string) => {
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SMS_INFO, name);
	};

	const handleGoBack = () => {
		if (!isScreenWithAddedNumbers) {
			handleReturnToAddedNumbersList();
			return;
		}
		handleLogAnalyticsEvent(FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK);
		handleClickBack();
	};

	const handleOpenAddPhoneModal = () => {
		handleLogAnalyticsEvent(FIREBASE_EVENT_ANALYTICS_BUTTON.ADD_PHONE);
		handleOpen();
	};

	const handleAddPhone = async (phone: string) => {
		if (!userId || !currentCard?.id) return;

		try {
			dispatch(setAppLoading(true));
			await RadabankService.setInternalCardSms({
				userId,
				cardid: currentCard.id,
				phonedest: removeCodeFormPhone(phone),
				typeid: RADABANK_SMS_TYPE_ID.ADD,
			});
			dispatch(showToast({ message: 'Номер успішно додано до СМС інформування', color: 'success' }));
			handleReturnToAddedNumbersList();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleClickPhoneFromContacts = () => {
		handleClose();
		handleLogAnalyticsEvent(FIREBASE_EVENT_ANALYTICS_BUTTON.ADD_PHONE_CONTACTS);
		setCurrentScreen(SmsInfoScreen.Contacts);
	};

	const handleClickEnterPhoneManually = () => {
		handleClose();
		handleLogAnalyticsEvent(FIREBASE_EVENT_ANALYTICS_BUTTON.ADD_PHONE_MANUAL);
		setCurrentScreen(SmsInfoScreen.Manual);
	};

	if (!currentCard) return <Navbar />;

	return (
		<>
			<div className={classNames('page', styles.page)}>
				<RadabankSectionHeader title="СМС інформування" onClickBack={handleGoBack} />
				{currentScreen === SmsInfoScreen.Preview && (
					<RadabankSmsInfoPhonesList onClickAddPhone={handleOpenAddPhoneModal} />
				)}
				{currentScreen === SmsInfoScreen.Contacts && <RadabankSmsInfoPhoneAdd onAddPhone={handleAddPhone} />}
				{currentScreen === SmsInfoScreen.Manual && (
					<RadabankManualAddPhone isModalClosed={isPresented} onAddPhone={handleAddPhone} />
				)}
				{cardSmsInfo.isLoading && !isAppLoading && <Loader />}
			</div>
			<CustomModal title="Додати номер телефону" isOpen={isOpen} onClose={handleDidPresent}>
				<MenuList>
					<CustomMenuItem title="Обрати номер з контактів" onClick={handleClickPhoneFromContacts} />
					<CustomMenuItem title="Ввести вручну" onClick={handleClickEnterPhoneManually} />
				</MenuList>
			</CustomModal>
		</>
	);
};
