import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useSwiper } from 'swiper/react';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON } from 'utils/constants';
import { FirebaseAnalytics } from 'utils/services';
import styles from './styles.module.scss';

export enum SLIDER_DIRECTION {
	NEXT = 'next',
	PREV = 'prev',
}

interface SliderButtonProps {
	image: ReactNode;
	direction: SLIDER_DIRECTION;
	disabled?: boolean;
	onClickHandler?: (dir: SLIDER_DIRECTION) => void;
	pageName: string;
}

const SliderButton: FC<SliderButtonProps> = ({ image, direction, pageName, disabled = false, onClickHandler }) => {
	const swiper = useSwiper();

	const onButtonClick = () => {
		FirebaseAnalytics.logEvent(
			pageName,
			direction === SLIDER_DIRECTION.NEXT
				? FIREBASE_EVENT_ANALYTICS_BUTTON.SLIDER_BUTTON_NEXT
				: FIREBASE_EVENT_ANALYTICS_BUTTON.SLIDER_BUTTON_PREV
		);
		if (direction === SLIDER_DIRECTION.NEXT) {
			swiper.slideNext();
			onClickHandler?.(SLIDER_DIRECTION.NEXT);

			return;
		}

		swiper.slidePrev();
		onClickHandler?.(SLIDER_DIRECTION.PREV);
	};

	return (
		<CustomButton
			className={classNames(styles.button, { [styles.button__active]: !disabled })}
			startIcon={image}
			disabled={disabled}
			onClick={onButtonClick}
			fill="clear"
		/>
	);
};

export default SliderButton;
