import { FC, memo } from 'react';
import { InfoHintModalAnchor } from 'components/shared';
import { HostIdInfoModalContent } from '../HostIdInfoModalContent';

interface HostIdInfoModalAnchorProps {
	className?: string;
}

export const HostIdInfoModalAnchor: FC<HostIdInfoModalAnchorProps> = memo(({ className = '' }) => {
	return (
		<InfoHintModalAnchor className={className}>
			<HostIdInfoModalContent />
		</InfoHintModalAnchor>
	);
});
