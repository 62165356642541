import { useEffect, useRef, useState } from 'react';

const isMobile = () => !!window.matchMedia?.('(pointer: coarse)')?.matches;

const useKeyboardWeb = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const initialHeight = useRef(window.visualViewport?.height);

  useEffect(() => {
    const orientationListener = () => {
      initialHeight.current = window.visualViewport?.height;
    };

    const resizeListener = () => {
      if (window.visualViewport?.height && initialHeight?.current) {
        const newState = window.visualViewport.height < initialHeight.current;
        setIsKeyboardOpen(newState);
      }
    };

    if (isMobile()) {
      window.screen?.orientation?.addEventListener(
        'change',
        orientationListener
      );
      window.visualViewport?.addEventListener('resize', resizeListener);
    }

    return () => {
      if (isMobile()) {
        window.visualViewport?.removeEventListener('resize', resizeListener);
        window.screen?.orientation?.removeEventListener(
          'change',
          orientationListener
        );
      }
    };
  }, []);

  return { isKeyboardOpen };
};

export default useKeyboardWeb;
