import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import CustomSwitch, { CustomSwitchProps } from 'components/shared/CustomSwitch';
import { RADABANK_BOOLEAN_VALUE } from 'utils/enums';
import s from './index.module.scss';

type OwnProps<T extends FieldValues> = {
	name: FieldPath<T>;
	label?: string;
};

type RadabankFormSwitchProps<T extends FieldValues> = OwnProps<T> & CustomSwitchProps;

export const RadabankFormSwitch = <T extends FieldValues>({
	name,
	label,
	...props
}: RadabankFormSwitchProps<T>) => {
	const { control } = useFormContext();

	const {
		field: { value, onChange },
	} = useController({ control, name });

	const isChecked = value === RADABANK_BOOLEAN_VALUE.TRUE;

	const handleChange = () => {
		onChange(isChecked ? RADABANK_BOOLEAN_VALUE.FALSE : RADABANK_BOOLEAN_VALUE.TRUE);
	};

	return (
		<div className={s.wrapper}>
			{label && <Typography onClick={handleChange}>{label}</Typography>}
			<CustomSwitch className={s.switch} checked={isChecked} onChange={handleChange} {...props} />
		</div>
	);
};
