import { FC, HTMLProps, useState } from 'react';
import { Typography } from '@mui/material';
import HostSVG from 'assets/icons/host-icon.svg';
import classNames from 'classnames';
import { IServicesCategoriesItemResponse } from 'utils/types';
import styles from './index.module.scss';

interface ServicesCategoryCardProps extends Omit<HTMLProps<HTMLDivElement>, 'data'> {
	data: IServicesCategoriesItemResponse;
	isDisabled: boolean;
}

export const ServicesCategoryCard: FC<ServicesCategoryCardProps> = ({ data, onClick, isDisabled }) => {
	const [imgSrc, setImgSrc] = useState(data.image ?? HostSVG);

	return (
		<div
			className={classNames('service-card', styles.content, { [styles.content_disabled]: isDisabled })}
			onClick={onClick}
		>
			<div className={styles.content__icon}>
				<img src={imgSrc} onError={() => setImgSrc(HostSVG)} alt="" />
			</div>
			<Typography component="span" variant="body1" textAlign="center" overflow="hidden" textOverflow="ellipsis">
				{data.title}
			</Typography>
		</div>
	);
};
