import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { PAYMENT_STATUS } from 'utils/enums';
import { ICheckPaymentStatusResponse } from 'utils/types';

export const checkBillStatusIsReturned = (data: ICheckPaymentStatusResponse): boolean => {
	const { status } = data;
	return (
		status === PAYMENT_STATUS.PAID ||
		status === PAYMENT_STATUS.REJECTED ||
		status === PAYMENT_STATUS.RETURNED ||
		status === PAYMENT_STATUS.EXPIRED
	);
};

export const getBillIsNeedConfirm3DS = (list: ICheckPaymentStatusResponse[]): ICheckPaymentStatusResponse | undefined =>
	list.find((item) => item.returnUrl_3DS && item.status === PAYMENT_STATUS.NEEDS_CONFIRMATION);

export const confirm3DS = async (returnUrl3DS: string): Promise<void> => {
	if (Capacitor.isNativePlatform()) {
		await Browser.open({ url: returnUrl3DS });
	} else {
		window.location.href = returnUrl3DS;
	}
};
