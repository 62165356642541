import { AxiosError } from 'axios';
import { ERROR_CODE, ERROR_MESSAGE } from 'utils/enums';
import { IErrorResponse } from 'utils/types';

export const getErrorResponse = (error: AxiosError<IErrorResponse>): IErrorResponse | undefined =>
	(error.response?.data || error?.response || error) as IErrorResponse | undefined;

export const getErrorMessage = (error: AxiosError<IErrorResponse>): string => {
	const errorResponse: IErrorResponse | undefined = getErrorResponse(error);
	return (
		errorResponse?.errorData?.clientMessage ||
		errorResponse?.errorData?.message ||
		// 'rejected throwing redux toolkit
		(errorResponse?.message === 'Rejected'
			? ''
			: errorResponse?.message || error?.message || (error as unknown as string))
	);
};

export const getAppartmentErrorMessage = (error: AxiosError<IErrorResponse>): string => {
	const errorResponse = getErrorResponse(error);
	let message = getErrorMessage(error);
	if (errorResponse?.errorData?.code === ERROR_CODE.APARTMENT_DUPLICATE_OR_NOT_FOUND) {
		message =
			errorResponse?.errorData?.message === 'Apartment account not found.'
				? ERROR_MESSAGE.APARTMENT_NOT_FOUND
				: ERROR_MESSAGE.APARTMENT_DUPLICATE;
	}
	return message;
};

export const checkIsSessionError = (error: AxiosError<IErrorResponse>): boolean => {
	const errorResponse: IErrorResponse | undefined = getErrorResponse(error);
	const code = errorResponse?.errorData?.code;
	return (
		code === ERROR_CODE.SHOULD_LOG_IN ||
		code === ERROR_CODE.SESSION_NOT_FOUND ||
		code === ERROR_CODE.SESSION_NOT_AUTHORIZED
	);
};

export const getReceiptErrorMessage = (error: AxiosError<IErrorResponse>): string => {
	const errorResponse = getErrorResponse(error);
	let message = getErrorMessage(error);
	if (errorResponse?.errorData?.code === ERROR_CODE.RECEIPT_NOT_FOUND) {
		message = ERROR_MESSAGE.RECEIPT_NOT_FOUND;
	}
	return message;
};

export const getCreateBillServiceRequestError = (error: any): string => {
	const errorResponse = getErrorResponse(error);

	return errorResponse?.errorData?.code === ERROR_CODE.SERVICE_ACCOUNT_NOT_FOUND &&
		errorResponse.errorData.message === 'Помилка при створенні підписки. Логін або номер договору вказано невірно.'
		? ERROR_MESSAGE.SERVICE_ACCOUNT_NOT_FOUND
		: getErrorMessage(error);
};
