import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import SliderButton, { SLIDER_DIRECTION } from 'components/SliderButton';
import { useDevice } from 'utils/hooks';
import { BENEFITS_LIST } from './helpers';
import styles from './index.module.scss';

interface SignupBenefitsSliderProps {
	pageName: string;
}

export const SignupBenefitsSlider: FC<SignupBenefitsSliderProps> = ({ pageName }) => {
	const { isMobile } = useDevice();

	const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);

	const onSwiperInit = (swiper: SwiperClass) => {
		if (swiperInstance) return;
		setSwiperInstance(swiper);
	};

	return (
		<>
			<Typography className={styles.title} variant="h6">
				Переваги реєстрації в HOST:
			</Typography>
			<Swiper
				className={styles.slider}
				wrapperClass={styles.slider__wrapper}
				slidesPerView={3}
				slidesPerGroup={3}
				spaceBetween={24}
				loop
				onInit={onSwiperInit}
				pagination={{
					bulletClass: styles.slider__bullet,
					bulletActiveClass: styles.slider__bullet_active,
					horizontalClass: styles.slider__horizontal,

					renderBullet(_index: number, className: string) {
						return `<span class="${className}"></span>`;
					},
				}}
				autoplay={{
					delay: 10000,
					disableOnInteraction: false,
				}}
				modules={[Pagination, Autoplay]}
			>
				{BENEFITS_LIST.map((item) => (
					<SwiperSlide key={item.icon} className={styles.slider__slide}>
						<div className={styles.slider__icon}>
							<img src={item.icon} alt="" />
						</div>
						<Typography variant="body2" textAlign="center">
							{item.title}
						</Typography>
					</SwiperSlide>
				))}

				{!isMobile && (
					<div className={styles.slider__nav}>
						<SliderButton image={<ArrowLeft />} direction={SLIDER_DIRECTION.PREV} pageName={pageName} />
						<SliderButton
							image={<ArrowLeft style={{ transform: 'rotateY(180deg)' }} />}
							direction={SLIDER_DIRECTION.NEXT}
							pageName={pageName}
						/>
					</div>
				)}
			</Swiper>
		</>
	);
};
