import { FC } from 'react';
import { Typography } from '@mui/material';
import Logo from 'assets/icons/host-icon.svg';
import styles from './index.module.scss';

interface DataHintModalContentProps {
	title: string;
	description: string[];
	logo?: string;
}

export const DataHintModalContent: FC<DataHintModalContentProps> = ({ title, description, logo = Logo }) => (
	<div className={styles.content}>
		<div className="data-hint-logo">
			<img src={logo} alt="" />
		</div>
		<Typography variant="h5" marginBottom="var(--spacing-3)">
			{title}
		</Typography>
		<div className={styles.content__list}>
			{description.map((item) => (
				<Typography key={item}>{item}</Typography>
			))}
		</div>
	</div>
);
