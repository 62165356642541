import { ChangeEvent, FC } from 'react';
// import { useHistory } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { Typography } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { getUserIsLoadingSelector } from 'store/user-service/selectors';
import { PrivacyLink, SignupBenefitsSlider } from 'components/Auth';
import { CustomButton, PasswordInput, PhoneInput } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
// import { ROUTER_URL } from 'utils/enums';
import { getUnmaskedPhone } from 'utils/helpers';
// import { FirebaseAnalytics } from 'utils/services';
import { LoginProps } from 'utils/types';
import styles from './index.module.scss';

export const LoginWeb: FC<LoginProps> = ({
	data,
	onChange,
	onSubmit,
	// onClickRestorePassword,
	onBlur,
	errors,
	touched,
}) => {
	// const history = useHistory();

	const isUserLoading = useAppSelector(getUserIsLoadingSelector);

	const handleChangePhone = (event: ChangeEvent<HTMLInputElement>): void => {
		onChange({ ...data, [event.target.name]: getUnmaskedPhone(event.target.value) });
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		onChange({ ...data, password: event.target.value });
	};

	// const handleClickSignup = () => {
	// 	FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.LOGIN, FIREBASE_EVENT_ANALYTICS_BUTTON.SIGN_UP);
	// 	history.push(ROUTER_URL.AUTH_CHECK_PHONE);
	// };

	return (
		<>
			<div className={styles.content}>
				<Typography variant="h5" textAlign="center">
					Вхід
				</Typography>
				<PhoneInput
					className={styles.input}
					value={data.phone}
					onChange={handleChangePhone}
					onBlur={onBlur}
					isError={!!errors.phone && touched.phone}
					helperText={touched.phone ? errors.phone : ''}
					onEnterPress={onSubmit}
				/>
				<PasswordInput
					onSubmit={onSubmit}
					className={styles.input}
					value={data.password}
					onChange={handleChangePassword}
					onBlur={onBlur}
					isError={!!errors.password && touched.password}
					errorText={errors.password}
				/>
				{/* <div className={styles.content__actions}>
					<CustomButton label="Зареєструватись" onClick={handleClickSignup} fill="clear" disabled={isUserLoading} />
					<CustomButton
						label="Забули ваш PIN?"
						onClick={onClickRestorePassword}
						fill="clear"
						disabled={isUserLoading}
					/>
				</div> */}
				<CustomButton
					disabled={!!(errors.password || errors.phone || !data.password.length || !data.phone.length || isUserLoading)}
					label="Увійти"
					onClick={onSubmit}
					isLoading={isUserLoading}
					size="large"
				/>
				<PrivacyLink
					textAlign="center"
					color="var(--color-primary-500)"
					marginTop="var(--spacing-6)"
					pageName={FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.LOGIN}
				/>
			</div>
			{!Capacitor.isNativePlatform() && <SignupBenefitsSlider pageName={FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.LOGIN} />}
		</>
	);
};
