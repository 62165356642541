import * as Yup from 'yup';
import { phoneValidationSchema } from './phone.schema';

export const addressVlidationSchema = Yup.object().shape({
	address: Yup.string().required("Адреса обов'язкове поле"),
	flat: Yup.string().required("№ квартири обов'язкове поле"),
});

export const passwordValidationSchema = Yup.string().min(6, 'Пароль має містити 6 цифр');

export const loginValidationSchema = Yup.object().shape({
	phone: phoneValidationSchema,
	password: passwordValidationSchema,
});
