// MUI
import { experimental_extendTheme as extendTheme } from '@mui/material';
import { MuiAutocomplete, MuiAutocompletePaper } from './components/Autocomplete';
import { MuiAvatar } from './components/Avatar';
import { MuiCheckbox } from './components/Checkbox';
import { MuiChip } from './components/Chip';
import { MuiFormControl, MuiInputBase, MuiInputLabel } from './components/Input';
import { MuiStepConnector } from './components/StepConnector';

declare module '@mui/material/styles' {
	interface Palette {
		'--color-primary-900': string;
		'--color-primary-800': string;
		'--color-primary-700': string;
		'--color-primary-600': string;
		'--color-primary-500': string;
		'--color-primary-400': string;
		'--color-primary-300': string;
		'--color-primary-200': string;
		'--color-primary-100': string;
		'--color-primary-50': string;

		'--color-neutral-500': string;
		'--color-neutral-300': string;
		'--color-neutral-200': string;
		'--color-neutral-50': string;
		'--color-neutral-white': string;

		'--color-warning-900': string;
		'--color-warning-800': string;
		'--color-warning-700': string;
		'--color-warning-600': string;
		'--color-warning-500': string;
		'--color-warning-400': string;
		'--color-warning-300': string;
		'--color-warning-200': string;
		'--color-warning-100': string;
		'--color-warning-50': string;

		'--color-error-900': string;
		'--color-error-800': string;
		'--color-error-700': string;
		'--color-error-600': string;
		'--color-error-500': string;
		'--color-error-400': string;
		'--color-error-300': string;
		'--color-error-200': string;
		'--color-error-100': string;
		'--color-error-50': string;

		'--color-success-500': string;
		'--color-success-400': string;
		'--color-success-50': string;

		'--color-info-500': string;

		'--color-neutral-layout-bg': string;

		'--box-shadow-1': string;
		'--box-shadow-2': string;
		'--box-shadow-3': string;
		'--box-shadow-4': string;

		'--spacing-2': string;
		'--spacing-3': string;
		'--spacing-4': string;
		'--spacing-5': string;
		'--spacing-6': string;
		'--spacing-7': string;
		'--spacing-8': string;
		'--spacing-9': string;

		'--main-card-border-radius': string;
		'--apartment-map-bg': string;
		'--font-family': string;
	}

	interface PaletteOptions {
		'--color-primary-900': string;
		'--color-primary-800': string;
		'--color-primary-700': string;
		'--color-primary-600': string;
		'--color-primary-500': string;
		'--color-primary-400': string;
		'--color-primary-300': string;
		'--color-primary-200': string;
		'--color-primary-100': string;
		'--color-primary-50': string;

		'--color-neutral-500': string;
		'--color-neutral-300': string;
		'--color-neutral-200': string;
		'--color-neutral-50': string;
		'--color-neutral-white': string;

		'--color-warning-900': string;
		'--color-warning-800': string;
		'--color-warning-700': string;
		'--color-warning-600': string;
		'--color-warning-500': string;
		'--color-warning-400': string;
		'--color-warning-300': string;
		'--color-warning-200': string;
		'--color-warning-100': string;
		'--color-warning-50': string;

		'--color-error-900': string;
		'--color-error-800': string;
		'--color-error-700': string;
		'--color-error-600': string;
		'--color-error-500': string;
		'--color-error-400': string;
		'--color-error-300': string;
		'--color-error-200': string;
		'--color-error-100': string;
		'--color-error-50': string;

		'--color-success-500': string;
		'--color-success-400': string;
		'--color-success-50': string;

		'--color-info-500': string;

		'--color-neutral-layout-bg': string;

		'--box-shadow-1': string;
		'--box-shadow-2': string;
		'--box-shadow-3': string;
		'--box-shadow-4': string;

		'--spacing-2': string;
		'--spacing-3': string;
		'--spacing-4': string;
		'--spacing-5': string;
		'--spacing-6': string;
		'--spacing-7': string;
		'--spacing-8': string;
		'--spacing-9': string;

		'--main-card-border-radius': string;
		'--apartment-map-bg': string;
		'--font-family': string;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		filled: true;
		outline: true;
	}
	interface ButtonPropsColorOverrides {
		darkTeal: true;
		white: true;
		transparent: true;
	}
}

const theme = extendTheme({
	cssVarPrefix: '',
	colorSchemes: {
		light: {
			palette: {
				'--color-primary-900': 'var(--color-primary-900)',
				'--color-primary-800': 'var(--color-primary-800)',
				'--color-primary-700': 'var(--color-primary-700)',
				'--color-primary-600': 'var(--color-primary-600)',
				'--color-primary-500': 'var(--color-primary-500)',
				'--color-primary-400': 'var(--color-primary-400)',
				'--color-primary-300': 'var(--color-primary-300)',
				'--color-primary-200': 'var(--color-primary-200)',
				'--color-primary-100': 'var(--color-primary-100)',
				'--color-primary-50': 'var(--color-primary-50)',

				'--color-neutral-500': 'var(--color-neutral-500)',
				'--color-neutral-300': 'var(--color-neutral-300)',
				'--color-neutral-200': 'var(--color-neutral-200)',
				'--color-neutral-50': 'var(--color-neutral-50)',
				'--color-neutral-white': 'var(--color-neutral-white)',

				'--color-warning-900': 'var(--color-warning-900)',
				'--color-warning-800': 'var(--color-warning-800)',
				'--color-warning-700': 'var(--color-warning-700)',
				'--color-warning-600': 'var(--color-warning-600)',
				'--color-warning-500': 'var(--color-warning-500)',
				'--color-warning-400': 'var(--color-warning-400)',
				'--color-warning-300': 'var(--color-warning-300)',
				'--color-warning-200': 'var(--color-warning-200)',
				'--color-warning-100': 'var(--color-warning-100)',
				'--color-warning-50': 'var(--color-warning-50)',

				'--color-error-900': 'var(--color-error-900)',
				'--color-error-800': 'var(--color-error-800)',
				'--color-error-700': 'var(--color-error-700)',
				'--color-error-600': 'var(--color-error-600)',
				'--color-error-500': 'var(--color-error-500)',
				'--color-error-400': 'var(--color-error-400)',
				'--color-error-300': 'var(--color-error-300)',
				'--color-error-200': 'var(--color-error-200)',
				'--color-error-100': 'var(--color-error-100)',
				'--color-error-50': 'var(--color-error-50)',

				'--color-success-500': 'var(--color-success-500)',
				'--color-success-400': 'var(--color-success-400)',
				'--color-success-50': 'var(--color-success-50)',

				'--color-info-500': 'var(--color-info-500)',

				'--color-neutral-layout-bg': 'var(--color-neutral-layout-bg)',

				'--box-shadow-1': 'var(--box-shadow-1)',
				'--box-shadow-2': 'var(--box-shadow-2)',
				'--box-shadow-3': 'var(--box-shadow-3)',
				'--box-shadow-4': 'var(--box-shadow-4)',

				'--spacing-2': 'var(--spacing-2)',
				'--spacing-3': 'var(--spacing-3)',
				'--spacing-4': 'var(--spacing-4)',
				'--spacing-5': 'var(--spacing-5)',
				'--spacing-6': 'var(--spacing-6)',
				'--spacing-7': 'var(--spacing-7)',
				'--spacing-8': 'var(--spacing-8)',
				'--spacing-9': 'var(--spacing-9)',

				'--main-card-border-radius': 'var(--main-card-border-radius)',
				'--apartment-map-bg': 'var(--apartment-map-bg)',
				'--font-family': 'var(--font-family)',
			},
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1024,
			lg: 1200,
			xl: 1536,
		},
	},
	typography: {
		fontFamily: 'var(--font-family)',
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		fontWeightLight: 300,
		h4: {
			fontSize: '34px',
			fontStyle: 'normal',
			fontWeight: '700',
			letterSpacing: '0.25px',
		},
		h5: {
			fontSize: '24px',
			fontStyle: 'normal',
			fontWeight: '700',
			lineHeight: '133.4%',
		},
		h6: {
			fontSize: '20px',
			fontStyle: 'normal',
			fontWeight: '700',
			letterSpacing: '0.15px',
			lineHeight: '160%',
		},
		caption: {
			fontSize: '12px',
			fontWeight: '400',
			letterSpacing: '0.4px',
			lineHeight: '150%',
		},
		body1: {
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: '400',
			letterSpacing: '0.15px',
			lineHeight: '150%',
		},
		body2: {
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: '400',
			letterSpacing: '0.15px',
			lineHeight: '143%',
		},
		subtitle1: {
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: '700',
			letterSpacing: '0.15px',
		},
		subtitle2: {
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: '700',
			letterSpacing: '0.1px',
		},
	},
	components: {
		MuiAutocomplete,
		MuiPaper: MuiAutocompletePaper,
		MuiInputLabel,
		MuiInputBase,
		MuiChip,
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					width: '100%',
					height: '52px',
					borderRadius: '40px',
					fontSize: '16px',
					fontWeight: 700,
					lineHeight: '24px',
					letterSpacing: '0.15px',

					'&:focus': {
						outline: 'none',
					},

					'&.Mui-disabled': {
						background: 'var(--color-primary-200)',
					},

					'&.Mui-disabled, &.MuiButton-outline': {
						backgroundColor: 'var(--color-neutral-white)',
					},

					'&.MuiButton-colorError': {
						borderColor: 'var(--color-error-500)',
						color: 'var(--color-error-500)',

						'& > * ': {
							color: 'inherit',
						},

						'&.Mui-disabled, &.MuiButton-outline': {
							backgroundColor: 'var(--color-neutral-white)',
						},
					},
				},
			},
			variants: [
				{
					props: {
						variant: 'filled',
						color: 'darkTeal',
					},
					style: {
						color: 'var(--color-neutral-white, #FFF)',
						backgroundColor: 'var(--color-primary-500, #57486d)',
						'&:hover': {
							backgroundColor: 'var(--color-primary-500, #57486d)',
						},
					},
				},
				{
					props: {
						variant: 'outline',
					},
					style: {
						border: '1px solid var(--color-primary-500)',
						color: 'var(--color-primary-500)',

						'&:hover': {
							backgroundColor: 'white',
						},
					},
				},
				{
					props: {
						variant: 'filled',
						color: 'transparent',
					},
					style: {
						'&, &:hover': {
							background: 'none',
							fontWeight: 700,
							fontSize: '16px',
						},
					},
				},
			],
		},
		MuiCheckbox,
		MuiAvatar,
		// MuiBottomNavigation,
		MuiBottomNavigation: {
			styleOverrides: {
				root: {
					position: 'absolute',
					bottom: 0,
					width: '100%',
					maxWidth: '100vw',

					svg: {
						path: {
							fill: 'var(--color-neutral-300)',
						},
					},
				},
			},
		},
		MuiBottomNavigationAction: {
			styleOverrides: {
				root: {
					minWidth: 'auto',
					fontWeight: 400,
					padding: 0,
					transition: 'none',

					'&.Mui-selected': {
						color: 'var(--color-primary-900)',
						fontWeight: 600,

						svg: {
							path: {
								fill: 'var(--color-primary-900)',
							},
						},
					},
				},
				label: {
					color: 'var(--color-neutral-300)',
					fontSize: '16px',

					'&.Mui-selected': {
						fontSize: '16px',
						color: 'var(--color-primary-900)',
					},
				},
			},
		},
		MuiStepConnector,
		MuiFormControl,

		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: '4px',
					width: 'max-content',

					'&.Mui-disabled svg path': {
						strokeOpacity: '0.5',
					},
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					margin: 0,
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					color: 'var(--color-primary-900)',
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					borderRadius: 'var(--spacing-2)',
					padding: 0,

					'&.Mui-expanded': {
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				},
				content: {
					width: '100%',
					margin: 0,
					padding: 'var(--spacing-2) var(--spacing-4)',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					'& input[value="default"] ~ .MuiSelect-select': {
						color: '#CCC',
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					width: '100%',
					minHeight: 'auto',
				},

				indicator: {
					backgroundColor: 'var(--color-primary-500)',
					height: '1px',
				},

				flexContainer: {
					height: '100%',
					borderBottom: '1px solid var(--color-neutral-300)',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: 'var(--color-neutral-300)',
					fontSize: '16px',
					fontWeight: '700',
					padding: 'var(--spacing-1)',
					paddingBottom: 'var(--spacing-3)',
					minWidth: 'auto',
					minHeight: 'auto',
					lineHeight: '160%',
					textTransform: 'none',
					flexGrow: '1',
					flexWrap: 'wrap',

					'& svg path': {
						stroke: 'var(--color-neutral-300) !important',
					},

					'&.Mui-selected': {
						color: 'var(--color-primary-500)',

						'& svg path': {
							stroke: 'var(--color-primary-500) !important',
						},
					},
				},
			},
		},

		MuiRadio: {
			styleOverrides: {
				root: {
					width: '40px',
					height: '40px',
					color: 'var(--color-neutral-500)',

					svg: {
						flexShrink: 0,
					},

					'&.Mui-checked': {
						color: 'var(--color-primary-500)',
					},
				},
			},
		},

		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					alignItems: 'flex-start',
					margin: 0,
					gap: 'var(--spacing-2)',

					'& .MuiFormControlLabel-label': {
						alignSelf: 'center',
					},
				},

				labelPlacementStart: {
					margin: 0,
					justifyContent: 'flex-end',
				},
			},
		},

		MuiLinearProgress: {
			styleOverrides: {
				root: {
					backgroundColor: '#E6E6E6',
					height: '4px',
					borderRadius: '2px',
				},
				bar: {
					backgroundColor: 'var(--color-primary-500)',
					borderRadius: '2px',
				},
			},
		},

		MuiList: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
	},
});

export type ThemePaletteKeys = keyof typeof theme.colorSchemes.light.palette;

export default theme;
