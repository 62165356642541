import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import { CustomModal } from 'components/shared/CustomModal';
import styles from './index.module.scss';

interface InfoAnchorModalProps {
	isOpen: boolean;
	handleClose: () => void;
}

export const InfoAnchorModal: FC<PropsWithChildren<InfoAnchorModalProps>> = ({ isOpen, handleClose, children }) => {
	return (
		<CustomModal isCloseHeader={false} isOpen={isOpen} onClose={handleClose} isMobileView={false}>
			<div className={styles.content}>
				<InfoIcon className={styles.icon} />
				{children}
				<Typography onClick={handleClose} className={styles.button}>Закрити</Typography>
			</div>
		</CustomModal>
	);
};
