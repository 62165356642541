// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_stepper-wrapper__Jc4lF {
  position: relative;
  background-color: white;
  display: flex;
  gap: 8px;
  flex: 1 1;
  width: 100%;
}

.styles_step__QvBl4 {
  text-align: center;
  flex: 1 1;
  height: 10vh;
  font-size: 14px;
  font-weight: 700;
  color: #2e3131;
  height: min-content;
  white-space: nowrap;
  max-width: 150px;
}
.styles_step_unactive__\\+gDPJ {
  opacity: 0.7;
}
.styles_step__title__ydKlw {
  margin: 0;
}

@media (max-width: 1024px) {
  .styles_step__title__ydKlw {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Stepper/styles.module.scss","webpack://./src/assets/styles/media.scss"],"names":[],"mappings":"AAEA;EACC,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;AADD;;AAIA;EACC,kBAAA;EACA,SAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AADD;AAGC;EACC,YAAA;AADF;AAGC;EACC,SAAA;AADF;;ACZC;EDkBA;IACC,aAAA;EAFA;AACF","sourcesContent":["@import 'assets/styles/media.scss';\n\n.stepper-wrapper {\n\tposition: relative;\n\tbackground-color: white;\n\tdisplay: flex;\n\tgap: 8px;\n\tflex: 1;\n\twidth: 100%;\n}\n\n.step {\n\ttext-align: center;\n\tflex: 1;\n\theight: 10vh;\n\tfont-size: 14px;\n\tfont-weight: 700;\n\tcolor: #2e3131;\n\theight: min-content;\n\twhite-space: nowrap;\n\tmax-width: 150px;\n\n\t&_unactive {\n\t\topacity: 0.7;\n\t}\n\t&__title {\n\t\tmargin: 0;\n\t}\n}\n\n@include mobile {\n\t.step__title {\n\t\tdisplay: none;\n\t}\n}\n","@mixin tablet {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin desktop {\n\t@media (min-width: 1025px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-md {\n\t@media (max-width:374px) {\n\t\t@content\n\t}\n}\n\n@mixin mobile-small {\n\t@media (max-width: 319px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-small-height {\n\t@media (max-height: 668px) {\n\t\t@content;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepper-wrapper": `styles_stepper-wrapper__Jc4lF`,
	"step": `styles_step__QvBl4`,
	"step_unactive": `styles_step_unactive__+gDPJ`,
	"step__title": `styles_step__title__ydKlw`
};
export default ___CSS_LOADER_EXPORT___;
