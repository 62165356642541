// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.Main_wrapper__DtVtN {
  overflow: hidden;
  padding: 0;
  gap: var(--spacing-3);
}`, "",{"version":3,"sources":["webpack://./src/pages/RadabankCard/Main/index.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,UAAA;EACA,qBAAA;AACD","sourcesContent":["div.wrapper {\n\toverflow: hidden;\n\tpadding: 0;\n\tgap: var(--spacing-3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Main_wrapper__DtVtN`
};
export default ___CSS_LOADER_EXPORT___;
