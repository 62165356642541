// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InternalCreateCard_button_next__ZM0xL {
  margin-top: auto;
}

#InternalCreateCard_caption__qJ3GK {
  display: inline-block;
  margin-top: var(--spacing-4);
}

.InternalCreateCard_link__PPmnM {
  text-decoration: underline;
  display: inline;
  color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/pages/RadabankCard/InternalCreateCard/index.module.scss"],"names":[],"mappings":"AACC;EACC,gBAAA;AAAF;;AAIA;EACC,qBAAA;EACA,4BAAA;AADD;;AAIA;EACC,0BAAA;EACA,eAAA;EACA,cAAA;AADD","sourcesContent":[".button {\n\t&_next {\n\t\tmargin-top: auto;\n\t}\n}\n\n#caption {\n\tdisplay: inline-block;\n\tmargin-top: var(--spacing-4);\n}\n\n.link {\n\ttext-decoration: underline;\n\tdisplay: inline;\n\tcolor: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_next": `InternalCreateCard_button_next__ZM0xL`,
	"caption": `InternalCreateCard_caption__qJ3GK`,
	"link": `InternalCreateCard_link__PPmnM`
};
export default ___CSS_LOADER_EXPORT___;
