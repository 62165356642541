import { FC, useState } from 'react';
import classNames from 'classnames';
import { getUserProfileSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { ConfirmOTPCode } from 'components/Auth/ConfirmOTPCode';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { RADABANK_OTP_LENGTH } from 'utils/constants';
import { getErrorMessage } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import styles from './index.module.scss';

interface ConfirmChangeRadabankPasswordNativeProps {
	onResendCode: () => Promise<void>;
	onPasswordChange: () => Promise<void>;
	onClose: () => void;
}

export const ConfirmChangeRadabankPasswordNative: FC<ConfirmChangeRadabankPasswordNativeProps> = ({
	onResendCode,
	onPasswordChange,
	onClose,
}) => {
	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const profile = useAppSelector(getUserProfileSelector);

	const [otp, setOtp] = useState('');
	const [isValid, setIsValid] = useState(true);

	const handleChangeOtp = (value: string) => {
		setIsValid(true);
		setOtp(value);
	};

	const handleConfirmChangeRadabankPassword = async () => {
		if (!profile?.userId) return;

		try {
			setIsLoading(true);
			await RadabankService.approveClientPassword({
				userId: profile.userId,
				otp,
			});
			await onPasswordChange();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			setIsValid(false);
		} finally {
			setIsLoading(false);
		}
	};

	if (!profile) return;

	return (
		<div className={classNames("host-card-page-color-white-auth", styles.wrapper)}>
			<ArrowBackButton onClick={onClose} />
			<div className="auth-content">
				<ConfirmOTPCode
					value={otp}
					onChange={handleChangeOtp}
					isValid={isValid}
					phone={profile?.phone}
					onResendCode={onResendCode}
					length={RADABANK_OTP_LENGTH}
					onSubmit={handleConfirmChangeRadabankPassword}
				/>
			</div>
			<CustomButton
				size="large"
				className={styles.button}
				label="Продовжити"
				onClick={handleConfirmChangeRadabankPassword}
				disabled={otp.length !== RADABANK_OTP_LENGTH}
				isLoading={isLoading}
			/>
		</div>
	);
};
