import { ReactComponent as CardIcon } from 'assets/icons/radabank/credit-card.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/radabank/download.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/radabank/mobile.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/radabank/upload.svg';
import { ROUTER_URL } from 'utils/enums';
import { getRadabankMobileTopUpUrl } from 'utils/helpers';
import { INavbarItem } from 'utils/types';

export const RADABANK_OPERATIONS_MENU_LIST: (INavbarItem & { from?: string; analyticsName: string })[] = [
	{
		label: 'Переказ на картку',
		icon: <UploadIcon />,
		path: ROUTER_URL.RADABANK_CARD_OPERATION,
		analyticsName: 'cardOperation',
	},
	{
		label: 'Платежі',
		icon: <CardIcon />,
		path: ROUTER_URL.SERVICES_CATEGORIES,
		from: ROUTER_URL.RADABANK_CARD,
		analyticsName: 'cardServices',
	},
	{
		label: 'Поповнити мобільний',
		icon: <MobileIcon />,
		path: getRadabankMobileTopUpUrl(),
		from: ROUTER_URL.RADABANK_CARD,
		analyticsName: 'addFundsMobile',
	},
	{
		label: 'Поповнити картку',
		icon: <DownloadIcon />,
		path: ROUTER_URL.RADABANK_CARD_OPERATION_TOP_UP,
		analyticsName: 'addFundsCard',
	},
];
