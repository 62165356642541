import { FC, useState } from 'react';
import { WelcomeViews } from 'layouts/WelcomeViews';
import { OnboardingSlider, StartScreen } from 'components/Welcome';

export const OnboardingPage: FC = () => {
	const [isSkipOnboarding, setIsSkipOnboarding] = useState(false);

	return (
		<WelcomeViews>
			{isSkipOnboarding ? <StartScreen /> : <OnboardingSlider onContinue={() => setIsSkipOnboarding(true)} />}
		</WelcomeViews>
	);
};
