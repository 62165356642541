// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component } from 'react';
import { ErrorPage } from 'pages/Error';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(error) {
		// eslint-disable-next-line no-console
		console.log('ErrorBoundary', error);
		return { hasError: true };
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			return (
				<ErrorPage
					onSubmit={() => {
						this.setState((prev) => ({ ...prev, hasError: false }));
					}}
				/>
			);
		}

		return children;
	}
}

export default ErrorBoundary;
