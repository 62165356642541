import { SORT_DIR, STREET_SORT_FIELD } from 'utils/enums';
import { IPaginatedSearchRequest, IPagination } from "utils/types";

export const STREET_DEFAULT_PAGINATION: IPagination = {
  offset: 0,
  limit: 5,
};

export const STREET_REQ_BODY: Partial<IPaginatedSearchRequest<STREET_SORT_FIELD>> = {
  pagination: STREET_DEFAULT_PAGINATION,
  sorting: {
    direction: SORT_DIR.ASC,
    field: STREET_SORT_FIELD.HCS,
  },
}
