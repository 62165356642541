export const convertCoinsToUAH = (amount: string | number): number => parseFloat((+amount / 100).toFixed(2));

export const convertUAHToCoins = (amount: string | number): number => parseFloat((+amount * 100).toFixed(2));

export const convertKeysFromCoinsToUAH = <T extends object>(data: T, keys: (keyof T)[]) => {
	const result = keys.reduce((acc, curr) => {
		const value = data[curr];

		if (typeof value === 'string' || typeof value === 'number') {
			return { ...acc, [curr]: convertCoinsToUAH(value) };
		}

		return acc;
	}, data);

	return result;
};
