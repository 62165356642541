// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Operation_tabs__V9bII {
  width: 100%;
}

.Operation_content__kPd8G {
  margin-top: var(--spacing-4);
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/RadabankCard/Operation/index.module.scss"],"names":[],"mappings":"AAEA;EACC,WAAA;AADD;;AAIA;EACC,4BAAA;EACA,WAAA;EACA,YAAA;AADD","sourcesContent":["@import 'assets/styles/mixins';\n\n.tabs {\n\twidth: 100%;\n}\n\n.content {\n\tmargin-top: var(--spacing-4);\n\twidth: 100%;\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `Operation_tabs__V9bII`,
	"content": `Operation_content__kPd8G`
};
export default ___CSS_LOADER_EXPORT___;
