import { FC, HTMLProps } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

interface RadabankOperationDetailInfoProps extends HTMLProps<HTMLDivElement> {
	description: string;
	isError?: boolean;
}

export const RadabankOperationDetailInfo: FC<RadabankOperationDetailInfoProps> = ({
	description,
	className,
	isError = false,
	...rest
}) => (
	<div {...rest} className={classNames(styles.content, className, { [styles.content_error]: isError })}>
		<InfoIcon className={styles.icon} />
		<Typography variant="caption" color="var(--color-neutral-600)">
			{description}
		</Typography>
	</div>
);
