import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Failed } from 'assets/icons/failed.svg';
import { CustomButton } from 'components/shared';
import { ERROR_MESSAGE, ROUTER_URL } from 'utils/enums';
import { getIsPumbUserAccessSelector, useAppSelector } from '../../store';
import styles from './index.module.scss';

interface ErrorPageProps {
	onSubmit?: () => void;
}

export const ErrorPage: FC<ErrorPageProps> = ({ onSubmit }) => {
	const history = useHistory();
	const { state } = useLocation<{ from?: string }>();
	const isUserPUMB = useAppSelector(getIsPumbUserAccessSelector);

	const handleClick = (): void => {
		const from = state?.from;

		const redirectUrl = from || ROUTER_URL.PROFILE;
		history.push(isUserPUMB ? redirectUrl : ROUTER_URL.SETTINGS);
		if (onSubmit) {
			onSubmit();
		}
	};

	return (
		<div className={styles.wrapper}>
			<Failed className={styles.error__icon} />
			<p className={styles.error__info}>{`${ERROR_MESSAGE.SOMETHING_WENT_WRONG}...`}</p>
			<div className={styles.error__action}>
				<CustomButton label="На головну" onClick={handleClick} />
			</div>
		</div>
	);
};
