// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmChangeRadabankPasswordNative_wrapper__Dz7p9 {
  padding-top: var(--spacing-6);
}

.ConfirmChangeRadabankPasswordNative_button__ZXFQe {
  position: fixed;
  left: 0;
  padding-bottom: var(--spacing-7);
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/PasswordSetup/ConfirmChangeRadabankPasswordNative/index.module.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;EACC,eAAA;EACA,OAAA;EACA,gCAAA;EACA,SAAA;AACD","sourcesContent":[".wrapper {\n    padding-top: var(--spacing-6);\n}\n\n.button {\n\tposition: fixed;\n\tleft: 0;\n\tpadding-bottom: var(--spacing-7);\n\tbottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ConfirmChangeRadabankPasswordNative_wrapper__Dz7p9`,
	"button": `ConfirmChangeRadabankPasswordNative_button__ZXFQe`
};
export default ___CSS_LOADER_EXPORT___;
