export const MuiAutocomplete = {
	styleOverrides: {
		root: {
			width: '100%',
			minHeight: '57px',
			zIndex: 0,

			svg: {
				transition: 'transform 0.2s ease-in-out',
			},

			'&.Mui-expanded': {
				svg: {
					transform: 'rotateZ(-180deg)',
				},
			},

			'& .MuiFormLabel-root': {
				maxWidth: 'calc(100% - 12px - var(--spacing-9))',
			},
		},

		endAdornment: {
			right: '5px !important',
			// top: 'unset',
			width: '35px',

			'& .MuiAutocomplete-clearIndicator': {
				visibility: 'visible',
			},
			'& button': {
				'& svg': {
					strokeWidth: '2px',
					stroke: '#242223',
					width: '24px',
					height: '24px',
				},
			},
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		popper: ({ theme }: { theme: any }) => ({
			marginTop: '14px !important',
			overflow: 'hidden',
			left: 0,
			[theme.breakpoints.up('md')]: {
				boxShadow: '0px 12px 20px 0px rgba(51, 75, 78, 0.20)',
				borderRadius: '8px',
			},
		}),
		listbox: {
			overflow: 'auto',
			width: '100%',
			padding: 'var(--spacing-3) 0',
		},

		option: {
			width: '100%',
			padding: 'var(--spacing-2) var(--spacing-3) !important',
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: '24px',
			color: 'var(--color-primary-800)',

			'&:not(&:last-of-type)': {
				borderBottom: '1px solid #EFF1F0',
			},
		},
	},
};

export const MuiAutocompletePaper = {
	styleOverrides: {
		root: ({ theme }: { theme: any }) => ({
			[theme.breakpoints.down('sm')]: {
				background: theme.palette.white,
				boxShadow: '0px 12px 20px 0px rgba(51, 75, 78, 0.20)',
			},
		}),
	},
};
