import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { VerifyDeviceProps } from 'pages/Auth/VerifyDevice';
import { ConfirmOTPCode, SetPasswordNative } from 'components/Auth';
import { PinScreenMobileVariantsType } from 'components/Auth/PasswordSetup/helpers';
import { ArrowBackButton, CustomButton, Loader } from 'components/shared';
import { ERROR_MESSAGE, ROUTER_URL } from 'utils/enums';

interface VerifyDeviceNativeProps extends VerifyDeviceProps {
	setPassword: (value: string) => void;
	onSubmitOTP: () => void;
	error: string;
}

export const VerifyDeviceNative: FC<VerifyDeviceNativeProps> = ({
	password,
	setPassword,
	onSubmit,
	error,
	onChangeOTP,
	onResendCode,
	onSubmitOTP,
	isLoading,
	isUserLoginSuccessed,
	phone,
	otp,
	isValidOTP,
	onClickBack,
	errorToast,
	isButtonDisabled,
}) => {
	const history = useHistory();
	const [isError, setIsError] = useState(false);

	const pinType = isError ? PinScreenMobileVariantsType.VerifyWrong : PinScreenMobileVariantsType.Verify;

	useEffect(() => {
		if (!isError && error === ERROR_MESSAGE.INVALID_PASSWORD_OR_NOT_FOUND) {
			setIsError(!!error);
		}
	}, [error]);

	const handleClickRestorePassword = async (): Promise<void> => {
		history.push(ROUTER_URL.AUTH_RESTORE_VERIFY);
	};

	return (
		<>
			{!isUserLoginSuccessed ? (
				<>
					{isLoading && <Loader />}
					<SetPasswordNative
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						onClickForgotPassword={handleClickRestorePassword}
						onSubmit={onSubmit}
						maxLength={6}
						type={pinType}
						isError={!!error}
					/>
				</>
			) : (
				<>
					<ArrowBackButton onClick={onClickBack} />

					<ConfirmOTPCode
						value={otp}
						onChange={onChangeOTP}
						isValid={isValidOTP}
						phone={phone}
						onResendCode={onResendCode}
						onSubmit={onSubmitOTP}
					/>
					<CustomButton
						className="auth-button"
						label="Продовжити"
						onClick={onSubmitOTP}
						disabled={isButtonDisabled}
						isLoading={isLoading}
					/>
				</>
			)}
			{errorToast}
		</>
	);
};
