import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Formik } from 'formik';
import {
	checkRadabankPlasticCardStatus,
	getRadabankCurrentCardSelector,
	getRadabankPlasticCardOrderStatusSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import {
	RadabankOrderPlasticCardAddressFormContent,
	RadabankOrderPlasticCardDeliveryFormContent,
	RadabankOrderPlasticCardStatusContent,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { RadabankFeeOtherPreview } from 'components/RadabankCard/OtherFeePreview';
import { CustomButton } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_NPOSHTA_CREATE_REQUEST_INITIAL_VALUES,
} from 'utils/constants';
import {
	RADABANK_FEE_TYPE,
	RADABANK_NPOSHTA_CARD_STATUS,
	RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST,
	ROUTER_URL,
	STORAGE_KEY,
} from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService, StorageService } from 'utils/services';
import { IRadabankNPoshtaCreateRequest, IUserProfileResponse } from 'utils/types';
import { radabankCardNPoshtaCreateRequestValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

const RadabankFeeOrderTypeMap = {
	[RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.DEPARTMENT]: RADABANK_FEE_TYPE.CARD_ORDER_TO_DEPARTMENT,
	[RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.PARCEL_LOCKER]: RADABANK_FEE_TYPE.CARD_ORDER_TO_LOCKER,
};

const getRadabankOrderFeeType = (type: RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST) => RadabankFeeOrderTypeMap[type];

export const RadabankOrderPlasticCardPage: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const handleClickBack = useGoBackRadabank();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const currentCardStatus = useAppSelector(getRadabankPlasticCardOrderStatusSelector);

	const [initialValues, setInitialValues] = useState(RADABANK_NPOSHTA_CREATE_REQUEST_INITIAL_VALUES);

	useEffect(() => {
		if (currentCard && profile) {
			setInitialValues({
				...initialValues,
				userId: profile.userId,
				cardid: currentCard.id,
				cardidsend: currentCard.id,
				accountidsend: currentCard.accountid,
			});
			handleGetCardStatus(currentCard.id);
		}
	}, []);

	const handleGetCardStatus = async (cardid: string): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			dispatch(checkRadabankPlasticCardStatus({ cardid, userId: (profile as IUserProfileResponse).userId })).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			history.replace(ROUTER_URL.ERROR, { from: ROUTER_URL.RADABANK_CARD });
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleSubmit = async (values: IRadabankNPoshtaCreateRequest): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ORDER_PLASTIC_CARD,
				FIREBASE_EVENT_ANALYTICS_BUTTON.NEXT
			);
			dispatch(setAppLoading(true));
			const response = await RadabankService.createNPoshtaInternalRequest(values);
			await StorageService.set(STORAGE_KEY.RADABANK_NPOSHTA_CREATE_REQUEST_ID, response.data.idrequest);
			await handleGetCardStatus(values.cardid);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ORDER_PLASTIC_CARD,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	if (!currentCardStatus || !currentCard) {
		return <Navbar />;
	}
	return currentCardStatus === RADABANK_NPOSHTA_CARD_STATUS.CAN_CREATE_REQUEST ? (
		<div className="page">
			<RadabankSectionHeader title="Замовлення картки" onClickBack={handleGoBack} />
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={radabankCardNPoshtaCreateRequestValidationSchema}
				enableReinitialize
				isInitialValid={false}
			>
				{({ isValid, values }) => (
					<Form className={styles.wrapper}>
						<RadabankOrderPlasticCardAddressFormContent />
						<RadabankOrderPlasticCardDeliveryFormContent />
						<div className={styles.footer}>
							<RadabankFeeOtherPreview feeType={getRadabankOrderFeeType(values.typerequest)} />
							<CustomButton type="submit" label="Далі" disabled={!isValid} />
						</div>
					</Form>
				)}
			</Formik>
		</div>
	) : (
		<RadabankOrderPlasticCardStatusContent statusId={currentCardStatus} />
	);
};
