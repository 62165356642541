import { FC, HTMLProps } from 'react';
import { Typography } from '@mui/material';
import CustomChip from 'components/shared/Chip';
import styles from './index.module.scss';

interface ReceiptProvidersListPreview extends HTMLProps<HTMLDivElement> {
	title: string;
	chipTitle?: string;
}

export const ReceiptProvidersListPreview: FC<ReceiptProvidersListPreview> = ({ children, title, chipTitle }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<Typography variant="h6">{title}</Typography>
				{!!chipTitle && <CustomChip label={chipTitle} color="primary" />}
			</div>
			{children}
		</div>
	);
};
