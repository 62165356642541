// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Services_wrapper__6k9nO {
  background-color: var(--color-neutral-white);
}

.Services_list__RJB8V {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Services/index.module.scss"],"names":[],"mappings":"AAGA;EACC,4CAAA;AAFD;;AAKA;EACC,WAAA;AAFD","sourcesContent":["@import 'assets/styles/mixins';\n@import 'assets/styles/media';\n\n.wrapper {\n\tbackground-color: var(--color-neutral-white);\n}\n\n.list {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Services_wrapper__6k9nO`,
	"list": `Services_list__RJB8V`
};
export default ___CSS_LOADER_EXPORT___;
