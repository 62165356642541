import { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { OtherServiceAmountInput } from 'components/shared';
import { checkIsAmountServiceFieldName } from 'utils/helpers';
import { useKeyPress } from 'utils/hooks';
import { IAddServiceFieldValueRequest, IServiceFieldResponse, IUpdateServiceFieldValueRequest } from 'utils/types';

interface ServiceFieldItemProps extends Omit<Partial<BaseTextFieldProps>, 'onBlur' | 'value' | 'defaultValue'> {
	data: IAddServiceFieldValueRequest | IUpdateServiceFieldValueRequest;
	value: string;
	onChange?: (data: IAddServiceFieldValueRequest | IUpdateServiceFieldValueRequest) => void;
	onValidate?: (name: string, value: string) => void;
	onBlur?: (name: string) => void;
	errors?: { [key: string]: string };
	touched?: { [key: string]: boolean };
	fieldConfig: IServiceFieldResponse;
	onSubmit?: () => void;
}

export const ServiceFieldItem: FC<ServiceFieldItemProps> = ({
	data,
	onChange,
	onValidate,
	onBlur,
	errors,
	touched,
	fieldConfig,
	onSubmit,
	...props
}) => {
	const [isError, setIsError] = useState(false);
	const [helperText, setHelperText] = useState('');

	useEffect(() => {
		if (errors && touched) {
			setIsError(props.error || (!!errors[data.name] && touched[data.name]));
		}
	}, [props.error, touched, errors]);

	useEffect(() => {
		if (errors && touched) {
			setHelperText(isError ? errors[data.name] : '' || (props.helperText as string));
		}
	}, [props.helperText, isError, errors]);

	const handleChangeStringValue = (event: ChangeEvent<HTMLInputElement>): void => {
		if (onChange) {
			onChange({ ...data, value: event.target.value });
		}
		if (onValidate) {
			onValidate(event.target.name, event.target.value);
		}
	};

	const handleChangeAmountValue = (value: string): void => {
		if (onChange) {
			onChange({ ...data, value: value === '' ? value : `${+value}` });
		}
		if (onValidate) {
			onValidate(data.name, value);
		}
	};

	const handleChangeNumValue = (value: string): void => {
		if (onChange) {
			onChange({ ...data, value });
		}
		if (onValidate) {
			onValidate(props.name as string, value);
		}
	};

	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		if (onBlur) {
			onBlur(event.target.name);
		}
		if (onValidate) {
			onValidate(event.target.name, data.value);
		}
	};

	const handleSubmitOnEnter = useKeyPress(onSubmit);

	if (checkIsAmountServiceFieldName(data.name)) {
		return (
			<OtherServiceAmountInput
				{...props}
				onSubmit={onSubmit}
				error={isError}
				defaultValue={data.value}
				helperText={helperText}
				value={data.value}
				onChange={handleChangeAmountValue}
				onBlur={handleBlur}
				fieldConfig={fieldConfig}
			/>
		);
	}


	return props.inputProps?.inputMode === 'decimal' ? (
		<NumericFormat
			{...props}
			type="text"
			error={isError}
			helperText={helperText}
			customInput={TextField}
			onValueChange={(values) => handleChangeNumValue(values.formattedValue)}
			onBlur={handleBlur}
			onKeyDown={handleSubmitOnEnter}
		/>
	) : (
		<TextField
			{...props}
			error={isError}
			helperText={helperText}
			onChange={handleChangeStringValue}
			onBlur={handleBlur}
			onKeyDown={handleSubmitOnEnter}
		/>
	);
};
