import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { getRadabankCurrentCardOverdraftDataSelector, useAppSelector } from 'store';
import { CardOverdraftAmountInput, RadabankSubmissionCheckbox } from 'components/RadabankCard';
import { CustomButton } from 'components/shared';
import { RADABANK_CLIENT_REQUEST_OVER_TYPE } from 'utils/enums';
import { getTextFieldValidationProps } from 'utils/helpers';
import { IRadabankClientRequestOverRequest } from 'utils/types';
import styles from './index.module.scss';

export const FormContent: FC = () => {
	const { values, setFieldValue, touched, errors, isValid, initialValues } =
		useFormikContext<IRadabankClientRequestOverRequest>();

	const overdraftData = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const isCloseOverdraft = values.typerequest === RADABANK_CLIENT_REQUEST_OVER_TYPE.CLOSE;

	const handleChangeSubmissionCheckbox = (value: boolean) => {
		setIsSubmitted(value);
		setFieldValue('typerequest', RADABANK_CLIENT_REQUEST_OVER_TYPE.UPDATE);
	};

	const handleChangeCloseCheckbox = (value: boolean) => {
		setFieldValue(
			'typerequest',
			value ? RADABANK_CLIENT_REQUEST_OVER_TYPE.CLOSE : RADABANK_CLIENT_REQUEST_OVER_TYPE.UPDATE
		);
		setIsSubmitted(false);
	};

	const isTheSameAmount = +initialValues.amount === +values.amount;

	return (
		!!overdraftData.data && (
			<div className={styles.content}>
				<Field
					name="amount"
					as={CardOverdraftAmountInput}
					onChange={(value: number) => setFieldValue('amount', value > 0 ? value.toString() : '')}
					min={+overdraftData.data.overamountmin}
					max={+overdraftData.data.overamountmax}
					error={getTextFieldValidationProps(errors, touched, 'amount').error}
				/>
				<RadabankSubmissionCheckbox
					className={styles.content__submission}
					// eslint-disable-next-line max-len
					label="Натискаючи кнопку «Зберегти», я прошу змінити суму ліміту овердрафту відповідно до моєї Заяви та на умовах ДКБО ФО"
					onChange={handleChangeSubmissionCheckbox}
					checked={isSubmitted}
				/>
				<div className={styles.content__close}>
					<Typography>Закриття кредитного ліміту</Typography>
					<Field
						name="typerequest"
						as={RadabankSubmissionCheckbox}
						onChange={handleChangeCloseCheckbox}
						checked={isCloseOverdraft}
						label="Прошу закрити кредитний ліміт"
					/>
				</div>
				<CustomButton
					type="submit"
					className={styles.content__submit}
					label="Зберегти"
					disabled={!isValid || ((!isSubmitted || isTheSameAmount) && !isCloseOverdraft)}
				/>
			</div>
		)
	);
};
