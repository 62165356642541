import { FC } from 'react';
import { Typography } from '@mui/material';
import { getRadabankCurrentCardOverdraftDataSelector, getRadabankCurrentCardSelector, useAppSelector } from 'store';
import { RadabankProgress } from 'components/RadabankCard';
import { CURRENCY_LABEL } from 'utils/enums';
import { getAmountTitle, getProgressValue } from 'utils/helpers';
import styles from './index.module.scss';

export const RadabankCardOverdarftProgress: FC = () => {
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const overdraft = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);

	const getOverdraftAmountTitle = (amount: string) =>
		getAmountTitle(amount, currentCard?.curriso === 'UAH' ? CURRENCY_LABEL.UAH : '', 0);

	if (!overdraft.data) return;

	return (
		<RadabankProgress
			className={styles.progress}
			label="Доступна сума"
			from={getOverdraftAmountTitle(overdraft.data.overamountmin)}
			to={getOverdraftAmountTitle(overdraft.data.overamountmax)}
			value={getProgressValue(overdraft.data.overdraft, overdraft.data.overamountmin, overdraft.data.overamountmax)}
		>
			<div className={styles.modal}>
				<Typography textAlign="center" fontWeight="600">
					Зміна суми
				</Typography>
				<Typography variant="body2">
					Подати заявку на зміну кредитного ліміту можна не частіше 1 раза в місяць.
				</Typography>
				<Typography variant="body2">
					Заявка на збільшення розглядається Банком після внесення як мінімум 3 обов&apos;язкових платежів.
				</Typography>
				<Typography variant="body2">
					Клієнти, якими допущено більше 1 прострочення платежу на строк більше 7 календарних днів та на суму більше 100
					грн., не підлягають під умови збільшення
				</Typography>
			</div>
		</RadabankProgress>
	);
};
