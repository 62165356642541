// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLayout_layout__4814U {
  background-color: var(--color-primary-layout-bg);
  overflow-x: clip;
}
@media (max-width: 1024px) {
  .MainLayout_layout__4814U {
    background-color: var(--color-neutral-layout-bg);
  }
  .MainLayout_layout__4814U header.MainLayout_header__E\\+IZJ {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/MainLayout/index.module.scss","webpack://./src/assets/styles/media.scss"],"names":[],"mappings":"AAGA;EACC,gDAAA;EACA,gBAAA;AAFD;ACUC;EDVD;IAKE,gDAAA;EADA;EAGA;IACC,aAAA;EADD;AACF","sourcesContent":["@import 'assets/styles/mixins';\n@import 'assets/styles/media';\n\n.layout {\n\tbackground-color: var(--color-primary-layout-bg);\n\toverflow-x: clip;\n\n\t@include mobile {\n\t\tbackground-color: var(--color-neutral-layout-bg);\n\n\t\theader.header {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n}\n","@mixin tablet {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin desktop {\n\t@media (min-width: 1025px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-md {\n\t@media (max-width:374px) {\n\t\t@content\n\t}\n}\n\n@mixin mobile-small {\n\t@media (max-width: 319px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-small-height {\n\t@media (max-height: 668px) {\n\t\t@content;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `MainLayout_layout__4814U`,
	"header": `MainLayout_header__E+IZJ`
};
export default ___CSS_LOADER_EXPORT___;
