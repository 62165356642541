import { ChangeEvent, FC, MouseEvent, Ref, useEffect, useState } from 'react';
import {
	Autocomplete,
	AutocompleteChangeReason,
	AutocompleteProps,
	CircularProgress,
	InputAdornment,
	TextField,
} from '@mui/material';
import { ReactComponent as DownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/clear.svg';
import { FieldInputProps, useFormikContext } from 'formik';
import { closeModal, openModal, useAppDispatch } from 'store';
import { useDevice } from 'utils/hooks';
import { ISelectOption } from 'utils/types';
import { NativeView } from './NativeView';

export interface CustomAutocompleteProps<T>
	extends Omit<AutocompleteProps<T, any, any, any, any>, 'renderInput' | 'options' | 'onChange'> {
	options?: ISelectOption<T>[];
	isClear?: boolean;
	renderOptions?: any;
	label?: string;
	name?: string;
	error?: boolean;
	helperText?: string;
	inputRef?: Ref<HTMLElement>;
	inputProps?: any;
	placeholder?: string;
	onChange?:
		| ((event: ChangeEvent<HTMLInputElement>) => void)
		| ((name: any, value: any, reason: AutocompleteChangeReason) => void);
	field?: FieldInputProps<T>;
	isSearchDisabled?: boolean;
	formatLabel?: (label: string) => string;
	onFieldBlur?: () => void;
}

export const CustomAutocomplete: FC<CustomAutocompleteProps<any>> = ({
	label,
	error,
	helperText,
	value,
	disabled,
	inputProps,
	onChange,
	field,
	onTouchEnd,
	formatLabel,
	options = [],
	name = '',
	loading = false,
	inputRef = null,
	defaultValue = '',
	placeholder = '',
	isClear = false,
	isSearchDisabled = false,
	onFieldBlur,
	...rest
}) => {
	const { isMobile } = useDevice();

	const dispatch = useAppDispatch();

	const formik = useFormikContext<any>();

	const [isShowClear, setIsShowClear] = useState(false);

	useEffect(() => {
		setIsShowClear(isClear !== undefined ? isClear : !!value);
	}, [isClear, value]);


	const handleOpenAutocomplete = () => {
		if (isMobile) {
			dispatch(
				openModal({
					content: (
						<NativeView
							{...rest}
							{...field}
							options={options}
							isSearchDisabled={isSearchDisabled}
							error={!disabled && !!error}
							label={label}
							onChange={handleChangeNativeItem}
							name={field?.name || name}
							helperText={helperText}
							value={value || field?.value}
						/>
					),
					onClose: onFieldBlur || (formik ? () => formik?.setFieldTouched(field?.name || name, true) : undefined),
					isFullHeight: true,
				})
			);
		}
	};

	const handleChangeNativeItem = (value: any, name: string, reason: AutocompleteChangeReason) => {
		const customEvent = {
			target: {
				name,
				value: value ?? defaultValue ?? '',
			},
		};
		if (formik && field) {
			formik.setFieldValue(field.name, value);
			setTimeout(() => {
				formik.setFieldTouched(field.name, true);
			});
		}
		if (onChange) {
			onChange(customEvent as any, value, reason);
		}
		if (reason !== 'clear') {
			dispatch(closeModal());
		}
	};

	const handleChangeAutocomplete = async (
		payload: ISelectOption<any> | null | string,
		reason: AutocompleteChangeReason
	): Promise<void> => {
		switch (reason) {
			case 'selectOption':
				handleChangeNativeItem((payload as ISelectOption<any>).value, name, reason);
				break;
			case 'clear':
				handleChangeNativeItem('', name, reason);
				break;

			default:
				break;
		}
	};

	const handleClickClear = (event: MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();
		handleChangeNativeItem('', field?.name || name, 'clear');
	};

	const handleAutocompleteClose = () => {
		if (!isMobile && formik) {
			formik.setFieldTouched(field?.name ?? name, true);
		}
	};

	const handleGetOptionLabel = (option: ISelectOption<any> | string) => {
		if (typeof option === 'string') return option;
		const label = (option as ISelectOption<any>)?.label ?? '';
		return formatLabel && label ? formatLabel(label) : label;
	};

	return (
		<Autocomplete
			{...rest}
			{...field}
			options={options}
			value={
				options.find((item) => item.value === field?.value || item.value === value) ?? {
					label: '',
					value: defaultValue ?? field?.value ?? value ?? '',
				}
			}
			onOpen={handleOpenAutocomplete}
			onClose={handleAutocompleteClose}
			renderInput={({ InputProps, ...params }) => (
				<TextField
					{...params}
					InputProps={{
						...InputProps,
						...inputProps,
						endAdornment:
							// eslint-disable-next-line no-nested-ternary
							!field?.value && !value && !loading ? (
								<DownIcon />
							) : loading ? (
								<InputAdornment position="end">
									<CircularProgress sx={{ cursor: 'pointer' }} color="inherit" size={20} />
								</InputAdornment>
							) : (
								(field?.value ?? value) &&
								!isSearchDisabled && <ClearIcon style={{ cursor: 'pointer' }} onClick={handleClickClear} />
							),
					}}
					helperText={!disabled && helperText}
					error={!disabled && !!error}
					placeholder={placeholder}
					label={label}
					inputRef={inputRef}
					{...params}
					disabled={disabled || loading}
					name={field?.name || name}
				/>
			)}
			getOptionLabel={handleGetOptionLabel}
			filterOptions={(options, state) =>
				options.filter((item) => item.label?.toLowerCase().includes(state.inputValue.toLowerCase()))
			}
			onChange={(_, value, reason) => handleChangeAutocomplete(value as ISelectOption<any>, reason)}
			disabled={disabled}
			limitTags={3}
			disableClearable={!isShowClear}
			readOnly={isMobile}
			onBlur={isMobile ? undefined : rest.onBlur}
			freeSolo
		/>
	);
};
