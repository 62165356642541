// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardOverdraftProgress_progress__MnMpU {
  margin-bottom: var(--spacing-6);
}

.CardOverdraftProgress_modal__rpWYi > p {
  margin-bottom: var(--spacing-5);
}
.CardOverdraftProgress_modal__rpWYi > p:not(:first-of-type) {
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/components/RadabankCard/CardOverdraft/CardOverdraftProgress/index.module.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ;;AAGI;EACI,+BAAA;AAAR;AAEQ;EACI,mBAAA;AAAZ","sourcesContent":[".progress {\n    margin-bottom: var(--spacing-6);\n}\n\n.modal {\n    & > p {\n        margin-bottom: var(--spacing-5);\n\n        &:not(:first-of-type) {\n            text-align: justify;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": `CardOverdraftProgress_progress__MnMpU`,
	"modal": `CardOverdraftProgress_modal__rpWYi`
};
export default ___CSS_LOADER_EXPORT___;
