interface MuiComponentSettings {
  [key: string]: string | number | object;
}

export const MuiStepConnector: MuiComponentSettings = {
  styleOverrides: {
    root: {
      position: 'static',
      flex: '1',
      height: 'fit-content',
      '.MuiStepConnector-line': {
        borderColor: '#C4C7C6',
        borderRadius: '8px',
        borderWidth: '4px',
      },
      '&.Mui-active, &.Mui-completed': {
        '.MuiStepConnector-line': {
          borderColor: '#2E3131',
        },
      },
    },
  },
};
