import { FC } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { showToast, useAppDispatch } from 'store';
import styles from './index.module.scss';

interface RadabankCardAccountDetailsRowProps {
	title: string;
	value: string;
}

export const RadabankCardAccountDetailsRow: FC<RadabankCardAccountDetailsRowProps> = ({ title, value }) => {
	const dispatch = useAppDispatch();

	const handleClickCopy = async () => {
		await Clipboard.write({ string: value });
		dispatch(showToast({ message: `${title} скопійовано до буферу`, color: 'success' }));
	};

	return (
		<div className={styles.content}>
			<Typography variant="caption" color="var(--color-neutral-500)">
				{title}
			</Typography>
			<div className={styles.content__row}>
				<Typography variant="subtitle1">{value}</Typography>
				<IconButton onClick={handleClickCopy}>
					<CopyIcon />
				</IconButton>
			</div>
		</div>
	);
};
