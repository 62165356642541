import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { OtherServiceAmountInput } from 'components/shared';
import { getAmountTitle, getServiceHelperText } from 'utils/helpers';
import { IReceiptOtherServiceResponse, IServiceFieldResponse } from 'utils/types';
import styles from './index.module.scss';

interface EditServiceAmountToPayProps {
	serviceData: IReceiptOtherServiceResponse;
	onChange: (amountToPay: string) => void;
	value: string;
	feeAmount: number;
	isDisabled: boolean;
	fieldName: string;
}

export const EditServiceAmountToPay: FC<EditServiceAmountToPayProps> = ({
	value,
	onChange,
	feeAmount,
	isDisabled,
	serviceData,
	fieldName,
}) => {
	const billReceipt = useAppSelector(getBillReceiptSelector);

	const [fieldConfig, setFieldConfig] = useState<IServiceFieldResponse | undefined>(undefined);

	useEffect(() => {
		setFieldConfig(serviceData.serviceFields.find((item) => item.name === fieldName));
	}, []);

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<div className={styles.content__info}>
					<Typography variant="caption">Комісія: {getAmountTitle(feeAmount)}</Typography>
					<OtherServiceAmountInput
						value={value}
						defaultValue={0}
						onChange={onChange}
						fieldConfig={fieldConfig}
						helperText={getServiceHelperText(fieldConfig)}
						disabled={billReceipt.isLoading || isDisabled}
						size="small"
					/>
				</div>
			</div>
		</div>
	);
};
