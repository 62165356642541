import { Theme } from '@mui/material';

// Type
interface MuiComponentSettings {
	[key: string]: string | number | object;
}

declare module '@mui/material/Chip' {
	interface ChipPropsVariantOverrides {
		withIcon: true;
		withComponent: true;
	}
}

export const MuiChip: MuiComponentSettings = {
	styleOverrides: {
		root: {
			position: 'absolute',
			color: '#FFF',
			backgroundColor: '#14AE5C',
			borderRadius: '12px',
		},
	},
	variants: [
		{
			props: {
				variant: 'withIcon',
			},
			style: {
				padding: '0px 13px',
				backgroundColor: '#FFF',
				borderRadius: '20px',
				gap: '7px',
				display: 'flex',
				alignItems: 'center',
				position: 'static',

				'.MuiChip-icon': {
					marginLeft: 0,
					marginRight: 0,
				},
				'.MuiChip-label': {
					fontSize: '14px',
					fontWeight: 400,
					lineHeight: '100%',
					color: '#000',
					opacity: 0.8,
					paddingLeft: 0,
					paddingRight: 0,
				},
			},
		},
		{
			props: {
				variant: 'withComponent',
			},
			style: {
				width: '147px',
				height: '40px',
				backgroundColor: '#FFF',
				gap: '7px',
				borderRadius: '20px',

				'.MuiChip-icon': {
					fontSize: '22px',
					fontWeight: 700,
					lineHeight: '14px',
					color: '#000',
					opacity: 0.8,
					marginLeft: 0,
					marginRight: 0,
				},
				'.MuiChip-label': {
					fontSize: '22px',
					fontWeight: 700,
					lineHeight: '14px',
					color: '#000',
					opacity: 0.8,
					paddingLeft: 0,
					paddingRight: 0,
					overflow: 'visible',
				},
			},
		},
		{
			props: {
				color: 'primary',
			},
			style: ({ theme }: { theme: Theme }) => ({
				backgroundColor: theme.palette['--color-primary-50'],
				color: theme.palette['--color-primary-800'],
				fontWeight: 400,
				letterSpacing: '0.4px',
				position: 'static',
			}),
		},
	],
};
