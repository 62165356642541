import { DependencyList, useMemo } from 'react';
import { throttle } from '../helpers';

export const useThrottle = <Args extends unknown[]>(
	cb: (...args: Args) => void,
	waitingTime: number,
	deps: DependencyList
) => {
	return useMemo(() => throttle(cb, waitingTime), deps);
};
