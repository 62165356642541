import { FC } from 'react';
import { useHistory } from 'react-router';
import { IonSpinner } from '@ionic/react';
import { Typography } from '@mui/material';
import { ReactComponent as AddIcon } from 'assets/icons/add-circle.svg';
import { ReactComponent as HostIcon } from 'assets/icons/host-icon.svg';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { CustomButton } from 'components/shared';
import { APARTMENTS_MAX_COUNT, FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { FirebaseAnalytics } from 'utils/services';
import { ApartmentCard } from './ApartmentCard';
import styles from './index.module.scss';

export const ApartmentsList: FC = () => {
	const history = useHistory();

	const billApartment = useAppSelector(getApartmentsSelector);

	const handleAddAddress = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.HOME,
			FIREBASE_EVENT_ANALYTICS_BUTTON.ADD_ADDRESS,
		)
		history.push(ROUTER_URL.ADD_ADDRESS);
	};

	return (
		<div className={styles.wrapper}>
			{!billApartment.list.length && billApartment.isLoading ? (
				<IonSpinner name="dots" className={styles.loader} />
			) : (
				<div className={styles.content}>
					{billApartment.list.length ? (
						<div className={styles.content__addresses_full}>
							{billApartment.list.map((apartment) => (
								<ApartmentCard key={apartment.apartmentAccountId} data={apartment} />
							))}
						</div>
					) : (
						<div className={styles.content__addresses_empty}>
							<HostIcon width={80} />
							<Typography variant="body1">Додайте вашу першу адресу</Typography>
							<Typography variant="body1">та сплачуйте комунальні послуги без зусиль</Typography>
						</div>
					)}
				</div>
			)}
			<CustomButton
				className={styles.button}
				startIcon={<AddIcon />}
				label="Додати адресу"
				onClick={handleAddAddress}
				disabled={billApartment.list.length >= APARTMENTS_MAX_COUNT}
				fill={billApartment.list.length ? 'clear' : 'solid'}
			/>
		</div>
	);
};
