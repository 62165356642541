import { FC, useEffect, useState } from 'react';
import { IonBackdrop, IonSpinner } from '@ionic/react';
import styles from './index.module.scss';

export const Loader: FC = () => {
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		return () => {
			setIsVisible(false);
		};
	}, []);

	return (
		<div className={styles.wrapper}>
			<IonBackdrop visible={isVisible} />
			<IonSpinner name="lines" />
		</div>
	);
};
