import { FC } from 'react';
import { Typography } from '@mui/material';
import { CHECK_RESPONSE } from 'utils/enums';
import styles from './index.module.scss';

interface ConfirmedDataPreviewProps {
	data: CHECK_RESPONSE;
	title: string;
}

export const ConfirmedDataPreview: FC<ConfirmedDataPreviewProps> = ({ data, title }) => (
	<div className={styles.content}>
		<Typography className={styles.content_title} variant="body2" color="--color-neutral-500">
			{title}
		</Typography>
		<Typography variant="caption" color={data === CHECK_RESPONSE.YES ? '--color-success-500' : '--color-error-500'}>
			{data === CHECK_RESPONSE.YES ? 'Підтверджено' : 'Не підтверджено'}
		</Typography>
	</div>
);
