import { FC } from 'react';
import { RestorePasswordProps } from 'pages/Auth/RestorePassword';
import { ConfirmPasswordNative, SetPasswordNative } from 'components/Auth';
import { Loader } from 'components/shared';
import { SETUP_PASSWORD_STEP } from 'utils/enums';

export const RestorePasswordNative: FC<RestorePasswordProps> = ({
	passwordData,
	step,
	onChangePassword,
	onChangeStep,
	onSubmit,
	isLoading,
}) => {
	return step === SETUP_PASSWORD_STEP.SET ? (
		<SetPasswordNative
			value={passwordData.password}
			onChange={onChangePassword}
			maxLength={6}
			onSubmit={() => onChangeStep(SETUP_PASSWORD_STEP.CONFIRM)}
		/>
	) : (
		<>
			{isLoading && <Loader />}
			<ConfirmPasswordNative
				value={passwordData.confirmPassword}
				password={passwordData.password}
				maxLength={6}
				onChange={onChangePassword}
				onSubmit={onSubmit}
			/>
		</>
	);
};
