import { FC } from 'react';
import { IonSpinner } from '@ionic/react';
import { Typography } from '@mui/material';
import { getAmountTitle } from 'utils/helpers';
import styles from './index.module.scss';

interface RadabankFeePreviewProps {
	amount: number;
	currency: string;
	isLoading?: boolean;
}

export const RadabankFeePreview: FC<RadabankFeePreviewProps> = ({ amount, currency, isLoading = false }) => {
	return (
		<div className={styles.content}>
			<Typography color="#404040" variant="body2">
				Комісія
			</Typography>
			{isLoading ? (
				<IonSpinner className={styles.loader} name="dots" />
			) : (
				<Typography color="#404040" variant="body2" textAlign="right">
					{getAmountTitle(amount, currency)}
				</Typography>
			)}
		</div>
	);
};
