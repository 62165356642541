import { FC } from 'react';
import { MenuItem, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { CardPreview } from 'components/shared';
import { ICardListItemResponse, StringOrNull } from 'utils/types';
import styles from './index.module.scss';

interface PaymentMethodCardItemProps {
	item: ICardListItemResponse;
	disabled: boolean;
	handlePayByCard: (cardId: string, provider: StringOrNull) => void;
	disabledMessage?: string;
}

export const PaymentMethodCardItem: FC<PaymentMethodCardItemProps> = ({
	item,
	disabled,
	handlePayByCard,
	disabledMessage = '',
}) => {
	const handlePay = () => {
		if (disabled) {
			return;
		}
		handlePayByCard(item.cardId, item.provider);
	};

	return (
		<MenuItem
			className={classNames(styles.item, {
				[styles.disabled]: disabled,
			})}
			onClick={handlePay}
		>
			{disabled ? (
				<Tooltip title={disabledMessage}>
					<div className={styles.tooltip}>
						<CardPreview data={item} />
					</div>
				</Tooltip>
			) : (
				<CardPreview data={item} />
			)}
		</MenuItem>
	);
};
