import { FC, memo } from 'react';
import { useHistory } from 'react-router';
import { MenuItem, Typography } from '@mui/material';
import { CURRENCY_LABEL } from 'utils/enums';
import { getAmountTitle, getLocaleAmountString, getRadabankTransactionByIdUrl } from 'utils/helpers';
import { IRadabankInternalOperation } from 'utils/types';
import styles from './index.module.scss';

interface OperationItemProps {
	data: IRadabankInternalOperation;
}

export const OperationItem: FC<OperationItemProps> = memo(({ data }) => {
	const history = useHistory();

	const handleNavigateToTransactionDetailsPage = () => {
		history.push(getRadabankTransactionByIdUrl(data.id));
	};

	const isUAHTransaction = data.currencyonline === CURRENCY_LABEL.UAH_CODE;

	return (
		<MenuItem onClick={handleNavigateToTransactionDetailsPage} className={styles.content}>
			<img
				className={styles.content__icon}
				src={`${process.env.REACT_APP_RADABANK_CATEGORY_IMAGE_URL}/${data.merchantpicture}`}
				alt=""
			/>
			<div className={styles.content__info}>
				<Typography variant="caption">
					{`${data.categoryname.slice(0, 1).toUpperCase()}${data.categoryname.slice(1)}`}
				</Typography>
				<Typography variant="body2">{`${data.merchantkey} ${data.merchantname}`}</Typography>
				{!!data.comment && <Typography fontSize="10px">Коментар: {data.comment}</Typography>}
			</div>
			<div className={styles.amounts}>
				{!isUAHTransaction && (
					<Typography variant="caption" className={styles.content__amount}>
						{getAmountTitle(data.amountonline, data.currencyonline)}
					</Typography>
				)}
				<Typography className={styles.content__amount}>{getLocaleAmountString(data.amount)}</Typography>
			</div>
		</MenuItem>
	);
});
