import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { CustomButton } from 'components/shared';
import styles from './index.module.scss';

export interface SurveyStepControlsProps {
	stepsCount: number;
	currentStep: number;
	onPreviousStep: () => void;
	onNextStep: () => void;
	className?: string;
}

export const SurveyStepControls: FC<SurveyStepControlsProps> = ({
	stepsCount,
	currentStep,
	onPreviousStep,
	onNextStep,
	className = ''
}) => {
	const { formState, trigger } = useFormContext();

	useEffect(() => {
		trigger();
	}, [currentStep]);

	const isFirstStep = !currentStep;

	const isLastStep = currentStep === stepsCount - 1;

	const nextStepButtonText = isLastStep ? 'Продовжити' : 'Далі';

	const nextStepButtonType = isLastStep ? 'submit' : 'button';

	return (
		<div className={classNames(styles.controls, className)}>
			{!isFirstStep && <CustomButton fill="outline" label="Назад" onClick={onPreviousStep} />}
			<CustomButton
				type={nextStepButtonType}
				label={nextStepButtonText}
				disabled={!formState.isValid}
				onClick={onNextStep}
			/>
		</div>
	);
};
