import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';
import { TextField, TextFieldProps } from '@mui/material';
import { CURRENCY_LABEL } from 'utils/enums';
import { getReactHookFormError } from 'utils/helpers';

type OwnProps<T extends FieldValues> = {
	name: FieldPath<T>;
};

type FormNumberInputProps<T extends FieldValues> = Omit<NumericFormatProps, 'customInput'> &
	OwnProps<T> &
	TextFieldProps;

export const FormNumberInput = <T extends FieldValues>({ name, ...props }: FormNumberInputProps<T>) => {
	const { control } = useFormContext<T>();

	const {
		field: { value, onChange, onBlur },
		fieldState: { isTouched, error: fieldError },
	} = useController<T>({ control, name });

	const { isErrorShown, error } = getReactHookFormError(isTouched, fieldError);

	const handleChangeValue = ({ value }: NumberFormatValues) => {
		onChange(value, isTouched);
	};

	return (
		<NumericFormat
			value={value}
			allowLeadingZeros
			fixedDecimalScale
			thousandSeparator=" "
			decimalScale={0}
			allowNegative={false}
			suffix={CURRENCY_LABEL.UAH}
			customInput={TextField}
			{...props}
			name={name}
			onValueChange={handleChangeValue}
			onBlur={onBlur}
			onChange={undefined}
			error={isErrorShown}
			helperText={isErrorShown ? error : ''}
		/>
	);
};
