import { KeyboardEvent } from 'react';

export const useKeyPress = (handler?: () => void, key = 'Enter') => {
	const handleKeyPress = (e: KeyboardEvent) => {
		if (e.key === key) {
			handler?.();
		}
	};

	return handleKeyPress;
};
