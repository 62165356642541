export enum PROVIDER_TYPE {
	KVBO = 'KVBO',
	HCS = 'HCS',
	KVD = 'KVD',
	KTS = 'KTS',
	PORTMONE = 'PORTMONE',
	HOSTPAY = 'HOSTPAY',
}

export enum PROVIDER_ID {
	KVBO = 1,
	KVD,
	KTS,
	HCS,
}
