/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { IMetersReducer } from 'utils/types';
import { metersInitialState } from './reducer';

export const resetMetersReducerOperation = () => {
	return { ...metersInitialState };
};

export const resetMetersListOperation = (state: IMetersReducer) => {
	return { ...state, list: [] };
};

export const setMetersApartmentAccountIdOperation = (state: IMetersReducer, { payload }: PayloadAction<number>) => {
	return { ...state, list: [], apartmentAccountId: payload };
};
