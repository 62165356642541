import { FC, PropsWithChildren, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { STORAGE_KEY } from 'utils/enums';
import { StorageService } from 'utils/services';

export const RadabankSurveyWrapper: FC<PropsWithChildren> = ({ children }) => {
	const { getValues } = useFormContext();

	const handleStoreData = async () => {
		try {
			await StorageService.set(STORAGE_KEY.RADABANK_INFO_ANKETA_DATA, getValues());
		} catch (error) {
			console.error('erorr during set form values to storage', error);
		}
	};

	useEffect(() => {
		return () => {
			handleStoreData();
		};
	}, []);

	return children;
};
