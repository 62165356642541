import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

export const openCamera = async (source: CameraSource, resultType: CameraResultType) => {
	try {
		return await Camera.getPhoto({
			quality: 90,
			source,
			resultType,
		});
	} catch (e) {
		console.error('Error capturing photo:', e);
	}
};
