import BigNumber from 'bignumber.js';

export const convertUuidToNumber = (uuid: string): string => {
	// remove the dashes from the given uuid and convert to a hexadecimal BigNumber object
	const bn = new BigNumber(uuid.replace(/-/g, ''), 16);
	// return the decimal representation of the BigNumber object as a string
	return bn.toString(10);
};

export const getProgressValue = (value: string | number, min: string | number, max: string | number): number => {
	return ((Number(value) - Number(min)) / (Number(max) - Number(min))) * 100;
};
