// Libs
import { FC } from 'react';
// MUI
import { Chip, ChipProps, SxProps } from '@mui/material';

interface ExtendedChipProps extends ChipProps {
  styles?: SxProps;
}

const CustomChip: FC<ExtendedChipProps> = ({
  icon,
  label,
  disabled = false,
  color,
  styles,
  ...props
}) => (
  <Chip
    icon={icon}
    label={label}
    color={color}
    disabled={disabled}
    sx={styles}
    {...props}
  />
);

export default CustomChip;
