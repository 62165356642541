import { FC, memo } from 'react';
import { Browser } from '@capacitor/browser';
import { Typography } from '@mui/material';
import { setAppLoading, useAppDispatch } from 'store';
import { RadabankProgress } from 'components/RadabankCard/Progress';
import { CustomButton } from 'components/shared';
import { ENV_VARIABLE, FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { convertCoinsToUAH, getAmountTitle, getProgressValue } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankGetP2PAmountResponse } from 'utils/types';
import styles from './index.module.scss';

interface RadabankOperationCardLimitsProgressBarProps {
	p2pLimits: IRadabankGetP2PAmountResponse;
}

export const RadabankOperationCardLimitsProgressBar: FC<RadabankOperationCardLimitsProgressBarProps> = memo(
	({ p2pLimits }) => {
		const dispatch = useAppDispatch();

		const progressValue = getProgressValue(
			convertCoinsToUAH(p2pLimits.amountrest),
			'0',
			convertCoinsToUAH(p2pLimits.amountmaxhost)
		);

		const handleOpenNbuLimits = async () => {
			try {
				dispatch(setAppLoading(true));
				const url = ENV_VARIABLE.RB_NBU_LIMITS_DOC;
				FirebaseAnalytics.logEvent(
					FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_CARD,
					FIREBASE_EVENT_ANALYTICS_BUTTON.VIEW_NBU_LIMITS
				);

				if (url) {
					await Browser.open({ url });
				}
			} catch (error) {
				console.error('Error during opening NBU Limits', error);
			} finally {
				dispatch(setAppLoading(false));
			}
		};

		return (
			<RadabankProgress
				label="НБУ встановив щомісячний ліміт"
				from={`Залишилось ${getAmountTitle(convertCoinsToUAH(p2pLimits.amountrest))}`}
				to={getAmountTitle(convertCoinsToUAH(p2pLimits.amountmaxhost))}
				value={progressValue}
			>
				<div className={styles.modal}>
					<Typography textAlign="center" fontWeight="600" mb="var(--spacing-3)">
						Постанова НБУ
					</Typography>
					<Typography variant="body2">
						Від 01.10.2024 Національний банк України встановлює ліміт на операції переказів на картки у 150 000 грн. на
						календарний місяць:
					</Typography>
					<ul className={styles.list}>
						<li>
							<Typography variant="body2">ліміт на операції p2p в додатку складає 100 000 грн</Typography>
						</li>
						<li>
							<Typography variant="body2">
								ліміт на операції p2p, що ініційовані в інших сервісах, складає 50 000 грн
							</Typography>
						</li>
						<li>
							<Typography variant="body2">перекази на IBAN не лімітовані</Typography>
						</li>
					</ul>
					<CustomButton
						onClick={handleOpenNbuLimits}
						fill="outline"
						className={styles.button}
						label="Переглянути постанову"
					/>
				</div>
			</RadabankProgress>
		);
	}
);
