// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloseButton_button__\\+Y9YH {
  width: 32px;
  height: 32px;
}
.CloseButton_button__\\+Y9YH .CloseButton_icon__4XqS- {
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/CloseButton/index.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;AACD;AACC;EACC,WAAA;EACA,YAAA;AACF","sourcesContent":[".button {\n\twidth: 32px;\n\theight: 32px;\n\n\t.icon {\n\t\twidth: 20px;\n\t\theight: 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `CloseButton_button__+Y9YH`,
	"icon": `CloseButton_icon__4XqS-`
};
export default ___CSS_LOADER_EXPORT___;
