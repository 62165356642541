import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Header } from 'components/Header';
import { useDevice } from 'utils/hooks';
import styles from './index.module.scss';

interface MainLayoutProps {
	children: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
	const { isIos } = useDevice();

	return (
		<section id="layout" className={classNames('layout', styles.layout, { 'layout-ios': isIos })}>
			<Header className={styles.header} />
			{children}
		</section>
	);
};
