import { FC } from 'react';
import { useHistory } from 'react-router';
import { MenuItem, Typography } from '@mui/material';
import { INavbarItem } from 'utils/types';
import styles from './index.module.scss';

interface RadabankSettingsItemProps {
	data: INavbarItem;
}

export const RadabankSettingsItem: FC<RadabankSettingsItemProps> = ({ data }) => {
	const history = useHistory();

	const handleClickItem = () => {
		history.push(data.path);
	};

	return (
		<MenuItem className={styles.content} onClick={handleClickItem}>
			<img className={styles.content__icon} src={data.icon as string} alt="" />
			<div className={styles.content__info}>
				<Typography>{data.label}</Typography>
				{!!data.description && (
					<Typography variant="body2" color="var(--color-neutral-600)">
						{data.description}
					</Typography>
				)}
			</div>
		</MenuItem>
	);
};
