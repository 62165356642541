import { FC, useState } from 'react';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { updateUserEmail } from 'store/user-service/actions';
import { ConfirmEmailModalContent } from 'components/Settings/UserProfile/ConfirmEmailModalContent';
import { EditModalContent } from 'components/Settings/UserProfile/EditModalContent';
import { CYRILLIC_CHARACTERS } from 'utils/constants';
import { EDIT_USER_EMAIL, STORAGE_KEY } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { StorageService, UserService } from 'utils/services';
import { IUserUpdateProfileRequest } from 'utils/types';
import { emailValidationSchema } from 'utils/validation';

interface EditEmailModalContent {
	defaultValue: string;
	onClose: () => void;
	isConfirmed: boolean;
	isOpenModal: boolean;
}

export const EditEmailModalContent: FC<EditEmailModalContent> = ({ defaultValue, onClose, isConfirmed, isOpenModal }) => {
	const dispatch = useAppDispatch();

	const [isConfirmStep, setIsConfirmStep] = useState<boolean>(false);
	const [email, setEmail] = useState(defaultValue as string);
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (updatedData: IUserUpdateProfileRequest) => {
		try {
			setIsLoading(true);
			if (updatedData.email !== defaultValue) {
				await UserService.checkUserEmail({ email: updatedData.email });
			}
			await dispatch(updateUserEmail(updatedData.email as string)).unwrap();
			await StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
			setEmail(updatedData.email as string);
			setIsConfirmStep(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	return isConfirmStep ? (
		<ConfirmEmailModalContent email={email} onClose={onClose} />
	) : (
		<EditModalContent
			{...EDIT_USER_EMAIL}
			onSubmit={onSubmit}
			isOpenModal={isOpenModal}
			validationSchema={emailValidationSchema}
			defaultValue={defaultValue}
			isLoading={isLoading}
			isConfirmed={isConfirmed}
			regex={CYRILLIC_CHARACTERS}
		/>
	);
};
