// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-url-listener_wrapper__NPG\\+o {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-neutral-white);
  z-index: 9999999;
}`, "",{"version":3,"sources":["webpack://./src/core/app-url-listener/index.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,4CAAA;EACA,gBAAA;AACD","sourcesContent":[".wrapper {\n\twidth: 100vw;\n\theight: 100vh;\n\theight: 100dvh;\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\tbackground-color: var(--color-neutral-white);\n\tz-index: 9999999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `app-url-listener_wrapper__NPG+o`
};
export default ___CSS_LOADER_EXPORT___;
