import { Capacitor } from '@capacitor/core';
import {
	FirebaseAnalytics as CapacitorFirebaseAnalytics,
	FirebaseInitOptions,
} from '@capacitor-community/firebase-analytics';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class FirebaseAnalytics {
	static init = async () => {
		const emptyKeys = Object.entries(firebaseConfig).reduce(
			(acc, entry) => (!entry[1] ? [...acc, entry[0]] : acc),
			[] as string[]
		);
		try {
			if (!emptyKeys.length) {
				// Initialize Firebase
				await CapacitorFirebaseAnalytics.initializeFirebase(firebaseConfig as FirebaseInitOptions);
			} else {
				throw new Error(`Not found ${emptyKeys.join(', ')}`);
			}
		} catch (error) {
			console.error('FirebaseAnalytics error: ', error);
		}
	};

	static logEvent(page: string, name: string) {
		CapacitorFirebaseAnalytics.logEvent({
			name: `view_${page}_${name}_${Capacitor.getPlatform() === 'android' ? 'aOS' : Capacitor.getPlatform()}`,
			params: {},
		});
	}
}

export { FirebaseAnalytics };

// TODO: remove later, max size of analytics name equals 40 chars
// eslint-disable-next-line no-console
console.log(
	Object.values(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH)
		.map((page) => Object.values(FIREBASE_EVENT_ANALYTICS_BUTTON).map((name) => `view_${page}_${name}_aOS`))
		.flat()
		.filter((item) => item.length > 40)
);
