import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import { TextField } from '@mui/material';
import { CustomButton } from 'components/shared';
import { handleKeyDownEmail } from 'utils/helpers';
import { useAutofocus } from 'utils/hooks/use-autofocus';
import { emailValidationSchema } from 'utils/validation';

interface DownloadStatementEmailFormProps {
	onSubmit: (email: string) => void;
}

export const DownloadStatementEmailForm: FC<DownloadStatementEmailFormProps> = ({ onSubmit }) => {
	const [email, setEmail] = useState('');
	const [isValid, setIsValid] = useState(false);
	const [isTouched, setIsTouched] = useState(false);

    const inputRef = useAutofocus();

	const handleChangeEmail = async (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setEmail(value);
		try {
			await emailValidationSchema.validate(value);
			setIsValid(true);
		} catch (error) {
			setIsValid(false);
		}
	};

	const handleKeyDownInput = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			onSubmit(email);
			return;
		}
		handleKeyDownEmail(event);
	};

	const handleBlurInput = () => {
		setIsTouched(true);
	};

	const handleSubmit = () => {
		onSubmit(email);
	};

	return (
		<div>
			<TextField
				sx={{ marginBottom: 'var(--spacing-5)' }}
				variant="standard"
				type="email"
				value={email}
				onChange={handleChangeEmail}
				label="Email"
				size="small"
                inputRef={inputRef}
				onBlur={handleBlurInput}
				onKeyDown={handleKeyDownInput}
				error={isTouched && !isValid}
			/>
			<CustomButton disabled={!isValid} label="Відправити" onClick={handleSubmit} />
		</div>
	);
};
