import { FC } from 'react';
import { FieldInputProps } from 'formik';
import { useFormikFieldError } from 'utils/hooks';
import { NumberInput, NumberInputProps } from '../NumberInput';

type OwnProps = {
	field: FieldInputProps<string | number>;
};

type NumberInputFormikProps = OwnProps & NumberInputProps;

export const NumberInputFormik: FC<NumberInputFormikProps> = ({ field, ...props }) => {
	const { isErrorShown, error, setValue } = useFormikFieldError(field);

	const handleChangeInput = (value: string) => {
		setValue(value, true);
	};

	return (
		<NumberInput
			error={isErrorShown}
			helperText={isErrorShown ? error : ''}
			{...field}
			{...props}
			onChange={handleChangeInput}
		/>
	);
};
