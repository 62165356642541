export enum TARIFF_ITEMS_STATE {
	EDITABLE = 'E',
	VISIBLE = 'V',
}

export enum SERVICE_CODE_KVD {
	WATTER_SUPPLY = '1',
	DRAINAGE = '2',
	CUSTOMER_SERVICE_WATTER_SUPPLY = '8',
	// TODO: add code when it will be
	CUSTOMER_SERVICE_DRAINAGE = '',
	MAINTENANCE_AND_SERVICE_WATTER_SUPPLY = '16',
	MAINTENANCE_AND_SERVICE_DRAINAGE = '32',
}

export enum SERVICE_CODE_KTS {
	SUPPLY_SERVICE_THERMAL_ENERGY = '3',
	SUPPLY_SERVICE_HOT_WATER = '4',
	MAINTENANCE_AND_REPAIR_HEAT_SUPPLY = '5',
	MAINTENANCE_AND_REPAIR_HOT_WATER = '6',
}

export enum SERVICE_CODE_HCS {
	RENT = '02',
}

export enum SERVICE_CODE_KVBO {
	HOUSEHOLD_WASTE = '1',
}
