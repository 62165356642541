import { FieldInputProps } from 'formik';
import { useFormikField } from './use-formik-field';

export const useFormikFieldError = <T>(field: FieldInputProps<T>) => {
	const { error, touched, ...rest } = useFormikField<T>(field);

	const isErrorShown = touched && !!error;

	return {
		error,
		isErrorShown,
		touched,
		...rest,
	};
};
