// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReceiptMenu_popover__XvNRZ::part(backdrop) {
  background: none;
}
.ReceiptMenu_popover__XvNRZ::part(content) {
  margin-top: 8px;
  width: 328px;
  box-sizing: border-box;
  padding: 0;
  border-radius: 8px;
  background: var(--color-neutral-white);
}

.ReceiptMenu_modal__header__1xDKy {
  position: relative;
}
@media (max-width: 1024px) {
  .ReceiptMenu_modal__header__1xDKy {
    margin-bottom: var(--spacing-4);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Receipt/ReceiptMap/Actions/ReceiptMenu/index.module.scss","webpack://./src/assets/styles/media.scss"],"names":[],"mappings":"AAGC;EACC,gBAAA;AAFF;AAKC;EACC,eAAA;EACA,YAAA;EACA,sBAAA;EACA,UAAA;EACA,kBAAA;EACA,sCAAA;AAHF;;AAQC;EACC,kBAAA;AALF;ACDC;EDUC;IACC,+BAAA;EAND;AACF","sourcesContent":["@import 'assets/styles/media';\n\n.popover {\n\t&::part(backdrop) {\n\t\tbackground: none;\n\t}\n\n\t&::part(content) {\n\t\tmargin-top: 8px;\n\t\twidth: 328px;\n\t\tbox-sizing: border-box;\n\t\tpadding: 0;\n\t\tborder-radius: 8px;\n\t\tbackground: var(--color-neutral-white);\n\t}\n}\n\n.modal {\n\t&__header {\n\t\tposition: relative;\n\t}\n\n\t@include mobile {\n\t\t&__header {\n\t\t\tmargin-bottom: var(--spacing-4);\n\t\t}\n\t}\n}\n","@mixin tablet {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin desktop {\n\t@media (min-width: 1025px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-md {\n\t@media (max-width:374px) {\n\t\t@content\n\t}\n}\n\n@mixin mobile-small {\n\t@media (max-width: 319px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-small-height {\n\t@media (max-height: 668px) {\n\t\t@content;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `ReceiptMenu_popover__XvNRZ`,
	"modal__header": `ReceiptMenu_modal__header__1xDKy`
};
export default ___CSS_LOADER_EXPORT___;
