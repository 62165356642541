import { useCallback, useRef } from 'react';
import { VariableSizeList } from 'react-window';

export const useVariableVirtualList = (defaultElementHeight: number) => {
	const listRef = useRef<VariableSizeList>(null);
	const sizeMap = useRef<Record<number, number>>({});

	const handleGetElementSize = (index: number) => sizeMap.current[index] ?? defaultElementHeight;

	const handleUpdateSizeMap = useCallback((index: number, size: number) => {
		listRef.current?.resetAfterIndex(0);
		sizeMap.current = { ...sizeMap.current, [index]: size };
	}, []);

	return {
		listRef,
		handleGetElementSize,
		handleUpdateSizeMap,
	};
};
