// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateProfileData_actions__5iJoV {
  margin-top: auto;
  display: grid;
  gap: 16px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/UpdateProfileData/index.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,SAAA;EACA,WAAA;AACJ","sourcesContent":[".actions {\n    margin-top: auto;\n    display: grid;\n    gap: 16px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `UpdateProfileData_actions__5iJoV`
};
export default ___CSS_LOADER_EXPORT___;
