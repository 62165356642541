import { FC, useEffect, useState } from 'react';
import {
	getRadabankCurrentCardOverdraftDataSelector,
	getRadabankCurrentCardSelector,
	getRadabankInternalInfoSprData,
	getUserProfileSelector,
	showToast,
	updateRadabankOverdraftData,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankCardOverdraftRequestBanner } from 'components/RadabankCard';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_CLIENT_REQUEST_OVER_INITIAL_VALUES,
} from 'utils/constants';
import { convertUAHToCoins, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankClientRequestOverRequest } from 'utils/types';
import { RadabankCardOverdraftCreateForm } from './Form';

interface RadabankCardOverdraftCreateProps {
	onGoBack: () => void;
}

export const RadabankCardOverdraftCreate: FC<RadabankCardOverdraftCreateProps> = ({ onGoBack }) => {
	const dispatch = useAppDispatch();

	const overdraftData = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [isShowBanner, setIsShowBanner] = useState(false);
	const [initialValues, setInitialValues] = useState(RADABANK_CLIENT_REQUEST_OVER_INITIAL_VALUES);

	useEffect(() => {
		if (overdraftData.data && currentCard?.id && profile?.userId) {
			setInitialValues((prev) => ({
				...prev,
				amount: overdraftData.data?.overdraft || '',
				cardid: currentCard.id,
				userId: profile.userId,
			}));
		}
	}, [overdraftData.data, currentCard?.id, profile?.userId]);

	useEffect(() => {
		if (profile?.userId) {
			handleGetInternalSprData(profile.userId);
			handleGetPreRequestOverDataMb(profile.userId);
		}
	}, [profile?.userId]);

	const handleGetInternalSprData = async (userId: string) => {
		try {
			await dispatch(getRadabankInternalInfoSprData(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleGetPreRequestOverDataMb = async (userId: string) => {
		try {
			const { data } = await RadabankService.internalClientPreRequestOverMb({ userId });
			// NOTE: set to undefined default response fields which are not related to form
			setInitialValues((prev) => ({ ...prev, ...data, result: undefined, duration: undefined, error: undefined }));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleSubmit = async (values: IRadabankClientRequestOverRequest): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_OVERDRAFT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE
			);
			await dispatch(
				updateRadabankOverdraftData({
					...values,
					cardid: values.cardid || `${currentCard?.id}`,
					amount: `${convertUAHToCoins(values.amount)}`,
					amount_out: `${convertUAHToCoins(values.amount_out)}`,
					amount_in: `${convertUAHToCoins(values.amount_in)}`,
					amount_add: `${convertUAHToCoins(values.amount_add)}`,
				})
			).unwrap();
			setIsShowBanner(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	if (isShowBanner) return <RadabankCardOverdraftRequestBanner />;

	return <RadabankCardOverdraftCreateForm onGoBack={onGoBack} initialValues={initialValues} onSubmit={handleSubmit} />;
};
