import { FC, HTMLProps } from 'react';
import { ReactComponent as Failed } from 'assets/icons/failed.svg';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

interface StatusIconProps extends HTMLProps<HTMLDivElement> {
	variant: 'success' | 'error';
}

export const StatusIcon: FC<StatusIconProps> = ({ variant, className, ...props }) => {
	return (
		<div
			{...props}
			className={classNames(styles.wrapper, className, {
				[styles.success]: variant === 'success',
				[styles.error]: variant === 'error',
			})}
		>
			{variant === 'success' ? <Success /> : <Failed />}
		</div>
	);
};
