import { Capacitor } from '@capacitor/core';
import { PLATFORMS } from 'utils/enums';

export const checkDeviceIsWeb = (): boolean => Capacitor.getPlatform() === PLATFORMS.WEB;

export const isMobileUseragent = {
	Android() {
		return /Android/i.test(navigator.userAgent);
	},
	iOS() {
		return /iPhone|iPad|iPod/i.test(navigator.userAgent);
	},
};
