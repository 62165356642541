import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { RADABANK_BOOLEAN_VALUE } from 'utils/enums';
import { RadabankSubmissionCheckbox, RadabankSubmissionCheckboxProps } from '../SubmissionCheckbox';

type OwnProps<T extends FieldValues> = {
	name: FieldPath<T>;
	label?: string;
};

type RadabankFormCheckboxProps<T extends FieldValues> = OwnProps<T> & Omit<RadabankSubmissionCheckboxProps, 'onChange'>;

export const RadabankFormCheckbox = <T extends FieldValues>({ name, ...props }: RadabankFormCheckboxProps<T>) => {
	const { control } = useFormContext();

	const {
		field: { value, onChange },
	} = useController({ control, name });

	const isChecked = value === RADABANK_BOOLEAN_VALUE.TRUE;

	const handleChange = () => {
		onChange(isChecked ? RADABANK_BOOLEAN_VALUE.FALSE : RADABANK_BOOLEAN_VALUE.TRUE);
	};

	return <RadabankSubmissionCheckbox {...props} checked={isChecked} onChange={handleChange} />;
};
