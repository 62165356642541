// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReceiptMenuList_menu__item__6eWT4 svg path {
  fill: var(--color-primary-500);
}`, "",{"version":3,"sources":["webpack://./src/components/Receipt/ReceiptMap/Actions/ReceiptMenu/ReceiptMenuList/index.module.scss"],"names":[],"mappings":"AACC;EACC,8BAAA;AAAF","sourcesContent":[".menu__item {\n\tsvg path {\n\t\tfill: var(--color-primary-500);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu__item": `ReceiptMenuList_menu__item__6eWT4`
};
export default ___CSS_LOADER_EXPORT___;
