import { Capacitor } from '@capacitor/core';
import UAParser from 'ua-parser-js';

const parser = new UAParser();
const result = parser.getResult();
const isMobile = result.device.type === 'mobile' || result.device.type === 'tablet';
const isIos = Capacitor.getPlatform() === 'ios' || result.os.name === 'iOS';
const isAndroid = Capacitor.getPlatform() === 'android';

export const useDevice = () => {
	return {
		isMobile: Capacitor.isNativePlatform() || isMobile,
		isIos,
		isAndroid,
	};
};
