// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#apple-pay-button {
  --apple-pay-button-height: 40px;
  width: 100%;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  border-radius: var(--spacing-9);
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/PaymentMethodModal/ApplePayButton/index.scss"],"names":[],"mappings":"AAAA;EACC,+BAAA;EACA,WAAA;EACA,qBAAA;EACA,qCAAA;EACA,6BAAA;EACA,8BAAA;EACA,+BAAA;EACA,gBAAA;AACD","sourcesContent":["#apple-pay-button {\n\t--apple-pay-button-height: 40px;\n\twidth: 100%;\n\tdisplay: inline-block;\n\t-webkit-appearance: -apple-pay-button;\n\t-apple-pay-button-type: plain;\n\t-apple-pay-button-style: black;\n\tborder-radius: var(--spacing-9);\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
