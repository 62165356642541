import { FC } from 'react';
import { Capacitor } from '@capacitor/core';
import { ReactComponent as SignOut } from 'assets/icons/sign-out.svg';
import { cancelAllRequests, enableAllRequests } from 'core';
import { getUserIdSelector, resetRadabankReducer, useAppSelector } from 'store';
import { resetBillReducer } from 'store/bill/reducer';
import { resetCardsReducer } from 'store/cards/reducer';
import { useAppDispatch } from 'store/hooks';
import { resetMetersReducer } from 'store/meters/reducer';
import { showToast } from 'store/toastify/reducer';
import { resetUserReducer } from 'store/user-service/reducer';
import { ActionModalContent, CustomButton, CustomModal } from 'components/shared';
import { ACTION_TIMEOUT_DELAY } from 'utils/constants';
import { getErrorMessage, redirectNotAuthorizedUser, userLogout } from 'utils/helpers';
import { useOpen } from 'utils/hooks';
import styles from './index.module.scss';

export const LogoutButton: FC = () => {
	const dispatch = useAppDispatch();
	const { isOpen, handleOpen, handleClose } = useOpen();
	const userId = useAppSelector(getUserIdSelector);

	const logout = async () => {
		try {
			cancelAllRequests();
			enableAllRequests();
			await userLogout(userId);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			handleClose();
			await redirectNotAuthorizedUser();
			setTimeout(() => {
				dispatch(resetBillReducer());
				dispatch(resetUserReducer());
				dispatch(resetCardsReducer());
				dispatch(resetMetersReducer());
				dispatch(resetRadabankReducer());
			}, ACTION_TIMEOUT_DELAY);
		}
	};

	return (
		<>
			<CustomButton
				className={styles.button}
				label="Вийти"
				startIcon={<SignOut />}
				onClick={handleOpen}
				fill="clear"
				color="danger"
			/>

			<CustomModal isOpen={isOpen} onClose={handleClose} isMobileView={false}>
				<>
					<ActionModalContent
						icon={<SignOut />}
						title="Вийти"
						subTitle={`Ви впевнені, що бажаєте вийти${Capacitor.isNativePlatform() ? ' з додатку' : ''}?`}
					/>
					<div className="modal-actions-column">
						<CustomButton label="Так, вийти" onClick={logout} fill="outline" color="danger" />
						<CustomButton label="Скасувати" onClick={handleClose} color="danger" />
					</div>
				</>
			</CustomModal>
		</>
	);
};
