/* eslint-disable react/no-unused-prop-types */
import { FC } from 'react';
import { MenuList, Typography } from '@mui/material';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { ADD_APARTMENT_VARIANT_LIST, APARTMENTS_MAX_COUNT, FIREBASE_EVENT_ANALYTICS_BUTTON } from 'utils/constants';
import { ADD_APARTMENT_VARIANT } from 'utils/enums';
import { FirebaseAnalytics } from 'utils/services';
import { AppartmentVariantItem } from './AppartmentVariantItem';
import styles from './index.module.scss';

interface AddApartmentVariantProps {
	onChange: (value: ADD_APARTMENT_VARIANT) => void;
	value: ADD_APARTMENT_VARIANT | null;
	pageName: string;
}

export const AddApartmentVariant: FC<AddApartmentVariantProps> = ({ onChange, pageName }) => {
	const aparments = useAppSelector(getApartmentsSelector);

	const handleChange = (value: ADD_APARTMENT_VARIANT): void => {
		let buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_ADDRESS;

		switch (value) {
			case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
				buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_HOST_ID;
				break;

			case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
				buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_PROVIDER_ACCOUNT_ID;
				break;

			default:
				break;
		}

		FirebaseAnalytics.logEvent(pageName, buttonName);
		onChange(value);
	};

	return (
		<>
			<Typography className={styles.title} variant="h5">
				Додайте адресу і сплачуйте комунальні послуги без зусиль:
			</Typography>
			<MenuList className={styles.list}>
				{ADD_APARTMENT_VARIANT_LIST.map(({ children, ...item }) => (
					<AppartmentVariantItem
						key={item.value}
						item={item}
						onClickItem={handleChange}
						disabled={aparments.list.length === APARTMENTS_MAX_COUNT}
					>
						{children}
					</AppartmentVariantItem>
				))}
			</MenuList>
		</>
	);
};
