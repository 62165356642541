import { FC, ReactNode, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as LogoSvg } from 'assets/images/host-logo-column.svg';
import styles from './index.module.scss';

interface RadabankOnboardingContentProps {
	title?: string;
	description?: string | string[];
	children?: ReactNode;
}

export const RadabankOnboardingContent: FC<RadabankOnboardingContentProps> = ({
	title = '',
	description = [],
	children,
}) => {
	const [descriptionList, setDescriptionList] = useState<string[]>([]);

	useEffect(() => {
		if (description) {
			setDescriptionList(Array.isArray(description) ? description : [description]);
		}
	}, [description]);

	return (
		<div className={styles.wrapper}>
			<LogoSvg className={styles.logo} />
			{!!title && (
				<Typography variant="h5" color="var(--color-neutral-white)" textAlign="center">
					{title}
				</Typography>
			)}
			{!!descriptionList.length &&
				descriptionList.map((item, index) => (
					<Typography
						variant="body2"
						key={item}
						marginTop={!index ? 'var(--spacing-3)' : 0}
						color="var(--color-neutral-white)"
						textAlign="center"
					>
						{item}
					</Typography>
				))}
			{children}
		</div>
	);
};
