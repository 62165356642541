import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import { getServicesByCategory } from 'store/bill/actions';
import { getServicesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import PaymentResult from 'components/PaymentResult';
import { Loader } from 'components/shared';
import { DELAY_STATUS_GET, SERVICES_DEFAULT_REQUEST_BODY } from 'utils/constants';
import { PAYMENT_STATUS, ROUTER_URL } from 'utils/enums';
import {
	checkBillStatusIsReturned,
	confirm3DS,
	convertCoinsToUAH,
	getBillIsNeedConfirm3DS,
	getErrorMessage,
	getServiceByIdRoute,
} from 'utils/helpers';
import { BillService } from 'utils/services';
import { ICheckPaymentStatusResponse, ServicesRequest } from 'utils/types';

type ServicePaymentStatusPageParams = {
	billId: string;
	categoryId: string;
	serviceId: string;
};

export const PaymentStatusServicePage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const params = useParams<ServicePaymentStatusPageParams>();

	const services = useAppSelector(getServicesSelector);

	const [data, setData] = useState<ICheckPaymentStatusResponse | null>(null);
	const [description, setDescription] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!params.billId || !params.categoryId || !params.serviceId) {
			handleRedirectToError();
		} else if (params.billId) {
			handleGetServices();
			checkPaymentStatus(params.billId);
		}
	}, []);

	useEffect(() => {
		const currentService = services.data.tableData.find((item) => item.id === params.serviceId);
		if (currentService) {
			setDescription(currentService.title ?? description);
		}
	}, [services.data.tableData]);

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;

		if (data && checkBillStatusIsReturned(data)) {
			setIsLoading(false);
		} else {
			if (!isLoading) {
				setIsLoading(true);
			}

			if (data) {
				const confirm3DSBill = getBillIsNeedConfirm3DS([data]);
				if (confirm3DSBill?.returnUrl_3DS) {
					confirm3DS(confirm3DSBill.returnUrl_3DS);
				}
			}

			interval = setInterval(() => {
				checkPaymentStatus(params.billId);
			}, DELAY_STATUS_GET);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [description, data]);

	const handleGetServices = async (): Promise<void> => {
		try {
			const reqBody: ServicesRequest = {
				...SERVICES_DEFAULT_REQUEST_BODY,
				options: {
					...SERVICES_DEFAULT_REQUEST_BODY.options,
					serviceCategoryId: [params.categoryId],
					serviceId: [params.serviceId],
				},
			};
			await dispatch(getServicesByCategory(reqBody)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const checkPaymentStatus = async (billId: string): Promise<void> => {
		try {
			setIsLoading(true);
			const response: AxiosResponse<ICheckPaymentStatusResponse> = await BillService.checkPaymentStatus({
				billId,
			});
			setData({
				...response.data,
				amount: convertCoinsToUAH(response.data.amount).toString(),
			});
		} catch (error) {
			handleRedirectToError();
		}
	};

	const handleRedirectToError = () => {
		dispatch(showToast({ message: 'Платіж не знайдено. Перевірте, будь ласка, архів платежів' }));
		history.push(ROUTER_URL.NOT_FOUND);
	};

	return (
		<div className="page">
			{data && (
				<PaymentResult
					totalAmount={checkBillStatusIsReturned(data) ? +data.amount : 0}
					isSuccess={data.status === PAYMENT_STATUS.PAID}
					isLoading={isLoading}
					description={description}
					rejectedUrl={getServiceByIdRoute(params.categoryId, params.serviceId)}
					archiveUrl={ROUTER_URL.SERVICES_ARCHIVE_LIST}
				/>
			)}
			{!data && isLoading && <Loader />}
		</div>
	);
};
