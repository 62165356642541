import { FC, useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_BUDGET_CREATE_PAYMENT_INITIAL_VALUES,
} from 'utils/constants';
import { convertUAHToCoins, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import {
	IRadabankBudgetCreatePaymentRequest,
	IRadabankTransferFeeResponse,
	ISelectOption,
	IUserProfileResponse,
} from 'utils/types';
import { radabankCardCreateTransferBudgetPaymentValidationSchema } from 'utils/validation';
import { FormContent } from './FormContent';
import { OperationDetails } from './OperationDetails';
import styles from './index.module.scss';

export const RadabankOperationBudget: FC = () => {
	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);
	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const [initialValues, setInitialValues] = useState<IRadabankBudgetCreatePaymentRequest>(
		RADABANK_BUDGET_CREATE_PAYMENT_INITIAL_VALUES
	);
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [feeData, setFeeData] = useState<IRadabankTransferFeeResponse | null>(null);
	const [budgetPaymentData, setbudgetPaymentData] = useState<IRadabankBudgetCreatePaymentRequest | null>(null);

	const [budgetCodeOptionsList, setBudgetCodeOptionsList] = useState<ISelectOption[]>([]);
	const [isLoadingBudgetCodeList, setIsLoadingBudgetCodeList] = useState(false);

	useEffect(() => {
		if (profile && cardData) {
			setInitialValues({ ...initialValues, userId: profile.userId, cardid: cardData.id });
		}
	}, [profile, cardData]);

	useEffect(() => {
		hangleGetCountiesList();
	}, []);

	const hangleGetCountiesList = async (): Promise<void> => {
		try {
			setIsLoadingBudgetCodeList(true);
			const response = await RadabankService.getBudgetCode((profile as IUserProfileResponse).userId);
			setBudgetCodeOptionsList(response.data.budgetcodes.map((item) => ({ value: item.id, label: item.name })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoadingBudgetCodeList(false);
		}
	};

	const handleSubmit = async (values: IRadabankBudgetCreatePaymentRequest) => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_BUDGET,
				FIREBASE_EVENT_ANALYTICS_BUTTON.NEXT
			);
			dispatch(setAppLoading(true));
			const response = await RadabankService.getTransferFee({
				...values,
				amount: `${convertUAHToCoins(values.amount)}`,
			});
			setFeeData(response.data);
			setbudgetPaymentData(values);
			setIsShowDetails(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const selectedCodeDetails = useMemo(
		() => budgetCodeOptionsList.find((option) => option.value === budgetPaymentData?.budgetcode)?.label,
		[budgetCodeOptionsList.length, budgetPaymentData?.budgetcode]
	);

	return (
		<div className={styles.content}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}
				isInitialValid={false}
				validationSchema={radabankCardCreateTransferBudgetPaymentValidationSchema}
			>
				<Form>
					<FormContent codeOptions={budgetCodeOptionsList} isCodeOptionsLoading={isLoadingBudgetCodeList} />
				</Form>
			</Formik>
			{isShowDetails && !!budgetPaymentData && !!feeData && (
				<OperationDetails
					codeLabel={selectedCodeDetails}
					feeData={feeData}
					budgetPaymentData={budgetPaymentData}
					onClickBack={() => setIsShowDetails(false)}
				/>
			)}
		</div>
	);
};
