// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_clear__81A1g {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}
.styles_clear__81A1g:hover {
  transform: scale(1.2);
}`, "",{"version":3,"sources":["webpack://./src/components/shared/SearchInput/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,sCAAA;AACJ;AACI;EACI,qBAAA;AACR","sourcesContent":[".clear {\n    cursor: pointer;\n    transition: transform 0.2s ease-in-out;\n\n    &:hover {\n        transform: scale(1.2);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clear": `styles_clear__81A1g`
};
export default ___CSS_LOADER_EXPORT___;
