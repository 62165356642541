import { FC, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { MapMarker, StaticGoogleMap } from 'components/shared';
import { getApartmentTitle } from 'utils/helpers';
import { ReceiptApartment } from 'utils/types';
import { Actions } from './Actions';
import styles from './index.module.scss';

interface ReceiptMapProps {
	apartment: ReceiptApartment | null;
	isDisabledActions?: boolean;
	isCollapsed?: boolean;
}

export const ReceiptMap: FC<ReceiptMapProps> = ({ apartment, isDisabledActions, isCollapsed }) => {
	const mapRef = useRef<HTMLDivElement>(null);

	const apartments = useAppSelector(getApartmentsSelector);

	const [apartmentIndex, setApartmentIndex] = useState<number | null>(null);

	useEffect(() => {
		if (apartment) {
			const currentApartmentIndex = apartments.list.findIndex(
				(item) => item.apartmentAccountId === apartment.apartmentAccountId
			);
			setApartmentIndex(currentApartmentIndex);
		}
	}, [apartments.list.length, apartment]);

	return (
		<div ref={mapRef} className={styles.wrapper}>
			{apartmentIndex !== null && (
				<MapMarker
					className={classNames(styles.marker, {
						[styles.marker_hidden]: isCollapsed,
					})}
					index={apartmentIndex}
				/>
			)}
			{!isDisabledActions && <Actions />}
			{!!mapRef.current && !!apartment && (
				<StaticGoogleMap
					className={styles.map}
					width={mapRef.current.offsetWidth}
					height={mapRef.current.offsetHeight}
					lat={+apartment.lat}
					lng={+apartment.lon}
				/>
			)}
			{!!apartment && (
				<div className={styles.content}>
					<Typography className={styles.content__id} color="--color-neutral-white" variant="subtitle1">
						HostID: {apartment.apartmentAccountId}
					</Typography>
					<Typography variant="subtitle1" color="--color-neutral-white">
						{getApartmentTitle(apartment)}
					</Typography>
				</div>
			)}
		</div>
	);
};
