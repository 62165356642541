// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__-USjb::part(native) {
  width: 52px;
  height: 48px;
  opacity: 0.5;
}
.styles_button__active__kdl85::part(native) {
  border: 1px solid var(--color-neutral-200);
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/SliderButton/styles.module.scss"],"names":[],"mappings":"AACC;EACC,WAAA;EACA,YAAA;EACA,YAAA;AAAF;AAIE;EACC,0CAAA;EACA,UAAA;AAFH","sourcesContent":[".button {\n\t&::part(native) {\n\t\twidth: 52px;\n\t\theight: 48px;\n\t\topacity: 0.5;\n\t}\n\n\t&__active {\n\t\t&::part(native) {\n\t\t\tborder: 1px solid var(--color-neutral-200);\n\t\t\topacity: 1;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__-USjb`,
	"button__active": `styles_button__active__kdl85`
};
export default ___CSS_LOADER_EXPORT___;
