import { FC, ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';
import styles from './index.module.scss';

interface ProviderAccordionProps extends Omit<AccordionProps, 'children' | 'title'> {
	title: ReactNode;
	children: ReactNode;
}

export const ProviderAccordion: FC<ProviderAccordionProps> = ({ title, children, ...rest }) => {
	return (
		<Accordion {...rest} className={styles.wrapper} disableGutters>
			<AccordionSummary className={styles.header}>{title}</AccordionSummary>
			<AccordionDetails className={styles.details}>{children}</AccordionDetails>
		</Accordion>
	);
};
