import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { RadabankSectionHeader } from 'components/RadabankCard/SectionHeader';
import { RadabankSurveyWrapper } from 'components/RadabankCard/SurveyWrapper';
import { SurveyStepControls, SurveyStepControlsProps } from '../StepControls';
import styles from './index.module.scss';

interface SurveyLetterFormContentProps extends SurveyStepControlsProps {
	title: string;
	onClickBack: () => void;
}

export const SurveyLetterFormContent: FC<PropsWithChildren<SurveyLetterFormContentProps>> = ({
	title,
	onClickBack,
	children,
	...props
}) => {
	return (
		<RadabankSurveyWrapper>
			<RadabankSectionHeader title={title} onClickBack={onClickBack} />
			<Box className={styles.form}>
				<div className={styles.list}>{children}</div>
				<SurveyStepControls {...props} />
			</Box>
		</RadabankSurveyWrapper>
	);
};
