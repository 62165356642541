import { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField, Typography } from '@mui/material';
import classNames from 'classnames';
import { PORTMONE_AMOUNT_FIELD_NAME } from 'utils/constants';
import { CURRENCY_LABEL, TARIFF_ITEMS_STATE } from 'utils/enums';
import { checkIsAmountServiceFieldName, getAmountTitle, getTariffItemDataPreviewByKey } from 'utils/helpers';
import { ITariffItemData } from 'utils/types';
import styles from './index.module.scss';

interface ServiceTariffItemProps {
	data: ITariffItemData;
}

export const ServiceTariffItem: FC<ServiceTariffItemProps> = ({ data }) => {
	const [fieldsKeysList, setFieldsKeysList] = useState<string[]>([]);
	const [fieldsList, setFieldsList] = useState<ITariffItemData[]>([]);

	useEffect(() => {
		const payload = Object.keys(data)
			.reduce((acc, item) => (item.includes('_name') ? [...acc, item.replace('_name', '')] : acc), [] as string[])
			// eslint-disable-next-line no-nested-ternary
			.sort((a, b) => {
				if (a === PORTMONE_AMOUNT_FIELD_NAME || b === PORTMONE_AMOUNT_FIELD_NAME) {
					return a === PORTMONE_AMOUNT_FIELD_NAME ? 1 : -1;
				}
				return a.localeCompare(b);
			});
		setFieldsKeysList(payload);
	}, [data]);

	useEffect(() => {
		if (fieldsKeysList.length) {
			const payload = fieldsKeysList.map((key) => getTariffItemDataPreviewByKey(data, key));
			setFieldsList(payload);
		}
	}, [fieldsKeysList.length]);

	if (!fieldsList.length) return null;
	return (
		<div className={styles.wrapper}>
			<Typography className={styles.title} variant="subtitle2" marginBottom="var(--spacing-2)">
				{data.name}
			</Typography>
			{fieldsList.map((item, index) => (
				<div
					key={`${item.name}-${index}`}
					className={classNames(styles.row, {
						[styles.row_editable]: item.state === TARIFF_ITEMS_STATE.EDITABLE,
					})}
				>
					<Typography variant="body2">{item.name}</Typography>
					{item.state === TARIFF_ITEMS_STATE.EDITABLE ? (
						<div className={styles.row__input}>
							<NumericFormat
								suffix={CURRENCY_LABEL.UAH}
								id={`${data.name}-input`}
								customInput={TextField}
								decimalScale={2}
								value={item.value as string}
								allowNegative={false}
								allowLeadingZeros
								fixedDecimalScale
								defaultValue={0}
								thousandSeparator=" "
								size="small"
								inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
								disabled
								allowedDecimalSeparators={[',']}
							/>
						</div>
					) : (
						<Typography variant="body2" textAlign="right">
							{checkIsAmountServiceFieldName(item.name) ? getAmountTitle(item.value as string) : (item.value as string)}
						</Typography>
					)}
				</div>
			))}
		</div>
	);
};
