import { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getSingleReceipt } from 'store/bill/actions';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { CalendarModal } from 'components/CalendarModal';
import { ReceiptMenu } from 'components/Receipt/ReceiptMap/Actions/ReceiptMenu';
import { CustomButton, MapMarker } from 'components/shared';
import { DATE_FORMAT, ERROR_CODE, ERROR_MESSAGE } from 'utils/enums';
import {
	encryptData,
	formatDateView,
	getAmountTitle,
	getApartmentTitle,
	getCurrentReceiptMonth,
	getCurrentReceiptYear,
	getErrorMessage,
	getErrorResponse,
	getReceiptRoute,
} from 'utils/helpers';
import { useOpen } from 'utils/hooks';
import { IApartmentResponse } from 'utils/types';
import styles from './index.module.scss';

interface ApartmentMapContentProps {
	data: IApartmentResponse;
}

export const ApartmentMapContent: FC<ApartmentMapContentProps> = ({ data }) => {
	const dispatch = useAppDispatch();

	const { isOpen, handleOpen, handleClose } = useOpen();

	const history = useHistory();

	const apartments = useAppSelector(getApartmentsSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const handleClick = (): void => {
		if (data.singleReceipt) {
			history.push(getReceiptRoute(encryptData(data.apartmentAccountId.toString())));
		} else {
			dispatch(showToast({ message: ERROR_MESSAGE.RECEIPT_NOT_FOUND }));
		}
	};

	const handleChangePeriod = async (date: string): Promise<void> => {
		try {
			if (dayjs(date).format('MM') === getCurrentReceiptMonth()) {
				handleClose();
				return;
			}

			await dispatch(
				getSingleReceipt({
					apartmentAccountId: data.apartmentAccountId,
					month: dayjs(date).format('MM'),
					year: dayjs(date).year().toString(),
					userId: profile?.userId,
				})
			).unwrap();
			handleClose();
			history.push(getReceiptRoute(encryptData(data.apartmentAccountId.toString())));
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			let message = getErrorMessage(error);

			if (errorResponse?.errorData?.code === ERROR_CODE.RECEIPT_NOT_FOUND) {
				message = `Єдину квитанцію за ${formatDateView(date, DATE_FORMAT.RECEIPT_VIEW)} не знайдено`;
			}
			dispatch(showToast({ message }));
		}
	};

	const handleClickChangePeriod = (event: MouseEvent<HTMLLIElement>): void => {
		event.stopPropagation();
		handleOpen();
	};

	const handleClickContent = (event: MouseEvent<HTMLDivElement>): void => {
		if (!data.singleReceipt && isOpen) {
			event.stopPropagation();
		}
	};

	if (!data) return null;
	return (
		<div className={styles.wrapper} onClick={handleClickContent}>
			<Typography
				color="var(--color-neutral-white)"
				maxWidth={apartments.list.length === 1 ? '100%' : '75%'}
				variant="h6"
				fontSize={{
					xs: '14px',
					md: '19px',
				}}
			>
				{getApartmentTitle(data)}
			</Typography>
			<div>
				<Typography
					variant="h4"
					color="var(--color-neutral-white)"
					fontSize={{
						xs: '19px',
						md: '34px',
					}}
					whiteSpace={data.singleReceipt ? 'nowrap' : undefined}
				>
					{data.singleReceipt ? getAmountTitle(data.singleReceipt.sumBalance) : 'Даних ЄК немає'}
				</Typography>
				{apartments.list.length === 1 && (
					<CustomButton
						className={styles.button}
						label="Сплатити"
						onClick={handleClick}
						disabled={!data.singleReceipt}
						color="secondary"
						size="large"
					/>
				)}
			</div>
			{apartments.list.length === 1 && <MapMarker index={0} onClick={handleClick} className={styles.marker} />}
			{!data.singleReceipt && (
				<div className={styles.actions}>
					<ReceiptMenu apartmentAccountId={data.apartmentAccountId} onClickChangePeriod={handleClickChangePeriod} />
				</div>
			)}
			<CalendarModal
				onSubmit={handleChangePeriod}
				isOpen={isOpen}
				onClose={handleClose}
				month={getCurrentReceiptMonth()}
				year={getCurrentReceiptYear()}
			/>
		</div>
	);
};
