/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { IUserProfileResponse, IUserReducer, IUserResponse } from 'utils/types';
import { userInitialState } from './reducer';

export const setUserProfileOperation = (
	state: IUserReducer,
	{ payload }: PayloadAction<IUserResponse | null>
): void => {
	state.profile = payload?.profile as IUserProfileResponse;
	state.roles = payload?.roles ?? state.roles;
	state.rights = payload?.rights?? state.rights;
};

export const resetUserReducerOperation = () => {
	return { ...userInitialState, isAppUrlListenerInProgress: false };
};
