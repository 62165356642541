import { ChangeEvent, FC, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { ReactComponent as RadioIcon } from 'assets/icons/radio.svg';
import { ReactComponent as RadioCheckedIcon } from 'assets/icons/radio-checked.svg';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { getUserProfileSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { RadabankCardActionButton, RadabankSectionHeader } from 'components/RadabankCard';
import { SearchModalContent } from 'components/shared';
import { RADABANK_NPOSHTA_DIVISION_TYPE, RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST } from 'utils/enums';
import { getErrorMessage, getTextFieldValidationProps, sortNPoshtaDivisionsByNumber } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import {
	IRadabankNPoshtaCreateRequest,
	IRadabankNPoshtaInternalDivisionItem,
	ISelectOption,
	IUserProfileResponse,
} from 'utils/types';
import styles from './index.module.scss';

export const RadabankOrderPlasticCardDeliveryFormContent: FC = () => {
	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);

	const { values, setValues, errors, touched, setTouched } = useFormikContext<IRadabankNPoshtaCreateRequest>();

	const [optionsList, setOptionsList] = useState<ISelectOption<IRadabankNPoshtaInternalDivisionItem>[]>([]);
	const [visibleOptionsList, setVisibleOptionsList] = useState<ISelectOption<IRadabankNPoshtaInternalDivisionItem>[]>(
		[]
	);
	const [isLoadingOptions, setIsLoadingOptions] = useState(false);
	const [isShowSearch, setIsShowSearch] = useState(false);

	useEffect(() => {
		if (values.cityref && values.typerequest) {
			handleGetInternalDivision(values);
		}
	}, [values.typerequest, values.cityref]);

	useEffect(() => {
		setVisibleOptionsList(optionsList);
	}, [optionsList]);

	const getDivisionTypeByTypeRequest = (divisionTypeRequest: RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST) =>
		divisionTypeRequest === RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.DEPARTMENT
			? RADABANK_NPOSHTA_DIVISION_TYPE.DEPARTMENT
			: RADABANK_NPOSHTA_DIVISION_TYPE.PARCEL_LOCKER;

	// Find the index of the division number and get it without address
	const getDivisionMainTitle = (divisionRef: string) => {
		const divisionOption = optionsList.find((item) => item.value.ref === divisionRef);
		if (divisionOption) {
			const divisionTitle = divisionOption.value.description;
			const divisionNumberTextEndIndex = divisionTitle.indexOf(':');
			const substring =
				divisionNumberTextEndIndex !== -1 ? divisionTitle.slice(0, divisionNumberTextEndIndex) : divisionTitle;
			return substring;
		}
		return '';
	};

	const getDivisionAddress = (divisionRef: string) =>
		optionsList.find((item) => item.value.ref === divisionRef)?.value.shortaddress ?? '';

	const handleSelectDivision = async (value: IRadabankNPoshtaInternalDivisionItem) => {
		await setValues({
			...values,
			divref: value.ref,
		});
		await setTouched({ ...touched, divref: true });
		setIsShowSearch(false);
	};

	const handleGetInternalDivision = async (data: IRadabankNPoshtaCreateRequest) => {
		try {
			setOptionsList([]);
			setIsLoadingOptions(true);
			const response = await RadabankService.getNPoshtaInternalDivisions({
				userId: (profile as IUserProfileResponse).userId,
				city: values.citydeliveryref,
				type: getDivisionTypeByTypeRequest(data.typerequest),
			});
			setOptionsList(
				sortNPoshtaDivisionsByNumber(response.data.divisions).map((item) => ({
					label: item.description,
					value: item,
				}))
			);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoadingOptions(false);
		}
	};

	const handleChangeDivisionType = async (event: ChangeEvent<HTMLInputElement>) => {
		const payload = event.target.value as RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST;
		await setValues({
			...values,
			divref: '',
			typerequest: payload,
		});
	};

	const handleChangeFilterOptions = (value: string) => {
		setVisibleOptionsList(optionsList.filter((item) => item.label.toLowerCase().includes(value.toLowerCase())));
	};

	const handleClickBackSearch = async () => {
		await setTouched({ ...touched, divref: true });
		setIsShowSearch(false);
	};

	return (
		<>
			<div className={styles.content}>
				<Typography variant="subtitle1" textAlign="center">
					Спосіб доставки
				</Typography>
				<RadioGroup className={styles.content} value={values.typerequest} onChange={handleChangeDivisionType}>
					<>
						<FormControlLabel
							value={RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.DEPARTMENT}
							control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} />}
							label="У відділення Нової Пошти"
							componentsProps={{
								typography: {
									variant: 'subtitle1',
								},
							}}
						/>
						{values.typerequest === RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.DEPARTMENT && (
							<RadabankCardActionButton
								labelDefault="Оберіть відділення"
								labelMain={getDivisionMainTitle(values.divref)}
								labelSecondary={getDivisionAddress(values.divref)}
								onClick={() => setIsShowSearch(true)}
								disabled={!values.cityref}
								color={getTextFieldValidationProps(errors, touched, 'divref').error ? 'error' : undefined}
							/>
						)}
					</>

					<FormControlLabel
						value={RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.PARCEL_LOCKER}
						control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} />}
						label="У поштомат Нової Пошти"
						componentsProps={{
							typography: {
								variant: 'subtitle1',
							},
						}}
					/>
					{values.typerequest === RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.PARCEL_LOCKER && (
						<RadabankCardActionButton
							labelDefault="Оберіть поштомат"
							labelMain={getDivisionMainTitle(values.divref)}
							labelSecondary={getDivisionAddress(values.divref)}
							onClick={() => setIsShowSearch(true)}
							disabled={!values.cityref}
							color={getTextFieldValidationProps(errors, touched, 'divref').error ? 'error' : undefined}
						/>
					)}
				</RadioGroup>
			</div>
			{isShowSearch && (
				<div className={classNames('host-card-page-color-white-auth', styles.search)}>
					<RadabankSectionHeader
						onClickBack={handleClickBackSearch}
						title={
							values.typerequest === RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.DEPARTMENT
								? 'Обрати відділення'
								: 'Обрати поштомат'
						}
					/>
					<SearchModalContent
						options={visibleOptionsList}
						onSelect={handleSelectDivision}
						isLoading={isLoadingOptions}
						onChangeInput={handleChangeFilterOptions}
					/>
				</div>
			)}
		</>
	);
};
