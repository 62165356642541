import { FC } from 'react';
import { Typography } from '@mui/material';
import { getRadabankCurrentCardOverdraftDataSelector, useAppSelector } from 'store';
import { getAmountTitle } from 'utils/helpers';
import styles from './styles.module.scss';

export const RadabankCardOverdarftPaymentDetails: FC = () => {
	const overdraft = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);

	if (!overdraft.data) return;

	return (
		<div>
			<Typography mb="var(--spacing-2)" variant="h6">
				Платіж
			</Typography>
			<div className={styles.info}>
				<div className={styles.row}>
					<Typography>Наступний платіж</Typography>
					<Typography>{overdraft.data.overdate || 'Відсутній'}</Typography>
				</div>
				<div className={styles.row}>
					<Typography>Сума платежу</Typography>
					<Typography>{getAmountTitle(overdraft.data.overamount)}</Typography>
				</div>
			</div>
		</div>
	);
};
