import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
	checkRadabankPlasticCardStatus,
	getRadabankCanUserOrderPlasticCardSelector,
	getRadabankCardIdSelector,
	getRadabankInfoStatus,
	getRadabankInternalCardsList,
	getRadabankInternalClientData,
	getRadabanlInternalClientSelector,
	getUserProfileSelector,
	resetRadabankReducer,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
	verifyCobrandCard,
} from 'store';
import { Navbar } from 'components/Navbar';
import { Loader } from 'components/shared';
import { RADABANK_BOOLEAN_VALUE, RADABANK_RESULT, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { decryptData, getErrorMessage, getRadabankPageToRedirect } from 'utils/helpers';
import { RadabankService, StorageService } from 'utils/services';
import { IRadabankInternalClientPhoneResponse, IUserProfileResponse } from 'utils/types';

interface RadabankLayoutProps {
	children: ReactNode;
}

export const RadabankLayout: FC<RadabankLayoutProps> = ({ children }) => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const profile = useAppSelector(getUserProfileSelector);
	const radabankInternalClient = useAppSelector(getRadabanlInternalClientSelector);
	const currentCardId = useAppSelector(getRadabankCardIdSelector);
	const canUserOrderPlasticCard = useAppSelector(getRadabankCanUserOrderPlasticCardSelector);

	const [isLoading, setIsLoading] = useState(true);

	const isRBLoginPage = window.location.pathname.includes(ROUTER_URL.RADABANK_CARD_AUTH_INTERNAL_AUTHORIZE);

	const content = useMemo(() => {
		return !radabankInternalClient && isLoading ? (
			<>
				<Loader />
				<Navbar />
			</>
		) : (
			children
		);
	}, [isLoading]);

	useEffect(() => {
		if (profile && !radabankInternalClient) {
			handleGetInternalClient(profile);
		} else if (radabankInternalClient && !isRBLoginPage) {
			getRadabankPage(radabankInternalClient);
		}

		return () => {
			if (profile && radabankInternalClient && !radabankInternalClient?.username) {
				dispatch(resetRadabankReducer());
			}
		};
	}, [profile, radabankInternalClient?.infostatusid]);

	useEffect(() => {
		if (
			radabankInternalClient?.username &&
			radabankInternalClient.clientcode &&
			radabankInternalClient?.cardexists !== RADABANK_BOOLEAN_VALUE.FALSE &&
			profile &&
			!isRBLoginPage
		) {
			handleGetCardsList(profile.userId);
		}
	}, [radabankInternalClient?.cardexists, profile]);

	useEffect(() => {
		if (currentCardId && canUserOrderPlasticCard && profile?.userId) {
			handleCheckCardOrderStatus(currentCardId, profile?.userId);
		}
	}, [currentCardId, profile?.userId, canUserOrderPlasticCard]);

	const handleCheckCardOrderStatus = async (cardid: string, userId: string) => {
		try {
			await dispatch(checkRadabankPlasticCardStatus({ cardid, userId })).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleGetInternalClient = async (profile: IUserProfileResponse) => {
		try {
			setIsLoading(true);
			await dispatch(getRadabankInternalClientData({ userId: profile.userId, userphone: profile.phone })).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			if (getErrorMessage(error) === 'Rejected') {
				history.replace(ROUTER_URL.ERROR);
			}
			setIsLoading(false);
		}
	};

	const handleGetCardsList = async (userId: string): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			await dispatch(getRadabankInternalCardsList(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const getRadabankPage = async (data: IRadabankInternalClientPhoneResponse): Promise<void> => {
		try {
			if (data.infostatusid !== '871') {
				await StorageService.remove(STORAGE_KEY.RADABANK_INFO_ANKETA_DATA);
			}
			if (data.infostatusid === '873') {
				try {
					const hashedPassword = await StorageService.get<string>(STORAGE_KEY.USER_PASSWORD);
					if (!hashedPassword) {
						history.replace(ROUTER_URL.ERROR);
					} else {
						const decryptedPassword = decryptData(hashedPassword);
						await RadabankService.setInfoPassword(decryptedPassword);
						history.replace(ROUTER_URL.RADABANK_CARD_AUTH_INFO_SIGN_DOCUMENTS);
					}
				} catch (error) {
					dispatch(showToast({ message: getErrorMessage(error) }));
					dispatch(setAppLoading(false));
				}
			} else {
				let redirectUrl = ROUTER_URL.RADABANK_CARD;
				const bankCreateCoBrandCardRequestId = await StorageService.get<string>(
					STORAGE_KEY.RADABANK_CREATE_CO_BRAND_CARD_REQUEST_ID
				);

				if (data.activate === RADABANK_BOOLEAN_VALUE.TRUE) {
					redirectUrl = ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD;
				} else if (bankCreateCoBrandCardRequestId) {
					try {
						dispatch(setAppLoading(true));
						const createCardCheckResponse = await dispatch(
							verifyCobrandCard({
								userId: (profile as IUserProfileResponse).userId,
								requestid: bankCreateCoBrandCardRequestId,
							})
						).unwrap();

						const { result, finish } = createCardCheckResponse;
						if (finish !== RADABANK_BOOLEAN_VALUE.TRUE || result !== RADABANK_RESULT.OK) {
							redirectUrl = ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD_SUBMITION;
						} else {
							await StorageService.remove(STORAGE_KEY.RADABANK_CREATE_CO_BRAND_CARD_REQUEST_ID);
						}
					} catch (error) {
						redirectUrl = ROUTER_URL.ERROR;
						dispatch(showToast({ message: getErrorMessage(error) }));
						await StorageService.remove(STORAGE_KEY.RADABANK_CREATE_CO_BRAND_CARD_REQUEST_ID);
					} finally {
						dispatch(setAppLoading(false));
					}
				} else {
					redirectUrl = (await getRadabankPageToRedirect(data)) as ROUTER_URL;

					if (redirectUrl === ROUTER_URL.RADABANK_CARD_AUTH_STATUS) {
						try {
							await dispatch(getRadabankInfoStatus(profile?.userId ?? ''));
						} catch (error) {
							dispatch(showToast({ message: getErrorMessage(error) }));
						}
					}
				}
				history.replace(redirectUrl);
			}
		} finally {
			setIsLoading(false);
		}
	};

	return content;
};
