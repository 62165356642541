import { FC, HTMLProps } from 'react';
import { Typography } from '@mui/material';
import AppStoreImg from 'assets/images/app-store-button.png';
import GooglePlayImg from 'assets/images/google-play-button.png';
import BannerImg from 'assets/images/public-banner-download.png';
import classNames from 'classnames';
import styles from './index.module.scss';

export const ReceiptPublicBanner: FC<HTMLProps<HTMLDivElement>> = ({ className, ...props }) => {
	return (
		<div className={classNames(styles.wrapper, className)} {...props}>
			<img src={BannerImg} alt="logo" />
			<Typography textAlign="center" variant="h5" color="var(--color-neutral-white)" marginBottom="var(--spacing-3)">
				У додатку зручніше
			</Typography>
			<Typography textAlign="center" variant="subtitle1" color="var(--color-neutral-white)">
				Завантажуй наш додаток
			</Typography>
			<div className={styles.list_links}>
				<a href={process.env.REACT_APP_GOOGLE_PLAY_URL as string} rel="noreferrer" target="_blank">
					<img src={GooglePlayImg} alt="download" />
				</a>
				<a href={process.env.REACT_APP_APP_STORE_URL as string} rel="noreferrer" target="_blank">
					<img src={AppStoreImg} alt="download" />
				</a>
			</div>
		</div>
	);
};
