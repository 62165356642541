export enum FEE_TYPE {
	FIXED = 'FIXED',
	PERCENT = 'PERCENT',
	RANGE = 'RANGE',
}

export enum FEE_DIRECTION {
	INNER = 'INNER',
	OUTER = 'OUTER',
}
