import { useCallback, useState } from 'react';

export const useMeasureNonRenderedElement = <T extends HTMLElement>(deps: unknown[] = []) => {
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	const elementRef = useCallback(
		(node: T) => {
			if (node) {
				setDimensions({ height: node.clientHeight, width: node.clientWidth });
			}
		},
		[...deps]
	);

	return {
		dimensions,
		elementRef,
	};
};
