import { AsyncThunk } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getErrorMessage } from 'utils/helpers';

export const useDispatchWithTryCatch = <Q, R>(
	action: AsyncThunk<any, any, any>,
	errorFunc?: (error: any) => void,
	finallyFunc?: () => void
) => {
	const dispatch = useAppDispatch();

	return async (payload: Q): Promise<R> => {
		try {
			const res: R = await dispatch(action(payload)).unwrap();

			return res;
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			errorFunc?.(error);
			return null as R;
		} finally {
			finallyFunc?.();
		}
	};
};
