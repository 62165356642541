import { FC, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { getUserProfileSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { FormContentDateRow, RadabankOperationDetailInfo } from 'components/RadabankCard';
import { CustomAutocomplete, CustomButton } from 'components/shared';
import { getErrorMessage, getTextFieldValidationProps } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import { IRadabankInternalAddRuleRequest, ISelectOption } from 'utils/types';
import { radabankAddCountryRuleValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

interface RadabankCardCountryRuleFormProps {
	onSubmit: (values: IRadabankInternalAddRuleRequest) => void;
	initialValues: IRadabankInternalAddRuleRequest;
}

export const RadabankCardCountryRuleForm: FC<RadabankCardCountryRuleFormProps> = ({ onSubmit, initialValues }) => {
	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);

	const [countriesOptionsList, setCountriesOptionsList] = useState<ISelectOption<string>[]>([]);
	const [isLoadingCountriesList, setIsLoadingCountriesList] = useState(false);

	useEffect(() => {
		if (profile) {
			hangleGetCountiesList(profile.userId);
		}
	}, [profile]);

	const hangleGetCountiesList = async (userId: string): Promise<void> => {
		try {
			setIsLoadingCountriesList(true);
			const response = await RadabankService.getCountryRule(userId);
			setCountriesOptionsList(response.data.countrys.map((item) => ({ value: item.id, label: item.name })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoadingCountriesList(false);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={onSubmit}
			validateOnMount
			validationSchema={radabankAddCountryRuleValidationSchema}
		>
			{({ isValid, errors, touched }) => (
				<Form className={styles.form}>
					<FormContentDateRow />
					<Field
						name="countryid"
						label="Країна"
						component={CustomAutocomplete}
						options={countriesOptionsList}
						loading={isLoadingCountriesList}
						{...getTextFieldValidationProps(errors, touched, 'countryid')}
					/>
					<RadabankOperationDetailInfo
						className={styles.info}
						// eslint-disable-next-line max-len
						description="Встановлюючи доступ на виконання платежів в країні, ви можете розраховуватись цією карткою на території обраної країни"
					/>
					<CustomButton className={styles.form__button} label="Додати дозвіл" type="submit" disabled={!isValid} />
				</Form>
			)}
		</Formik>
	);
};
