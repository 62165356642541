import { IConfirmModal, IDocumentViewer, IModal } from 'utils/types';
import { StoreType } from '../root';

export const getAppLoadingSelector = (store: StoreType): boolean => {
	return store.systemStore.isLoading;
};

export const getIsAppUrlListenerInProgressSelector = (store: StoreType): boolean =>
	store.systemStore.isAppUrlListenerInProgress;

export const getIsNetworkConnectedSelector = (store: StoreType): boolean => store.systemStore.isNetworkConnected;

export const getUserPlatformSelector = (store: StoreType) => {
	return store.systemStore.platform;
};

export const getConfirmModalSelector = (store: StoreType): IConfirmModal => {
	return store.systemStore.confirmModal;
};

export const getModalSelector = (store: StoreType): IModal => {
	return store.systemStore.modal;
};

export const getDocumentViewerSelector = (store: StoreType): IDocumentViewer => store.systemStore.documentViewer;
