import { FC } from 'react';
import { Typography } from '@mui/material';
import styles from './index.module.scss';

export const HostIdInfoModalContent: FC = () => {
	return (
		<div className={styles.content}>
			<Typography mb="8px" variant="subtitle1">Де знайти HOST ID?</Typography>
			<Typography color="var(--color-neutral-500)" variant="body2">
				Ваш HOST ID розміщено вгорі надрукованої квитанції, посередині
			</Typography>
		</div>
	);
};
