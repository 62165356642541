// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HostIdInfoModalContent_content__I4A9E {
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Apartment/HostIdInfoModalContent/index.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;AACJ","sourcesContent":[".content {\n    width: 100%;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `HostIdInfoModalContent_content__I4A9E`
};
export default ___CSS_LOADER_EXPORT___;
