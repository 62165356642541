import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { GuardedRoute } from 'layouts/GuaredRoute';
import { PublicLayout } from 'layouts/PublicLayout';
import { ErrorPage } from 'pages/Error';
import { NetworkErrorPage } from 'pages/NetworkError';
import { NotFoundPage } from 'pages/NotFound';
import { PaymentStatusPublicPage } from 'pages/PaymentStatusPublic';
import { ReceiptPublicPayPage } from 'pages/ReceiptPublicPay';
import { ROUTER_URL } from 'utils/enums';
import { AuthRouter } from './AuthRouter';
import { MainRouter } from './MainRouter';

export const WebRouter: FC = () => {
	return (
		<Switch>
			<Route exact path={ROUTER_URL.MAIN} render={() => <Redirect to={ROUTER_URL.PROFILE} />} />
			<Route path={ROUTER_URL.AUTH}>
				<AuthRouter />
			</Route>
			<Route path={ROUTER_URL.PROFILE}>
				<GuardedRoute redirectUrl={ROUTER_URL.SERVICES_CATEGORIES}>
					<MainRouter />
				</GuardedRoute>
			</Route>
			<Route exact path={ROUTER_URL.ERROR_NETWORK} component={NetworkErrorPage} />
			<Route exact path={ROUTER_URL.ERROR} component={ErrorPage} />
			<Route exact path={ROUTER_URL.RECEIPT_PAY_DEEPLINK} component={() => null} />
			<Route exact path={ROUTER_URL.RECEIPT_PAY_PUBLIC}>
				<PublicLayout>
					<ReceiptPublicPayPage />
				</PublicLayout>
			</Route>
			<Route exact path={ROUTER_URL.RECEIPT_PAY_PUBLIC_STATUS}>
				<PublicLayout>
					<PaymentStatusPublicPage />
				</PublicLayout>
			</Route>
			<Route path="*" component={NotFoundPage} />
		</Switch>
	);
};
