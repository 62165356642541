import { FC, RefObject } from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-left.svg';
import classNames from 'classnames';
import { useScrollToTop } from 'utils/hooks';
import styles from './index.module.scss';

export interface ScrollTopButtonProps {
	elementRef: RefObject<HTMLElement>;
    className?: string;
}

export const ScrollTopButton: FC<ScrollTopButtonProps> = ({ elementRef, className = '' }) => {
	const { isShowButton, handleScrollTop } = useScrollToTop(elementRef);

	if (!isShowButton) return;

	return (
		<IconButton className={classNames(styles.button, className)} onClick={handleScrollTop}>
			<ArrowIcon className={styles.icon} />
		</IconButton>
	);
};
