import { FC, ReactNode, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getIsAppUrlListenerInProgressSelector } from 'store/system/selectors';
import { showToast } from 'store/toastify/reducer';
import { getUserProfile } from 'store/user-service/actions';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { Loader } from 'components/shared';
import { ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { checkIsSessionError, getErrorMessage } from 'utils/helpers';
import { PushNotificationsService, StorageService } from 'utils/services';

interface IGuardedRouteProps {
	children: ReactNode;
	redirectUrl?: string;
}

export const GuardedRoute: FC<IGuardedRouteProps> = ({ children, redirectUrl = '' }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { pathname } = useLocation();

	const [isHasAccess, setIsHasAccess] = useState<null | boolean>(null);

	const isAppUrlListenerInProgress: boolean = useAppSelector(getIsAppUrlListenerInProgressSelector);
	const userProfile = useAppSelector(getUserProfileSelector);

	const checkUserHasAccess = useCallback(async () => {
		if (isAppUrlListenerInProgress) return;

		try {
			if (!userProfile) {
				await dispatch(getUserProfile()).unwrap();
			} else if (userProfile) {
				setIsHasAccess(true);
			}
			if (!redirectUrl) return;

			const isPumb = await StorageService.get(STORAGE_KEY.IS_USER_PUMB);

			if (isPumb && [ROUTER_URL.PROFILE, '/'].includes(pathname)) {
				history.replace(redirectUrl);
			}
		} catch (error) {
			if (!checkIsSessionError(error)) {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		}
	}, [userProfile, isAppUrlListenerInProgress]);

	useEffect(() => {
		checkUserHasAccess();
	}, [checkUserHasAccess]);

	useLayoutEffect(() => {
		if (isHasAccess && userProfile?.userId) {
			handleInitPushNotifications();
		}
	}, [isHasAccess, userProfile?.userId]);

	const handleInitPushNotifications = () => {
		if (PushNotificationsService.isEnabled === null && Capacitor.isNativePlatform()) {
			history.replace(ROUTER_URL.PUSH_NOTIFICATIONS_BANNER);
		}
	};

	if (isHasAccess === null) {
		return <Loader />;
	}

	return isHasAccess ? children : <Redirect to={ROUTER_URL.NOT_FOUND} />;
};
