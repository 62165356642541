import { FC } from 'react';
import { ReactComponent as Failed } from 'assets/icons/failed.svg';
import { useAppSelector } from 'store/hooks';
import { getIsNetworkConnectedSelector } from 'store/system/selectors';
import { CustomButton } from 'components/shared';
import { getNetworkErrorMessage } from 'utils/helpers';
import { useGoBack } from 'utils/hooks';
import styles from './index.module.scss';

export const NetworkErrorPage: FC = () => {
	const isNetworkActive = useAppSelector(getIsNetworkConnectedSelector);

	const handleClickBack = useGoBack();

	return (
		<div className={styles.wrapper}>
			<Failed className={styles.error__icon} />
			<p className={styles.error__info}>{getNetworkErrorMessage(isNetworkActive)}</p>
			<div className={styles.error__action}>
				<CustomButton label="Повернутись" onClick={handleClickBack} disabled={!isNetworkActive} />
			</div>
		</div>
	);
};
