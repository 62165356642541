/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CHECK_RESPONSE } from 'utils/enums';
// Types
import { IUserProfileResponse, IUserReducer } from 'utils/types';
import {
	confirmUserEmail,
	deleteUserAvatar,
	getUserAvatar,
	getUserProfile,
	updateUserAvatar,
	updateUserEmail,
	updateUserProfile,
	userLogin,
} from './actions';
// Actions
import { resetUserReducerOperation, setUserProfileOperation } from './operations';

export const userInitialState: IUserReducer = {
	isLoading: false,
	avatarUser: null,
	profile: null,
	rights: [],
	roles: [],
};

const userStore = createSlice({
	name: 'userSlice',
	initialState: userInitialState,
	reducers: {
		setUserProfile: setUserProfileOperation,
		resetUserReducer: resetUserReducerOperation,
	},
	extraReducers: (builder) => {
		builder.addCase(userLogin.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(userLogin.fulfilled, (state, { payload }) => {
			state.profile = payload.profile as IUserProfileResponse;
			state.roles = payload.roles ?? [];
			state.rights = payload.rights ?? [];
			state.isLoading = false;
		});
		builder.addCase(userLogin.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(updateUserProfile.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
			state.profile = { ...state.profile, ...payload };
			state.isLoading = false;
		});
		builder.addCase(updateUserProfile.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(getUserProfile.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
			state.profile = { ...state.profile, ...payload };
			state.isLoading = false;
		});
		builder.addCase(getUserProfile.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(updateUserEmail.fulfilled, (state, { payload }) => {
			state.profile = {
				...(state.profile as IUserProfileResponse),
				email: payload,
				isEmailConfirmed: CHECK_RESPONSE.NO,
			};
		});

		builder.addCase(updateUserAvatar.fulfilled, (state, { payload }) => {
			state.avatarUser = payload.url;
		});

		builder.addCase(getUserAvatar.fulfilled, (state, { payload }) => {
			state.avatarUser = payload.url;
		});

		builder.addCase(deleteUserAvatar.fulfilled, (state) => {
			state.avatarUser = null;
		});

		builder.addCase(confirmUserEmail.fulfilled, (state) => {
			state.profile = { ...(state.profile as IUserProfileResponse), isEmailConfirmed: CHECK_RESPONSE.YES };
		});
	},
});

export const { setUserProfile, resetUserReducer } = userStore.actions;

export default userStore.reducer;
