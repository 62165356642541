import { ChangeEvent, FC, useState } from 'react';
import { AuthContentHeader } from 'components/Auth/AuthContentHeader';
import { ArrowBackButton, CustomButton, PasswordInput } from 'components/shared';
import { TextHandlePassword } from '../helpers';

// TODO should rework
interface ConfirmPasswordWebProps {
	value: string;
	password: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onSubmit: () => Promise<void>;
	onClickBack: () => void;
	isLoading: boolean;
	isShowButton?: boolean;
	isShowDescription?: boolean;
}

export const ConfirmPasswordWeb: FC<ConfirmPasswordWebProps> = ({
	value,
	onChange,
	onSubmit,
	onClickBack,
	password,
	isLoading,
	isShowButton = true,
	isShowDescription = true,
}) => {
	const [errorTitle, setErrorTitle] = useState('');
	const [touched, setTouched] = useState(false);

	const isFormDisabled = value !== password || !value.length;

	const validateConfirmPassword = (value: string, touched: boolean): void => {
		if (touched || value.length === 6) {
			setErrorTitle(value !== password ? 'Невірний пароль' : '');
		}
	};

	const handleBlur = (): void => {
		if (!touched) {
			setTouched(true);
			validateConfirmPassword(value, true);
		}
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		onChange(event);
		validateConfirmPassword(event.target.value, touched);
	};

	const handleSubmitPassword = () => {
		if (isFormDisabled) return;
		onSubmit();
	};

	return (
		<>
			{isShowButton && <ArrowBackButton onClick={onClickBack} />}
			<AuthContentHeader
				title={errorTitle || 'Повторіть PIN код'}
				description={isShowDescription ? TextHandlePassword.Confirm : ''}
			/>
			<PasswordInput
				onSubmit={handleSubmitPassword}
				name="confirmPassword"
				value={value}
				onChange={handleChangePassword}
				onBlur={handleBlur}
				isError={!!errorTitle || (touched && value.length !== 6)}
				helperText={`${value?.length} / 6`}
			/>
			<CustomButton
				className="auth-button-submit"
				size="large"
				disabled={isFormDisabled}
				label="Продовжити"
				onClick={onSubmit}
				isLoading={isLoading}
			/>
		</>
	);
};
