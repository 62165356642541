/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IReportReducer } from 'utils/types';
import { getReportPaymentReceipt } from './actions';
import { resetReportReceiptOperation } from './operations';

export const reportInitialState: IReportReducer = {
	receipt: {
		data: null,
		isLoading: false,
	},
};

const reportStore = createSlice({
	name: 'reportSlice',
	initialState: reportInitialState,
	reducers: {
		resetReportReceipt: resetReportReceiptOperation,
	},
	extraReducers: (builder) => {
		builder.addCase(getReportPaymentReceipt.pending, (state) => {
			state.receipt.isLoading = true;
		});
		builder.addCase(getReportPaymentReceipt.fulfilled, (state, { payload }) => {
			state.receipt.data = payload;
			state.receipt.isLoading = false;
		});
		builder.addCase(getReportPaymentReceipt.rejected, (state) => {
			state.receipt.isLoading = false;
		});
	},
});

export const { resetReportReceipt } = reportStore.actions;

export default reportStore.reducer;
