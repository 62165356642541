// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentMethodCardItem_item__qMuLF {
  -webkit-user-select: none;
          user-select: none;
  padding: 0;
}
.PaymentMethodCardItem_item__qMuLF.PaymentMethodCardItem_disabled__LR9WS {
  opacity: 0.5;
  cursor: default;
}
.PaymentMethodCardItem_item__qMuLF .PaymentMethodCardItem_tooltip__CD3tR {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/PaymentMethodModal/PaymentMethodCardItem/index.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;UAAA,iBAAA;EACA,UAAA;AACD;AACC;EACC,YAAA;EACA,eAAA;AACF;AAEC;EACC,WAAA;AAAF","sourcesContent":[".item {\n\tuser-select: none;\n\tpadding: 0;\n\n\t&.disabled {\n\t\topacity: 0.5;\n\t\tcursor: default;\n\t}\n\n\t.tooltip {\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `PaymentMethodCardItem_item__qMuLF`,
	"disabled": `PaymentMethodCardItem_disabled__LR9WS`,
	"tooltip": `PaymentMethodCardItem_tooltip__CD3tR`
};
export default ___CSS_LOADER_EXPORT___;
