import { FC, useEffect, useRef, useState } from 'react';
import { TypeOrNull } from 'utils/types';
import { ScrollTopButton, ScrollTopButtonProps } from '../ScrollTopButton';

interface ScrollToLayoutTopButtonProps extends Omit<ScrollTopButtonProps, 'elementRef'> {
	isPageScrollable?: boolean;
}

export const ScrollToLayoutTopButton: FC<ScrollToLayoutTopButtonProps> = ({ isPageScrollable, ...props }) => {
	const layoutRef = useRef<TypeOrNull<HTMLElement>>(null);

	const [isLayoutRendered, setIsLayoutRendered] = useState(false);

	useEffect(() => {
		layoutRef.current = isPageScrollable ? document.querySelector('.page') : document.getElementById('layout');
		if (layoutRef.current) {
			setIsLayoutRendered(true);
		}
	}, [isPageScrollable]);

	return isLayoutRendered ? <ScrollTopButton elementRef={layoutRef} {...props} /> : null;
};
