import { ChangeEvent, FC, useEffect } from 'react';
import { FormControl, RadioGroup, Typography } from '@mui/material';
import {
	getApartmentListByAccountIds,
	getPossibleApartmentsListSelector,
	getUserIdSelector,
	resetPossibleApartmentsList,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { getErrorMessage } from 'utils/helpers';
import ApartmentRadioItem from './ApartmentRadio';
import styles from './index.module.scss';

interface ApartmentsChoiceStepProps {
	apartmentIds: number[];
	onChange: (apartmentAccountId?: number) => void;
	apartmentAccountId?: number;
}

const ApartmentsChoiceStep: FC<ApartmentsChoiceStepProps> = ({ apartmentIds, onChange, apartmentAccountId }) => {
	const dispatch = useAppDispatch();
	const apartments = useAppSelector(getPossibleApartmentsListSelector);
	const userId = useAppSelector(getUserIdSelector);

	const handleGetApartmentList = async () => {
		try {
			dispatch(setAppLoading(true));
			const payload = await dispatch(
				getApartmentListByAccountIds({ apartmentAccountIds: apartmentIds, userId: userId ?? '' })
			).unwrap();
			onChange(payload?.[0]?.apartmentAccountId);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	useEffect(() => {
		handleGetApartmentList();
	}, [apartmentIds]);

	useEffect(() => {
		return () => {
			dispatch(resetPossibleApartmentsList());
		};
	}, []);

	const handleChangeApartmentId = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(+e.target.value);
	};

	if (!apartmentAccountId) return;

	return (
		<>
			<Typography variant="h5" textAlign="center" width="100%">
				Ми знайшли наступні особові рахунки. Оберіть потрібний вам варіант
			</Typography>
			<FormControl>
				<RadioGroup className={styles.container} value={apartmentAccountId} onChange={handleChangeApartmentId}>
					{apartments.map((apartment) => (
						<ApartmentRadioItem
							isSelected={apartment.apartmentAccountId === apartmentAccountId}
							apartment={apartment}
							key={apartment.apartmentAccountId}
						/>
					))}
				</RadioGroup>
			</FormControl>
		</>
	);
};

export default ApartmentsChoiceStep;
