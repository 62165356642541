import { AnyObjectSchema } from 'yup';
import { PropertyStatusStep } from 'components/RadabankCard/SurveyLetterForm/PropertyStatusStep';
import { RadabankOverdraftStep } from 'utils/enums';
import {
	commonTogglersStepValidationSchema,
	generalQuestionsFormValidationSchema,
	radabankOverdraftInitialStepValidation,
	radabankOverdraftPropertyStatusStepValidationSchema,
	radabankOverdraftSocialStatusValidationSchema,
} from 'utils/validation';
import {
	OverdraftCreateInitialStep,
	OverdraftGeneralQuestionsStep,
	OverdraftSocialStatusStep,
	OverdraftTogglersStep,
} from './Steps';

const overdraftFormSteps: Record<
	RadabankOverdraftStep,
	{ title: string; Component: React.FC; validationSchema: AnyObjectSchema }
> = {
	[RadabankOverdraftStep.Initial]: {
		title: '',
		Component: OverdraftCreateInitialStep,
		validationSchema: radabankOverdraftInitialStepValidation,
	},
	[RadabankOverdraftStep.General]: {
		title: 'Основна інформація',
		Component: OverdraftGeneralQuestionsStep,
		validationSchema: generalQuestionsFormValidationSchema,
	},
	[RadabankOverdraftStep.PropertyStatus]: {
		title: 'Майновий статус',
		Component: PropertyStatusStep,
		validationSchema: radabankOverdraftPropertyStatusStepValidationSchema,
	},
	[RadabankOverdraftStep.SocialStatus]: {
		title: 'Соціальний статус',
		Component: OverdraftSocialStatusStep,
		validationSchema: radabankOverdraftSocialStatusValidationSchema,
	},
	[RadabankOverdraftStep.LastQuestions]: {
		title: 'Останні питання',
		Component: OverdraftTogglersStep,
		validationSchema: commonTogglersStepValidationSchema,
	},
};

export const getRadabankOverdraftStep = (step: RadabankOverdraftStep) => overdraftFormSteps[step];

export const RADABANK_OVERDRAFT_STEPS_COUNT = Object.keys(overdraftFormSteps).length;
