/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { IMetersReducer } from 'utils/types';
import { getMetersList, updateMeterValue } from './actions';
import {
	resetMetersListOperation,
	resetMetersReducerOperation,
	setMetersApartmentAccountIdOperation,
} from './operations';

export const metersInitialState: IMetersReducer = {
	list: [],
	isLoading: false,
	apartmentAccountId: null,
};

const metersStore = createSlice({
	name: 'metersSlice',
	initialState: metersInitialState,
	reducers: {
		resetMetersReducer: resetMetersReducerOperation,
		resetMetersList: resetMetersListOperation,
		setMetersApartmentAccountId: setMetersApartmentAccountIdOperation,
	},
	extraReducers: (builder) => {
		builder.addCase(getMetersList.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getMetersList.fulfilled, (state, { payload }) => {
			state.list = [...state.list, ...payload];
			state.isLoading = false;
		});
		builder.addCase(getMetersList.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(updateMeterValue.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateMeterValue.fulfilled, (state, { payload }) => {
			state.list = payload;
			state.isLoading = false;
		});
		builder.addCase(updateMeterValue.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { resetMetersReducer, setMetersApartmentAccountId, resetMetersList } = metersStore.actions;

export default metersStore.reducer;
