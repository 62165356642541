import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserProfileSelector, setAppLoading, showToast, useAppDispatch, useAppSelector } from 'store';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_IMAGE_TYPE_CODE, ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService, SignatureService } from 'utils/services';

export const RadabankAuthInfoSetSignPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const profile = useAppSelector(getUserProfileSelector);

	const handleSubmit = async (): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SET_SIGN,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			const imageBase64 = await SignatureService.getSign();
			if (imageBase64 && profile) {
				await RadabankService.setInfoImage({
					userId: profile.userId,
					imagecontext: [imageBase64],
					typecode: RADABANK_IMAGE_TYPE_CODE.SIGNATURE,
				});
				await RadabankService.commitInfoSignDocument(profile.userId);
				history.replace(ROUTER_URL.RADABANK_CARD_AUTH_SIGN_DOCUMENTS_SUBMISSION);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.SET_SIGN,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} />
			<RadabankOnboardingContent title="Підписання документів" description={['Залишилось лише поставити підпис']} />
			<CustomButton label="Продовжити" color="secondary" onClick={handleSubmit} />
		</div>
	);
};
