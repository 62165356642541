import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { getUserProfileSelector, setAppLoading, showToast, useAppDispatch, useAppSelector } from 'store';
import { HostCardSurveyLetterForm, RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE, RADABANK_SURVEY_LETTER_INITIAL_VALUES } from 'utils/constants';
import { ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { decryptData, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService, StorageService } from 'utils/services';
import { IRadabankInfoSetAnketaForm, IRadabankInfoSetAnketaRequest, IUserProfileResponse } from 'utils/types';
import { SURVEY_LETTER_STEP } from './enums';

export const RadabankAuthInfoSurveyLetterPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);

	const { search } = useLocation();

	const [step, setStep] = useState(() => {
		const params = new URLSearchParams(search);

		const initialStep = params.get('step');

		return initialStep ? Number(initialStep) : SURVEY_LETTER_STEP.STEP_1;
	});
	const [surveyLetterInitialValues, setSurveyLetterInitialValues] = useState<IRadabankInfoSetAnketaForm>(
		RADABANK_SURVEY_LETTER_INITIAL_VALUES
	);

	useEffect(() => {
		if (step !== SURVEY_LETTER_STEP.STEP_3) {
			getSurveyValuesFromStorage();
		}
	}, [step]);

	const getSurveyValuesFromStorage = async () => {
		const values = await StorageService.get<Omit<IRadabankInfoSetAnketaRequest, 'userId' | 'infotoken' | 'infocode'>>(
			STORAGE_KEY.RADABANK_INFO_ANKETA_DATA
		);
		if (values) {
			setSurveyLetterInitialValues((prev) => {
				if (JSON.stringify(prev) !== JSON.stringify(values)) {
					return values;
				}
				return prev;
			});
		}
	};

	const handleClickBack = async () => {
		if (step === SURVEY_LETTER_STEP.STEP_1) {
			await StorageService.remove(STORAGE_KEY.RADABANK_INFO_ANKETA_DATA);
			history.goBack();
			return;
		}
		let stepPayload = SURVEY_LETTER_STEP.STEP_2;
		if (step === SURVEY_LETTER_STEP.STEP_2) {
			stepPayload = SURVEY_LETTER_STEP.STEP_1;
		}
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INFO_ANKETA,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		setStep(stepPayload);
	};

	const handleClickStartSurvey = (): void => {
		setStep(SURVEY_LETTER_STEP.STEP_2);
	};

	const handleSubmitSurveyLetter = async (values: IRadabankInfoSetAnketaForm): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INFO_ANKETA,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			const hashedPassword = await StorageService.get<string>(STORAGE_KEY.USER_PASSWORD);
			if (!hashedPassword) {
				history.replace(ROUTER_URL.ERROR);
			} else {
				await RadabankService.setInfoAnketa({ ...values, userId: (profile as IUserProfileResponse).userId });
				const decryptedPassword = decryptData(hashedPassword);
				await RadabankService.setInfoPassword(decryptedPassword);
				setStep(SURVEY_LETTER_STEP.STEP_3);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleClickContinue = (): void => {
		history.replace(ROUTER_URL.RADABANK_CARD_AUTH_INFO_SIGN_DOCUMENTS);
	};

	return (
		<div
			className={classNames({
				'host-card-page-color-primary': step !== SURVEY_LETTER_STEP.STEP_2,
				'page page-scrollable': step === SURVEY_LETTER_STEP.STEP_2,
			})}
		>
			{step !== SURVEY_LETTER_STEP.STEP_2 && (
				<ArrowBackButton className="button-arrow-radabank" onClick={handleClickBack} />
			)}
			{step === SURVEY_LETTER_STEP.STEP_1 && (
				<>
					<RadabankOnboardingContent
						title="Опитувальний лист"
						description="Згідно з законодавством вам потрібно відповісти на стандартний перелік запитань"
					/>
					<CustomButton label="Пройти опитування" color="secondary" onClick={handleClickStartSurvey} />
				</>
			)}

			{step === SURVEY_LETTER_STEP.STEP_2 && (
				<HostCardSurveyLetterForm
					onGoBack={handleClickBack}
					initialValues={surveyLetterInitialValues}
					onSubmit={handleSubmitSurveyLetter}
				/>
			)}

			{step === SURVEY_LETTER_STEP.STEP_3 && (
				<>
					<RadabankOnboardingContent
						title="Опитувальний лист успішно надіслано"
						description={[
							'Залишилось лише підписати документи.',
							'Будь ласка, ознайомтесь з документами, які необхідно підписати',
						]}
					/>
					<CustomButton label="Далі" color="secondary" onClick={handleClickContinue} />
				</>
			)}
		</div>
	);
};
