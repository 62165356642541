import { useHistory, useLocation } from 'react-router-dom';
import { ROUTER_URL } from 'utils/enums';

export const useGoBack = (stepCount?: number): (() => void) => {
	const history = useHistory();

	const handleClickRedirect = (): void => {
		if (history.length > 2) {
			if (stepCount && history.length > stepCount) {
				history.go(stepCount);
			} else {
				history.goBack();
			}
		} else {
			history.push(ROUTER_URL.PROFILE);
		}
	};

	return handleClickRedirect;
};

export const useGoBackRadabank = (): (() => void) => {
	const location = useLocation<{ isFromCard?: boolean }>();
	const history = useHistory();

	const handleClickBack = (): void => {
		if (location.state?.isFromCard) {
			history.push(ROUTER_URL.PROFILE);
		} else {
			// history.push(ROUTER_URL.PROFILE);
			history.goBack();
		}
	};

	return handleClickBack;
};
