import { GetResult, Preferences } from '@capacitor/preferences';
import { STORAGE_KEY } from 'utils/enums';

class StorageService {
	static set = async (key: STORAGE_KEY, value: any): Promise<void> => {
		await Preferences.set({ key, value: JSON.stringify(value) });
	};

	static get = async <T>(key: STORAGE_KEY): Promise<T | null> => {
		let value = null;
		try {
			const result: GetResult = await Preferences.get({ key });
			value = result.value;

			if (value === null) {
				return value as T | null;
			}
			return JSON.parse(value);
		} catch (error) {
			await this.set(key, value);
			const result = await this.get(key);
			return result as T | null;
		}
	};

	static remove = async (key: STORAGE_KEY): Promise<void> => {
		await Preferences.remove({ key });
	};

	static clear = async (): Promise<void> => {
		await Preferences.clear();
	};
}

export { StorageService };
