import * as yup from 'yup';
import { EMAIL_REGEXP, MIN_LENGTH } from 'utils/constants';
import { VALIDATION_ERROR } from 'utils/enums';

export const emailValidationSchema = yup
	.string()
	.required(VALIDATION_ERROR.REQUIRED)
	.matches(EMAIL_REGEXP, { message: VALIDATION_ERROR.EMAIL_INVALID });

export const stringMinLength = yup
	.string()
	.test('first character', VALIDATION_ERROR.FIRST_CHARACTER, (value) => !/^[-']/.test(value as string))
	.matches(/^[a-zA-Zа-яА-ЯіІїЇєЄ'][a-zA-Zа-яА-ЯіІЇїЄє'-]*$/, {
		message: VALIDATION_ERROR.SPECIAL_CHARACTERS,
		excludeEmptyString: true,
	})
	.min(MIN_LENGTH, VALIDATION_ERROR.MIN_LENGTH)
	.required(VALIDATION_ERROR.REQUIRED)
	.nullable();

export const userAuthUpdateProfileValidationSchema = yup.object().shape({
	firstName: stringMinLength,
	lastName: stringMinLength,
});
