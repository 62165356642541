// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthLayout_wrapper__geWiU {
  background-color: var(--color-neutral-white);
}
.AuthLayout_wrapper__geWiU .AuthLayout_content_webLogin__egq7t {
  max-width: none;
}
.AuthLayout_wrapper__geWiU .AuthLayout_content__zZL1W.AuthLayout_content-lg__pqZnH {
  max-width: 684px;
}
@media (max-width: 1024px) {
  .AuthLayout_wrapper__geWiU .AuthLayout_content__zZL1W {
    position: relative;
    height: 100%;
  }
  .AuthLayout_wrapper__geWiU .AuthLayout_content__zZL1W:not(.AuthLayout_wrapper__geWiU .AuthLayout_content__zZL1W.AuthLayout_content-lg__pqZnH) {
    overflow: hidden;
  }
  .AuthLayout_wrapper__geWiU .AuthLayout_content__zZL1W ion-button:last-of-type {
    margin-top: auto;
  }
  .AuthLayout_wrapper__geWiU .AuthLayout_content_webLogin__egq7t {
    overflow-y: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/AuthLayout/index.module.scss","webpack://./src/assets/styles/media.scss"],"names":[],"mappings":"AAGA;EACC,4CAAA;AAFD;AAKE;EACC,eAAA;AAHH;AAME;EACC,gBAAA;AAJH;ACKC;EDIC;IACC,kBAAA;IACA,YAAA;EAND;EAQC;IACC,gBAAA;EANF;EASC;IACC,gBAAA;EAPF;EAUC;IACC,gBAAA;EARF;AACF","sourcesContent":["@import 'assets/styles/mixins';\n@import 'assets/styles/media.scss';\n\n.wrapper {\n\tbackground-color: var(--color-neutral-white);\n\n\t.content {\n\t\t&_webLogin {\n\t\t\tmax-width: none;\n\t\t}\n\n\t\t&.content-lg {\n\t\t\tmax-width: 684px;\n\t\t}\n\t}\n\n\t@include mobile {\n\t\t.content {\n\t\t\tposition: relative;\n\t\t\theight: 100%;\n\n\t\t\t&:not(&.content-lg) {\n\t\t\t\toverflow: hidden;\n\t\t\t}\n\n\t\t\t& ion-button:last-of-type {\n\t\t\t\tmargin-top: auto;\n\t\t\t}\n\n\t\t\t&_webLogin {\n\t\t\t\toverflow-y: auto;\n\t\t\t}\n\t\t}\n\t}\n}\n","@mixin tablet {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin desktop {\n\t@media (min-width: 1025px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-md {\n\t@media (max-width:374px) {\n\t\t@content\n\t}\n}\n\n@mixin mobile-small {\n\t@media (max-width: 319px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-small-height {\n\t@media (max-height: 668px) {\n\t\t@content;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AuthLayout_wrapper__geWiU`,
	"content_webLogin": `AuthLayout_content_webLogin__egq7t`,
	"content": `AuthLayout_content__zZL1W`,
	"content-lg": `AuthLayout_content-lg__pqZnH`
};
export default ___CSS_LOADER_EXPORT___;
