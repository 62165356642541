import { useEffect, useRef } from 'react';

export const useAutofocus = (isShouldFocus = true) => {
	const inputRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (isShouldFocus && inputRef?.current) {
			inputRef.current.focus();
		}
	}, [isShouldFocus]);

	return inputRef;
};
