import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/system/reducer';
import { DataHint, DataHintModalContent, ProviderAccordionGroup } from 'components/shared';
import {
	EMPTY_RECEIPT_HINT_DATA,
	EMPTY_RECEIPT_HINT_MODAL_DATA,
	MAX_LENGTH_SENT_TO_BILL_PROVIDERS,
	WAITING_PROVIDER_DATA_HINT_DATA,
	WAITING_PROVIDER_DATA_HINT_MODAL_DATA,
} from 'utils/constants';
import { DATE_FORMAT } from 'utils/enums';
import { formatDateView, getCurrentReceiptMonth, getTotalFeeTitle, sortProvidersObjByType } from 'utils/helpers';
import { IBillReceipt, IProviderServiceResponse, ISingleReceiptPayProviderServiceRequest } from 'utils/types';
import { IHint } from 'utils/types/hint.interface';
import { PaymentProviderItem } from '../PaymentProviderItem';
import { ReceiptProvidersListPreview } from '../ReceiptProvidersListPreview';

const RECEIPT_TITLE = 'Єдина квитанція';

interface ReceiptProviderServiceListProps {
	selectedServiceList: ISingleReceiptPayProviderServiceRequest[];
	onChangeSelectedList: (value: ISingleReceiptPayProviderServiceRequest[]) => void;
	defaultServiceList?: IProviderServiceResponse[][];
}

export const ReceiptProviderServiceList: FC<ReceiptProviderServiceListProps> = ({
	selectedServiceList,
	onChangeSelectedList,
	defaultServiceList,
}) => {
	const dispatch = useAppDispatch();

	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);
	const [serviceList, setServiceList] = useState<IProviderServiceResponse[][]>([]);
	const [hintProps, setHintProps] = useState<null | IHint>(null);

	const updateHintProps = () => {
		const hasService = !!serviceList.length;

		const providersLength = receipt.data?.parsingInfo.sentToBillProviders?.length || 0;

		const isProviderDataParsed = providersLength === MAX_LENGTH_SENT_TO_BILL_PROVIDERS;

		const isDataForPreviousMonth = +getCurrentReceiptMonth() > Number(receipt.data?.month);

		if ((hasService && isProviderDataParsed) || isDataForPreviousMonth) {
			setHintProps(null);
		} else {
			setHintProps(
				hasService && providersLength && providersLength < MAX_LENGTH_SENT_TO_BILL_PROVIDERS
					? WAITING_PROVIDER_DATA_HINT_DATA
					: EMPTY_RECEIPT_HINT_DATA
			);
		}
	};

	useEffect(() => {
		let serviceListPayload = defaultServiceList ?? [];
		if (receipt.data?.id) {
			serviceListPayload = sortProvidersObjByType(receipt.data.serviceProviderData);
		}
		setServiceList(serviceListPayload);
	}, [receipt.data?.id, defaultServiceList]);

	useEffect(() => {
		if (!receipt.data) return;
		updateHintProps();
	}, [serviceList]);

	const handleOpenModal = () => {
		const props = serviceList.length ? WAITING_PROVIDER_DATA_HINT_MODAL_DATA : EMPTY_RECEIPT_HINT_MODAL_DATA;
		dispatch(
			openModal({
				content: <DataHintModalContent {...props} />,
			})
		);
	};

	const date = dayjs()
		.set('month', Number(receipt.data?.month) - 1)
		.set('year', Number(receipt.data?.year));

	const title = `${RECEIPT_TITLE} за ${formatDateView(date, DATE_FORMAT.RECEIPT_VIEW)}`;

	if (!receipt.data && !defaultServiceList?.length) return null;

	return (
		<ReceiptProvidersListPreview title={title} chipTitle={getTotalFeeTitle(0)}>
			<ProviderAccordionGroup>
				{serviceList.map((providerServiceList: IProviderServiceResponse[]) => (
					<PaymentProviderItem
						key={providerServiceList[0].providerType}
						serviceList={providerServiceList}
						onChange={onChangeSelectedList}
						selectedServiceList={selectedServiceList}
					/>
				))}
			</ProviderAccordionGroup>
			{!!hintProps && <DataHint {...hintProps} onOpenModal={handleOpenModal} />}
		</ReceiptProvidersListPreview>
	);
};
