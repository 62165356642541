import { FC, KeyboardEvent, useRef } from 'react';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';
import { TextField, TextFieldProps } from '@mui/material';
import { CURRENCY_LABEL } from 'utils/enums';
import { handleKeyDownServiceAmount } from 'utils/helpers';

type OwnProps = { value: string | number; onChange: (value: string) => void };

export type NumberInputProps = Omit<NumericFormatProps, 'onChange'> & Omit<TextFieldProps, 'onChange'> & OwnProps;

export const NumberInput: FC<NumberInputProps> = ({ value, onChange, max, ...props }) => {
	const inputRef = useRef<HTMLInputElement>();

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		handleKeyDownServiceAmount(e, value);
	};

	const handleChange = ({ value }: NumberFormatValues): void => {
		onChange(value);
		if (inputRef.current && +value === 0) {
			inputRef.current.setSelectionRange(2, 1);
		}
	};

	return (
		<NumericFormat
			{...props}
			value={value}
			inputRef={inputRef}
			onValueChange={handleChange}
			customInput={TextField}
			onChange={undefined}
			allowNegative={false}
			allowLeadingZeros
			suffix={CURRENCY_LABEL.UAH}
			fixedDecimalScale
			defaultValue={0}
			thousandSeparator=" "
			onKeyDown={handleKeyDown}
			inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
			decimalScale={2}
			allowedDecimalSeparators={[',']}
			isAllowed={max ? ({ value }) => Number(value) <= Number(max) : undefined}
		/>
	);
};
