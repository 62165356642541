import { FC, HTMLProps } from 'react';
import classNames from 'classnames';
import { HeaderPublic } from 'components/HeaderPublic';
import styles from './index.module.scss';

interface PublicLayoutProps extends HTMLProps<HTMLDivElement> {
	isLoginDisabled?: boolean;
}

export const PublicLayout: FC<PublicLayoutProps> = ({ children, isLoginDisabled = false }) => {
	return (
		<section className={classNames('layout', styles.layout)}>
			<HeaderPublic isLoginDisabled={isLoginDisabled} />
			{children}
		</section>
	);
};
