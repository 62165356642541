// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./NunitoSans/NunitoSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./NunitoSans/NunitoSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./NunitoSans/NunitoSans-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./NunitoSans/NunitoSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "NunitoSans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "NunitoSans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "NunitoSans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "NunitoSans";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: 700;
  font-display: swap;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AACF;AAEA;EACE,yBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AAAF;AAGA;EACE,yBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AADF;AAIA;EACE,yBAAA;EACA,+DAAA;EACA,gBAAA;EACA,kBAAA;AAFF","sourcesContent":["@font-face {\n  font-family: 'NunitoSans';\n  src: url('./NunitoSans/NunitoSans-Regular.ttf') format('truetype');\n  font-weight: 400;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'NunitoSans';\n  src: url('./NunitoSans/NunitoSans-Medium.ttf') format('truetype');\n  font-weight: 500;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'NunitoSans';\n  src: url('./NunitoSans/NunitoSans-Black.ttf') format('truetype');\n  font-weight: 900;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'NunitoSans';\n  src: url('./NunitoSans/NunitoSans-Bold.ttf') format('truetype');\n  font-weight: 700;\n  font-display: swap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
