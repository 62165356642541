import { BILL_TYPE, PROVIDER_TYPE } from 'utils/enums';
import { LOCALE } from 'utils/enums/locale.enum';
import {
	IAddServiceFieldValueRequest,
	IPaginatedResponse,
	IServicesCategoriesItemResponse,
	IServicesCategoriesRequest,
	IServicesItemResponse,
	ServicesRequest,
} from 'utils/types';

export const DEFAULT_SERVICE_LIST_DATA = {
	listData: {
		showedFrom: 0,
		totalRows: 0,
		showedTo: null,
	},
};

export const DEFAULT_SERVICES_CATEGORIES_PAGINATED_DATA: IPaginatedResponse<IServicesCategoriesItemResponse> = {
	...DEFAULT_SERVICE_LIST_DATA,
	tableData: [],
};

export const DEFAULT_SERVICES_PAGINATED_DATA: IPaginatedResponse<IServicesItemResponse> = {
	...DEFAULT_SERVICE_LIST_DATA,
	tableData: [],
};

export const DEFAULT_ADD_SERVICE_VALUE: IAddServiceFieldValueRequest = {
	userId: '',
	serviceId: '',
	name: '',
	value: '',
	apartmentAccountId: null,
};

export const SERVICES_CATEGORIES_DEFAULT_REQUEST_BODY: IServicesCategoriesRequest = {
	offset: 0,
	limit: 12,
	locale: LOCALE.UK,
	options: {
		serviceCategoryTitle: '',
	},
	billType: BILL_TYPE.EK,
};

export const SERVICES_DEFAULT_REQUEST_BODY: ServicesRequest = {
	offset: 0,
	limit: 12,
	locale: LOCALE.UK,
	options: {
		serviceTitle: '',
		serviceCategoryId: [],
		provider: PROVIDER_TYPE.PORTMONE,
	},
};

export const PORTMONE_AMOUNT_FIELD_NAME = 'amount';

export const PORTMONE_SERVICE_CODE = {
	NAFTOGAS: '1017P',
	ENERGOZBUT: '620P',
};
