import { ChangeEvent, FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { getReactHookFormError, getUnmaskedPhone } from 'utils/helpers';
import { PhoneInput, PhoneInputProps } from '../PhoneInput';

type OwnProps = {
	name: string;
};

type FormPhoneInputProps = OwnProps & Omit<PhoneInputProps, 'value' | 'onChange'>;

export const FormPhoneInput: FC<FormPhoneInputProps> = ({ name, ...props }) => {
	const { control } = useFormContext();
	const {
		field: { onChange, ...field },
		fieldState: { error: fieldError, isTouched },
	} = useController({ control, name });

	const { isErrorShown, error } = getReactHookFormError(isTouched, fieldError);

	const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
		const phoneNumber = getUnmaskedPhone(event.target.value);
		// format should be 090000000
		onChange(phoneNumber.slice(2));
	};

	return <PhoneInput error={isErrorShown} errorText={error} {...field} {...props} onChange={handleChangeValue} />;
};
