import axios, { AxiosResponse } from 'axios';
import { USER_API } from 'core/api';
import { STORAGE_KEY, USER_API_URL } from 'utils/enums';
import {
	IDeleteUserAvatarRequest,
	IRestorePasswordRequest,
	IUserCheckDeviceResponse,
	IUserCheckResponse,
	IUserLogoutResponse,
	IUserProfileResponse,
	IUserRequest,
	IUserResponse,
	IUserUpdateProfileRequest,
	IUserVerifyResponse,
	IVerifyUserDevice,
	UserCreateRequest,
	UserLoginRequest,
} from 'utils/types';
import { StorageService } from './storage.service';

class UserService {
	static checkIsUserExists = async (
		reqBody: Pick<IUserRequest, 'phone'>
	): Promise<AxiosResponse<IUserCheckResponse>> => {
		return USER_API.post(USER_API_URL.USER_CHECK, reqBody);
	};

	static checkIsUserDeviceConfirmed = async (userId: string | null): Promise<AxiosResponse<IUserCheckDeviceResponse>> =>
		USER_API.post(USER_API_URL.USER_CHECK_DEVICE_IS_CONFIRMED, { userId });

	static verifyUser = async (
		reqBody: Pick<IUserRequest, 'password' | 'phone'>
	): Promise<AxiosResponse<IUserVerifyResponse>> => USER_API.post(USER_API_URL.USER_VERIFY, reqBody);

	static verifyUserResend = async (): Promise<AxiosResponse> => {
		try {
			const verificationId: string | null = await StorageService.get(STORAGE_KEY.VERIFICATION_ID);
			if (verificationId) {
				return USER_API.post(USER_API_URL.USER_VERIFY_RESEND, { verificationId });
			}
			throw new Error('Повторна відправка неможлива, код не знайдено');
		} catch (error) {
			throw new Error(error);
		}
	};

	static verifyRestorePassword = async (reqBody: Pick<IUserRequest, 'phone'>): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_VERIFY_RESTORE, reqBody);

	static confirmRestorePassword = async (reqBody: IVerifyUserDevice): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_CONFIRM_RESTORE, reqBody);

	static restorePassword = async (reqBody: IRestorePasswordRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_RESTORE_PASSWORD, reqBody);

	static confirmUser = async (reqBody: IVerifyUserDevice): Promise<AxiosResponse<IUserResponse>> =>
		USER_API.post(USER_API_URL.USER_CONFIRM, reqBody);

	static createUser = async (reqBody: UserCreateRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_CREATE, reqBody);

	static login = async (reqBody: UserLoginRequest): Promise<AxiosResponse<IUserResponse>> =>
		USER_API.post(USER_API_URL.USER_LOGIN, reqBody);

	static getProfile = async (): Promise<AxiosResponse<IUserProfileResponse>> =>
		USER_API.post(USER_API_URL.USER_PROFILE);

	static logout = async (): Promise<AxiosResponse<IUserLogoutResponse>> => USER_API.post(USER_API_URL.USER_LOGOUT);

	static updateUserProfile = async (reqBody: IUserUpdateProfileRequest): Promise<AxiosResponse<IUserProfileResponse>> =>
		USER_API.post(USER_API_URL.USER_PROFILE_UPDATE, reqBody);

	static updateUserEmail = async (reqBody: IUserUpdateProfileRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_PROFILE_EMAIL_UPDATE, reqBody);

	static deleteUserAvatar = async (reqBody: IDeleteUserAvatarRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_DELETE_AVATAR, reqBody);

	static generateImageUrl = async (userId: string, fileName: string): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_GENERATE_IMAGE_URL, { userId, fileName });

	static getImage = async (userId: string): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_GET_IMAGE_URL, { userId });

	static uploadImage = async (file: File, url: string): Promise<AxiosResponse> =>
		axios.put(url, file, {
			headers: {
				'Content-Type': file.type,
			},
		});

	static checkUserEmail = async (reqBody: IUserUpdateProfileRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_PROFILE_EMAIL_CHECK, reqBody);

	static resendEmailUpdateConfirmCode = async (): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_PROFILE_CONFIRM_UPDATE_EMAIL_RESEND);

	static confirmDevice = async (reqBody: Partial<IVerifyUserDevice>): Promise<AxiosResponse<IUserResponse>> =>
		USER_API.post(USER_API_URL.USER_DEVICE_CONFIRM, reqBody);

	static confirmEmailUpdate = async (reqBody: IVerifyUserDevice): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.USER_PROFILE_CONFIRM_UPDATE_EMAIL, reqBody);
}

export { UserService };
