import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import { MenuList, Typography } from '@mui/material';
import InfoIcon from 'assets/icons/info-circle.svg';
import {
	getUserProfileSelector,
	openDocumentViewer,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankDocumentViewButton } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_GET_IMAGE_TYPE, ROUTER_URL } from 'utils/enums';
import { getErrorMessage, getRadabankDocumentUrl } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IDocumentViewer, IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const RadabankAuthInfoSignDocumentsPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const profile = useAppSelector(getUserProfileSelector);

	const [contractFile, setContractFile] = useState<IDocumentViewer>({ url: '', fileName: '' });

	useEffect(() => {
		if (profile) {
			handleGetImage(profile.userId);
		}
	}, [profile]);

	const handleGetImage = async (userId: string): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			await RadabankService.createInfoStartDocument(userId);
			const responseContractInfoImage = await RadabankService.getInfoImage(userId, RADABANK_GET_IMAGE_TYPE.CONTRACT);
			const { name } = responseContractInfoImage.data;
			setContractFile({ url: getRadabankDocumentUrl(name), fileName: name, openAsUrl: true, isShareEnabled: true });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleClickViewContract = (): void => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ZAYAVA,
			FIREBASE_EVENT_ANALYTICS_BUTTON.ZAYAVA
		);
		dispatch(openDocumentViewer(contractFile));
	};

	const handleClickViewPublicContract = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ZAYAVA,
				FIREBASE_EVENT_ANALYTICS_BUTTON.PUBLIC_CONTRACT
			);
			await Browser.open({ url: process.env.REACT_APP_RADABANK_PUBLIC_CONTRACT_URL as string });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ZAYAVA,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			await RadabankService.commitInfoStartDocument((profile as IUserProfileResponse).userId);
			history.replace(ROUTER_URL.RADABANK_CARD_AUTH_INFO_IMAGE_SET_SIGN);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ZAYAVA,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-white-auth">
			<ArrowBackButton onClick={handleGoBack} />
			<Typography variant="h5" marginBottom="var(--spacing-6) !important">
				Підписання документів
			</Typography>
			<MenuList className={styles.list}>
				<RadabankDocumentViewButton onClick={handleClickViewContract} label="Заява-згода" />
				<RadabankDocumentViewButton onClick={handleClickViewPublicContract} label="Публічний договір" />
			</MenuList>
			<div className={styles.info}>
				<img src={InfoIcon} alt="" />
				<Typography variant="caption">
					Натискаючи “Далі” ви підтверджуєте, що ознайомлені з документами та підписуєте їх
				</Typography>
			</div>
			<CustomButton label="Далі" className={styles.button} onClick={handleSubmit} />
		</div>
	);
};
