import { FC, MouseEvent, ReactNode } from 'react';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import styles from './index.module.scss';

interface MapActionButtonProps {
	id?: string;
	icon: ReactNode;
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const MapActionButton: FC<MapActionButtonProps> = ({ id, icon, onClick }) => {
	const { isLoading } = useAppSelector(getBillReceiptSelector);

	return (
		<button id={id} className={styles.button} onClick={onClick} disabled={isLoading}>
			{icon}
		</button>
	);
};
