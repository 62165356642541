import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { CheckPhone } from 'components/Auth';
import { ArrowBackButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { CHECK_RESPONSE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { getErrorMessage, getUnmaskedPhone } from 'utils/helpers';
import { FirebaseAnalytics, StorageService, UserService } from 'utils/services';
import { IUserCheckDeviceResponse, IUserCheckResponse } from 'utils/types';

export const CheckUserPhonePage: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const { search } = useLocation();

	const [isLoading, setIsLoading] = useState(false);

	const handleConfirm = async (phoneNumber: string): Promise<void> => {
		try {
			setIsLoading(true);
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.CHECK_PHONE,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONFIRM_PHONE
			);
			const phonePayload: string = getUnmaskedPhone(phoneNumber);
			StorageService.set(STORAGE_KEY.PHONE, phonePayload);
			const userExistsResponse: AxiosResponse<IUserCheckResponse> = await UserService.checkIsUserExists({
				phone: phonePayload,
			});
			let redirectPath: string = ROUTER_URL.AUTH_PASSWORD_SETUP;
			let queryString = '';

			if (userExistsResponse.data.userExists === CHECK_RESPONSE.YES && userExistsResponse.data.userId) {
				const checkIsDeviceConfirmedResponse: AxiosResponse<IUserCheckDeviceResponse> =
					await UserService.checkIsUserDeviceConfirmed(userExistsResponse.data.userId);

				if (checkIsDeviceConfirmedResponse.data.isConfirmed === CHECK_RESPONSE.YES) {
					redirectPath = ROUTER_URL.AUTH_LOGIN;
					queryString = search;
				} else {
					redirectPath = ROUTER_URL.AUTH_LOGIN_VERIFY_DEVICE;
				}
			}

			history.push({ pathname: redirectPath, search: queryString });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleClickBack = () => {
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.CHECK_PHONE, FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK);
		history.goBack();
	};

	return (
		<>
			<ArrowBackButton onClick={handleClickBack} />
			<CheckPhone onSubmit={handleConfirm} isLoading={isLoading} />
		</>
	);
};
