import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import classNames from 'classnames';
import { PAYMENT_STATUS } from 'utils/enums';
import { getPaymentStatusTitle } from 'utils/helpers';
import styles from './index.module.scss';

interface PaymentStatusTitleProps extends TypographyProps {
	status: PAYMENT_STATUS;
}

export const PaymentStatusTitle: FC<PaymentStatusTitleProps> = ({ status, ...props }) => (
	<Typography
		variant="body1"
		className={classNames(styles.status, {
			[styles.status_success]: status === PAYMENT_STATUS.PAID || status === PAYMENT_STATUS.PAID_PARTIAL,
		})}
		{...props}
	>
		{getPaymentStatusTitle(status)}
	</Typography>
);
