import { FC } from 'react';
import { Typography } from '@mui/material';
import { FormPhoneInput } from 'components/shared/FormPhoneInput';

export const SurveyContactPersonPhone: FC = () => {
	return (
		<>
			<Typography>Номер телефону контактної особи</Typography>
			<FormPhoneInput name="contact_phone" />
		</>
	);
};
