// Type
interface MuiComponentSettings {
  [key: string]: string | number | object;
}

export const MuiAvatar: MuiComponentSettings = {
  styleOverrides: {
    root: {
      width: '63px',
      height: '63px',
    },
  },
};
