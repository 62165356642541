import { useEffect } from 'react';

export const useAndroidBackButton = (onGoBack: () => void, ...deps: unknown[]) => {
	const handleBackButton = (ev: any) => {
		ev.detail.register(1, () => {
			onGoBack();
		});
	};

	useEffect(() => {
		document.addEventListener('ionBackButton', handleBackButton);

		return () => {
			document.removeEventListener('ionBackButton', handleBackButton);
		};
	}, deps);
};
