import { IConfirmModal, IModal } from 'utils/types';

export const EMPTY_CONFIRM_MODAL: IConfirmModal = {
	isOpen: false,
	submitButtonLabel: '',
	cancelButtonLabel: 'Скасувати',
	title: '',
	subTitle: '',
	icon: null,
	color: 'danger',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSubmit: () => {},
	isPresented: false,
};

export const MODAL_DATA: IModal = {
	isOpen: false,
	content: null,
	isPresented: false,
};
