import { FC, useEffect, useState } from 'react';
import { IonSpinner } from '@ionic/react';
import { getServicesByCategory } from 'store/bill/actions';
import { getBillReceiptSelector, getServicesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { ServicesHeader, ServicesList } from 'components/OtherServices';
import { SERVICES_DEFAULT_REQUEST_BODY } from 'utils/constants';
import { PROVIDER_TYPE } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { IBillReceipt, ServicesRequest } from 'utils/types';
import styles from './index.module.scss';

interface ServicesProps {
	categoryId: string;
	onClickBack: () => void;
	onChange: (serviceId: string, serviceName: string) => void;
}

export const Services: FC<ServicesProps> = ({ categoryId, onClickBack, onChange }) => {
	const dispatch = useAppDispatch();

	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);
	const services = useAppSelector(getServicesSelector);

	const [reqBody, setReqBody] = useState<ServicesRequest>({
		...SERVICES_DEFAULT_REQUEST_BODY,
		options: { ...SERVICES_DEFAULT_REQUEST_BODY.options, serviceCategoryId: [categoryId] },
	});
	const [disabledServicesCodeList, setDisabledServicesCodeList] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		handleGetServices(reqBody);
	}, [reqBody]);

	useEffect(() => {
		if (receipt.data) {
			const otherPaymentServicesPayload = receipt.data.otherPaymentServices.map((item) => item.code);
			const defaultPortmonePaymentServicesPayload = receipt.data.serviceProviderData[PROVIDER_TYPE.PORTMONE]?.map(
				(item) => item.code
			);
			let payload = [...otherPaymentServicesPayload];
			if (defaultPortmonePaymentServicesPayload) {
				payload = [...payload, ...defaultPortmonePaymentServicesPayload];
			}
			setDisabledServicesCodeList(payload);
		}
	}, [receipt.data]);

	const handleGetServices = async (reqBody: ServicesRequest): Promise<void> => {
		try {
			setIsLoading(true);
			await dispatch(getServicesByCategory(reqBody)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<ServicesHeader
					className="receipt-service-modal-header"
					categoryId={categoryId}
					data={reqBody}
					onChange={(data) => setReqBody(data)}
					onClickBack={onClickBack}
				/>
				<ServicesList
					data={reqBody}
					onChange={(data) => setReqBody(data)}
					onClickCard={onChange}
					disabledServicesCodeList={disabledServicesCodeList}
				/>
			</div>
			{isLoading && !services.data.tableData.length && <IonSpinner className="loader-dots" name="dots" />}
		</div>
	);
};
