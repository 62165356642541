import { useState } from 'react';

export const useOpen = (isOpenDefault = false) => {
	const [isOpen, setIsOpen] = useState(isOpenDefault);
	const [isPresented, setIsPresented] = useState(false);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setIsPresented(false);
	};

	const handleDidPresent = () => {
		setIsPresented(true);
	};

	return { isOpen, handleClose, handleOpen, handleDidPresent, isPresented };
};
