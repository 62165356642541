import { FC } from 'react';
import { FormTextField } from 'components/shared/FormTextField';

export const SurveyLetterAddress: FC = () => {
	return (
		<>
			<FormTextField name="adr_street" label="Вулиця" />
			<FormTextField name="adr_house" label="Будинок" />
			<FormTextField name="adr_flat" label="Квартира" />
		</>
	);
};
