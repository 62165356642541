import { PDF_TYPE, PROVIDER_TYPE } from 'utils/enums';
import {
	IBillsSubBillItem,
	IReceiptResponse,
	IReportPaymentReceiptExtraData,
	IReportPaymentReceiptExtraDataProviderItem,
	IReportPaymentReceiptResponse,
} from 'utils/types';

export const getReportDownloadFileName = (
	data: IReceiptResponse | IReportPaymentReceiptResponse,
	type = PDF_TYPE.RECEIPT
) => {
	let month = '';
	let year = '';
	let id = '';
	if ((data as IReportPaymentReceiptResponse)?.extraData) {
		const extraData: IReportPaymentReceiptExtraData = JSON.parse((data as IReportPaymentReceiptResponse)?.extraData);
		month = extraData.date_DD_MM_YYYY?.slice(3, 5) ?? '';
		year = extraData.date_DD_MM_YYYY?.slice(6) ?? '';
		id = extraData.id?.toString() ?? (data as IReportPaymentReceiptResponse).id.toString();
	} else {
		month = (data as IReceiptResponse).month;
		year = (data as IReceiptResponse).year;
		id = (data as IReceiptResponse).id.toString();
	}
	return `${type}-${month}-${year}-${id}`;
};

export const getReportProviderTotalAmount = (data: IReportPaymentReceiptExtraDataProviderItem[]): number =>
	data.reduce((acc, item) => acc + (item.payed || (item.currentPayed ? +item.currentPayed : 0)), 0);

export const getPortmoneSubBillsByService = (subBills: IBillsSubBillItem[]): IBillsSubBillItem[][] =>
	subBills
		.filter((item) => item.provider === PROVIDER_TYPE.PORTMONE)
		.reduce(
			(acc, item) =>
				!acc.includes(item.serviceId as string) && item.serviceId ? [...acc, item.serviceId as string] : acc,
			[] as string[]
		)
		.reduce((acc, item) => {
			const serviceSubBills = subBills.filter((subBillItem) => subBillItem.serviceId === item);
			return serviceSubBills.length ? [...acc, serviceSubBills] : acc;
		}, [] as IBillsSubBillItem[][]);
