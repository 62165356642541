import { ADD_ADDRESS_FIELD_NAME } from 'utils/enums';

export const APARTMENT_FIELD_CONFIG = {
	[ADD_ADDRESS_FIELD_NAME.CITY]: {
		label: 'Місто',
		placeholder: 'Введіть назву мicта',
	},
	[ADD_ADDRESS_FIELD_NAME.STREET]: {
		label: 'Вулиця',
		placeholder: 'Введіть назву вулиці',
	},
	[ADD_ADDRESS_FIELD_NAME.HOUSE]: {
		label: 'Будинок',
		placeholder: 'Виберіть номер будинку',
	},
	[ADD_ADDRESS_FIELD_NAME.FLAT]: {
		label: 'Квартира',
		placeholder: 'Виберіть номер квартири',
	},
};
