import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { FIREBASE_EVENT_ANALYTICS_BUTTON } from 'utils/constants';
import { getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';

interface PrivacyLinkProps extends TypographyProps {
	pageName: string;
}

export const PrivacyLink: FC<PrivacyLinkProps> = ({ pageName, ...props }) => {
	const dispatch = useAppDispatch();

	const handleOpenPrivacy = async () => {
		try {
			FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.PRIVACY_POLICY);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return (
		<Typography variant="caption" {...props}>
			{/* eslint-disable-next-line react/no-unescaped-entities */}
			Натискаючи кнопку "Увійти", ви погоджуєтеся з умовами
			<br />
			<a
				href={process.env.REACT_APP_AGREEMENT_URL}
				onClick={handleOpenPrivacy}
				style={{ display: 'inline', textDecoration: 'underline', cursor: 'pointer' }}
				target="_blank"
				rel="noreferrer"
			>
				Користувацької угоди
			</a>{' '}
			HOST
		</Typography>
	);
};
