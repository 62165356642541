import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getRadabankInfoStatusClientMessageSelector, getRadabankInfoStatusSelector } from 'store';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';

export const RadabankAuthStatusPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const statusCode = useSelector(getRadabankInfoStatusSelector);
	const statusClientMessage = useSelector(getRadabankInfoStatusClientMessageSelector);

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.STATUS,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK}-${statusCode}`
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} />
			<RadabankOnboardingContent title={statusClientMessage} />
			<CustomButton label="Зрозуміло" color="secondary" onClick={handleGoBack} />
		</div>
	);
};
