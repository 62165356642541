import { FC } from 'react';
import { RadabankFormSwitch } from 'components/RadabankCard/FormSwitch';
import { SurveyLetterAddress } from 'components/RadabankCard/SurveyLetterAddress';
import { SurveyLetterLocation } from 'components/RadabankCard/SurveyLetterLocation';

export const SurveyLetterHomeAddressStep: FC = () => {
	return (
		<>
			<RadabankFormSwitch name="adr_input" label="Не проживаю за місцем реєстрації" />
			<SurveyLetterLocation />
			<SurveyLetterAddress />
		</>
	);
};
