// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentStatusTitle_status__BXWrK {
  color: var(--color-error-500);
  background-color: #ffd4dc;
  border-radius: 100px;
  padding: var(--spacing-1) var(--spacing-3);
}
.PaymentStatusTitle_status_success__fdefm {
  color: var(--color-success-500);
  background-color: #f4fff6;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/PaymentStatusTitle/index.module.scss"],"names":[],"mappings":"AAAA;EACC,6BAAA;EACA,yBAAA;EACA,oBAAA;EACA,0CAAA;AACD;AACC;EACC,+BAAA;EACA,yBAAA;AACF","sourcesContent":[".status {\n\tcolor: var(--color-error-500);\n\tbackground-color: #ffd4dc;\n\tborder-radius: 100px;\n\tpadding: var(--spacing-1) var(--spacing-3);\n\n\t&_success {\n\t\tcolor: var(--color-success-500);\n\t\tbackground-color: #f4fff6;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `PaymentStatusTitle_status__BXWrK`,
	"status_success": `PaymentStatusTitle_status_success__fdefm`
};
export default ___CSS_LOADER_EXPORT___;
