import { FC } from 'react';
import classNames from 'classnames';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	openDocumentViewer,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import { RadabankCardAccountDetails, RadabankCardActionButton, RadabankSectionHeader } from 'components/RadabankCard';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ERROR_MESSAGE } from 'utils/enums';
import { getErrorMessage, getRadabankDocumentUrl } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankInternalCurrentCard, IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

export const RadabankCardDetailsAndDocumentsPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const handleClickGetDocument = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.DETAILS_AND_DOCUMENTS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.ZAYAVA_PRO_VIDKRYTTIA
			);
			dispatch(setAppLoading(true));
			const response = await RadabankService.printInternalDocumentData({
				cardid: (currentCard as IRadabankInternalCurrentCard).id,
				userId: (profile as IUserProfileResponse).userId,
			});
			if (response.data.name) {
				dispatch(
					openDocumentViewer({
						url: getRadabankDocumentUrl(response.data.name),
						fileName: response.data.name,
					})
				);
			} else throw new Error(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.DETAILS_AND_DOCUMENTS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	if (!currentCard) return <Navbar />;
	return (
		<div className={classNames('host-card-page-color-white-auth', styles.page)}>
			<RadabankSectionHeader title="Реквізити рахунку" onClickBack={handleGoBack} />
			<div className={styles.content}>
				<RadabankCardAccountDetails />
				<RadabankCardActionButton labelDefault="Заява про відкриття рахунку" onClick={handleClickGetDocument} />
				{/* TODO: not able now */}
				{/* {(currentCard.add_printdogopen === RADABANK_BOOLEAN_VALUE.TRUE ||
					currentCard.add_printdogover === RADABANK_BOOLEAN_VALUE.TRUE) && <RadabankCardAccountDetailsAgreements />} */}
			</div>
		</div>
	);
};
