import { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { resetOtherServicesList } from 'store/bill/reducer';
import { useAppDispatch } from 'store/hooks';
import { CustomModal } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { useDevice } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { IAddOtherServiceToSingleReceipt, IDefaultModalProps } from 'utils/types';
import { ProviderService } from './ProviderService';
import { Services } from './Services';
import { ServicesCategories } from './ServicesCategories';
import styles from './index.module.scss';

interface AddPortmoneServiceModalProps extends IDefaultModalProps {
	onSubmit: (data: IAddOtherServiceToSingleReceipt) => void;
}

export const AddPortmoneServiceModal: FC<AddPortmoneServiceModalProps> = ({ isOpen, onClose, onSubmit, ...props }) => {
	const dispatch = useAppDispatch();

	const { isMobile } = useDevice();

	const [categoryId, setCategoryId] = useState('');
	const [serviceId, setServiceId] = useState('');

	useEffect(() => {
		if (!isOpen) {
			setCategoryId('');
			setServiceId('');
		}
	}, [isOpen]);

	const handleClickBackServicesList = () => {
		setCategoryId('');
		dispatch(resetOtherServicesList());
	};

	const handleChooseCategory = (categoryId: string, categoryName: string) => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.SR_CHOOSE_SERVICE_CATEGORY}-${categoryName}`
		);
		setCategoryId(categoryId);
	};

	const handleChooseService = (serviceId: string, serviceName: string) => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.SR_CHOOSE_SERVICE}-${serviceName}`
		);
		setServiceId(serviceId);
	};

	const content = useMemo(() => {
		if (!categoryId) {
			return <ServicesCategories onChange={handleChooseCategory} />;
		}
		if (categoryId && !serviceId) {
			return (
				<Services categoryId={categoryId} onClickBack={handleClickBackServicesList} onChange={handleChooseService} />
			);
		}
		return <ProviderService serviceId={serviceId} onClickBack={() => setServiceId('')} onSubmit={onSubmit} />;
	}, [categoryId, serviceId]);

	return (
		<CustomModal
			{...props}
			isOpen={isOpen}
			isCloseHeader={!(isMobile && categoryId)}
			onClose={onClose}
			className={classNames(styles.wrapper, {
				[styles.service]: serviceId && categoryId,
			})}
			isFullHeight
		>
			{content}
		</CustomModal>
	);
};
