import { FC } from 'react';
import { Box } from '@mui/material';
import { RadabankFormSwitch } from '../FormSwitch';
import styles from './index.module.scss';

export const SurveyAssetsAndProperty: FC = () => {
	return (
		<Box className={styles.wrapper}>
			<RadabankFormSwitch name="have_flat" label="Я маю власну квартиру" />
			<RadabankFormSwitch name="have_house" label="Я маю власний будинок" />
			<RadabankFormSwitch name="have_car" label="Я маю власне авто" />
			<RadabankFormSwitch name="have_land" label="Я маю земельну ділянку" />
			<RadabankFormSwitch name="have_deposit" label="Я маю депозит" />
		</Box>
	);
};
