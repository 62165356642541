import { getUserReducerSelector } from "store";
import { useAppSelector } from "store/hooks";
import { Rights } from "utils/enums/rights.enum";

export type CanIFn = ((permission: string) => boolean);

export const usePermissions = (): CanIFn => {
    const userProfile = useAppSelector(getUserReducerSelector);
    if (!userProfile?.rights.length) {
        return (): boolean => {
            return true;
        }
    }
    return (permission: string): boolean => {
        return (userProfile?.rights as string[])?.includes(Rights.FULL_ACCESS)
            || (userProfile?.rights as string[])?.includes(permission)
            || false;
    };
}
