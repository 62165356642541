import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { ProviderServiceHeader } from 'components/OtherServices';
import { getPhoneNumberFromString, getProviderInfoDataByType } from 'utils/helpers';
import { IBillsSubBillItem, IProviderServiceResponse } from 'utils/types';
import styles from './index.module.scss';

interface ProviderDefaultInfoModalProps {
	data: IProviderServiceResponse | IBillsSubBillItem | null;
}

export const ProviderDefaultInfoModal: FC<ProviderDefaultInfoModalProps> = ({ data }) => {
	const [providerData] = useState(getProviderInfoDataByType(data));

	return (
		<>
			<ProviderServiceHeader
				className="receipt-service-modal-header"
				title={providerData.title}
				icon={providerData.logo}
			/>
			<div className={styles.details}>
				<div className={styles.details__item}>
					<Typography variant="body1">Контакти:</Typography>
					<div className={styles.details__contacts}>
						{providerData.contacts.list.map((item, index) => (
							<a key={item} href={`tel:${getPhoneNumberFromString(item)}`}>
								{item}
								{index !== providerData.contacts.list.length - 1 && ','}
							</a>
						))}
					</div>
				</div>
				<div className={styles.details__item}>
					<Typography variant="body1">Графік роботи:</Typography>
					<Typography variant="body1" textAlign="right">
						{providerData.schedule}
					</Typography>
				</div>
				<div className={styles.details__item}>
					<Typography variant="body1">Сайт:</Typography>
					<a href={providerData.link} target="_blank" rel="noreferrer">
						<Typography variant="body1">{providerData.link}</Typography>
					</a>
				</div>
			</div>
		</>
	);
};
