import { FC } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface ParentCompProps {
	children?: React.ReactNode;
}

export const WelcomeViews: FC<ParentCompProps> = ({ children }) => {
	return <div className={classNames('layout', styles.layout)}>{children}</div>;
};
