import { FC } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

export const CloseButton: FC<IconButtonProps> = ({ className, ...props }) => {
	return (
		<IconButton {...props} className={classNames(className, styles.button)}>
			<Close className={styles.icon} />
		</IconButton>
	);
};
