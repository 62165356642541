import { ChangeEvent, FC, KeyboardEvent } from 'react';
import InputMask from 'react-input-mask';
import { BaseTextFieldProps } from '@mui/material';
import Input from 'components/shared/Input';

export interface PhoneInputProps extends Omit<BaseTextFieldProps, 'value' | 'defaultValue'> {
	isError?: boolean;
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	errorText?: string;
	touched?: boolean;
	onEnterPress?: () => void;
}

export const PhoneInput: FC<PhoneInputProps> = ({ errorText, error, touched, helperText, onEnterPress, ...props }) => {
	const handlePressEnter = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			onEnterPress?.();
		}
	};

	return (
		<InputMask
			name="phone"
			id="phone"
			{...(props as any)}
			mask="+38 (099) 999 99 99"
			placeholder="+38 (000) 000 00 00"
			maskChar={null}
			alwaysShowMask
		>
			<Input
				isError={!!((errorText || helperText) && (touched || error))}
				helperText={(touched || error) && (errorText || helperText) ? errorText || helperText : ''}
				inputProps={{ inputMode: 'numeric' }}
				onKeyDown={handlePressEnter}
			/>
		</InputMask>
	);
};
