import { FieldError } from 'react-hook-form';

export const getTextFieldValidationProps = (
	errors: { [key: string]: string },
	touched: { [key: string]: boolean },
	key: string
) => ({
	error: !!touched[key] && !!errors[key],
	helperText: (!!touched[key] && errors[key]) || '',
});

export const getReactHookFormError = (isTouched: boolean, fieldError?: FieldError) => {
	const error = fieldError?.message;

	const isErrorShown = isTouched && !!error;

	return {
		error,
		isErrorShown,
	};
};
