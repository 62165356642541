import { FC, useEffect, useState } from 'react';
import { Timeout } from 'react-number-format/types/types';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { CustomButton } from 'components/shared';
import { OTP_TIMEOUT_MS } from 'utils/constants';
import { STORAGE_KEY } from 'utils/enums';
import { getDiffTimeString } from 'utils/helpers';
import { StorageService } from 'utils/services';
import styles from './index.module.scss';

interface CountdownTimerProps {
	onResendCode: () => void;
}

export const CountdownTimer: FC<CountdownTimerProps> = ({ onResendCode }) => {
	const [timeString, setTimeString] = useState('00:00');

	const [counter, setCounter] = useState(0);

	useEffect(() => {
		StorageService.get<string>(STORAGE_KEY.VERIFICATION_ID_DATE).then((result: string | null) => {
			let timeoutMs = OTP_TIMEOUT_MS;
			if (result) {
				const diff = OTP_TIMEOUT_MS - dayjs().diff(dayjs(result), 'ms');
				timeoutMs = diff <= 0 ? 0 : diff;
				setCounter(timeoutMs);
			}
		});
	}, []);

	useEffect(() => {
		let timer: Timeout | null = null;
		if (counter) {
			setTimeString(getDiffTimeString(counter));

			timer = setTimeout(() => {
				setCounter(counter - 1000 <= 0 ? 0 : counter - 1000);
			}, 1000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [counter]);

	const handleResendCode = async (): Promise<void> => {
		onResendCode();
		setCounter(OTP_TIMEOUT_MS);
		StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
	};

	return (
		<div className={styles.timer}>
			{!counter ? (
				<CustomButton fill="clear" onClick={handleResendCode} label="Вислати код повторно" />
			) : (
				<Typography variant="body2" color="--color-neutral-500">
					Вислати код повторно {timeString}
				</Typography>
			)}
		</div>
	);
};
