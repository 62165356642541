import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getErrorMessage } from '../helpers';

export const useLoading = () => {
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const handleStartLoading = async (res: () => Promise<void>, onFinally?: () => void) => {
		try {
			setIsLoading(true);
			await res();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
			if (onFinally) {
				onFinally();
			}
		}
	};

	return { isLoading, handleStartLoading };
};
