import { FC } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import { ReactComponent as CheckSvg } from 'assets/icons/check-circle.svg';
import { getUserProfileSelector, setAppLoading, showToast, useAppDispatch, useAppSelector } from 'store';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService, StorageService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import { ADVANTAGES_LIST } from './constants';
import styles from './index.module.scss';

export const RadabankAuthInfoBeginPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const profile = useAppSelector(getUserProfileSelector);

	const handleSubmit = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INFO_BEGIN,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			dispatch(setAppLoading(true));
			const response = await RadabankService.authInfoBegin({
				userId: (profile as IUserProfileResponse).userId,
				// format should be: 0900000000, our format is 380900000000
				phone: (profile as IUserProfileResponse).phone.slice(2),
			});
			await StorageService.set(STORAGE_KEY.RADABANK_AUTH_INFO, {
				infotoken: response.data.infotoken,
				infocode: response.data.infocode,
			});
			history.replace(ROUTER_URL.RADABANK_CARD_AUTH_APPROVE_CODE);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INFO_BEGIN,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} />
			<RadabankOnboardingContent title="Щоб отримати картку, потрібно">
				<ul className={styles.list}>
					{ADVANTAGES_LIST.map((item) => (
						<li key={item}>
							<CheckSvg />
							<Typography variant="body1" color="var(--color-neutral-white)">
								{item}
							</Typography>
						</li>
					))}
				</ul>
			</RadabankOnboardingContent>
			<CustomButton label="Продовжити" color="secondary" onClick={handleSubmit} />
		</div>
	);
};
