import { ChangeEvent, FC, useEffect } from 'react';
import { NumberFormatValues, PatternFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { getUserIdSelector, setAppLoading, showToast, useAppDispatch, useAppSelector } from 'store';
import { CustomButton, NumberInputFormik } from 'components/shared';
import { RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT } from 'utils/constants';
import { RADABANK_BOOLEAN_VALUE } from 'utils/enums';
import { convertCoinsToUAH, getErrorMessage, getTextFieldValidationProps } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';
import { RadabankService } from 'utils/services';
import { BooleanOrNull, IRadabankCreatePaymentRequest, IRadabankGetP2PAmountResponse, TypeOrNull } from 'utils/types';
import { RadabankOperationCardLimitsProgressBar } from '../Progress';
import styles from './index.module.scss';

interface OperationCardFormContentProps {
	onChangeIsRadabankCard: (value: boolean) => void;
	isRadabankCard: BooleanOrNull;
	p2pLimits: TypeOrNull<IRadabankGetP2PAmountResponse>;
}

export const FormContent: FC<OperationCardFormContentProps> = ({
	onChangeIsRadabankCard,
	isRadabankCard,
	p2pLimits,
}) => {
	const { setFieldValue, errors, touched, isValid, values, validateForm } =
		useFormikContext<IRadabankCreatePaymentRequest>();

	const dispatch = useAppDispatch();

	const userId = useAppSelector(getUserIdSelector);

	const handleChangePrevent = (event: ChangeEvent<HTMLDivElement>): void => {
		event.preventDefault();
	};

	const debouncedCard = useDebounce(values.card.trim());

	const cardValidataionProps = getTextFieldValidationProps(errors, touched, 'card');

	const handleCheckIsRadabankClient = async (cardnum: string, userId: string) => {
		try {
			dispatch(setAppLoading(true));
			const { data } = await RadabankService.checkIsRadabankCard({ cardnum, userId });
			onChangeIsRadabankCard(data.count !== RADABANK_BOOLEAN_VALUE.FALSE);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	useEffect(() => {
		if (userId && !cardValidataionProps.error && !cardValidataionProps.helperText && debouncedCard.length === 16) {
			handleCheckIsRadabankClient(debouncedCard, userId);
		}
	}, [userId, debouncedCard, cardValidataionProps.error, cardValidataionProps.helperText]);

	useEffect(() => {
		if (values.amount) {
			validateForm();
		}
		if (values.comment && isRadabankCard === false) {
			setFieldValue('comment', '');
		}
	}, [isRadabankCard]);

	const isShowLimitsProgressbar = isRadabankCard === false && p2pLimits;

	const availableAmount = p2pLimits
		? convertCoinsToUAH(p2pLimits?.amountrest)
		: RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT;

	const maxAllowedAmount =
		availableAmount > RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT
			? RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT
			: availableAmount;

	return (
		<>
			<div className={styles.content}>
				<Field
					name="card"
					onValueChange={(props: NumberFormatValues) => setFieldValue('card', props.value)}
					as={PatternFormat}
					valueIsNumericString
					format="#### #### #### ####"
					customInput={TextField}
					label="Картка отримувача"
					inputProps={{ inputMode: 'numeric' }}
					onChange={handleChangePrevent}
					{...cardValidataionProps}
				/>
				<Field name="amount" label="Сума переказу" component={NumberInputFormik} max={maxAllowedAmount} />
				{isShowLimitsProgressbar && <RadabankOperationCardLimitsProgressBar p2pLimits={p2pLimits} />}
				{isRadabankCard && <Field name="comment" as={TextField} label="Коментар" multiline />}
			</div>
			<CustomButton type="submit" label="Далі" disabled={!isValid} />
		</>
	);
};
