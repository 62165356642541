import { FC, useEffect, useState } from 'react';
import { MenuItem, MenuItemProps, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

interface RadabankCardLockReasonItemProps extends Omit<MenuItemProps, 'onChange'> {
	onChange: (value: string) => void;
	label: string;
	value: string;
	isSelected: boolean;
}

export const RadabankCardLockReasonItem: FC<RadabankCardLockReasonItemProps> = ({
	label,
	onChange,
	value,
	isSelected,
	...rest
}) => {
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');

	useEffect(() => {
		if (label) {
			handleInit(label);
		}
	}, [label]);

	const handleInit = (label: string) => {
		const splittedLabel = label
			.split(/[()]/g)
			.reduce((acc, item) => (item ? [...acc, item.trim()] : acc), [] as string[]);
		setTitle(splittedLabel[0].toLowerCase());
		setDescription(splittedLabel[1] ?? '');
	};

	return (
		<MenuItem
			{...rest}
			className={classNames(styles.content, {
				[styles.content_active]: isSelected,
				[styles.content_error]: (value === 'ST' || value === 'LT') && isSelected,
			})}
			onClick={() => onChange(value)}
		>
			<div className={styles.info}>
				<Typography variant="subtitle1" textAlign="left" className={styles.info__title}>
					{title}
				</Typography>
				<Typography variant="caption" textAlign="right" className={styles.info__description}>
					{description}
				</Typography>
			</div>
		</MenuItem>
	);
};
