import { FC, Fragment, useEffect, useState } from 'react';
import {
	ProviderAccordion,
	ProviderPreview,
	ProviderServiceBalancePreview,
	ProviderServicesGroupTitle,
} from 'components/shared';
import { PROVIDER_TYPE } from 'utils/enums';
import {
	getGroupedServicesListByType,
	getPortmoneProviderPreviewData,
	getProvideIconByType,
	getProviderPreviewData,
	getProviderServiceBalanceByType,
	getProviderServiceTitle,
	getProviderTitle,
} from 'utils/helpers';
import { IProviderServiceResponse, IReceiptOtherServiceResponse } from 'utils/types';

interface SingleReceiptProviderItemProps {
	serviceList: (IProviderServiceResponse | IReceiptOtherServiceResponse)[];
	defaultExpanded?: boolean;
	isOtherService?: boolean;
}

export const SingleReceiptProviderItem: FC<SingleReceiptProviderItemProps> = ({
	serviceList,
	defaultExpanded,
	isOtherService,
}) => {
	const [providerType, setProviderType] = useState<PROVIDER_TYPE | null>(null);

	useEffect(() => {
		setProviderType(
			(serviceList[0] as IProviderServiceResponse)?.providerType ||
				(serviceList[0] as IReceiptOtherServiceResponse).provider
		);
	}, [serviceList]);

	if (!providerType) return null;
	return (
		<ProviderAccordion
			title={
				<ProviderPreview
					title={getProviderTitle(serviceList[0])}
					description={
						isOtherService
							? getPortmoneProviderPreviewData(serviceList[0] as IReceiptOtherServiceResponse)
							: getProviderPreviewData(serviceList as IProviderServiceResponse[])
					}
					icon={getProvideIconByType(providerType, serviceList[0] as IProviderServiceResponse)}
					code={serviceList[0].code}
					providerType={providerType}
					data={serviceList[0] as IReceiptOtherServiceResponse}
				/>
			}
			defaultExpanded={defaultExpanded}
		>
			{getGroupedServicesListByType(
				serviceList,
				(serviceList[0] as IProviderServiceResponse)?.providerType ??
					(serviceList[0] as IReceiptOtherServiceResponse)?.provider
			).map((item, index) => (
				<Fragment key={`grouped-service-${serviceList[0].id}-${index}`}>
					{!!item.title && <ProviderServicesGroupTitle title={item.title} marginTop="var(--spacing-2)" />}
					{item.list.map((serviceItem) => (
						<ProviderServiceBalancePreview
							key={`${serviceItem.id ?? serviceItem.code}-${index}`}
							title={getProviderServiceTitle(serviceItem)}
							amount={-getProviderServiceBalanceByType(serviceItem)}
						/>
					))}
				</Fragment>
			))}
		</ProviderAccordion>
	);
};
