import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { showToast, useAppDispatch } from 'store';
import { CheckPhone, ConfirmOTPCode } from 'components/Auth';
import { ArrowBackButton, CustomButton } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	OTP_LENGTH,
	OTP_TIMEOUT_MS,
} from 'utils/constants';
import { CHECK_RESPONSE, ERROR_CODE, ERROR_MESSAGE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { getDiffTimeString, getErrorMessage, getErrorResponse, getUnmaskedPhone } from 'utils/helpers';
import { FirebaseAnalytics, StorageService, UserService } from 'utils/services';
import { IUserCheckResponse } from 'utils/types';

export const VerifyRestorePassword: FC = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const [phone, setPhone] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [otp, setOtp] = useState('');
	const [isValid, setIsValid] = useState(true);
	const [isOTPStep, setIsOTPStep] = useState(false);

	const handleConfirmRestore = async () => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.PASSWORD_RESTORE_VERIFY,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONFIRM_OTP
			);
			setIsLoading(true);
			await UserService.confirmRestorePassword({ code: otp });
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);
			await StorageService.set(STORAGE_KEY.VERIFICATION_CODE, otp);
			history.push(ROUTER_URL.AUTH_PASSWORD_RESTORE);
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			if (errorResponse?.errorData?.code === ERROR_CODE.INVALID_CONFIRM_RESTORE_CODE) {
				setIsValid(false);
			} else {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeOTP = (value: string) => {
		setIsValid(true);
		setOtp(value);
	};

	const handleResendCode = async () => {
		try {
			setIsLoading(true);
			await UserService.verifyRestorePassword({
				phone,
			});
			StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmitPhoneModal = async (phoneNumber: string) => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.PASSWORD_RESTORE_VERIFY,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONFIRM_PHONE
			);
			setIsLoading(true);
			const phonePayload: string = getUnmaskedPhone(phoneNumber);
			setPhone(phonePayload);
			StorageService.set(STORAGE_KEY.PHONE, phonePayload);
			const userExistsResponse: AxiosResponse<IUserCheckResponse> = await UserService.checkIsUserExists({
				phone: phonePayload,
			});
			if (userExistsResponse.data.userExists === CHECK_RESPONSE.YES && userExistsResponse.data.userId) {
				await UserService.verifyRestorePassword({
					phone: phoneNumber,
				});
				StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
				setIsOTPStep(true);
			} else {
				dispatch(showToast({ message: ERROR_MESSAGE.INVALID_PHONE }));
			}
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			if (errorResponse?.errorData?.code === ERROR_CODE.RESEND_RESET_PASSWORD_TIMEOUT) {
				const verificationDate = await StorageService.get<string>(STORAGE_KEY.VERIFICATION_ID_DATE);
				let message = '';
				if (verificationDate) {
					const diff = OTP_TIMEOUT_MS - dayjs().diff(dayjs(verificationDate), 'ms');
					const timeoutMs = diff <= 0 ? 0 : diff;
					message = `Ви можете спробувати ще раз через ${getDiffTimeString(timeoutMs)}`;
				} else {
					message = ERROR_MESSAGE.SOMETHING_WENT_WRONG;
				}
				dispatch(showToast({ message }));
			} else {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleClickBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.PASSWORD_RESTORE_VERIFY,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		if (isOTPStep) {
			setIsOTPStep(false);
		} else {
			history.goBack();
		}
		setOtp('');
	};

	return (
		<>
			<ArrowBackButton onClick={handleClickBack} />
			{isOTPStep ? (
				<>
					<ConfirmOTPCode
						onSubmit={handleConfirmRestore}
						value={otp}
						onChange={handleChangeOTP}
						isValid={isValid}
						onResendCode={handleResendCode}
						phone={phone}
					/>
					<CustomButton
						label="Продовжити"
						onClick={handleConfirmRestore}
						disabled={otp.length !== OTP_LENGTH}
						isLoading={isLoading}
						size="large"
					/>
				</>
			) : (
				<CheckPhone onSubmit={handleSubmitPhoneModal} isLoading={isLoading} />
			)}
		</>
	);
};
