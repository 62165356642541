import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { getAmountTitle, getBillsTotalAmount, getBillsTotalFee } from 'utils/helpers';
import { IBillsReportResponse } from 'utils/types';
import styles from './index.module.scss';

interface BillsPaymentDetailsProps {
	data: IBillsReportResponse;
}

export const BillsPaymentDetails: FC<BillsPaymentDetailsProps> = ({ data }) => {
	const [totalAmount, setTotalAmount] = useState(0);
	const [totalFee, setTotalFee] = useState(0);

	useEffect(() => {
		if (data) {
			const totalFeePayload = getBillsTotalFee(data);
			const totalAmountPayload = getBillsTotalAmount(data);
			setTotalFee(totalFeePayload);
			setTotalAmount(totalAmountPayload);
		}
	}, [data]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.detail}>
				<Typography variant="subtitle1">Сума</Typography>
				<Typography variant="subtitle1" whiteSpace="nowrap">
					{getAmountTitle(totalAmount)}
				</Typography>
			</div>
			<div className={styles.detail}>
				<Typography variant="subtitle1">Комісія</Typography>
				<Typography variant="subtitle1" whiteSpace="nowrap">
					{getAmountTitle(totalFee)}
				</Typography>
			</div>
			<div className={styles.detail}>
				<Typography variant="subtitle1">Загальна сума</Typography>
				<Typography variant="subtitle1" whiteSpace="nowrap">
					{getAmountTitle(totalAmount + totalFee)}
				</Typography>
			</div>
		</div>
	);
};
