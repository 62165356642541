// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
	box-sizing: border-box;
}

ul,
ol {
	padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

body {
	min-height: 100vh;
	min-height: 100dvh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

ul,
ol {
	list-style: none;
}

a:not() {
	text-decoration-skip-ink: auto;
}

img {
	max-width: 100%;
	display: block;
}

article>*+* {
	margin-top: 1em;
}

input,
button,
textarea,
select {
	font: inherit;
}

@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

a {
	display: block;
	text-decoration: none;
	color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;CAGC,sBAAsB;AACvB;;AAEA;;CAEC,UAAU;AACX;;AAEA;;;;;;;;;;;;;;CAcC,SAAS;AACV;;AAEA;CACC,iBAAiB;CACjB,kBAAkB;CAClB,uBAAuB;CACvB,6BAA6B;CAC7B,gBAAgB;AACjB;;AAEA;;CAEC,gBAAgB;AACjB;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,eAAe;AAChB;;AAEA;;;;CAIC,aAAa;AACd;;AAEA;CACC;EACC,qCAAqC;EACrC,uCAAuC;EACvC,sCAAsC;EACtC,gCAAgC;CACjC;AACD;;AAEA;CACC,cAAc;CACd,qBAAqB;CACrB,cAAc;AACf","sourcesContent":["*,\n*::before,\n*::after {\n\tbox-sizing: border-box;\n}\n\nul,\nol {\n\tpadding: 0;\n}\n\nbody,\nh1,\nh2,\nh3,\nh4,\np,\nul,\nol,\nli,\nfigure,\nfigcaption,\nblockquote,\ndl,\ndd {\n\tmargin: 0;\n}\n\nbody {\n\tmin-height: 100vh;\n\tmin-height: 100dvh;\n\tscroll-behavior: smooth;\n\ttext-rendering: optimizeSpeed;\n\tline-height: 1.5;\n}\n\nul,\nol {\n\tlist-style: none;\n}\n\na:not() {\n\ttext-decoration-skip-ink: auto;\n}\n\nimg {\n\tmax-width: 100%;\n\tdisplay: block;\n}\n\narticle>*+* {\n\tmargin-top: 1em;\n}\n\ninput,\nbutton,\ntextarea,\nselect {\n\tfont: inherit;\n}\n\n@media (prefers-reduced-motion: reduce) {\n\t* {\n\t\tanimation-duration: 0.01ms !important;\n\t\tanimation-iteration-count: 1 !important;\n\t\ttransition-duration: 0.01ms !important;\n\t\tscroll-behavior: auto !important;\n\t}\n}\n\na {\n\tdisplay: block;\n\ttext-decoration: none;\n\tcolor: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
