import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getRadabankInfoSprData, getUserIdSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { ValidationError } from 'yup';
import { HostCardSurveyStep } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useStepper } from 'utils/hooks';
import { IRadabankInfoSetAnketaForm } from 'utils/types';
import { SurveyLetterFormContent } from './Content';
import { getRadabankSurveyStep, HOST_CARD_SURVEY_STEPS_COUNT } from './data';
import styles from './index.module.scss';

interface HostCardSurveyLetterFormProps {
	onGoBack: () => void;
	initialValues: IRadabankInfoSetAnketaForm;
	onSubmit: (values: IRadabankInfoSetAnketaForm) => void;
}

export const HostCardSurveyLetterForm: FC<HostCardSurveyLetterFormProps> = memo(
	({ onGoBack, initialValues, onSubmit }) => {
		const dispatch = useAppDispatch();

		const userId = useAppSelector(getUserIdSelector);

		const { currentStep, handleNextStep, handlePreviousStep } = useStepper(
			HOST_CARD_SURVEY_STEPS_COUNT,
			HostCardSurveyStep.General
		);

		const { Component, validationSchema, title } = useMemo(() => getRadabankSurveyStep(currentStep), [currentStep]);

		const methods = useForm({
			defaultValues: initialValues,
			context: { schema: validationSchema },
			mode: 'onTouched',
			resolver: async (values, context) => {
				try {
					await context?.schema?.validate(values, { abortEarly: false });

					return {
						values,
						errors: {},
					};
				} catch (validationErrors) {
					const formattedErrors = validationErrors.inner.reduce(
						(allErrors: Record<string, { type: string; message: string }>, currentError: ValidationError) => {
							if (!currentError.path) return allErrors;

							return {
								...allErrors,
								[currentError.path]: {
									type: currentError.type ?? 'validation',
									message: currentError.message,
								},
							};
						},
						{}
					);

					return {
						values,
						errors: formattedErrors,
					};
				}
			},
		});

		const { reset, handleSubmit } = methods;

		useEffect(() => {
			if (userId) {
				handleGetInfoSprData(userId);
			}
		}, [userId]);

		useEffect(() => {
			reset(initialValues);
		}, [initialValues]);

		const handleGetInfoSprData = async (userId: string): Promise<void> => {
			try {
				await dispatch(getRadabankInfoSprData(userId)).unwrap();
			} catch (error) {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		};

		const handleClickArrowBack = () => {
			if (currentStep === HostCardSurveyStep.General) {
				onGoBack();
				return;
			}
			handlePreviousStep();
		};

		const formRef = useCallback(
			(node: HTMLDivElement) => {
				if (node) {
					node.scrollIntoView({ block: 'start', behavior: 'instant' });
				}
			},
			[currentStep]
		);

		return (
			<div className={styles.wrapper} ref={formRef}>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<SurveyLetterFormContent
							title={title}
							onClickBack={handleClickArrowBack}
							onPreviousStep={handlePreviousStep}
							onNextStep={handleNextStep}
							currentStep={currentStep}
							stepsCount={HOST_CARD_SURVEY_STEPS_COUNT}
						>
							<Component />
						</SurveyLetterFormContent>
					</form>
				</FormProvider>
			</div>
		);
	}
);
