import BitcoinCardSvg from 'assets/icons/benefits/bitcoin-card.svg';
import CardsSvg from 'assets/icons/benefits/cards.svg';
import ClockSvg from 'assets/icons/benefits/clock.svg';
import DocumentCopySvg from 'assets/icons/benefits/document-copy.svg';
import ShieldTick from 'assets/icons/benefits/shield-tick.svg';
import TaskSvg from 'assets/icons/benefits/task.svg';

export const BENEFITS_LIST = [
	{ title: 'Безпечно зберігайте картки, щоб сплачувати за послуги без введення всіх реквізитів', icon: ShieldTick },
	{ title: 'Здійснюйте оплати у кілька кліків збереженими картками або Google Pay та Apple Pay', icon: CardsSvg },
	{ title: 'Сплачуйте картками будь-яких українських банків Visa та Mastercard', icon: BitcoinCardSvg },
	{ title: 'Отримайте доступ до архіву ваших платежів', icon: DocumentCopySvg },
	{ title: 'Майте можливість завантажити квитанції за будь-який період', icon: ClockSvg },
	{ title: 'Автоматичний пошук та додавання комунальних послуг до адреси', icon: TaskSvg },
];
