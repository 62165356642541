import { FC, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SmartBanner from 'react-smartbanner';
import { Capacitor } from '@capacitor/core';
import { Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { ReceiptPublicBanner, ReceiptPublicContent } from 'components/ReceiptPublic';
import { CustomButton, Loader } from 'components/shared';
import { ROUTER_URL } from 'utils/enums';
import { convertCoinsToUAH, getErrorMessage, getErrorResponse, parseQueryString } from 'utils/helpers';
import { ReportService } from 'utils/services';
import { IReceiptAnauthorizedResponse } from 'utils/types';
import 'react-smartbanner/dist/main.css';
import styles from './index.module.scss';

const EXPIRED_ERROR_MESSAGE = 'Термін оплати квитанції завершився. Зареєструйтесь і отримайте ЄК за останній місяць';

export const ReceiptPublicPayPage: FC = () => {
	const dispatch = useAppDispatch();

	const location = useLocation();

	const [data, setData] = useState<IReceiptAnauthorizedResponse | null>(null);
	const [token] = useState(parseQueryString<{ token: string }>(location.search).token);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (token && token !== String(null)) {
			getReceiptData(token);
		} else if (token === String(null)) {
			setError(EXPIRED_ERROR_MESSAGE);
		}
	}, [token]);

	const getReceiptData = async (token: string): Promise<void> => {
		try {
			setIsLoading(true);

			const response: AxiosResponse<IReceiptAnauthorizedResponse> = await ReportService.getUnathorizedReceiptData(
				token
			);
			if (response.data) {
				const { sumBalance, serviceProviderData, ...rest } = response.data;

				const payload: IReceiptAnauthorizedResponse = {
					...rest,
					sumBalance: convertCoinsToUAH(sumBalance),
					serviceProviderData: serviceProviderData.map((item) => ({
						...item,
						amountToPay: convertCoinsToUAH(item.payed ? item.incomeBalance - item.payed : item.incomeBalance),
						incomeBalance: convertCoinsToUAH(item.incomeBalance),
						payed: item.payed ? convertCoinsToUAH(item.payed) : item.payed,
					})),
				};
				setData(payload);
			} else {
				setError('Платіж не знайдено');
			}
		} catch (error) {
			const errorData = getErrorResponse(error);
			const errorMessage = getErrorMessage(error);

			if (errorMessage === 'Unauthorized' && errorData?.statusCode === 401) {
				setError(EXPIRED_ERROR_MESSAGE);
			} else {
				dispatch(showToast({ message: errorMessage }));
			}
		} finally {
			setIsLoading(false);
		}
	};

	if (token === undefined) return <Redirect to={ROUTER_URL.ERROR} />;
	return (
		<div className={styles.wrapper}>
			<SmartBanner
				daysHidden={1}
				title="HOST"
				button="Завантажити"
				storeText={{ ios: 'В App Store', android: 'В Google Play' }}
				price={{ ios: 'Безкоштовно', android: 'Безкоштовно' }}
				url={{ ios: process.env.REACT_APP_APP_STORE_URL, android: process.env.REACT_APP_GOOGLE_PLAY_URL }}
			/>
			<div className={classNames('page', styles.content)}>
				<ReceiptPublicBanner />
				{data ? (
					<ReceiptPublicContent data={data} token={token} />
				) : (
					!isLoading && (
						<div className={styles.error}>
							<Link
								className={classNames(styles.error__link, {
									[styles.error__link_hidden]: isLoading,
								})}
								to={Capacitor.isNativePlatform() ? ROUTER_URL.AUTH_CHECK_PHONE : ROUTER_URL.AUTH_LOGIN}
							>
								<CustomButton fill="outline" label="Увійти" />
							</Link>
							<Typography variant="h5" textAlign="center">
								{error}
							</Typography>
						</div>
					)
				)}
				{isLoading && <Loader />}
			</div>
		</div>
	);
};
