import { PayloadAction } from '@reduxjs/toolkit';
import { IToast, IToastifyReducer } from 'utils/types';

export const showToastOperation = (state: IToastifyReducer, { payload }: PayloadAction<IToast>): void => {
	state.toastList.push({ ...payload, id: state.toastList.length + Date.now() });
};

export const closeToastOperation = (state: IToastifyReducer, { payload }: PayloadAction<number>): void => {
	state.toastList.filter((item: IToast) => item.id !== payload);
};
