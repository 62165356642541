import { FC } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as NewFuncIcon } from 'assets/images/home-coming-soon.svg';
import styles from './index.module.scss';

export const ComingSoonContent: FC = () => {
	return (
		<div className={styles.content}>
			<NewFuncIcon />
			<div className={styles.content__info}>
				<Typography variant="h6" textAlign="center">
					Нові функції незабаром
				</Typography>
				<Typography variant="body1" textAlign="center" color="var(--color-neutral-500)">
					Залишайтеся з нами — цікаві оновлення найближчим часом
				</Typography>
			</div>
		</div>
	);
};
