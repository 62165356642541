import { FC, MouseEvent } from 'react';
import { Capacitor } from '@capacitor/core';
import { IonContent, IonPopover } from '@ionic/react';
import { ReactComponent as DotsSvg } from 'assets/icons/three-dots-icon.svg';
import { CustomModal } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { useDevice, useOpen } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { MapActionButton } from '../Button';
import { ReceiptMenuList } from './ReceiptMenuList';
import styles from './index.module.scss';

interface ReceiptMenuProps {
	onClickChangePeriod?: (event: MouseEvent<HTMLLIElement>) => void;
	apartmentAccountId: string | number;
	title?: string;
}

export const ReceiptMenu: FC<ReceiptMenuProps> = ({ onClickChangePeriod, apartmentAccountId, title }) => {
	const { isOpen, handleOpen, handleClose } = useOpen();

	const { isMobile } = useDevice();

	const handleClickOpen = (event: MouseEvent<HTMLButtonElement>): void => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.MENU
		);
		event.stopPropagation();
		handleOpen();
	};

	return (
		<>
			<MapActionButton onClick={handleClickOpen} id={`popover-button-${apartmentAccountId}`} icon={<DotsSvg />} />
			{isMobile || Capacitor.isNativePlatform() ? (
				<CustomModal
					classNameHeader={styles.modal__header}
					title={title || 'Керування квитанцією'}
					isOpen={isOpen}
					onClose={handleClose}
				>
					<ReceiptMenuList
						onClick={handleClose}
						onClickChangePeriod={onClickChangePeriod}
						apartmentAccountId={apartmentAccountId}
					/>
				</CustomModal>
			) : (
				<IonPopover className={styles.popover} trigger={`popover-button-${apartmentAccountId}`} dismissOnSelect>
					<IonContent>
						<ReceiptMenuList onClickChangePeriod={onClickChangePeriod} apartmentAccountId={apartmentAccountId} />
					</IonContent>
				</IonPopover>
			)}
		</>
	);
};
