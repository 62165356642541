import { FC, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

interface ProviderAccordionProps extends BoxProps {
	children: ReactNode;
}

export const ProviderAccordionGroup: FC<ProviderAccordionProps> = ({ children, ...rest }) => (
	<Box className={classNames(styles.wrapper, rest.className)} {...rest}>
		{children}
	</Box>
);
