import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorResponse } from 'utils/helpers';
import { ReportService } from 'utils/services';
import { IReportPaymentReceiptRequest, IReportPaymentReceiptResponse } from 'utils/types';

export const getReportPaymentReceipt = createAsyncThunk(
	'reportSlice/getReportPaymentReceipt',
	async (reqBody: IReportPaymentReceiptRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IReportPaymentReceiptResponse> = await ReportService.getPaymentReceipt(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
