// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArrowBackButton_button__9sVAw {
  width: 32px;
  height: 32px;
}
.ArrowBackButton_button__9sVAw .ArrowBackButton_icon__8B6bu {
  width: 20px;
  height: 20px;
}
.ArrowBackButton_button__9sVAw .ArrowBackButton_icon_white__6oxZG path {
  stroke: var(--color-neutral-white) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/ArrowBackButton/index.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;AACD;AACC;EACC,WAAA;EACA,YAAA;AACF;AAEG;EACC,6CAAA;AAAJ","sourcesContent":[".button {\n\twidth: 32px;\n\theight: 32px;\n\n\t.icon {\n\t\twidth: 20px;\n\t\theight: 20px;\n\n\t\t&_white {\n\t\t\tpath {\n\t\t\t\tstroke: var(--color-neutral-white) !important;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ArrowBackButton_button__9sVAw`,
	"icon": `ArrowBackButton_icon__8B6bu`,
	"icon_white": `ArrowBackButton_icon_white__6oxZG`
};
export default ___CSS_LOADER_EXPORT___;
