import { FC } from 'react';
import { Typography } from '@mui/material';

export const RadabankTransactionFee: FC = () => {
	return (
		<Typography textAlign="justify">
			При здійсненні переказу за рахунок кредитних коштів стягується комісія у розмірі відповідно до умов кредитного
			продукту
		</Typography>
	);
};
