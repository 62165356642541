import { StoreType } from 'store/root';
import { IBillApartments, IBillReceipt, IBillServices, IBillServicesCategories } from 'utils/types';

export const getBillReceiptSelector = (store: StoreType): IBillReceipt => {
	return store.billStore.receipt;
};

export const getApartmentsSelector = (store: StoreType): IBillApartments => {
	return store.billStore.apartments;
};

export const getServicesCategoriesSelector = (store: StoreType): IBillServicesCategories => {
	return store.billStore.otherServices.categories;
};

export const getServicesSelector = (store: StoreType): IBillServices => {
	return store.billStore.otherServices.services;
};

export const getBillsListSelector = (store: StoreType) => store.billStore.bills.list;

export const getBillsReportSelector = (store: StoreType) => store.billStore.bills.report;

export const getProvidersListStateSelector = (store: StoreType) => store.billStore.providers;

export const getPossibleApartmentsListSelector = (store: StoreType) => store.billStore.possibleApartments.list;

export const getIsPossibleApartmentsStepSelector = (store: StoreType) =>
	!!store.billStore.possibleApartments.list.length || store.billStore.possibleApartments.isLoading;

export const getPossibleApartmentsLoadingSelector = (store: StoreType) => store.billStore.possibleApartments.isLoading;
