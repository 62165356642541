// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BarcodeScanner_wrapper__etu-g {
  display: flex;
  gap: 10px;
}

.BarcodeScanner_backdrop__FE1D2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: white;
}`, "",{"version":3,"sources":["webpack://./src/components/BarcodeScanner/index.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,SAAA;AACD;;AAEA;EACC,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;AACD","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tgap: 10px;\n}\n\n.backdrop {\n\tposition: absolute;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100%;\n\theight: 100vh;\n\tbackground: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BarcodeScanner_wrapper__etu-g`,
	"backdrop": `BarcodeScanner_backdrop__FE1D2`
};
export default ___CSS_LOADER_EXPORT___;
