import { Contacts } from '@capacitor-community/contacts';
import { STORAGE_KEY } from 'utils/enums';
import { StorageService } from 'utils/services';
import { IContactItem, IContacts } from 'utils/types';

export const removeCodeFormPhone = (phone: string) => phone.replace(/^\+38|^38|\s+|\(|\)/g, '');

export const getContacts = async (): Promise<IContacts[]> => {
	const permission = await Contacts.checkPermissions();
	if (permission.contacts !== 'granted') {
		const permissionResult = await Contacts.requestPermissions();
		if (permissionResult.contacts === 'denied') {
			return [];
		}
	}
	const contactsResult = await Contacts.getContacts({
		projection: {
			name: true,
			phones: true,
			image: true,
		},
	});
	const contactsList = contactsResult.contacts.reduce((acc, item) => {
		if (!item.phones?.length) return acc;
		const contactPhones = item.phones.reduce(
			(contactAcc, contact) =>
				// plugin can return +380 999 999 999, 80 (96) 99 999 999, 0999999999 or other
				// format for radabank is 0999999999 = 10 characters
				contact.number && removeCodeFormPhone(contact.number).length === 10
					? [...contactAcc, `+38${removeCodeFormPhone(contact.number)}`]
					: contactAcc,
			[] as string[]
		);
		const uniquePhones = [...new Set(contactPhones)];
		return [
			...acc,
			...uniquePhones.map((phone) => ({
				phone,
				name: item.name?.display ?? phone,
				image: item.image?.base64String ?? '',
			})),
		];
	}, [] as IContactItem[]);
	const uniqueFirstChars = [...new Set(contactsList.map((item) => item.name.slice(0, 1).toLocaleUpperCase()))].sort(
		(a, b) => a.localeCompare(b)
	);
	const groupedList = uniqueFirstChars.reduce(
		(acc, firstChar) => [
			...acc,
			{
				firstChar,
				list: contactsList.filter((contact) => contact.name.slice(0, 1).toLocaleUpperCase() === firstChar),
			},
		],
		[] as IContacts[]
	);
	let result = groupedList;
	const userPhone = await StorageService.get<string>(STORAGE_KEY.PHONE);
	if (userPhone) {
		const userPhoneInList = contactsList.find(
			(item) => removeCodeFormPhone(item.phone) === removeCodeFormPhone(userPhone)
		);
		result = [
			{
				firstChar: 'Мій номер',
				list: [{ name: '', image: '', phone: `+38${userPhoneInList?.phone ?? removeCodeFormPhone(userPhone)}` }],
			},
			...groupedList,
		];
	}
	return result;
};
