import { FC } from 'react';
import { Typography } from '@mui/material';
import { getRadabankCurrentCardOverdraftDataSelector, useAppSelector } from 'store';
import { getAmountTitle } from 'utils/helpers';
import { RadabankCardOverdraftInfoModal } from '../InformationModal';
import styles from './index.module.scss';

export const RadabankCardOverdraftInformation: FC = () => {
	const overdraft = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);

	if (!overdraft.data) return;

	return (
		<div className={styles.wrapper}>
			<Typography mb="var(--spacing-2)" variant="h6">Інформація</Typography>
			<div className={styles.info}>
				<div className={styles.row}>
					<Typography>Сума ліміту</Typography>
					<Typography>{getAmountTitle(overdraft.data.overdraft)}</Typography>
				</div>
				<div className={styles.row}>
					<Typography>Використано</Typography>
					<Typography>{getAmountTitle(overdraft.data.amountother)}</Typography>
				</div>
				<div className={styles.row}>
					<Typography>Пільговий період</Typography>
					<Typography>{overdraft.data.overfindate}</Typography>
				</div>
				<div className={styles.row}>
					<RadabankCardOverdraftInfoModal />
					<Typography>{getAmountTitle(overdraft.data.overamountgrace)}</Typography>
				</div>
			</div>
		</div>
	);
};
