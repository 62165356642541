import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorResponse } from 'utils/helpers';
import { MetersService } from 'utils/services';
import { IGetMetersListRequest, IMeterDataResponse, IUpdateMetersRequest } from 'utils/types';

export const getMetersList = createAsyncThunk(
	'metersSlice/getMetersList',
	async (reqBody: IGetMetersListRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IMeterDataResponse[]> = await MetersService.getMetersList(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateMeterValue = createAsyncThunk(
	'metersSlice/updateMeterValue',
	async (reqBody: IUpdateMetersRequest, { rejectWithValue }) => {
		try {
			const response = await MetersService.updateMeterValue(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
