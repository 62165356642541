// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardOverdraftUpdate_icon__hFKuI path {
  stroke: var(--color-error-500);
}`, "",{"version":3,"sources":["webpack://./src/components/RadabankCard/CardOverdraft/CardOverdraftUpdate/index.module.scss"],"names":[],"mappings":"AAAA;EACC,8BAAA;AACD","sourcesContent":[".icon path {\n\tstroke: var(--color-error-500);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `CardOverdraftUpdate_icon__hFKuI`
};
export default ___CSS_LOADER_EXPORT___;
