import { FieldInputProps, useField } from 'formik';

export const useFormikField = <T>(field: FieldInputProps<T>) => {
	const [fieldData, meta, helpers] = useField(field.name);


	return {
		...fieldData,
		...meta,
		...helpers,
	};
};
