/* eslint-disable */
import { FC, Ref, useEffect, useState } from 'react';
// MUI
import { TextField, Autocomplete, AutocompleteProps } from '@mui/material';
// Assets
import { ReactComponent as Clear } from 'assets/icons/clear.svg';
import { IonSpinner } from '@ionic/react';

interface AddressAutocompleteProps<T> extends Omit<AutocompleteProps<T, any, any, any, any>, 'renderInput'> {
	options: T[];
	isClear?: boolean;
	renderOptions?: any;
	label?: string;
	name?: string;
	isError?: boolean;
	helperText?: string;
	inputRef?: Ref<HTMLElement>;
	inputProps?: any;
	placeholder?: string;
}

export const AddressAutocomplete: FC<AddressAutocompleteProps<any>> = ({
	placeholder = '',
	isClear = false,
	label,
	name,
	isError,
	helperText,
	loading = false,
	inputRef = null,
	value,
	disabled,
	inputProps,
	...rest
}) => {
	const [isShowClear, setIsShowClear] = useState(false);

	useEffect(() => {
		setIsShowClear((isClear !== undefined ? isClear : !!value) || loading);
	}, [isClear, value, loading]);

	const additionalInputProps = loading ? { endAdornment: <IonSpinner className="spinner-icon" name="crescent" /> } : {}

	return (
		<Autocomplete
			{...rest}
			value={value}
			renderInput={({ InputProps, ...params }) => (
				<TextField
					InputProps={{
						...InputProps,
						...inputProps,
						...additionalInputProps,
					}}
					name={name}
					helperText={!disabled && helperText}
					error={!disabled && isError}
					placeholder={placeholder}
					label={label}
					inputRef={inputRef}
					{...params}
				/>
			)}
			disabled={disabled}
			limitTags={3}
			disableClearable={!isShowClear}
			clearIcon={<Clear />}
			freeSolo
		/>
	);
};
