import { AxiosResponse } from 'axios';
import { PAYMENT_API } from 'core/api';
import { CHECK_RESPONSE, PAYMENT_API_URL, STORAGE_KEY } from 'utils/enums';
import { StorageService } from 'utils/services';
import { IAppleSession, IAppleSessionResponse, ISession } from 'utils/types';

export const getApplePaySession = async (): Promise<IAppleSession> => {
	try {
		const sessionResponse: AxiosResponse<{ data: ISession }> = await PAYMENT_API.post(PAYMENT_API_URL.GET_SESSION, {
			returnSession: CHECK_RESPONSE.YES,
		});
		await StorageService.set(STORAGE_KEY.SESSION_ID_PAYMENT, sessionResponse.data.data.sessionId);
		await PAYMENT_API.post(PAYMENT_API_URL.CHECK_SESSION);
		const response: AxiosResponse<IAppleSessionResponse> = await PAYMENT_API.post(PAYMENT_API_URL.GET_PAYMENT_SESSION);
		return response.data.data.session;
	} catch (error) {
		throw new Error(error);
	}
};
