import { FC, KeyboardEvent, useMemo } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { TextField, TextFieldProps } from '@mui/material';
import { getRadabankCurrentCardOverdraftDataSelector, useAppSelector } from 'store';
import { RadabankInfoList } from 'components/RadabankCard/InfoList';
import { getLocaleAmountString, handleKeyDownServiceAmount } from 'utils/helpers';

export interface CardOverdraftAmountInputProps extends Omit<TextFieldProps, 'onChange'> {
	onChange: (amount: number) => void;
	value: number;
	min: number;
	max: number;
}

export const CardOverdraftAmountInput: FC<CardOverdraftAmountInputProps> = ({ onChange, value, min, max, ...rest }) => {
	const overdraftData = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);

	const handleChangeAmountValue = ({ value }: NumberFormatValues): void => {
		onChange(+value);
	};

	const infoList = useMemo(
		() => [
			`Мінімальна сума кредиту ${getLocaleAmountString(overdraftData.data?.overamountmin ?? 0, ' ', 0)}`,
			`Максимальна сума кредиту ${getLocaleAmountString(overdraftData.data?.overamountmax ?? 0, ' ', 0)}`,
			'Сума повинна бути кратна 1000',
		],
		[overdraftData.data?.overamountmin, overdraftData.data?.overamountmax]
	);

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		handleKeyDownServiceAmount(e, value);
	};


	return (
		<>
			<NumericFormat
				{...rest}
				defaultValue=""
				type="text"
				customInput={TextField}
				label="Зміна суми"
				value={value}
				onValueChange={handleChangeAmountValue}
				isAllowed={({ value }: NumberFormatValues) => +value <= max}
				onChange={undefined}
				allowNegative={false}
				decimalScale={0}
				valueIsNumericString
				thousandSeparator=" "
				onKeyDown={handleKeyDown}
				inputProps={{
					inputMode: 'decimal',
					autoComplete: 'off',
				}}
			/>
			<RadabankInfoList infoList={infoList} />
		</>
	);
};
