import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { IonSpinner } from '@ionic/react';
import { AxiosResponse } from 'axios';
import { getServicesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAppLoading } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { ProviderServiceFieldsList, ProviderServiceHeader } from 'components/OtherServices';
import { CustomButton } from 'components/shared';
import { DEFAULT_ADD_SERVICE_VALUE, PORTMONE_AMOUNT_FIELD_NAME } from 'utils/constants';
import { BILL_TYPE, ERROR_CODE, ERROR_MESSAGE, LOCALE, ROUTER_URL } from 'utils/enums';
import {
	checkIsAmountServiceField,
	decryptData,
	findServiceProviderAccountIdValue,
	getContractNumberFieldName,
	getCreateBillRequestServiceFieldsData,
	getCreateBillServiceRequestError,
	getErrorMessage,
	getErrorResponse,
	getServicesFieldsList,
	sortServicesFieldsByFieldsNames,
} from 'utils/helpers';
import { BillService } from 'utils/services';
import {
	IAddOtherServiceToSingleReceipt,
	IAddServiceFieldValueRequest,
	IBillRequestData,
	ICreateBillRequest,
	IGetServiceDataResponse,
	IServiceBillRequestData,
	IServiceFieldResponse,
	IServicesItemResponse,
	IUpdateServiceFieldValueRequest,
	IUserProfileResponse,
} from 'utils/types';
import styles from './index.module.scss';

interface ProviderServiceProps {
	serviceId: string;
	onClickBack: () => void;
	onSubmit: (data: IAddOtherServiceToSingleReceipt) => void;
}

export const ProviderService: FC<ProviderServiceProps> = ({ serviceId, onClickBack, onSubmit }) => {
	const params = useParams<{ apartmentAccountId: string }>();
	const history = useHistory();

	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);
	const services = useAppSelector(getServicesSelector);

	const [currentService, setCurrentService] = useState<IServicesItemResponse | null>(
		services.data.tableData.find((item) => item.id === serviceId) ?? null
	);
	const [currentServiceData, setCurrentServiceData] = useState<IGetServiceDataResponse | null>(null);
	const [isValid, setIsValid] = useState(false);
	const [serviceFieldsValues, setServiceFieldsValues] = useState<IAddServiceFieldValueRequest[]>([]);
	const [apartmentAccountId] = useState(+decryptData(params.apartmentAccountId));
	const [serviceFieldsList, setServiceFieldsList] = useState<IServiceFieldResponse[]>([]);
	const [visibleServiceFieldsList, setVisibleServiceFieldsList] = useState<IServiceFieldResponse[]>([]);
	const [billRequestData, setBillRequestData] = useState<IServiceBillRequestData | IServiceBillRequestData[] | null>(
		null
	);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!currentService) {
			const currentService = services.data.tableData.find((item: IServicesItemResponse) => item.id === serviceId);
			if (currentService) {
				setCurrentService(currentService);
			}
		}
	}, [services.data.tableData.length]);

	useEffect(() => {
		if (currentService && profile) {
			getCurrentServiceData(currentService.id, currentService.servicesContractsMap[0].contractId);
		}
	}, [profile, currentService]);

	useEffect(() => {
		if (currentServiceData?.serviceId) {
			getCurrentServiceFields(currentServiceData.serviceId);
		}
	}, [currentServiceData?.serviceId]);

	const getServiceFieldsValuesList = (serviceFieldsList: IServiceFieldResponse[]): IAddServiceFieldValueRequest[] =>
		serviceFieldsList.map((item) => {
			const existsFieldValue = serviceFieldsValues.find((serviceFieldValue) => serviceFieldValue.name === item.name);
			return (
				existsFieldValue || {
					...DEFAULT_ADD_SERVICE_VALUE,
					apartmentAccountId,
					serviceId: (currentServiceData as IGetServiceDataResponse).serviceId,
					name: item.name,
					userId: (profile as IUserProfileResponse).userId,
				}
			);
		});

	const getCurrentServiceData = async (serviceId: string, contractId: string): Promise<void> => {
		try {
			setIsLoading(true);
			const response: AxiosResponse<IGetServiceDataResponse> = await BillService.getServiceData({
				serviceId,
				contractId,
			});
			setCurrentServiceData(response.data);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			history.replace(ROUTER_URL.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const getCurrentServiceFields = async (serviceId: string): Promise<void> => {
		try {
			setIsLoading(true);
			const fieldsResponse: AxiosResponse<IServiceFieldResponse[]> = await BillService.getServiceFields({
				serviceId,
				locale: LOCALE.UK,
			});
			const serviceFieldsList = getServicesFieldsList(
				fieldsResponse.data,
				(currentServiceData as IGetServiceDataResponse).dataObject
			);
			setServiceFieldsList(sortServicesFieldsByFieldsNames(fieldsResponse.data));
			setVisibleServiceFieldsList(serviceFieldsList);
			setServiceFieldsValues(getServiceFieldsValuesList(serviceFieldsList));
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			if (errorResponse?.errorData?.code === ERROR_CODE.SERVICE_FIELDS_NOT_FOUND) {
				dispatch(showToast({ message: ERROR_MESSAGE.SERVICE_FIELDS_NOT_FOUND }));
				history.replace(ROUTER_URL.NOT_FOUND);
			} else {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const createBillRequest = async (): Promise<void> => {
		if (currentServiceData) {
			const contractNumber = findServiceProviderAccountIdValue(serviceFieldsValues, currentServiceData);
			if (contractNumber) {
				const reqBody: ICreateBillRequest = {
					payeeId: currentServiceData.dataObject.payeeId,
					serviceFieldsData: getCreateBillRequestServiceFieldsData(currentServiceData.dataObject, serviceFieldsValues),
					billType: currentServiceData.billType,
				};
				const response = await BillService.createBillRequest(reqBody);
				if (currentServiceData && currentServiceData.billType === BILL_TYPE.FIRST) {
					setVisibleServiceFieldsList(serviceFieldsList);
					const serviceFieldsValues = getServiceFieldsValuesList(serviceFieldsList);
					const fieldsValuesList = serviceFieldsValues.find(checkIsAmountServiceField)
						? serviceFieldsValues
						: [
								...serviceFieldsValues,
								{
									...DEFAULT_ADD_SERVICE_VALUE,
									serviceId: currentServiceData.serviceId,
									name: PORTMONE_AMOUNT_FIELD_NAME,
									userId: (profile as IUserProfileResponse).userId,
								},
						  ];
					const servicesFieldsValuesPayload = fieldsValuesList.map((item) =>
						checkIsAmountServiceField(item) ? { ...item, value: '' } : item
					);
					setServiceFieldsValues(servicesFieldsValuesPayload);
					setServiceFieldsValues(getServiceFieldsValuesList(serviceFieldsList));
					setBillRequestData(response.data);
				}
			}
		}
	};

	const handleSubmit = async (): Promise<void> => {
		if(!isValid) return;
		try {
			dispatch(setAppLoading(true));
			if (!billRequestData) {
				await createBillRequest();
				if (currentServiceData?.billType !== BILL_TYPE.FIRST) {
					onSubmit({
						fieldsRequestData: serviceFieldsValues,
						serviceRequestData: {
							apartmentAccountId,
							serviceId: (currentService as IServicesItemResponse).id,
							userId: (profile as IUserProfileResponse).userId,
						},
					});
				}
				// TODO: rework
			} else {
				onSubmit({
					fieldsRequestData: serviceFieldsValues,
					serviceRequestData: {
						apartmentAccountId,
						serviceId: (currentService as IServicesItemResponse).id,
						userId: (profile as IUserProfileResponse).userId,
					},
				});
			}
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			if (
				errorResponse?.errorData?.code === ERROR_CODE.SERVICE_PAID_ALREADY &&
				(currentServiceData as IGetServiceDataResponse).billType !== BILL_TYPE.FIRST
			) {
				onSubmit({
					fieldsRequestData: serviceFieldsValues,
					serviceRequestData: {
						apartmentAccountId,
						serviceId: (currentService as IServicesItemResponse).id,
						userId: (profile as IUserProfileResponse).userId,
					},
				});
			} else {
				dispatch(showToast({ message: getCreateBillServiceRequestError(error) }));
			}
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleClickBack = (): void => {
		if (currentServiceData && currentServiceData.billType === BILL_TYPE.FIRST && billRequestData) {
			const visibleServiceFieldsListPayload = getServicesFieldsList(serviceFieldsList, currentServiceData.dataObject);
			setVisibleServiceFieldsList(visibleServiceFieldsListPayload);
			setServiceFieldsValues(getServiceFieldsValuesList(visibleServiceFieldsListPayload));
			setBillRequestData(null);
		} else {
			onClickBack();
		}
	};

	return (
		currentService && (
			<div className={styles.wrapper}>
				<ProviderServiceHeader
					className="receipt-service-modal-header"
					onClickBack={handleClickBack}
					title={currentService.title}
					icon={currentService.imageUrl ?? ''}
				/>

				{!!currentServiceData && !!visibleServiceFieldsList.length && !isLoading && (
					<>
						<ProviderServiceFieldsList
							onSubmit={handleSubmit}
							fieldsList={visibleServiceFieldsList}
							values={serviceFieldsValues}
							serviceId={currentServiceData.serviceId}
							setIsValid={(value: boolean) => setIsValid(value)}
							onChange={(values: IAddServiceFieldValueRequest[] | IUpdateServiceFieldValueRequest[]) =>
								setServiceFieldsValues(values as IAddServiceFieldValueRequest[])
							}
							providerAccountIdFieldName={getContractNumberFieldName(currentServiceData)}
							isFieldsDisabled={
								(currentServiceData.billType !== BILL_TYPE.THIRD &&
									visibleServiceFieldsList.length === serviceFieldsList.length) ||
								(!Array.isArray(billRequestData) &&
									(billRequestData as IBillRequestData)?.tariff_items &&
									!Object.keys((billRequestData as IBillRequestData)?.tariff_items).length)
							}
						/>
						<CustomButton
							disabled={!isValid}
							label={!billRequestData && currentServiceData.billType === BILL_TYPE.FIRST ? 'Продовжити' : 'Додати'}
							onClick={handleSubmit}
						/>
					</>
				)}

				{isLoading && <IonSpinner className="loader-dots" name="dots" />}
			</div>
		)
	);
};
