import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthLayout } from 'layouts/AuthLayout';
import {
	AuthProfileUpdatePage,
	CheckUserPhonePage,
	LoginPage,
	PasswordSetupPage,
	RestorePasswordPage,
	VerifyDevicePage,
	VerifyRestorePassword,
	VerifyUserSignupPage,
} from 'pages/Auth';
import { ErrorPage } from 'pages/Error';
import { NotFoundPage } from 'pages/NotFound';
import { FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';

export const AuthRouter: FC = () => {
	return (
		<AuthLayout>
			<Switch>
				<Route exact path={ROUTER_URL.AUTH_CHECK_PHONE} component={CheckUserPhonePage} />
				<Route exact path={ROUTER_URL.AUTH_PASSWORD_SETUP}>
					<PasswordSetupPage pageName={FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.SET_PASSWORD} />
				</Route>
				<Route exact path={ROUTER_URL.AUTH_VERIFY_USER_SIGNUP} component={VerifyUserSignupPage} />
				<Route exact path={ROUTER_URL.AUTH_UPDATE_PROFILE} component={AuthProfileUpdatePage} />
				<Route exact path={ROUTER_URL.AUTH_LOGIN} component={LoginPage} />
				<Route exact path={ROUTER_URL.AUTH_LOGIN_VERIFY_DEVICE} component={VerifyDevicePage} />
				<Route exact path={ROUTER_URL.AUTH_RESTORE_VERIFY} component={VerifyRestorePassword} />
				<Route exact path={ROUTER_URL.AUTH_PASSWORD_RESTORE} component={RestorePasswordPage} />
				<Route exact path={ROUTER_URL.ERROR} component={ErrorPage} />
				<Route path="*" component={NotFoundPage} />
			</Switch>
		</AuthLayout>
	);
};
