export enum USER_API_URL {
	SESSION_GET = 'session/get',
	SESSION_CHECK = 'session/check',

	USER_CHECK = 'user/check',
	USER_VERIFY = 'user/verify',
	USER_VERIFY_RESEND = 'user/verify/resend',
	USER_VERIFY_RESEND_CHECK = 'user/verify/check',
	USER_CREATE = 'user/create',
	USER_CONFIRM = 'user/confirm',
	USER_LOGIN = 'user/login',
	USER_LOGOUT = 'user/logout',
	USER_PROFILE = 'user/profile',
	USER_RESTORE_PASSWORD = 'user/recovery/reset',
	USER_VERIFY_RESTORE = 'user/recovery',
	USER_CONFIRM_RESTORE = 'user/recovery/check',
	USER_DEVICE_CONFIRM = 'user/device/confirm',
	USER_PROFILE_UPDATE = 'user/profile/update',
	USER_PROFILE_EMAIL_UPDATE = 'user/email/verify',
	USER_GENERATE_IMAGE_URL = 'user/generate-imageUrl',
	USER_DELETE_AVATAR = 'user/delete-image',
	USER_GET_IMAGE_URL = 'user/get-image-url',
	USER_PROFILE_CONFIRM_UPDATE_EMAIL = 'user/email/confirm',
	USER_PROFILE_EMAIL_CHECK = 'user/email/check',
	USER_PROFILE_CONFIRM_UPDATE_EMAIL_RESEND = 'user/verify/resend',
	USER_CHECK_DEVICE_IS_CONFIRMED = 'user/device/is-confirm',
	USER_PUSH_NOTIFICATIONS_SUBSCRIBE = 'user/notifications-subscribe',
	USER_PUSH_NOTIFICATIONS_UNSUBSCRIBE = 'user/remove-subscription',

	PAYMENTS_ARCHIVE = 'report/paymentreceipt/list',
	SINGLE_ARCHIVE_PAYMENT = 'report/paymentreceipt/one',

	CITY_SEARCH = 'paa/search/cities',
	STREET_SEARCH = 'paa/search/streets',
	HOUSE_SEARCH = 'paa/search/houses',
	FLAT_SEARCH = 'paa/search/flat',
	GET_APARTMENT_IDS_LIST = 'paa/search/apartment-account-ids',
	GET_APARTMENT_LIST_BY_IDS = 'paa/search/apartment-account/get-apartment-location-list',

	BILL_CREATE_APARTMENT = 'bill/create/apartment-account-user-map',
	BILL_GET_APARTMENT_LIST = 'bill/get/apartment-account-user-map-v2',
	BILL_DELETE_APARTMENT_LIST = 'bill/delete/apartment-account-user-map',
	BILL_GET_SINGLE_RECEIPT = 'bill/get/single-receipt-v2',
	BILL_CREATE_PAYMENT = 'bill/pay',
	BILL_PAY_SINGLE_RECEIPT = 'bill/pay/single-receipt',
	BILL_CHECK_PAYMENT_STATUS = 'bill/status',
	BILL_GET_SERVICES_CATERGORIES = 'bill/group/list',
	BILL_GET_SERVICES = 'bill/services/list',
	BILL_GET_SERVICE_FIELDS = 'bill/get/service-field',
	BILL_GET_SERVICE_FIELDS_VALUES = 'bill/service-fields-users-values-map/list',
	BILL_ADD_SERVICE_FIELD_VALUE = 'bill/service-fields-users-values-map/add',
	BILL_UPDATE_SERVICE_FIELD_VALUE = 'bill/service-fields-users-values-map/update',
	BILL_DELETE_SERVICE_FIELD_VALUE = 'bill/service-fields-users-values-map/delete',
	BILL_GET_SERVICE_DATA = 'bill/get/service-data',
	BILL_GET_SERVICE_FEE = 'bill/calculate-fee',
	BILL_OTHER_SERVICE_PAY = 'bill/pay/other-payment',
	BILL_ADD_SERVICE_TO_RECEIPT = 'bill/create/service-apartment-map',
	BILL_DELETE_SERVICE_FROM_RECEIPT = 'bill/delete/service-apartment-map',
	BILL_UPDATE_UNATHORIZED_PAYMENT_EMAIL = '/bill/update/unauthorized-payment/email',
	BILL_GET_BILLS_LIST = '/bill/bills/list',
	BILL_GET_BILL = '/bill/bills/info',
	BILL_CREATE_BILL_REQUEST = '/bill/bill-create-request',
	BILL_GET_PROVIDERS_LIST = '/bill/providers/list-with-id',

	REPORT_DOWNLOAD_RECEIPT_PDF = 'report/singlereceipt/pdf',
	REPORT_GET_SINGLE_RECEIPT = 'report/singlereceipt/get',
	REPORT_GET_UNATHORIZED_SINGLE_RECEIPT = '/report/get-single-receipt-data-for-invoice',
	REPORT_PAY_UNATHORIZED_SINGLE_RECEIPT = '/report/get-invoice-single-receipt',
	REPORT_GET_PAYMENT_RECEIPT = '/report/paymentreceipt/one',

	CARD_REQUEST = 'card/request',
	CARD_DELETE = 'card/delete',
	CARD_LIST = 'card/list',

	METER_GET_LIST = 'meter/get-meters',
	METER_UPDATE_VALUE = 'meter/update-meter',

	RADABANK_AUTH_INTERNAL_CLIENT_PHONE = 'bankissues/auth/internal-client-phone',
	RADABANK_AUTH_INFO_BEGIN = 'bankissues/auth/info-begin',
	RADABANK_AUTH_INFO_VERIFY_TOKEN = 'bankissues/auth/internal-token-verify',
	RADABANK_AUTH_INFO_STATUS = 'bankissues/auth/info-status',
	RADABANK_AUTH_INFO_APPROVE_CODE = 'bankissues/auth/info-approve-code',
	RADABANK_AUTH_INFO_SEND_CODE = 'bankissues/auth/info-send-code',
	RADABANK_AUTH_INFO_DIYA_BEGIN = 'bankissues/auth/info-diya-begin',
	RADABANK_AUTH_INFO_IMAGE_SET = 'bankissues/auth/info-image-set',
	RADABANK_AUTH_INFO_IMAGE_COMMIT = 'bankissues/auth/info-image-commit',
	RADABANK_AUTH_INFO_SPR_DATA = 'bankissues/auth/info-spr-data',
	RADABANK_AUTH_INFO_GET_ADDRESS_REGION = 'bankissues/auth/info-get-address-region',
	RADABANK_AUTH_INFO_GET_ADDRESS_DISTRICT = 'bankissues/auth/info-get-address-district',
	RADABANK_AUTH_INFO_GET_ADDRESS_CITY_TYPES = 'bankissues/auth/info-get-address-city-type',
	RADABANK_AUTH_INFO_GET_ADDRESS_CITIES = 'bankissues/auth/info-get-address-city',
	RADABANK_AUTH_INFO_GET_ADDRESS_STREET_TYPES = 'bankissues/auth/info-get-address-street-type',
	RADABANK_AUTH_INFO_SET_ANKETA = 'bankissues/auth/info-set-anketa',
	RADABANK_AUTH_INFO_SET_PASSWORD = 'bankissues/auth/info-set-password',
	RADABANK_AUTH_INFO_CREATE_START_DOCUMENT = 'bankissues/auth/info-create-start-document',
	RADABANK_AUTH_INFO_GET_IMAGE = 'bankissues/auth/info-get-image',
	RADABANK_AUTH_INFO_COMMIT_START_DOCUMENT = 'bankissues/auth/info-commit-start-document',
	RADABANK_AUTH_INFO_COMMIT_SIGN_DOCUMENT = 'bankissues/auth/info-commit-sign-document',
	RADABANK_AUTH_START_ACTIVATE_INTERNAL = 'bankissues/auth/internal-start-activate',
	RADABANK_AUTH_INTERNAL_VERIFY_ACTIVATE_CODE = 'bankissues/auth/internal-verify-activate-code',
	RADABANK_AUTH_INTERNAL_ACTIVATE = 'bankissues/auth/internal-activate',
	RADABANK_AUTH_INTERNAL_AUTHORIZE = 'bankissues/auth/internal-authorize',
	RADABANK_AUTH_INTERNAL_SMS_VERIFY = 'bankissues/auth/internal-sms-verify',
	RADABANK_AUTH_INTERNAL_CREATE_CO_BRAND_CARD = 'bankissues/auth/internal-co-brand-create-card',
	RADABANK_AUTH_INTERNAL_VERIFY_CO_BRAND_CARD = 'bankissues/auth/internal-co-brand-verify-card',
	RADABANK_INTERNAL_LIST_CURRENT_CARD = 'bankissues/auth/internal-list-current-card',
	RADABANK_INTERNAL_LIST_CLOSED_CARD = 'bankissues/auth/internal-list-closed-card',
	RADABANK_INTERNAL_GET_CVV_CARD = 'bankissues/auth/internal-get-cvv-card',
	RADABANK_INTERNAL_GET_OPERATIONS_LIST = 'bankissues/auth/show-statement-doc',
	RADABANK_INTERNAL_CARD_CREATE_PAYMENT = 'bankissues/auth/card-create-payment',
	RADABANK_INTERNAL_CARD_FEE = 'bankissues/auth/card-fee',
	RADABANK_INTERNAL_TRANSFER_CREATE_PAYMENT = 'bankissues/auth/transfer-create-payment',
	RADABANK_INTERNAL_TRANSFER_FEE = 'bankissues/auth/transfer-fee',
	RADABANK_INTERNAL_GET_STATUS_PAYMENT_BY_ID = 'bankissues/auth/get-status-payment-by-id',
	RADABANK_INTERNAL_GET_COUNTRY_RULE = 'bankissues/auth/internal-get-country-rule',
	RADABANK_INTERNAL_GET_CURRENCY_RULE = 'bankissues/auth/internal-get-currency-rule',
	RADABANK_INTERNAL_GET_BUDGET_CODE = 'bankissues/auth/get-budget-code',
	RADABANK_INTERNAL_GET_CARD_LOCK_REASONS = 'bankissues/auth/internal-card-lock-reasons',
	RADABANK_INTERNAL_BLOCK_CARD = 'bankissues/auth/internal-bloack-card',
	RADABANK_INTERNAL_DEBLOCK_CARD = 'bankissues/auth/internal-deblock-card',
	RADABANK_INTERNAL_REMIND_PIN_CARD = 'bankissues/auth/internal-remind-pin-card',
	RADABANK_INTERNAL_CHANGE_PIN_CARD = 'bankissues/auth/internal-change-pin-card',
	RADABANK_INTERNAL_GET_CARD_CURRENT_REST_DATA = 'bankissues/auth/internal-card-current-rest-data',
	RADABANK_INTERNAL_CLIENT_REQUEST_OVER = 'bankissues/auth/internal-client-request-over',
	RADABANK_INTERNAL_CLIENT_PRE_REQUEST_OVER_MB = 'bankissues/auth/internal-client-pre-request-over-mb',
	RADABANK_INTERNAL_GET_SPR_DATA = 'bankissues/auth/internal-spr-data',
	RADABANK_INTERNAL_GET_DATA_CARD_LIMIT = 'bankissues/auth/internal-data-card-limit',
	RADABANK_INTERNAL_GET_SELECTED_DOCUMENT = 'bankissues/auth/internal-get-selected-doc',
	RADABANK_INTERNAL_GET_CURRENT_RULES = 'bankissues/auth/internal-current-rules',
	RADABANK_INTERNAL_ADD_RULE = 'bankissues/auth/internal-add-rule',
	RADABANK_INTERNAL_DELETE_RULE = 'bankissues/auth/internal-delete-rule',
	RADABANK_INTERNAL_PRINT_DOCUMENT_DATA = 'bankissues/auth/internal-print-document-data',
	RADABANK_INTERNAL_SMS_CARD = 'bankissues/auth/internal-sms-card',
	RADABANK_INTERNAL_SET_CARD_SMS = 'bankissues/auth/internal-set-card-sms',
	RADABANK_INTERNAL_CREATE_P2P_DATA = 'bankissues/auth/create-2p2-data',
	RADABANK_INTERNAL_GET_AMOUNT_PTP_TRANSFER = 'bankissues/auth/get-amount-p2p-transfer',

	RADABANK_INTERNAL_GET_FEE_OTHER = 'bankissues/auth/internal-get-fee-other',
	RADABANK_INTERNAL_CHECK_CARD_BIN = 'bankissues/auth/internal-check-card-bin',

	RADABANK_PRINT_STATEMENT_DOC_DATA = 'bankissues/auth/print-statement-doc-data',
	RADABANK_PRINT_EMAIL_STATEMENT_DOC_DATA = 'bankissues/auth/print-email-statement-doc-data',

	RADABANK_INTERNAL_CHANGE_CLIENT_PASSWORD = 'bankissues/auth/internal-change-client-pass',
	RADABANK_INTERNAL_APPROVE_CLIENT_PASSWORD = 'bankissues/auth/internal-approve-client-pass',

	// NPoshta
	RADABANK_NPOSHTA_GET_INTERNAL_CITY = 'bankissues/np/internal-city',
	RADABANK_NPOSHTA_GET_INTERNAL_STREET = 'bankissues/np/internal-street',
	RADABANK_NPOSHTA_GET_INTERNAL_DIVISION = 'bankissues/np/internal-division',
	RADABANK_NPOSHTA_INTERNAL_CREATE_REQUEST = 'bankissues/np/internal-create-request',
	RADABANK_NPOSHTA_GET_INTERNAL_CARD_STATUS = 'bankissues/np/internal-card-status',
	RADABANK_NPOSHTA_INTERNAL_ACTIVATE_CARD = 'bankissues/np/internal-activate-card',
}

export enum PAYMENT_API_URL {
	GET_SESSION = 'session/get',
	CHECK_SESSION = 'session/check',
	GET_PAYMENT_SESSION = 'account/payment/session',
}
