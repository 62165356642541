import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { StyledEngineProvider } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ukUA } from '@mui/x-date-pickers/locales';
import theme from 'assets/theme';
import { AppUrlListener } from 'core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import store from 'store/root';
import { customHistory } from 'utils/constants';
import 'dayjs/locale/uk';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import './index.css';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.locale('uk');

const ukrainianLocale = ukUA.components.MuiLocalizationProvider.defaultProps.localeText;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Provider store={store}>
		<StyledEngineProvider injectFirst>
			<CssVarsProvider theme={theme}>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="uk" localeText={ukrainianLocale}>
					<IonApp>
						<IonReactRouter history={customHistory}>
							<AppUrlListener />
							<ErrorBoundary>
								<App />
							</ErrorBoundary>
						</IonReactRouter>
					</IonApp>
				</LocalizationProvider>
			</CssVarsProvider>
		</StyledEngineProvider>
	</Provider>
);
