import { FC } from 'react';
import { TextFieldProps } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT } from 'utils/enums';

interface CustomDatePickerProps
	extends Omit<DesktopDatePickerProps<Dayjs>, 'value' | 'onChange'>,
		Pick<TextFieldProps, 'error' | 'helperText' | 'onBlur'> {
	value: string;
	onChange: (value: string) => void;
	formatValue?: DATE_FORMAT | string;
}

export const CustomDatePicker: FC<CustomDatePickerProps> = ({
	onChange,
	value,
	formatValue,
	format,
	error,
	helperText,
	onBlur,
	...props
}) => {
	const handleChangeDate = (newValue: Dayjs | null) => {
		if (newValue?.isValid()) {
			onChange(formatValue ? newValue.format(formatValue) : `${newValue}`);
		}
	};

	return (
		<DesktopDatePicker
			{...props}
			value={dayjs(value).isValid() ? dayjs(value, formatValue) : null}
			onChange={(newValue) => handleChangeDate(newValue)}
			slots={{
				openPickerIcon: CalendarIcon,
			}}
			slotProps={{
				textField: {
					InputLabelProps: {
						shrink: true,
						focused: true,
					},
					placeholder: 'ДД.ММ.РР',
					error,
					helperText,
					onBlur,
				},
				day: {
					sx: {
						'&.MuiPickersDay-root': {
							borderColor: 'var(--color-primary-500)',

							'&.Mui-selected': {
								backgroundColor: 'var(--color-primary-500)',
							},
						},
					},
				},
				yearButton: {
					sx: {
						'&.MuiPickersYear-yearButton.Mui-selected': {
							backgroundColor: 'var(--color-primary-500)',
						},
					},
				},
				calendarHeader: {
					sx: {
						'& .MuiPickersCalendarHeader-label::first-letter': {
							textTransform: 'capitalize',
						},
					},
				},
			}}
		/>
	);
};
