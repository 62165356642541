import { FC, HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/host-logo.svg';
import classNames from 'classnames';
import { CustomButton } from 'components/shared';
import { ROUTER_URL } from 'utils/enums';
import styles from './index.module.scss';

interface HeaderPublicProps extends HTMLProps<HTMLDivElement> {
	isLoginDisabled: boolean;
}

export const HeaderPublic: FC<HeaderPublicProps> = ({ isLoginDisabled }) => {
	return (
		<header className={classNames(styles.header)}>
			<Logo className={styles.header__logo} />
			{!isLoginDisabled && (
				<Link to={ROUTER_URL.AUTH_LOGIN}>
					<CustomButton fill="outline" label="Увійти" />
				</Link>
			)}
		</header>
	);
};
