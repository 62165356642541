import { ROUTES_PERMISSIONS } from "../constants";
import { ROUTER_URL } from "../enums";
import { CanIFn } from "../hooks";

export const getIndexRoute = (canI: CanIFn): ROUTER_URL => {

    const route = Object.keys(ROUTES_PERMISSIONS).find((key: string): boolean => {
        const permission = ROUTES_PERMISSIONS[key as ROUTER_URL];
        if (!permission) {
            return true;
        }
        return canI(permission);
    });

    if (route) {
        return route as ROUTER_URL;
    }

    return ROUTER_URL.ERROR;
}
