import { ChangeEvent, FC, useEffect } from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';
import { CustomButton } from 'components/shared';
import DotStepper from '../DotStepper';
import { helperTextExceptions, pinCodeMobileScreenText, PinScreenMobileVariantsType } from '../helpers';
import { NumKeyboard } from '../NumKeyboard';
import styles from './index.module.scss';

interface SetPasswordNativeProps {
	value: string;
	maxLength: number;
	type?: keyof typeof pinCodeMobileScreenText;
	onSubmit: () => void;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onClickForgotPassword?: () => void;
	isError?: boolean;
	contentClasses?: string;
	isVisibleDescription?: boolean;
}

const makeEvent = (value: string) =>
	({
		target: {
			value,
			name: 'password',
		},
	} as ChangeEvent<HTMLInputElement>);

export const SetPasswordNative: FC<SetPasswordNativeProps> = ({
	value,
	maxLength,
	onChange,
	onSubmit,
	onClickForgotPassword,
	type = PinScreenMobileVariantsType.Set,
	isError,
	contentClasses = '',
	isVisibleDescription = true,
}) => {
	const { header, text } = pinCodeMobileScreenText[type];

	useEffect(() => {
		if (value.length === maxLength) {
			onSubmit();
		}
	}, [value]);

	const onNumClick = (num: number) => {
		if (value.length === maxLength && num >= 0) {
			onChange(makeEvent(value + num));

			return;
		}

		if (num < 0) {
			onChange(makeEvent(value.substring(0, value.length - 1)));

			return;
		}

		onChange(makeEvent(value + num));
	};

	return (
		<div
			className={cx(styles.pin_screen, {
				[styles.pin_screen_wrong]: isError,
			})}
		>
			<div className={cx(contentClasses, styles.pin_screen__content)}>
				<div className={styles.pin_screen__description}>
					<Typography
						variant="h5"
						color="--color-neutral-white"
						textAlign="center"
						marginBottom="var(--spacing-2)"
						whiteSpace="nowrap"
					>
						{header}
					</Typography>

					{!helperTextExceptions.includes(type) && isVisibleDescription && (
						<Typography
							variant="body2"
							color="--color-neutral-white"
							textAlign="center"
							marginBottom="var(--spacing-4)"
						>
							{text}
						</Typography>
					)}

					<DotStepper steps={6} currentStep={value.length} />
				</div>

				<div className={styles.pin_screen__keyboard_wrap}>
					{helperTextExceptions.includes(type) && (
						<CustomButton fill="clear" onClick={onClickForgotPassword} label={text} />
					)}

					<NumKeyboard onButtonClick={(num: number) => onNumClick(num)} />
				</div>
			</div>
		</div>
	);
};
