import { FC } from 'react';
import { Typography } from '@mui/material';
import { FormTextField } from 'components/shared/FormTextField';
import { RadabankFormSwitch } from '../FormSwitch';

export const SurveyContactPerson: FC = () => {
	return (
		<>
			<Typography>ПІБ контактної особи</Typography>
			<FormTextField name="contact_lastname" label="Прізвище" />
			<FormTextField name="contact_firstname" label="Ім’я" />
			<FormTextField name="contact_middlename" label="По-батькові" />
			<RadabankFormSwitch
				name="contact_live"
				label="Контактна особа проживає разом"
			/>
		</>
	);
};
