import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import cx from 'classnames';
import DotStepper from '../DotStepper';
import { pinCodeMobileScreenText, PinScreenMobileVariantsType } from '../helpers';
import { NumKeyboard } from '../NumKeyboard';
import styles from './index.module.scss';

interface ConfirmPasswordNativeProps {
	value: string;
	password: string;
	maxLength: number;
	contentClasses?: string;
	variant?: PinScreenMobileVariantsType;
	isVisibleDescription?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onSubmit: (event: ChangeEvent<HTMLInputElement>) => void;
}

const makeEvent = (value: string) =>
	({
		target: {
			value,
			name: 'confirmPassword',
		},
	} as ChangeEvent<HTMLInputElement>);

export const ConfirmPasswordNative: FC<ConfirmPasswordNativeProps> = ({
	value,
	maxLength,
	password,
	onChange,
	onSubmit,
	contentClasses = '',
	variant = PinScreenMobileVariantsType.Confirm,
	isVisibleDescription = true
}) => {
	const [isConfirmError, setIsConfirmError] = useState(false);
	const [isShakingError, setIsShakingError] = useState(false);

	const giveMobilePINVariant = () => {
		if (value.length !== 6 && !isConfirmError) return variant;

		if (isConfirmError) return PinScreenMobileVariantsType.WrongConfirm;

		// if (value.length !== 6) return PinScreenMobileVariantsType.Set;

		return variant;
	};

	const { header, text } = pinCodeMobileScreenText[giveMobilePINVariant()];

	useEffect(() => {
		if (value.length === maxLength) {
			onSubmit(makeEvent(value));
		}
	}, [value]);

	const onNumClick = (num: number) => {
		if (value.length === maxLength - 1 && num >= 0) {
			if (password !== value + num) {
				setIsConfirmError(true);
				setIsShakingError(true);
				onChange(makeEvent(''));

				return;
			}

			onChange(makeEvent(value + num));
			setIsConfirmError(false);

			return;
		}

		if (num < 0) {
			onChange(makeEvent(value.substring(0, value.length - 1)));

			return;
		}

		setIsShakingError(false);
		onChange(makeEvent(value + num));
	};

	// TODO: rework
	return (
		<div className={cx(styles.pin_screen, { [styles.pin_screen_wrong]: isShakingError })}>
			<div className={cx(contentClasses, styles.pin_screen__content)}>
				<div className={styles.pin_screen__description}>
					<Typography
						variant="h5"
						color="--color-neutral-white"
						textAlign="center"
						marginBottom="var(--spacing-2)"
						whiteSpace="nowrap"
					>
						{header}
					</Typography>

					{isVisibleDescription && (
						<Typography variant="body2" color="--color-neutral-white" textAlign="center" marginBottom="var(--spacing-4)">
							{text}
						</Typography>
					)}

					<DotStepper steps={6} currentStep={value.length} />
				</div>

				<div className={styles.pin_screen__keyboard_wrap}>
					<NumKeyboard onButtonClick={(num) => onNumClick(num)} />
				</div>
			</div>
		</div>
	);
};
