import { FC, useEffect, useState } from 'react';
import { TextFieldProps, Typography } from '@mui/material';
import { METER_TYPE } from 'utils/enums';
import { getMetersListByIndexFromProvider, getMeterType, getMeterTypeTitle } from 'utils/helpers';
import { IMeterDataResponse } from 'utils/types';
import { MeterItem } from './MeterItem';
import styles from './index.module.scss';

export interface MetersListItemProps {
	list: IMeterDataResponse[];
	onChange: (indexFromProvider: string, value: number | null) => void;
	onBlur: (indexFromProvider: string) => void;
	getValidationError: (indexFromProvider: string) => Pick<TextFieldProps, 'error' | 'helperText'>;
}

export const MetersListItem: FC<MetersListItemProps> = ({ list, onChange, onBlur, getValidationError }) => {
	const [metersListByIndexFromProvider, setMetersListByIndexFromProvider] = useState<
		{ meterType: METER_TYPE; list: IMeterDataResponse[] }[]
	>([]);

	useEffect(() => {
		setMetersListByIndexFromProvider(getMetersListByIndexFromProvider(list));
	}, [list]);

	return (
		<div className={styles.wrapper}>
			<Typography variant="body2" color="var(--color-neutral-500)" textAlign="center">
				{list[0].providerTitle}
			</Typography>
			<Typography variant="body2" textAlign="center" color="var(--color-neutral-500)" marginBottom="var(--spacing-2)">
				Показання необхідно передавати до 20 числа за поточний місяць
			</Typography>
			<div className={styles.list}>
				{metersListByIndexFromProvider.map((item) => (
					<div className={styles.list__item} key={item.meterType}>
						<Typography variant="h6" color="var(--color-primary-800)" textAlign="center">
							{getMeterTypeTitle(getMeterType(item.meterType))}
						</Typography>
						{item.list.map((meterItem) => (
							<MeterItem
								key={meterItem.indexFromProvider}
								data={meterItem}
								onChange={onChange}
								onBlur={onBlur}
								getValidationError={getValidationError}
							/>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
