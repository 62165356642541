import { FC } from 'react';
import { CameraSource } from '@capacitor/camera';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as Picture } from 'assets/icons/picture.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { CustomMenuItem } from 'components/shared';

interface AvatarNativeContentProps {
	isUserAvatar: boolean;
	handleEditPhotoMobile: (typePhoto: CameraSource) => Promise<void>;
	handleDeletePhoto: () => void;
}

export const AvatarNativeContent: FC<AvatarNativeContentProps> = ({
	isUserAvatar,
	handleEditPhotoMobile,
	handleDeletePhoto,
}) => {
	return (
		<div style={{ marginTop: '16px' }}>
			<CustomMenuItem icon={<CameraIcon />} title="Камера" onClick={() => handleEditPhotoMobile(CameraSource.Camera)} />
			<CustomMenuItem icon={<Picture />} title="Галерея" onClick={() => handleEditPhotoMobile(CameraSource.Photos)} />
			{isUserAvatar && (
				<CustomMenuItem icon={<Trash />} color="--color-error-500" title="Видалити фото" onClick={handleDeletePhoto} />
			)}
		</div>
	);
};
