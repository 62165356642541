import { FC } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { getIsCurrentCardBlockedSelector, useAppSelector } from 'store';
import { FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { FirebaseAnalytics } from 'utils/services';
import { INavbarItem } from 'utils/types';
import { RADABANK_OPERATIONS_MENU_LIST } from './helpers';
import styles from './index.module.scss';

interface RadabankOperationsMenuProps {
	isDisabled: boolean;
}

export const RadabankOperationsMenu: FC<RadabankOperationsMenuProps> = ({ isDisabled }) => {
	const history = useHistory();

	const isCardBlocked = useAppSelector(getIsCurrentCardBlockedSelector);

	const handleClickItem = (
		item: INavbarItem & {
			from?: string;
			analyticsName: string;
		}
	) => {
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_MAIN, item.analyticsName);
		history.push(item.path, item.from ? { from: item.from } : undefined);
	};

	return (
		<ul
			className={classNames(styles.list, {
				[styles.list_blocked]: isCardBlocked || isDisabled,
			})}
		>
			{RADABANK_OPERATIONS_MENU_LIST.map((item) => (
				// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
				<li key={item.path} className={styles.item} onClick={() => handleClickItem(item)}>
					<div className={styles.icon}>{item.icon}</div>
					<Typography variant="caption" className={styles.label}>
						{item.label}
					</Typography>
				</li>
			))}
		</ul>
	);
};
