import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'utils/helpers';
import { CardService } from 'utils/services';
import { ICardDeleteRequest, ICardListResponse } from 'utils/types';

export const getCardsList = createAsyncThunk('cardsSlice/getCardsList', async (userId: string, { rejectWithValue }) => {
	try {
		const response: AxiosResponse<ICardListResponse> = await CardService.getList({
			cardType: 'OWN',
			userId,
		});
		return response.data.cards;
	} catch (error) {
		return rejectWithValue(getErrorMessage(error));
	}
});

export const deleteCard = createAsyncThunk(
	'cardsSlice/deleteCard',
	async (reqBody: ICardDeleteRequest, { rejectWithValue }) => {
		try {
			await CardService.deleteCard(reqBody);
			return reqBody.cardId;
		} catch (error) {
			return rejectWithValue(getErrorMessage(error));
		}
	}
);
