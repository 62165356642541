import { FC, useEffect } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';
import {  FormTextField } from 'components/shared';
import { FormCustomAutocomplete } from 'components/shared/FormAutocomplete';
import { IRadabankInfoSetAnketaRequest, ISelectOption } from 'utils/types';
import styles from './index.module.scss';

interface SurveyContactPersonRelationsProps {
	isLoading: boolean;
	contactPersonOptions?: ISelectOption[];
}

export const SurveyContactPersonRelations: FC<SurveyContactPersonRelationsProps> = ({
	isLoading,
	contactPersonOptions,
}) => {
	const { control } = useFormContext<Omit<IRadabankInfoSetAnketaRequest, 'userId' | 'infotoken' | 'infocode'>>();

	const contactPerson = useWatch<Omit<IRadabankInfoSetAnketaRequest, 'userId' | 'infotoken' | 'infocode'>>({
		control,
		name: 'contact_person',
	});

	const {
		field: { onChange: setContactPersonExt },
	} = useController({ control, name: 'contact_person_ext' });

	const isOtherRelations = contactPerson === '12';

	useEffect(() => {
		if (!isOtherRelations) {
			setContactPersonExt('');
		}
	}, [isOtherRelations]);

	return (
		<>
			<div className={styles.column}>
				<Typography>Ступінь відносин з контактною особою</Typography>
				<FormCustomAutocomplete
					name="contact_person"
					label="Оберіть варіант"
					options={contactPersonOptions}
					loading={isLoading}
				/>
			</div>
			{isOtherRelations && <FormTextField name="contact_person_ext" label="Вкажіть ступінь відносин" />}
		</>
	);
};
