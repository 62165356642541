import { ReactNode, useEffect, useState } from 'react';
import { Color } from '@ionic/core';
import { IonToast } from '@ionic/react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeToast } from 'store/toastify/reducer';
import { getToastListSelector } from 'store/toastify/selectors';
import { IToast } from 'utils/types';

export const useToastify = (
	isOpen: boolean,
	message: string,
	onClose: () => void,
	color: Color = 'danger',
	duration = 3000
): ReactNode => {
	const [isShow, setIsShow] = useState(false);

	useEffect(() => {
		setIsShow(isOpen);
	}, [isOpen]);

	const handleToastClose = (): void => {
		setIsShow(false);
		onClose();
	};

	return (
		<IonToast
			position="top"
			color={color}
			isOpen={isShow}
			message={message}
			onDidDismiss={handleToastClose}
			duration={duration}
		/>
	);
};

export const useToast = (): ReactNode => {
	const dispatch = useAppDispatch();

	const toastList: IToast[] = useAppSelector(getToastListSelector);

	return (
		<>
			{toastList
				.filter((item) => !!item.message)
				.map((item: IToast, index: number) => (
					<IonToast
						id={`${item.id}` || `${item.message}-${index}`}
						key={item.id}
						position="top"
						isOpen
						color={item.color || 'danger'}
						message={item.message}
						onDidDismiss={() => dispatch(closeToast(item.id as number))}
						duration={item.duration || 3000}
					/>
				))}
		</>
	);
};
