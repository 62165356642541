import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { updateUserProfile } from 'store/user-service/actions';
import { ConfirmPasswordNative, ConfirmPasswordWeb, SetPasswordNative, SetPasswordWeb } from 'components/Auth';
import { TextHandlePassword } from 'components/Auth/PasswordSetup/helpers';
import { Loader } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON } from 'utils/constants';
import { ERROR_CODE, ERROR_MESSAGE, ROUTER_URL, SETUP_PASSWORD_STEP, STORAGE_KEY } from 'utils/enums';
import { encryptData, getErrorMessage, getErrorResponse, redirectNotAuthorizedUser } from 'utils/helpers';
import { useToastify } from 'utils/hooks/use-toastify';
import { FirebaseAnalytics, StorageService, UserService } from 'utils/services';
import { IUserVerifyResponse } from 'utils/types';

interface PasswordSetupPageProps {
	isUpdatePassword?: boolean;
	isShowButton?: boolean;
	handleClose?: () => void;
	pageName: string;
}

export const PasswordSetupPage: FC<PasswordSetupPageProps> = ({
	isUpdatePassword = false,
	isShowButton = true,
	handleClose,
	pageName,
}) => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const [phone, setPhone] = useState('');
	const [step, setStep] = useState(SETUP_PASSWORD_STEP.SET);
	const [passwordData, setPasswordData] = useState({
		currentPassword: '',
		password: '',
		confirmPassword: '',
	});
	const [isFetching, setIsFetching] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const errorToast = useToastify(!!errorMessage, errorMessage, () => setErrorMessage(''));

	const analyticsButtonName = // eslint-disable-next-line no-nested-ternary
		step === SETUP_PASSWORD_STEP.SET
			? FIREBASE_EVENT_ANALYTICS_BUTTON.SET_PASSWORD
			: step === SETUP_PASSWORD_STEP.CONFIRM
			? FIREBASE_EVENT_ANALYTICS_BUTTON.CONFIRM_PASSWORD
			: FIREBASE_EVENT_ANALYTICS_BUTTON.CURRENT_PASSWORD;

	useEffect(() => {
		StorageService.get<string>(STORAGE_KEY.PHONE).then((value: string | null) => {
			if (!value) {
				redirectNotAuthorizedUser();
			} else {
				setPhone(value ? value.toString() : '');
			}
		});

		if (isUpdatePassword) {
			setStep(SETUP_PASSWORD_STEP.CURRENT);
		}
	}, []);

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		if (!/^[0-9]+$|^$/.test(event.target.value)) return;
		setPasswordData({ ...passwordData, [event.target.name]: event.target.value });
	};

	const handleConfirmPassword = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(pageName, analyticsButtonName);
			if (isUpdatePassword) {
				// Update password
				await handleUpdatePassword();
			} else {
				// Create password
				await handleCreatePassword();
			}
		} catch (error) {
			const message = getErrorMessage(error);
			setErrorMessage(message);
		} finally {
			setIsFetching(false);
		}
	};

	const handleCreatePassword = async () => {
		setIsFetching(true);
		const response: AxiosResponse<IUserVerifyResponse> = await UserService.verifyUser({
			phone,
			password: passwordData.password,
		});
		if (Capacitor.isNativePlatform()) {
			await StorageService.set(STORAGE_KEY.USER_PASSWORD, encryptData(passwordData.password));
		}
		await StorageService.set(STORAGE_KEY.VERIFICATION_ID, response.data.verificationId);
		await StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
		history.push(ROUTER_URL.AUTH_VERIFY_USER_SIGNUP);
	};

	const handleUpdatePassword = async () => {
		try {
			await dispatch(
				updateUserProfile({ password: passwordData.currentPassword, newPassword: passwordData.password })
			).unwrap();
			await StorageService.set(STORAGE_KEY.USER_PASSWORD, encryptData(passwordData.password));
			if (handleClose) {
				handleClose();
			}
			dispatch(showToast({ message: 'Пароль змінено успішно', color: 'success' }));
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			dispatch(
				showToast({
					message:
						errorResponse?.errorData?.code === ERROR_CODE.INVALID_CURRENT_PASSWORD
							? ERROR_MESSAGE.INVALID_CURRENT_PASSWORD
							: getErrorMessage(error),
				})
			);
		}
	};

	const handleShowSetPassword = () => {
		FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK);
		if (isUpdatePassword) {
			setStep(SETUP_PASSWORD_STEP.CURRENT);
			setPasswordData({ currentPassword: '', password: '', confirmPassword: '' });
		} else {
			setStep(SETUP_PASSWORD_STEP.SET);
			setPasswordData((prevData) => ({ ...prevData, confirmPassword: '' }));
		}
	};

	// Select current step
	const content = useMemo(() => {
		if (step === SETUP_PASSWORD_STEP.SET) {
			return (
				<SetPasswordWeb
					label="Встановіть PIN код"
					description={!isUpdatePassword ? TextHandlePassword.Set : ''}
					value={passwordData.password}
					onChange={handleChangePassword}
					onSubmit={() => {
						FirebaseAnalytics.logEvent(pageName, analyticsButtonName);
						setStep(SETUP_PASSWORD_STEP.CONFIRM);
					}}
					isButtonBackVisible={!isUpdatePassword}
					pageName={pageName}
				/>
			);
		}
		if (step === SETUP_PASSWORD_STEP.CURRENT) {
			return (
				<SetPasswordWeb
					label="Введіть поточний PIN код"
					description={!isUpdatePassword ? TextHandlePassword.Set : ''}
					value={passwordData.currentPassword}
					onChange={handleChangePassword}
					onSubmit={() => {
						FirebaseAnalytics.logEvent(pageName, analyticsButtonName);
						setStep(SETUP_PASSWORD_STEP.SET);
					}}
					nameInput="currentPassword"
					isButtonBackVisible={!isUpdatePassword}
					pageName={pageName}
				/>
			);
		}
		return (
			<ConfirmPasswordWeb
				onClickBack={handleShowSetPassword}
				isShowDescription={!isUpdatePassword}
				value={passwordData.confirmPassword}
				onChange={handleChangePassword}
				onSubmit={handleConfirmPassword}
				password={passwordData.password}
				isLoading={isFetching}
				isShowButton={isShowButton}
			/>
		);
	}, [step, handleChangePassword, passwordData]);

	return !Capacitor.isNativePlatform() ? (
		<>
			{content}
			{errorToast}
		</>
	) : (
		<>
			{step === SETUP_PASSWORD_STEP.SET ? (
				<SetPasswordNative
					value={passwordData.password}
					maxLength={6}
					onChange={handleChangePassword}
					onSubmit={() => {
						FirebaseAnalytics.logEvent(pageName, analyticsButtonName);
						setStep(SETUP_PASSWORD_STEP.CONFIRM);
					}}
				/>
			) : (
				<>
					{isFetching && <Loader />}
					<ConfirmPasswordNative
						value={passwordData.confirmPassword}
						maxLength={6}
						password={passwordData.password}
						onChange={handleChangePassword}
						onSubmit={handleConfirmPassword}
					/>
				</>
			)}
			{errorToast}
		</>
	);
};
