import { FC, useEffect, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import {
	closeConfirmModal,
	getAppLoadingSelector,
	getIsCurrentCardBlockedSelector,
	getRadabankCardSmsPhonesList,
	getRadabankCurrentCardSelector,
	getRadabankCurrentCardSmsInfoSelector,
	getUserProfileSelector,
	openConfirmModal,
	setRadabankCardSmsPhone,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { CustomButton, Loader } from 'components/shared';
import { RADABANK_SMS_TYPE_ID } from 'utils/enums';
import { getErrorMessage, removeCodeFormPhone } from 'utils/helpers';
import { IRadabankInternalCurrentCard, IRadabankInternalSmsCardItem, IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

interface RadabankSmsInfoPhonesListProps {
	onClickAddPhone: () => void;
}

export const RadabankSmsInfoPhonesList: FC<RadabankSmsInfoPhonesListProps> = ({ onClickAddPhone }) => {
	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);
	const cardSmsInfo = useAppSelector(getRadabankCurrentCardSmsInfoSelector);
	const isCardBlocked = useAppSelector(getIsCurrentCardBlockedSelector);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (currentCard && profile) {
			handleGetSmsPhonesList(profile.userId, currentCard.id);
		}
	}, [currentCard, profile]);

	const handleGetSmsPhonesList = async (userId: string, cardid: string) => {
		try {
			await dispatch(getRadabankCardSmsPhonesList({ userId, cardid })).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleDeletePhone = async (data: IRadabankInternalSmsCardItem) => {
		try {
			setIsLoading(true);
			await dispatch(
				setRadabankCardSmsPhone({
					userId: (profile as IUserProfileResponse).userId,
					cardid: (currentCard as IRadabankInternalCurrentCard).id,
					phonedest: removeCodeFormPhone(data.phone),
					typeid: RADABANK_SMS_TYPE_ID.DELETE,
				})
			).unwrap();
			dispatch(showToast({ message: 'Номер успішно видалено з СМС інформування', color: 'success' }));
			dispatch(closeConfirmModal());
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleClickDeletePhone = (data: IRadabankInternalSmsCardItem) => {
		dispatch(
			openConfirmModal({
				title: 'Видалити номер',
				subTitle: 'Ви впевнені, що бажаєте видалити номер?',
				icon: <TrashIcon />,
				submitButtonLabel: 'Так, видалити',
				onSubmit: () => handleDeletePhone(data),
			})
		);
	};

	return (
		<>
			<div className={styles.content}>
				<Typography variant="subtitle2" textAlign="center" marginBottom="var(--spacing-4)">
					Номери для отримання СМС
				</Typography>
				<ul className={styles.list}>
					{cardSmsInfo.list.length ? (
						<>
							{cardSmsInfo.list.map((item) => (
								<li className={styles.list__item} key={item.id}>
									<Typography>{item.phone}</Typography>
									{!isCardBlocked && (
										<IconButton className={styles.list__button_delete} onClick={() => handleClickDeletePhone(item)}>
											<CloseIcon />
										</IconButton>
									)}
								</li>
							))}
						</>
					) : (
						<Typography variant="caption" color="var(--color-neutral-600)" textAlign="center">
							У вас поки немає доданих номерів
						</Typography>
					)}
				</ul>
				{!isCardBlocked && <CustomButton label="Додати телефон" onClick={onClickAddPhone} />}
			</div>
			{isLoading && !isAppLoading && <Loader />}
		</>
	);
};
