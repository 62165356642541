import { AxiosResponse } from 'axios';
import { USER_API } from 'core/api';
import { USER_API_URL } from 'utils/enums';
import { ICitiesRequest, IStreetsFlatsRequest, IStreetsHousesRequest, IStreetsRequest } from 'utils/types';

class StreetsService {
	static searchCities = async (reqBody: ICitiesRequest): Promise<AxiosResponse<string[]>> =>
		USER_API.post(USER_API_URL.CITY_SEARCH, {
			...reqBody,
			limit: 20,
		});

	static searchStreets = async (reqBody: IStreetsRequest): Promise<AxiosResponse<string[]>> =>
		USER_API.post(USER_API_URL.STREET_SEARCH, {
			...reqBody,
			limit: 20,
		});

	static searchHouses = async (reqBody: IStreetsHousesRequest): Promise<AxiosResponse<string[]>> =>
		USER_API.post(USER_API_URL.HOUSE_SEARCH, {
			...reqBody,
		});

	static searchFlats = async (reqBody: IStreetsFlatsRequest): Promise<AxiosResponse<string[]>> =>
		USER_API.post(USER_API_URL.FLAT_SEARCH, reqBody);
}

export { StreetsService };
