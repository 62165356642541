import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { getReactHookFormError } from 'utils/helpers';
import { CardOverdraftAmountInput, CardOverdraftAmountInputProps } from '..';

type OwnProps<T extends FieldValues> = {
	name: FieldPath<T>;
};

type FormRadabankCardOverdarftAmountInputProps<T extends FieldValues> = OwnProps<T> &
	Omit<CardOverdraftAmountInputProps, 'onChange' | 'value'>;

export const FormRadabankCardOverdarftAmountInput = <T extends FieldValues>({
	name,
	...props
}: FormRadabankCardOverdarftAmountInputProps<T>) => {
	const { control } = useFormContext<T>();

	const {
		field,
		fieldState: { isTouched, error: fieldError },
	} = useController<T>({ control, name });

	const { isErrorShown } = getReactHookFormError(isTouched, fieldError);

	return <CardOverdraftAmountInput error={isErrorShown} {...field} {...props} />;
};
