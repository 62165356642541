import { FC } from 'react';
import { useHistory } from 'react-router';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { RADABANK_BOOLEAN_VALUE, ROUTER_URL } from 'utils/enums';

export const RadabankInternalActivateSuccessBanner: FC = () => {
	const history = useHistory();

	const handleNavigateToCardCreationPage = () => {
		history.replace(`${ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD}?skipBanner=${RADABANK_BOOLEAN_VALUE.TRUE}`);
	};

	return (
		<div className="host-card-page-color-primary">
			<ArrowBackButton />
			<RadabankOnboardingContent
				title="Ваш обліковий запис активовано!"
				description="Натисніть кнопку для продовження відкриття картки в HOST"
			/>
			<CustomButton label="Продовжити" color="secondary" onClick={handleNavigateToCardCreationPage} />
		</div>
	);
};
