import qs, { ParseOptions } from 'query-string';
import { removeEmptyProps } from './remove-empty-props';

export const formatQueryString = <T>(params: T, isRemoveFalse = false): string => {
	const query = qs.stringify(removeEmptyProps<T>(params, isRemoveFalse));
	return query ? `?${query}` : '';
};

export const parseQueryString = <T>(queryString: string, options?: ParseOptions): T =>
	qs.parse(queryString, options) as T;
