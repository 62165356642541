import { FC } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as HostLogoSvg } from 'assets/icons/host-icon.svg';
import { ReactComponent as MastercardSVG } from 'assets/icons/mastercard.svg';
import { ReactComponent as VisaSVG } from 'assets/icons/visa.svg';
import { PAYMENT_PROVIDER } from 'utils/enums';
import { ICardListItemResponse } from 'utils/types';
import styles from './index.module.scss';

interface CardPreviewProps {
	data: ICardListItemResponse;
}

export const CardPreview: FC<CardPreviewProps> = ({ data }) => {
	const isRadabankCard = data.provider === PAYMENT_PROVIDER.RADABANK;

	const anotherBankCardIcon = data.panMasked.slice(0, 1) === '4' ? <VisaSVG /> : <MastercardSVG />;

	return (
		<div className={styles.content}>
			<div className={styles.content__icon}>{isRadabankCard ? <HostLogoSvg /> : anotherBankCardIcon}</div>
			<div className={styles.content__description}>
				<Typography variant="subtitle1">** {data.panMasked.split('*')[1]}</Typography>
				<Typography variant="body2">{isRadabankCard ? 'HOSTCARD' : data.cardName}</Typography>
			</div>
		</div>
	);
};
