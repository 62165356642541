import { FC } from 'react';
import { convertCoinsToUAH, getAmountTitle } from 'utils/helpers';
import { RadabankOperationDetailRow } from '../OperationDetailRow';
import { RadabankTransactionFee } from '../TransactionFee';

interface RadabankOperationFeeDetailsProps {
	isShowInfo: boolean;
	fee: string;
	currency: string;
}

export const RadabankOperationFeeDetails: FC<RadabankOperationFeeDetailsProps> = ({ isShowInfo, fee, currency }) => {
	return (
		<RadabankOperationDetailRow
			title="Комісія"
			description={getAmountTitle(convertCoinsToUAH(fee), currency)}
			isShowInfo={isShowInfo}
		>
			<RadabankTransactionFee />
		</RadabankOperationDetailRow>
	);
};
