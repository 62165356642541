// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button.OrderPlasticCardStatusContent_button_main_margin__1wyWZ {
  margin-top: var(--spacing-6) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/RadabankCard/OrderPlasticCard/OrderPlasticCardStatusContent/index.module.scss"],"names":[],"mappings":"AAEE;EACC,uCAAA;AADH","sourcesContent":["ion-button.button {\n\t&_main {\n\t\t&_margin {\n\t\t\tmargin-top: var(--spacing-6) !important;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_main_margin": `OrderPlasticCardStatusContent_button_main_margin__1wyWZ`
};
export default ___CSS_LOADER_EXPORT___;
