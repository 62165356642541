import { FC } from 'react';
import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { GuardedRoute } from 'layouts/GuaredRoute';
import { ErrorPage } from 'pages/Error';
import { NetworkErrorPage } from 'pages/NetworkError';
import { NotFoundPage } from 'pages/NotFound';
import { OnboardingPage } from 'pages/Onboarding';
import { ROUTER_URL } from 'utils/enums';
import { AuthRouter } from './AuthRouter';
import { MainRouter } from './MainRouter';

export const NativeRouter: FC = () => {
	return (
		<Switch>
			<Route exact path={ROUTER_URL.MAIN} render={() => <Redirect to={ROUTER_URL.PROFILE} />} />
			<Route path={ROUTER_URL.AUTH}>
				<AuthRouter />
			</Route>
			<Route path={ROUTER_URL.PROFILE}>
				<GuardedRoute>
					<MainRouter />
				</GuardedRoute>
			</Route>
			<Route exact path={ROUTER_URL.WELCOME} component={OnboardingPage} />
			<Route exact path={ROUTER_URL.ERROR_NETWORK} component={NetworkErrorPage} />
			<Route exact path={ROUTER_URL.ERROR} component={ErrorPage} />
			<Route exact path={ROUTER_URL.RECEIPT_PAY_DEEPLINK} component={() => null} />
			<Route path="*" component={NotFoundPage} />
		</Switch>
	);
};
