// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.PublicLayout_layout__kuV4k {
  background-color: var(--color-neutral-layout-bg);
  overflow-x: clip;
  height: calc(100dvh - env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
}
@media (max-width: 1024px) {
  section.PublicLayout_layout__kuV4k {
    grid-template-rows: 100px 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/PublicLayout/index.module.scss","webpack://./src/assets/styles/media.scss"],"names":[],"mappings":"AAEA;EACC,gDAAA;EACA,gBAAA;EACA,+CAAA;EACA,qCAAA;AADD;ACQC;EDXD;IAOE,6BAAA;EAAA;AACF","sourcesContent":["@import 'assets/styles/mixins';\n\nsection.layout {\n\tbackground-color: var(--color-neutral-layout-bg);\n\toverflow-x: clip;\n\theight: calc(100dvh - env(safe-area-inset-top));\n\tpadding-top: env(safe-area-inset-top);\n\n\t@include mobile {\n\t\tgrid-template-rows: 100px 1fr;\n\t}\n}\n","@mixin tablet {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin desktop {\n\t@media (min-width: 1025px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile {\n\t@media (max-width: 1024px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-md {\n\t@media (max-width:374px) {\n\t\t@content\n\t}\n}\n\n@mixin mobile-small {\n\t@media (max-width: 319px) {\n\t\t@content;\n\t}\n}\n\n@mixin mobile-small-height {\n\t@media (max-height: 668px) {\n\t\t@content;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `PublicLayout_layout__kuV4k`
};
export default ___CSS_LOADER_EXPORT___;
