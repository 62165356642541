import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useDevice } from 'utils/hooks';
import { ScrollToLayoutTopButton } from '../ScrollToLayoutTopButton';
import styles from './index.module.scss';

interface ScrollToLayoutTopButtonContainerProps {
	// if has page scrollable class
	isPageScrollable?: boolean;
}

export const ScrollToLayoutTopButtonContainer: FC<PropsWithChildren<ScrollToLayoutTopButtonContainerProps>> = ({
	children,
	isPageScrollable = false,
}) => {
	const { isIos, isMobile } = useDevice();
	return (
		<div className={styles.wrapper}>
			{children}
			<div className={styles.scroll}>
				<ScrollToLayoutTopButton
					isPageScrollable={isIos && isPageScrollable}
					className={classNames(styles.scroll__button, { [styles.native]: isMobile })}
				/>
			</div>
		</div>
	);
};
