import { FC, useMemo } from 'react';
import { Typography } from '@mui/material';
import AvatarIcon from 'assets/icons/profile.svg';
import { closeConfirmModal, openConfirmModal, useAppDispatch } from 'store';
import { IContactItem } from 'utils/types';
import styles from './index.module.scss';

interface ContactItemProps {
	data: IContactItem;
	onAddPhone: (phone: string) => Promise<void>;
}

export const ContactItem: FC<ContactItemProps> = ({ data, onAddPhone }) => {
	const dispatch = useAppDispatch();

	const visiblePhone = useMemo(
		() => data.phone.replace(/(\+380)(\d{2})(\d{2})(\d{2})(\d{3})/, '$1 $2 $3 $4 $5'),
		[data.phone]
	);

	const handleAddPhone = async () => {
		await onAddPhone(data.phone);
		dispatch(closeConfirmModal());
	};

	const handleClickAddPhone = () => {
		dispatch(
			openConfirmModal({
				title: 'Додати номер',
				subTitle: `Ви впевнені, що бажаєте додати номер ${visiblePhone}?`,
				submitButtonLabel: 'Так, додати',
				color: 'primary',
				onSubmit: handleAddPhone,
			})
		);
	};

	return (
		<li role="presentation" className={styles.content} onClick={handleClickAddPhone}>
			<img className={styles.content__img} src={data.image || AvatarIcon} alt="" />
			<div className={styles.content__info}>
				<Typography variant="body2">{data.name}</Typography>
				<Typography variant="caption" color="var(--color-neutral-300)">
					{visiblePhone}
				</Typography>
			</div>
		</li>
	);
};
