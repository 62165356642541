import { useEffect, useRef } from 'react';
import { MenuItem } from '@mui/material';
import styles from './index.module.scss';

export const SearchModalOption = ({ item, index, style, onUpdateSizeMap, onClick }: any) => {
	const rowRef = useRef<HTMLLIElement>(null);

	useEffect(() => {
		onUpdateSizeMap(index, rowRef.current?.getBoundingClientRect().height);
	}, [onUpdateSizeMap, index]);

	const handleClickItem = () => {
		onClick(item);
	};

	return (
		<div className={styles.row} style={style}>
			<MenuItem ref={rowRef} onClick={handleClickItem}>
				{item.label}
			</MenuItem>
		</div>
	);
};
