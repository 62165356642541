import { FC, useState } from 'react';
import { useLocation } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { getRadabankCardExistsSelector } from 'store';
import { useAppSelector } from 'store/hooks';
import { getIsUserHasFullAccessSelector } from 'store/user-service/selectors';
import { EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { ComingSoonContent } from 'components/ComingSoonContent';
import { ApartmentsList, CardBanner } from 'components/Home';
import SliderButton, { SLIDER_DIRECTION } from 'components/SliderButton';
import { FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { useDevice } from 'utils/hooks';
import styles from './index.module.scss';

export interface MainPageSliderProps {
	onClickCardBanner: () => void;
}

export const MainPageSlider: FC<MainPageSliderProps> = ({ onClickCardBanner }) => {
	const { isMobile } = useDevice();

	const location = useLocation<{ initialSlide?: number }>();

	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);
	const isHideRadabankBanner = useAppSelector(getRadabankCardExistsSelector);

	const [activeSlideIndex, setActiveSlideIndex] = useState(0);
	const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);

	const onSlideChange = (swiper: SwiperClass) => {
		setActiveSlideIndex(swiper.activeIndex);
	};

	const onSwiperInit = (swiper: SwiperClass) => {
		if (swiperInstance) return;
		setSwiperInstance(swiper);
	};

	return (
		<div className={styles.content}>
			<Swiper
				className={styles.slider}
				slidePrevClass={styles.custom_no_active_slide}
				slideNextClass={styles.custom_no_active_slide}
				slidesPerView={isMobile ? 1.2 : 'auto'}
				wrapperClass={styles.slider__wrapper}
				effect="coverflow"
				initialSlide={location?.state?.initialSlide}
				centeredSlides
				spaceBetween={0}
				onSlideChange={onSlideChange}
				onInit={onSwiperInit}
				coverflowEffect={{
					rotate: 0,
					stretch: 0,
					scale: 0.85,
					depth: isMobile ? 20 : 50,
					modifier: 0.9,
					slideShadows: false,
				}}
				pagination={{
					bulletClass: styles.slider__bullet,
					bulletActiveClass: styles.slider__bullet_active,
					horizontalClass: styles.slider__horizontal,
					renderBullet(_index: number, className: string) {
						return `<span class="${className}"></span>`;
					},
				}}
				modules={[Pagination, EffectCoverflow]}
			>
				<SwiperSlide className={styles.slider__slide}>
					<ApartmentsList />
				</SwiperSlide>
				{isHasFullAccess && !isHideRadabankBanner && (
					<SwiperSlide className={styles.slider__slide}>
						<CardBanner onClick={onClickCardBanner} isActionsDisabled={!Capacitor.isNativePlatform()} />
					</SwiperSlide>
				)}
				<SwiperSlide className={styles.slider__slide}>
					<ComingSoonContent />
				</SwiperSlide>

				{!isMobile && (
					<div className={styles.slider__nav}>
						<SliderButton
							image={<ArrowLeft />}
							direction={SLIDER_DIRECTION.PREV}
							disabled={activeSlideIndex === 0}
							pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.HOME}
						/>
						<SliderButton
							image={<ArrowLeft style={{ transform: 'rotateY(180deg)' }} />}
							direction={SLIDER_DIRECTION.NEXT}
							disabled={isHasFullAccess && !isHideRadabankBanner ? activeSlideIndex === 2 : activeSlideIndex === 1}
							pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.HOME}
						/>
					</div>
				)}
			</Swiper>
		</div>
	);
};
