import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { AuthContentHeader } from 'components/Auth/AuthContentHeader';
// import { ConfirmPhoneModal } from 'components/Auth/ConfirmPhoneModal';
import { CustomButton, PhoneInput } from 'components/shared';
import { STORAGE_KEY } from 'utils/enums';
import { getUnmaskedPhone } from 'utils/helpers';
import { useOpen } from 'utils/hooks';
import { useAutofocus } from 'utils/hooks/use-autofocus';
import { StorageService } from 'utils/services';
import { phoneValidationSchema } from 'utils/validation';

interface CheckPhoneProps {
	onSubmit: (value: string) => void;
	isLoading: boolean;
}

export const CheckPhone: FC<CheckPhoneProps> = ({ onSubmit, isLoading }) => {
	const [phone, setPhone] = useState('');
	const [error, setError] = useState('');
	const [touched, setTouched] = useState(false);

	const { isPresented, handleDidPresent } = useOpen();

	const inputRef = useAutofocus(isPresented);

	const buttonRef = useRef(null);

	useEffect(() => {
		handleDidPresent();
		StorageService.get<string>(STORAGE_KEY.PHONE).then((value: string | null) => {
			if (value) {
				setPhone(value);
			}
		});
	}, []);

	const validatePhone = async (phone: string): Promise<void> => {
		try {
			await phoneValidationSchema.validate(phone, { abortEarly: false });
			setError('');
		} catch (error) {
			setError(error.inner[0]?.message);
		}
	};

	const handleChangePhone = (event: ChangeEvent<HTMLInputElement>): void => {
		const payload = getUnmaskedPhone(event.target.value);
		setPhone(payload);
		validatePhone(payload);
	};

	const handleBlurPhone = (): void => {
		if (!touched) {
			validatePhone(phone);
		}
		setTouched(true);
	};

	const handlePressEnter = () => {
		if(!error && phone.length){
			onSubmit(phone);
		}
	};

	return (
		<>
			<AuthContentHeader
				title="Введіть ваш номер телефону"
				description="На вказаний номер телефону буде надіслано код підтвердження"
			/>
			<PhoneInput
				inputRef={inputRef}
				value={phone}
				onChange={handleChangePhone}
				onBlur={handleBlurPhone}
				isError={!!error && touched}
				helperText={touched && error ? error : ''}
				onEnterPress={handlePressEnter}
			/>
			<CustomButton
				ref={buttonRef}
				className="auth-button-submit"
				size="large"
				disabled={!!error || !phone.length}
				label="Продовжити"
				onClick={() => onSubmit(phone)}
				isLoading={isLoading}
			/>
			{/* <ConfirmPhoneModal
				phone={phone}
				onClose={handleClose}
				isOpen={isOpen}
				onSubmit={() => onSubmit(phone)}
				isLoading={isLoading}
			/> */}
		</>
	);
};
