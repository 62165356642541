import { ChangeEvent, FC } from 'react';
import { CustomButton } from 'components/shared';
import styles from './index.module.scss';

interface AvatarWebContentProps {
	isUserAvatar: boolean;
	onClose: () => void;
	handleEditPhotoWeb: (event: ChangeEvent<HTMLInputElement>) => void;
	handleDeletePhoto: () => void;
}

export const AvatarWebContent: FC<AvatarWebContentProps> = ({
	isUserAvatar,
	onClose,
	handleDeletePhoto,
	handleEditPhotoWeb,
}) => {
	return (
		<div className={styles.avatar__modal}>
			<label htmlFor="upload-photo">
				<CustomButton label="Завантажити фото" fill="clear" />
			</label>
			<input
				className={styles.avatar__input}
				accept="image/*"
				id="upload-photo"
				type="file"
				onChange={handleEditPhotoWeb}
			/>
			{isUserAvatar && (
				<CustomButton
					className={styles.avatar__delete}
					color="danger"
					label="Видалити фото"
					onClick={handleDeletePhoto}
					fill="clear"
				/>
			)}
			<CustomButton className={styles.avatar__close} label="Скасувати" onClick={onClose} fill="outline" />
		</div>
	);
};
