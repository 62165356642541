import { FC, ReactNode } from 'react';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as Download } from 'assets/icons/download-simple.svg';
import classNames from 'classnames';
import { ArrowBackButton } from 'components/shared';
import styles from './index.module.scss';

interface ReceiptPageHeaderProps {
	title?: string | ReactNode;
	description?: string | ReactNode;
	onBackClick: () => void;
	onDownload?: () => void;
	className?: string;
}

export const ReceiptPageHeader: FC<ReceiptPageHeaderProps> = ({
	title,
	description,
	onBackClick,
	onDownload,
	className,
}) => {
	return (
		<div className={classNames(styles.header, className)}>
			<ArrowBackButton className={styles.button__back} onClick={onBackClick} />
			<div className={styles.text}>
				{typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}

				<Typography variant="body2" color="--color-neutral-500">
					{description}
				</Typography>
			</div>

			{!!onDownload && (
				<IconButton onClick={onDownload}>
					<Download />
				</IconButton>
			)}
		</div>
	);
};
