import { Capacitor } from '@capacitor/core';
import { AxiosResponse } from 'axios';
import { STORAGE_KEY } from 'utils/enums';
import { PushNotificationsService, SessionsService, StorageService, UserService } from 'utils/services';
import { ISession } from 'utils/types';

export const getUserSession = async (): Promise<Pick<ISession, 'sessionId'>> => {
	try {
		const sessionResponse: AxiosResponse<Pick<ISession, 'sessionId'>> = await SessionsService.getSession();
		StorageService.set(STORAGE_KEY.SESSION_ID, sessionResponse.data.sessionId);
		return sessionResponse.data;
	} catch (error) {
		throw new Error(error);
	}
};

export const userLogout = async (userId?: string): Promise<void> => {
	try {
		if(userId && PushNotificationsService.isEnabled && Capacitor.getPlatform() !== 'web') {
			await PushNotificationsService.unsubscribe(userId);
		}
		await UserService.logout();
	} finally {
		if (userId) {
			await StorageService.set(STORAGE_KEY.LAST_LOGGED_IN_USER_ID, userId);
		}

		await StorageService.remove(STORAGE_KEY.SESSION_ID);
		await StorageService.remove(STORAGE_KEY.USER_PASSWORD);
		await StorageService.remove(STORAGE_KEY.VERIFICATION_ID);
		await StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);
		await StorageService.remove(STORAGE_KEY.VERIFICATION_CODE);
		await StorageService.remove(STORAGE_KEY.APP_LAST_BACKGROUND_TIME);
		await StorageService.remove(STORAGE_KEY.RECEIPT_SELECTED_LIST);
		await StorageService.remove(STORAGE_KEY.RADABANK_INFO_ANKETA_DATA);
		await StorageService.remove(STORAGE_KEY.RADABANK_AUTH_INTERNAL);
		await StorageService.remove(STORAGE_KEY.RADABANK_TOKEN_EXP_DATE);

		if (Capacitor.isNativePlatform()) {
			await StorageService.remove(STORAGE_KEY.PHONE);
		}

		const { sessionId } = (await SessionsService.getSession()).data;
		await StorageService.set(STORAGE_KEY.SESSION_ID, sessionId);
		await SessionsService.checkSession();
	}
};
