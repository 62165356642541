/* eslint-disable */
import { ChangeEvent, FC, forwardRef, KeyboardEvent } from 'react';
import cx from 'classnames';
import { Box, BaseTextFieldProps, TextField } from '@mui/material';
import styles from './index.module.scss';
import { useKeyPress } from 'utils/hooks';

interface InputProps extends Omit<BaseTextFieldProps, 'value' | 'defaultValue'> {
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	value?: string;
	defaultValue?: string;
	endDecorator?: React.ReactNode;
	slots?: { input?: React.ComponentType<any> };
	slotProps?: { input?: Record<string, any> };
	isError?: boolean;
	maskChar?: any;
	onSubmit?: () => void;
}

const Input: FC<InputProps> = ({
	type = 'text',
	className,
	endDecorator,
	slotProps = {},
	sx = {},
	isError = false,
	maskChar,
	inputProps,
	onSubmit,
	onKeyDown,
	...rest
}) => {
	const handlePressEnter = useKeyPress(onSubmit);

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (onKeyDown) {
			onKeyDown(e);
		}
		handlePressEnter(e);
	};

	return (
		<Box className={cx(className, styles['input-wrapper'])} sx={sx}>
			<TextField
				{...rest}
				inputProps={{ ...inputProps, ...slotProps.input }}
				onKeyDown={handleKeyDown}
				type={type}
				error={isError}
			/>
			{endDecorator}
		</Box>
	);
};

export default Input;
