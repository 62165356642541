import { Capacitor } from '@capacitor/core';
import { Signature } from 'signature';

class SignatureService {
	static getSign = async () => {
		if (Capacitor.getPlatform() !== 'web') {
			const result = await Signature.echo({ value: '' });
			return result.value;
		}
	};
}

export { SignatureService };
