import * as Yup from 'yup';
import { APARTMENT_ACCOUNT_ID_CHARS_LENGTH, APARTMENT_VALIDATION_ERROR } from 'utils/constants';
import { VALIDATION_ERROR } from 'utils/enums';

export const apartmentAccountIdValidationSchema = Yup.string()
	.min(APARTMENT_ACCOUNT_ID_CHARS_LENGTH, `HOST ID має містити ${APARTMENT_ACCOUNT_ID_CHARS_LENGTH} цифр`)
	.required("HOST ID обов'язкове поле");

export const apartmentProviderIdValidationSchema = Yup.object().shape({
	providerId: Yup.number()
		.min(1, VALIDATION_ERROR.PROVIDER_ID_REQUIRED)
		.required(VALIDATION_ERROR.PROVIDER_ID_REQUIRED),
	providerAccountId: Yup.string().required(VALIDATION_ERROR.PROVIDER_ACCOUNT_ID_REQUIRED),
});

export const createApartmentValidationSchema = Yup.object().shape({
	street: Yup.string().required(APARTMENT_VALIDATION_ERROR.street),
	house: Yup.string().required(APARTMENT_VALIDATION_ERROR.house),
});

export const createApartmentWithFlatValidationSchema = createApartmentValidationSchema.concat(
	Yup.object().shape({
		flat: Yup.string().required(APARTMENT_VALIDATION_ERROR.flat),
	})
);
