import { FC, HTMLProps } from 'react';
import { List, MenuItem, Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

interface RadabankInfoListProps extends HTMLProps<HTMLDivElement> {
	infoList: string[];
}

export const RadabankInfoList: FC<RadabankInfoListProps> = ({ infoList, className, ...rest }) => (
	<div {...rest} className={classNames(styles.content, className)}>
		<InfoIcon className={styles.icon} />
		<List>
			{infoList.map((info, index) => (
				<MenuItem disableRipple className={styles.item} key={`${info}-${index}`}>
					<Typography variant="caption" color="var(--color-neutral-600)">
						{info}
					</Typography>
				</MenuItem>
			))}
		</List>
	</div>
);
