import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import { InfoAnchor } from 'components/shared';
import styles from './index.module.scss';

interface RadabankOperationDetailRowProps {
	title: string;
	description?: string;
	isShowInfo?: boolean;
}

export const RadabankOperationDetailRow: FC<PropsWithChildren<RadabankOperationDetailRowProps>> = ({
	title,
	description,
	isShowInfo = false,
	children,
}) => (
	<div className={styles.content}>
		{children ? (
			<InfoAnchor className={styles.content__title} title={title}>
				{children}
			</InfoAnchor>
		) : (
			<div className={styles.content__title}>
				<Typography fontSize="14px" variant="caption" color="var(--color-neutral-500)">
					{title}
				</Typography>
				{isShowInfo && <InfoIcon className={styles.content__icon} />}
			</div>
		)}
		<Typography fontSize="16px" variant="subtitle2">
			{description}
		</Typography>
	</div>
);
