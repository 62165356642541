import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import { useLocation } from 'react-router';
import { Browser } from '@capacitor/browser';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { IconButton } from '@mui/material';
import { ReactComponent as CaretSvg } from 'assets/icons/caret.svg';
import {
	closeDocumentViewer,
	getDocumentViewerSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { ERROR_MESSAGE } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { FilesystemService } from 'utils/services';
import { ArrowBackButton } from '../ArrowBackButton';
import { CustomButton } from '../CustomButton';
import { Loader } from '../Loader';
import styles from './index.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

export const DocumentViewer: FC = () => {
	const dispatch = useAppDispatch();

	const { pathname } = useLocation();

	const documentViewer = useAppSelector(getDocumentViewerSelector);

	const [pagesCount, setPagesCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [viewElementWidth, setViewElementWidth] = useState(0);
	const [file, setFile] = useState<File | null>(null);

	const viewElementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (documentViewer.isOpen) {
			dispatch(closeDocumentViewer());
		}
	}, [pathname]);

	useEffect(() => {
		if (documentViewer.url) {
			setFile(null);
			handleGetArrayBuffer(documentViewer.url);
		}
	}, [documentViewer.url]);

	useEffect(() => {
		if (viewElementRef.current) {
			setViewElementWidth(viewElementRef.current.offsetWidth - 32);
		}
	}, [viewElementRef.current]);

	const handleGetArrayBuffer = async (url: string): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			// returned base64 without mime type
			const response = await CapacitorHttp.request({
				url,
				headers: {
					Accept: 'application/pdf',
				},
				responseType: 'arraybuffer',
			});
			const base64Response = `${FilesystemService.getBase64Prefix('pdf')}${response.data}`;
			const file = FilesystemService.convertBase64ToFile(base64Response);
			setFile(file);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const onDocumentLoadSuccess = ({ numPages }: DocumentCallback): void => {
		setPagesCount(numPages);
		setCurrentPage(1);
	};

	const handleShowError = (): void => {
		dispatch(showToast({ message: `${ERROR_MESSAGE.SOMETHING_WENT_WRONG}. Документ неможливо прочитати` }));
	};

	const handleError = async () => {
		try {
			dispatch(closeDocumentViewer());
			if (documentViewer.url) {
				await Browser.open({ url: documentViewer.url });
			} else {
				handleShowError();
			}
		} catch (error) {
			handleShowError();
			if (documentViewer.url) {
				window.location.href = documentViewer.url;
			}
		}
	};

	const handleClickShare = async (): Promise<void> => {
		try {
			if (documentViewer.handleShare) {
				await documentViewer.handleShare();
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const fileUrl = useMemo(() => (documentViewer.openAsUrl ? documentViewer.url : file), [documentViewer, file]);

	return documentViewer.isOpen ? (
		<div className={styles.wrapper}>
			<ArrowBackButton onClick={() => dispatch(closeDocumentViewer())} />
			<div className={styles.viewer} ref={viewElementRef}>
				{file && (
					<Document
						inputRef={viewElementRef}
						className={styles.viewer}
						onLoadSuccess={onDocumentLoadSuccess}
						file={fileUrl}
						onLoadError={handleError}
						loading={<Loader />}
					>
						{/* scrollable version */}
						{/* {Array.from(new Array(pagesCount), (_, index) => (
					<Page
						key={`page_${index + 1}`}
						pageNumber={index + 1}
						renderAnnotationLayer={false}
						renderTextLayer={false}
						loading={<Loader />}
					/>
				))} */}
						<Page
							className={styles.viewer__page}
							width={viewElementWidth}
							renderAnnotationLayer={false}
							renderTextLayer={false}
							pageNumber={currentPage}
							loading={<Loader />}
						>
							{pagesCount > 1 && (
								<div className={styles.nav}>
									<IconButton
										onClick={() => setCurrentPage(currentPage - 1)}
										disabled={currentPage === 1 || pagesCount < 2}
									>
										<CaretSvg />
									</IconButton>
									<IconButton
										onClick={() => setCurrentPage(currentPage + 1)}
										disabled={currentPage === pagesCount || pagesCount < 2}
									>
										<CaretSvg />
									</IconButton>
								</div>
							)}
						</Page>
					</Document>
				)}
			</div>

			{!!(documentViewer.isShareEnabled && file) && (
				<CustomButton
					className={styles.button_submit}
					label={Capacitor.isNativePlatform() ? 'Поділитись' : 'Завантажити'}
					onClick={handleClickShare}
				/>
			)}
		</div>
	) : null;
};
