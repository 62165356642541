/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { IBillReducer } from 'utils/types';
import { billInitialState } from './reducer';

export const resetReceiptOperation = (state: IBillReducer): void => {
	state.receipt.data = null;
};

export const toggleLoadingReceiptOperation = (state: IBillReducer, { payload }: PayloadAction<boolean>): void => {
	state.receipt.isLoading = payload;
};

export const resetBillReducerOperation = () => {
	return { ...billInitialState };
};

export const resetBillsReportOperation = (state: IBillReducer): void => {
	state.bills.report = { ...billInitialState.bills.report };
};

export const resetBillsListOperation = (state: IBillReducer): void => {
	state.bills.list = { ...billInitialState.bills.list };
};

export const resetOtherServicesListOperation = (state: IBillReducer): void => {
	state.otherServices.services = { ...billInitialState.otherServices.services };
};

export const resetOtherServiceCategoriesOperation = (state: IBillReducer): void => {
	state.otherServices.categories = { ...billInitialState.otherServices.categories };
};

export const resetPossibleApartmentsListOperation = (state: IBillReducer): void => {
	state.possibleApartments = { ...billInitialState.possibleApartments };
};
