import { FC, MouseEvent } from 'react';
import { Box } from '@mui/material';
import Logo from 'assets/icons/host-icon.svg';
import { ReactComponent as QuestionSVG } from 'assets/icons/question.svg';
import { IHint } from 'utils/types/hint.interface';
import styles from './index.module.scss';

export const DataHint: FC<IHint> = ({ title, subTitle, onOpenModal, logo = Logo }) => {
	const handleOpenModal = (event: MouseEvent<HTMLOrSVGElement>): void => {
		event.stopPropagation();
		if (onOpenModal) {
			onOpenModal();
		}
	};

	return (
		<Box className={styles.card}>
			<div className="data-hint-logo">
				<img src={logo} alt="" />
			</div>
			<QuestionSVG className={styles.card__question} onClick={handleOpenModal} />
			<Box className={styles.card__info}>
				<Box className={styles.card__info__title}>{title}</Box>
				<Box className={styles.card__info__description}>{subTitle}</Box>
			</Box>
		</Box>
	);
};
