import { FC } from 'react';
// MUI
import { StepConnector } from '@mui/material';
import cx from 'classnames';
// Styles
import styles from './styles.module.scss';

interface StepperProps {
	steps: string[];
	activeStep: number;
}

const Stepper: FC<StepperProps> = ({ steps, activeStep }) => {
	return (
		<div className={styles['stepper-wrapper']}>
			{steps.map((step, i) => (
				<div
					key={step}
					className={cx(styles.step, {
						[styles.step_unactive]: i > activeStep,
					})}
				>
					<p className={styles.step__title}>{step}</p>
					<StepConnector
						className={cx({
							'Mui-active': i === activeStep,
							'Mui-completed': i < activeStep,
						})}
					/>
				</div>
			))}
		</div>
	);
};
export default Stepper;
