import { STORAGE_KEY } from 'utils/enums';

// https://javascript.info/cookie#getcookie-name
export const getCookie = (name: STORAGE_KEY): string | undefined => {
	const matches = document.cookie.match(
		// eslint-disable-next-line prefer-template, no-useless-escape
		new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: STORAGE_KEY, value: string): void => {
	document.cookie = `${name}=${value}`;
};
