export enum PAYMENT_STATUS {
	PAID = 'PAID',
	REJECTED = 'REJECTED',
	PENDING = 'PENDING',
	CONFIRMED = 'CONFIRMED',
	NEEDS_CONFIRMATION = 'NEEDS_CONFIRMATION',
	RETURNED = 'RETURNED',
	EXPIRED = 'EXPIRED',

	// for multiple views custom value
	UNPAID = 'UNPAID',
	PAID_PARTIAL = 'PAID_PARTIAL',
}

export enum PAYMENT_STATUS_TITLE {
	PAID = 'Cплачено',
	REJECTED = 'Відхилено',
	EXPIRED = 'Прострочено',
	RETURNED = 'Повернено',
	IN_PROGRESS = 'В опрацюванні',

	// multiple views
	PAID_PARTIAL = 'Частково сплачено ',
	UNPAID = 'Не сплачено',
}

export enum AUTHORIZED_STATUS {
	IS_UNAUTHORIZED = 'IS_UNAUTHORIZED',
	IS_AUTHORIZED = 'IS_AUTHORIZED',
}
