import { ChangeEvent, FC } from 'react';
import { FieldInputProps } from 'formik';
import { getUnmaskedPhone } from 'utils/helpers';
import { useFormikField,useFormikFieldError } from 'utils/hooks';
import { PhoneInput, PhoneInputProps } from '../PhoneInput';

type OwnProps = {
	field: FieldInputProps<string>;
};

type PhoneInputFormikProps = OwnProps & PhoneInputProps;

export const PhoneInputFormik: FC<PhoneInputFormikProps> = ({ field, ...props }) => {
	const { isErrorShown, error } = useFormikFieldError(field);

	const { setValue } = useFormikField(field);

	const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
		const phoneNumber = getUnmaskedPhone(event.target.value);
		// format should be 090000000
		setValue(phoneNumber.slice(2));
	};

	return <PhoneInput error={isErrorShown} errorText={error} {...field} {...props} onChange={handleChangeValue} />;
};
