import { Device, DeviceId } from '@capacitor/device';
import FingerprintJS, { Agent, GetResult } from '@fingerprintjs/fingerprintjs';
import { STORAGE_KEY } from 'utils/enums';
import { StorageService } from 'utils/services';
import { checkDeviceIsWeb } from './device';

export const getDeviceFP = async (): Promise<string> => {
	let deviceFP: string | null = await StorageService.get(STORAGE_KEY.DEVICE_FP);

	if (deviceFP) return deviceFP;

	if (checkDeviceIsWeb()) {
		const fpPromise: Agent = await FingerprintJS.load();
		const fpResult: GetResult = await fpPromise.get();
		deviceFP = fpResult.visitorId;
	} else {
		const fp: DeviceId = await Device.getId();
		deviceFP = fp.identifier;
	}

	StorageService.set(STORAGE_KEY.DEVICE_FP, deviceFP);
	return deviceFP;
};
