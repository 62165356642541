import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { IconButton, MenuItem, MenuList } from '@mui/material';
import { ReactComponent as CaretSVG } from 'assets/icons/caret.svg';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { CustomButton, CustomModal } from 'components/shared';
import { formatDateView } from 'utils/helpers';
import styles from './index.module.scss';

dayjs.extend(localeData);

interface CalendarModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (date: string) => void;
	month: string;
	year: string;
}

export const CalendarModal: FC<CalendarModalProps> = ({ isOpen, onClose, onSubmit, month, year }) => {
	const [date, setDate] = useState<Dayjs>(dayjs());

	const updateDate = useCallback(() => {
		setDate(
			dayjs()
				.set('month', +month - 1)
				.set('year', +year)
		);
	}, [month, year]);

	useEffect(() => {
		updateDate();
	}, [updateDate]);

	const handleChangeYear = (event: MouseEvent<HTMLButtonElement>, isNext = false): void => {
		event.stopPropagation();
		setDate(date.add(isNext ? 1 : -1, 'year'));
	};

	const onClickMonth = (event: MouseEvent<HTMLLIElement>, index: number): void => {
		event.stopPropagation();
		setDate(date.set('month', index));
	};

	const handleClose = (): void => {
		updateDate();
		onClose();
	};

	const checkIsMonthDisabled = (index: number): boolean => {
		const firstDayCurrentDate = dayjs(date).set('month', index).set('date', 1).set('year', date.year());
		const minDate = dayjs().set('month', 7).set('date', 1).set('year', 2024);
		return !firstDayCurrentDate.isAfter(minDate);
	};

	const handleClickSubmit = (event: MouseEvent<HTMLIonButtonElement>): void => {
		event.stopPropagation();
		onSubmit(date.toISOString());
	};

	return (
		<CustomModal classNameHeader={styles.content__header} title="Оберіть період" isOpen={isOpen} onClose={handleClose}>
			<div className={styles.content}>
				<div className={styles.content__toolbar}>
					<IconButton onClick={handleChangeYear} disabled={date.year() <= 2023}>
						<CaretSVG />
					</IconButton>
					<span>{formatDateView(date, 'YYYY')}</span>
					<IconButton onClick={(event) => handleChangeYear(event, true)} disabled={date.year() === dayjs().year()}>
						<CaretSVG />
					</IconButton>
				</div>
				<MenuList className={styles.content__list}>
					{dayjs.monthsShort().map((item: string, index: number) => (
						<MenuItem
							className={classNames(styles.month, {
								[styles.month_selected]: index === date.month() && !checkIsMonthDisabled(index),
							})}
							key={item}
							disabled={checkIsMonthDisabled(index)}
							onClick={(event) => onClickMonth(event, index)}
						>
							{item}
						</MenuItem>
					))}
				</MenuList>
				<CustomButton onClick={handleClickSubmit} className={styles.button} label="Обрати" />
			</div>
		</CustomModal>
	);
};
