import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { TextField, Typography } from '@mui/material';
import { ReactComponent as SuccessSVG } from 'assets/icons/success.svg';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { CustomButton } from 'components/shared';
import { ROUTER_URL } from 'utils/enums';
import {
	convertCoinsToUAH,
	getAmountTitle,
	getApartmentTitleFromHash,
	getErrorMessage,
	handleKeyDownEmail,
	parseQueryString,
} from 'utils/helpers';
import { BillService } from 'utils/services';
import { emailValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

export const ReceiptPublicStatusContent: FC = () => {
	const dispatch = useAppDispatch();

	const params = useParams<{ billId: string }>();
	const location = useLocation();
	const history = useHistory();

	const [email, setEmail] = useState('');
	const [touched, setTouched] = useState(false);
	const [validationError, setValidationError] = useState('');
	const [apartmentTitle, setApartmentTitle] = useState('');
	const [paymentAmount, setPaymentAmount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const queryParams = parseQueryString<{ hash: string; amount: number }>(location.search);
		setApartmentTitle(getApartmentTitleFromHash(queryParams.hash));
		setPaymentAmount(convertCoinsToUAH(queryParams.amount));
	}, []);

	const validateValue = async (value: string): Promise<void> => {
		try {
			await emailValidationSchema.validate(value, { abortEarly: false });
			setValidationError('');
		} catch (error) {
			setValidationError(error.message);
		}
	};

	const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		validateValue(event.target.value);
	};

	const handleBlurEmail = () => {
		setTouched(true);
		validateValue(email);
	};

	const handleSendEmail = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const response = await BillService.updateUnauthorizedEmail({ email, billId: params.billId });
			dispatch(
				showToast({
					message: `Квитанцію ${response.data.isSent ? 'вже було' : 'буде'} надіслано ${
						response.data.isSent ? '' : `на ${email}`
					}`,
					color: response.data.isSent ? 'danger' : 'success',
				})
			);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleRedirect = (): void => {
		history.push(ROUTER_URL.AUTH_LOGIN);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.icon}>
				<SuccessSVG />
			</div>
			{!!paymentAmount && (
				<Typography variant="h5" textAlign="center" marginBottom="var(--spacing-3)">
					{`Сплачено успішно ${getAmountTitle(paymentAmount)}`}
				</Typography>
			)}
			{!!apartmentTitle && (
				<Typography variant="body1" color=" var(--color-neutral-500)" textAlign="center">
					{apartmentTitle}
				</Typography>
			)}
			<Typography variant="body2" textAlign="center" marginTop="var(--spacing-4)">
				{`ID транзакції: ${params.billId}`}
			</Typography>
			<div className={styles.actions}>
				<Typography variant="body2" textAlign="center" marginBottom="var(--spacing-4)">
					Для отримання квитанції введіть вашу пошту нижче
				</Typography>
				<TextField
					type="email"
					value={email}
					onChange={handleChangeEmail}
					onBlur={handleBlurEmail}
					label="Email"
					size="small"
					error={!!validationError && touched}
					helperText={touched && validationError}
					onKeyDown={handleKeyDownEmail}
				/>
				<CustomButton
					onClick={handleSendEmail}
					label="Надіслати квитанцію"
					disabled={!!validationError || !email || isLoading}
					isLoading={isLoading}
				/>
				<CustomButton className={styles.button__auth} onClick={handleRedirect} fill="outline" label="Увійти" />
			</div>
		</div>
	);
};
