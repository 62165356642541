// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NumKeyboard_wrapper__T6ug1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-4);
  width: 100%;
}
.NumKeyboard_wrapper__T6ug1 ion-button {
  --border-radius: var(--spacing-3);
  --background: #ffffff12 !important;
  --box-shadow: none;
  --padding-top: 12px;
  height: 52px;
  font-size: 20px;
  font-weight: 700;
  color: var(--color-neutral-white);
}
.NumKeyboard_wrapper__T6ug1 ion-button.NumKeyboard_invisible__FXxey {
  visibility: hidden;
  opacity: 0;
}
.NumKeyboard_wrapper__T6ug1 ion-button:last-child {
  --background: transparent !important;
}
.NumKeyboard_wrapper__T6ug1.NumKeyboard_color_primary__u4ijR ion-button {
  --background: var(--color-primary-50) !important;
  color: var(--color-primary-500);
}
.NumKeyboard_wrapper__T6ug1.NumKeyboard_color_primary__u4ijR svg path {
  stroke: var(--color-primary-500);
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/PasswordSetup/NumKeyboard/index.module.scss"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,qCAAA;EACA,qBAAA;EACA,WAAA;AADD;AAGC;EACC,iCAAA;EACA,kCAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iCAAA;AADF;AAGE;EACC,kBAAA;EACA,UAAA;AADH;AAIE;EACC,oCAAA;AAFH;AAOE;EACC,gDAAA;EACA,+BAAA;AALH;AAQE;EACC,gCAAA;AANH","sourcesContent":["@import 'assets/styles/mixins';\n\n.wrapper {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(3, 1fr);\n\tgap: var(--spacing-4);\n\twidth: 100%;\n\n\tion-button {\n\t\t--border-radius: var(--spacing-3);\n\t\t--background: #ffffff12 !important;\n\t\t--box-shadow: none;\n\t\t--padding-top: 12px;\n\t\theight: 52px;\n\t\tfont-size: 20px;\n\t\tfont-weight: 700;\n\t\tcolor: var(--color-neutral-white);\n\n\t\t&.invisible {\n\t\t\tvisibility: hidden;\n\t\t\topacity: 0;\n\t\t}\n\n\t\t&:last-child {\n\t\t\t--background: transparent !important;\n\t\t}\n\t}\n\n\t&.color_primary {\n\t\tion-button {\n\t\t\t--background: var(--color-primary-50) !important;\n\t\t\tcolor: var(--color-primary-500);\n\t\t}\n\n\t\tsvg path {\n\t\t\tstroke: var(--color-primary-500);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NumKeyboard_wrapper__T6ug1`,
	"invisible": `NumKeyboard_invisible__FXxey`,
	"color_primary": `NumKeyboard_color_primary__u4ijR`
};
export default ___CSS_LOADER_EXPORT___;
