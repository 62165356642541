import { FC, useEffect, useState } from 'react';
import { IonSpinner } from '@ionic/react';
import classNames from 'classnames';
import { getServicesCategories } from 'store/bill/actions';
import { getServicesCategoriesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { ServicesCategoriesHeader, ServicesCategoriesList } from 'components/OtherServices';
import { SERVICES_CATEGORIES_DEFAULT_REQUEST_BODY } from 'utils/constants';
import { getErrorMessage } from 'utils/helpers';
import { IServicesCategoriesRequest } from 'utils/types';
import styles from './index.module.scss';

interface ServicesCategoriesProps {
	onChange: (categoryId: string, categoryName: string) => void;
	onClickBack?: () => void;
}

export const ServicesCategories: FC<ServicesCategoriesProps> = ({ onChange, onClickBack }) => {
	const dispatch = useAppDispatch();

	const [reqBody, setReqBody] = useState<IServicesCategoriesRequest>(SERVICES_CATEGORIES_DEFAULT_REQUEST_BODY);
	const [isLoading, setIsLoading] = useState(false);
	const categories = useAppSelector(getServicesCategoriesSelector);

	useEffect(() => {
		handleGetServicesCategories(reqBody);
	}, [reqBody]);

	const handleGetServicesCategories = async (reqBody: IServicesCategoriesRequest): Promise<void> => {
		try {
			setIsLoading(true);
			await dispatch(getServicesCategories(reqBody)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<ServicesCategoriesHeader
					className={classNames('receipt-service-modal-header', styles.content__header)}
					data={reqBody}
					onChange={(data) => setReqBody(data)}
					onClickBack={onClickBack}
				/>
				<ServicesCategoriesList data={reqBody} onChange={(data) => setReqBody(data)} onClickCard={onChange} />
			</div>
			{isLoading && !categories.data.tableData.length && <IonSpinner className="loader-dots" name="dots" />}
		</div>
	);
};
