import { Capacitor } from '@capacitor/core';
import { AxiosResponse } from 'axios';
import { USER_API } from 'core/api';
import { AUTHORIZED_STATUS, FEE_DIRECTION, PROVIDER_TYPE, USER_API_URL } from 'utils/enums';
import { removeEmptyProps } from 'utils/helpers';
import {
	IAddApartmentRequest,
	IAddServiceFieldValueRequest,
	IAddServiceToReceiptRequest,
	IApartmentListResponse,
	IBillsListItemResponse,
	IBillsListRequest,
	IBillsReportResponse,
	ICheckPaymentStatusRequest,
	ICheckPaymentStatusResponse,
	ICreateBillRequest,
	IDeleteServiceFieldValueRequest,
	IDeleteServiceFromReceiptRequest,
	IGetServiceDataRequest,
	IGetServiceDataResponse,
	IGetServiceFeeRequest,
	IGetServiceFeeResponse,
	IOtherServicePaymentRequest,
	IPaginatedRequest,
	IPaginatedResponse,
	IPaymentResponse,
	IProviderListItemResponse,
	IReceiptArchiveListRequest,
	IReceiptArchiveListResponse,
	IReceiptPayRequest,
	IReceiptRequest,
	IReceiptResponse,
	IRemoveApartmentRequest,
	IServiceBillRequestData,
	IServiceFieldResponse,
	IServiceFieldsRequest,
	IServicesCategoriesRequest,
	ISingleArchiveReceiptRequest,
	ISingleArchiveReceiptResponse,
	IStreetsFlatsRequest,
	IUpdateServiceFieldValueRequest,
	IUpdateUnauthorizedEmailReqsponse,
	IUpdateUnauthorizedEmailRequest,
	ServiceFieldsValuesRequest,
	ServiceFieldsValuesResponse,
	ServicesCategoriesResponse,
	ServicesRequest,
	ServicesResponse,
} from 'utils/types';

class BillService {
	static addApartment = async (
		reqBody: IAddApartmentRequest
	): Promise<AxiosResponse<{ result: number; message: string }>> =>
		USER_API.post(USER_API_URL.BILL_CREATE_APARTMENT, removeEmptyProps(reqBody));

	static getApartmentAccountIdsList = async (reqBody: IStreetsFlatsRequest): Promise<AxiosResponse<number[]>> =>
		USER_API.post(USER_API_URL.GET_APARTMENT_IDS_LIST, removeEmptyProps(reqBody));

	static getApartmentListByAccountIds = async (apartmentAccountId: number[]): Promise<AxiosResponse<any>> =>
		USER_API.post(USER_API_URL.GET_APARTMENT_LIST_BY_IDS, removeEmptyProps({ options: { apartmentAccountId } }));

	static getApartmentList = async (userId: string): Promise<AxiosResponse<IApartmentListResponse>> =>
		USER_API.post(USER_API_URL.BILL_GET_APARTMENT_LIST, removeEmptyProps({ options: { userId: [userId] } }));

	static removeApartment = async (reqBody: IRemoveApartmentRequest): Promise<AxiosResponse<any>> =>
		USER_API.post(USER_API_URL.BILL_DELETE_APARTMENT_LIST, removeEmptyProps(reqBody));

	static getSingleReceipt = async (reqBody: IReceiptRequest): Promise<AxiosResponse<IReceiptResponse>> =>
		USER_API.post(USER_API_URL.BILL_GET_SINGLE_RECEIPT, removeEmptyProps(reqBody));

	static paySingleReceipt = async (
		reqBody: IReceiptPayRequest,
		isAuthorized = AUTHORIZED_STATUS.IS_AUTHORIZED
	): Promise<AxiosResponse<IPaymentResponse[]>> =>
		USER_API.post(
			USER_API_URL.BILL_PAY_SINGLE_RECEIPT,
			removeEmptyProps({ ...reqBody, paymentStatus: isAuthorized, platform: Capacitor.getPlatform() })
		);

	static checkPaymentStatus = async (
		reqBody: ICheckPaymentStatusRequest
	): Promise<AxiosResponse<ICheckPaymentStatusResponse>> =>
		USER_API.post(USER_API_URL.BILL_CHECK_PAYMENT_STATUS, reqBody);

	static getPaymentsArchive = async (
		reqBody: IReceiptArchiveListRequest
	): Promise<AxiosResponse<IReceiptArchiveListResponse>> => USER_API.post(USER_API_URL.PAYMENTS_ARCHIVE, reqBody);

	static getSingleArchivePayment = async (
		reqBody: ISingleArchiveReceiptRequest
	): Promise<AxiosResponse<ISingleArchiveReceiptResponse>> =>
		USER_API.post(USER_API_URL.SINGLE_ARCHIVE_PAYMENT, reqBody);

	static getServicesCategories = async (
		reqBody: IServicesCategoriesRequest
	): Promise<AxiosResponse<ServicesCategoriesResponse>> =>
		USER_API.post(USER_API_URL.BILL_GET_SERVICES_CATERGORIES, removeEmptyProps(reqBody));

	static getServicesByCategory = async (reqBody: ServicesRequest): Promise<AxiosResponse<ServicesResponse>> =>
		USER_API.post(USER_API_URL.BILL_GET_SERVICES, removeEmptyProps(reqBody));

	static getServiceFields = async (reqBody: IServiceFieldsRequest): Promise<AxiosResponse<IServiceFieldResponse[]>> =>
		USER_API.post(USER_API_URL.BILL_GET_SERVICE_FIELDS, reqBody);

	static getServiceFieldsValues = async (
		reqBody: ServiceFieldsValuesRequest
	): Promise<AxiosResponse<ServiceFieldsValuesResponse>> =>
		USER_API.post(USER_API_URL.BILL_GET_SERVICE_FIELDS_VALUES, reqBody);

	static addServiceFieldValue = async (reqBody: IAddServiceFieldValueRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.BILL_ADD_SERVICE_FIELD_VALUE, reqBody);

	static updateServiceFieldValue = async (reqBody: IUpdateServiceFieldValueRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.BILL_UPDATE_SERVICE_FIELD_VALUE, reqBody);

	static deleteServiceFieldValue = async (reqBody: IDeleteServiceFieldValueRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.BILL_DELETE_SERVICE_FIELD_VALUE, reqBody);

	static getServiceData = async (
		reqBody: Pick<IGetServiceDataRequest, 'contractId' | 'serviceId'>
	): Promise<AxiosResponse<IGetServiceDataResponse>> =>
		USER_API.post(USER_API_URL.BILL_GET_SERVICE_DATA, {
			...reqBody,
			provider: PROVIDER_TYPE.PORTMONE,
		});

	static getServiceFee = async (amount: number, code: string): Promise<AxiosResponse<IGetServiceFeeResponse>> => {
		const reqBody: IGetServiceFeeRequest = {
			code,
			amount,
			provider: PROVIDER_TYPE.PORTMONE,
			feeDirectionType: FEE_DIRECTION.OUTER,
		};
		return USER_API.post(USER_API_URL.BILL_GET_SERVICE_FEE, reqBody);
	};

	static createBillRequest = async (
		reqBody: ICreateBillRequest
	): Promise<AxiosResponse<IServiceBillRequestData | IServiceBillRequestData[]>> =>
		USER_API.post(USER_API_URL.BILL_CREATE_BILL_REQUEST, { ...reqBody, clientId: 1 });

	static otherServicePay = async (reqBody: IOtherServicePaymentRequest): Promise<AxiosResponse<IPaymentResponse[]>> =>
		USER_API.post(USER_API_URL.BILL_OTHER_SERVICE_PAY, { ...reqBody, platform: Capacitor.getPlatform() });

	static addServiceToReceipt = async (reqBody: IAddServiceToReceiptRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.BILL_ADD_SERVICE_TO_RECEIPT, reqBody);

	static deleteServiceFromReceipt = async (reqBody: IDeleteServiceFromReceiptRequest): Promise<AxiosResponse> =>
		USER_API.post(USER_API_URL.BILL_DELETE_SERVICE_FROM_RECEIPT, reqBody);

	static updateUnauthorizedEmail = async (
		reqBody: IUpdateUnauthorizedEmailRequest
	): Promise<AxiosResponse<IUpdateUnauthorizedEmailReqsponse>> =>
		USER_API.post(USER_API_URL.BILL_UPDATE_UNATHORIZED_PAYMENT_EMAIL, reqBody);

	static getBillsList = async (
		reqBody: IPaginatedRequest<IBillsListRequest>
	): Promise<AxiosResponse<IPaginatedResponse<IBillsListItemResponse>>> =>
		USER_API.post(USER_API_URL.BILL_GET_BILLS_LIST, reqBody);

	static getBillsReport = async (billId: string): Promise<AxiosResponse<IBillsReportResponse>> =>
		USER_API.post(USER_API_URL.BILL_GET_BILL, { billId });

	static getProvidersList = async (): Promise<AxiosResponse<IProviderListItemResponse[]>> =>
		USER_API.post(USER_API_URL.BILL_GET_PROVIDERS_LIST);
}

export { BillService };
