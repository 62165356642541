import { FC } from 'react';
import { RadabankFormSwitch } from '../FormSwitch';

export const SurveyCriminal: FC = () => {
	return (
		<RadabankFormSwitch
			name="criminal"
			label="Притягались ви або члени вашої родини до кримінальної відповідальності?"
		/>
	);
};
