// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApartmentRadio_item__nO4ot {
  padding: var(--spacing-5);
  border-radius: var(--spacing-5);
  border: 1px solid transparent;
  width: 100%;
}
.ApartmentRadio_item__nO4ot > span:not(:first-of-type) {
  width: 100%;
}
.ApartmentRadio_item__nO4ot.ApartmentRadio_selected__-UGF9 {
  border: 1px solid;
}
@media (max-width: 768px) {
  .ApartmentRadio_item__nO4ot > span:first-of-type {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Apartment/AddApartmentByAddress/ApartmentsChoiceStep/ApartmentRadio/index.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,+BAAA;EACA,6BAAA;EACA,WAAA;AACJ;AACI;EACI,WAAA;AACR;AAEI;EACI,iBAAA;AAAR;AAGI;EACI;IACI,aAAA;EADV;AACF","sourcesContent":[".item {\n    padding: var(--spacing-5);\n    border-radius: var(--spacing-5);\n    border: 1px solid transparent;\n    width: 100%;\n\n    & > span:not(:first-of-type) {\n        width: 100%;\n    }\n\n    &.selected {\n        border: 1px solid;\n    }\n\n    @media (max-width: 768px) {\n        & > span:first-of-type {\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `ApartmentRadio_item__nO4ot`,
	"selected": `ApartmentRadio_selected__-UGF9`
};
export default ___CSS_LOADER_EXPORT___;
