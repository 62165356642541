// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthContentHeader_content__QjGkm {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/AuthContentHeader/index.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD","sourcesContent":[".content {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `AuthContentHeader_content__QjGkm`
};
export default ___CSS_LOADER_EXPORT___;
