import { FC, HTMLProps } from 'react';
import classNames from 'classnames';
import { getIsCurrentCardBlockedSelector, useAppSelector } from 'store';
import styles from './index.module.scss';

export const RadabankCardPreview: FC<HTMLProps<HTMLDivElement>> = ({ className, children, ...props }) => {
	const isCardBlocked = useAppSelector(getIsCurrentCardBlockedSelector);

	return (
		<div
			{...props}
			className={classNames(styles.wrapper, 'radabank-card-preview', className, {
				[styles.blocked]: isCardBlocked,
			})}
		>
			{children}
		</div>
	);
};
