/* eslint-disable camelcase */
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { getUserIdSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { FormCustomAutocomplete } from 'components/shared/FormAutocomplete';
import { ENV_VARIABLE, RADABANK_SURVEY_LETTER_INITIAL_VALUES } from 'utils/constants';
import { getErrorMessage } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import {
	IRadabankInfoAddressCitiesRequest,
	IRadabankInfoAddressCitiesResponse,
	IRadabankInfoAddressCityTypeRequest,
	IRadabankInfoAddressCityTypeResponse,
	IRadabankInfoAddressDistrictRequest,
	IRadabankInfoAddressDistrictResponse,
	IRadabankInfoAddressRegionResponse,
	IRadabankInfoAddressStreetTypesRequest,
	IRadabankInfoAddressStreetTypesResponse,
	IRadabankInfoSetAnketaRequest,
	ISelectOption,
	RadabankInfoAnketaRequestKeys,
} from 'utils/types';

export const SurveyLetterLocation: FC = () => {
	const dispatch = useAppDispatch();
	const userId = useAppSelector(getUserIdSelector);

	const { setValue: setFieldValue, watch } =
		useFormContext<Omit<IRadabankInfoSetAnketaRequest, 'userId' | 'infotoken' | 'infocode'>>();

	const [adrRegionId, adrDistrictId, adrCityTypeId, adrCityId] = watch([
		'adr_region_id',
		'adr_district_id',
		'adr_citytype_id',
		'adr_city_id',
	]);

	const [isLoading, setIsLoading] = useState({
		adr_region_id: false,
		adr_district_id: false,
		adr_citytype_id: false,
		adr_city_id: false,
		adr_streettype_id: false,
	});

	const [regionsOptions, setRegionsOptions] = useState<ISelectOption[]>([]);
	const [districtsOptions, setDistrictsOptions] = useState<ISelectOption[]>([]);
	const [cityTypeOptions, setCityTypeOptions] = useState<ISelectOption[]>([]);
	const [citiesOptions, setCitiesOptions] = useState<ISelectOption[]>([]);
	const [streetTypeOptions, setStreetTypeOptions] = useState<ISelectOption[]>([]);

	const handleSetInitialValue = (nameList: RadabankInfoAnketaRequestKeys[]) => {
		nameList.forEach((item) => {
			setFieldValue(item, RADABANK_SURVEY_LETTER_INITIAL_VALUES[item], { shouldValidate: true });
		});
	};

	const handleGetAddressRegion = async (userId: string): Promise<void> => {
		try {
			setIsLoading((prev) => ({ ...prev, adr_region_id: true }));
			const response: AxiosResponse<IRadabankInfoAddressRegionResponse> = await RadabankService.getInfoAddressRegion(
				userId
			);

			setRegionsOptions(response.data.regions.map((item) => ({ label: item.name, value: item.id })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, adr_region_id: false }));
		}
	};

	const handleGetAddressDistrict = async (
		reqBody: Pick<IRadabankInfoAddressDistrictRequest, 'userId' | 'adr_region_id'>
	): Promise<void> => {
		try {
			setIsLoading((prev) => ({ ...prev, adr_district_id: true }));
			const response: AxiosResponse<IRadabankInfoAddressDistrictResponse> =
				await RadabankService.getInfoAddressDistrict(reqBody);

			setDistrictsOptions(response.data.districts.map((item) => ({ label: item.name, value: item.id })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, adr_district_id: false }));
		}
	};

	const handleGetAddressCityTypes = async (
		reqBody: Pick<IRadabankInfoAddressCityTypeRequest, 'userId' | 'adr_region_id' | 'adr_district_id'>
	): Promise<void> => {
		try {
			setIsLoading((prev) => ({ ...prev, adr_citytype_id: true }));
			const response: AxiosResponse<IRadabankInfoAddressCityTypeResponse> =
				await RadabankService.getInfoAddressCityTypes(reqBody);

			setCityTypeOptions(response.data.citytypes.map((item) => ({ label: item.name, value: item.id })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, adr_citytype_id: false }));
		}
	};

	const handleGetAddressCities = async (
		reqBody: Pick<IRadabankInfoAddressCitiesRequest, 'userId' | 'adr_region_id' | 'adr_district_id' | 'adr_citytype_id'>
	): Promise<void> => {
		try {
			setIsLoading((prev) => ({ ...prev, adr_city_id: true }));
			const response: AxiosResponse<IRadabankInfoAddressCitiesResponse> = await RadabankService.getInfoAddressCities(
				reqBody
			);
			setCitiesOptions(response.data.cities.map((item) => ({ label: item.name, value: item.id })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, adr_city_id: false }));
		}
	};

	const handleGetAddressStreetTypes = async (
		reqBody: Pick<
			IRadabankInfoAddressStreetTypesRequest,
			'userId' | 'adr_region_id' | 'adr_district_id' | 'adr_city_id'
		>
	): Promise<void> => {
		try {
			setIsLoading((prev) => ({ ...prev, adr_streettype_id: true }));
			const response: AxiosResponse<IRadabankInfoAddressStreetTypesResponse> =
				await RadabankService.getInfoAddressStreetTypes(reqBody);
			setStreetTypeOptions(response.data.streettypes.map((item) => ({ label: item.name, value: item.id })));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, adr_streettype_id: false }));
		}
	};

	useEffect(() => {
		if (userId) {
			handleGetAddressRegion(userId);
		}
	}, [userId]);

	useEffect(() => {
		if (adrRegionId && userId) {
			handleGetAddressDistrict({ adr_region_id: adrRegionId, userId });
		}
	}, [adrRegionId, userId]);

	useEffect(() => {
		if (adrDistrictId && userId) {
			handleGetAddressCityTypes({ adr_region_id: adrRegionId, adr_district_id: adrDistrictId, userId });
		}
	}, [adrDistrictId, userId]);

	useEffect(() => {
		if (adrCityTypeId && userId) {
			handleGetAddressCities({
				adr_citytype_id: adrCityTypeId,
				adr_region_id: adrRegionId,
				adr_district_id: adrDistrictId,
				userId,
			});
		}
	}, [adrCityTypeId, userId]);

	useEffect(() => {
		if (adrCityId && userId) {
			handleGetAddressStreetTypes({
				adr_city_id: adrCityId,
				adr_region_id: adrRegionId,
				adr_district_id: adrDistrictId,
				userId,
			});
		}
	}, [adrCityId, userId]);

	const handleChangeFieldValue = (e: ChangeEvent<HTMLInputElement>, key: RadabankInfoAnketaRequestKeys) => {
		const { value } = e.target;
		setFieldValue(key, value, { shouldTouch: true, shouldValidate: !value });
	};

	const handleChangeRegion = (e: ChangeEvent<HTMLInputElement>) => {
		handleChangeFieldValue(e, 'adr_region_id');
		setDistrictsOptions([]);
		setCityTypeOptions([]);
		setCitiesOptions([]);
		setStreetTypeOptions([]);

		if (e.target.value === ENV_VARIABLE.RB_REGION_KYIV_ID) {
			setFieldValue('adr_district_id', ENV_VARIABLE.RB_DISTRICT_KYIV_ID ?? '');
			setFieldValue('adr_citytype_id', ENV_VARIABLE.RB_CITYTYPE_CITY_ID ?? '');
			setFieldValue('adr_city_id', ENV_VARIABLE.RB_CITY_KYIV_ID ?? '');
			handleSetInitialValue(['adr_streettype_id']);
			return;
		}
		handleSetInitialValue(['adr_district_id', 'adr_citytype_id', 'adr_city_id', 'adr_streettype_id']);
	};

	const handleChangeDistrict = (e: ChangeEvent<HTMLInputElement>) => {
		handleChangeFieldValue(e, 'adr_district_id');
		setCityTypeOptions([]);
		setCitiesOptions([]);
		setStreetTypeOptions([]);
		handleSetInitialValue(['adr_citytype_id', 'adr_city_id', 'adr_streettype_id']);
	};

	const handleChangeCityType = (e: ChangeEvent<HTMLInputElement>) => {
		handleChangeFieldValue(e, 'adr_citytype_id');
		setCitiesOptions([]);
		setStreetTypeOptions([]);
		handleSetInitialValue(['adr_city_id', 'adr_streettype_id']);
	};

	const handleChangeCityId = (e: ChangeEvent<HTMLInputElement>) => {
		handleChangeFieldValue(e, 'adr_city_id');
		setStreetTypeOptions([]);
		handleSetInitialValue(['adr_streettype_id']);
	};

	const handleChangeStreetTypeId = (e: ChangeEvent<HTMLInputElement>) => {
		handleChangeFieldValue(e, 'adr_streettype_id');
	};

	const isKyivRegionSelected = adrRegionId === ENV_VARIABLE.RB_REGION_KYIV_ID;

	return (
		<>
			<FormCustomAutocomplete
				name="adr_region_id"
				label="Область"
				options={regionsOptions}
				loading={isLoading.adr_region_id}
				disabled={isLoading.adr_region_id}
				onChange={handleChangeRegion}
			/>
			{!isKyivRegionSelected && (
				<>
					<FormCustomAutocomplete
						name="adr_district_id"
						label="Район"
						options={districtsOptions}
						loading={isLoading.adr_district_id}
						disabled={isLoading.adr_district_id}
						onChange={handleChangeDistrict}
					/>
					<FormCustomAutocomplete
						name="adr_citytype_id"
						label="Тип населеного пункту"
						options={cityTypeOptions}
						loading={isLoading.adr_citytype_id}
						disabled={isLoading.adr_citytype_id}
						onChange={handleChangeCityType}
					/>
					<FormCustomAutocomplete
						name="adr_city_id"
						label="Населений пункт"
						options={citiesOptions}
						loading={isLoading.adr_city_id}
						disabled={isLoading.adr_city_id}
						onChange={handleChangeCityId}
					/>
				</>
			)}
			<FormCustomAutocomplete
				name="adr_streettype_id"
				label="Тип вулиці"
				options={streetTypeOptions}
				loading={isLoading.adr_streettype_id}
				disabled={isLoading.adr_streettype_id}
				onChange={handleChangeStreetTypeId}
			/>
		</>
	);
};
