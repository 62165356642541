export enum REGISTRATION_TITLE {
	name = 'Вкажіть ваші ім’я та прізвище',
	address = 'Вкажіть вашу адресу в місті Харків',
	apartmentAccountId = 'Вкажіть HOST ID код',
}

export enum REG_NAMES {
	NAME = 'firstName',
	ADDRESS = 'street',
	FLAT_NUMBER = 'flat',
	HOST_ID = 'apartmentAccountId',
	SUR_NAME = 'lastName',
	PERSONAL_BILL = 'personalBill',
}

export enum REG_PLACEHOLDERS {
	hostId = '0000000000000',
	personalBill = '0000000000000',
	address = 'Введіть вашу адресу',
	house = 'Введіть номер вашого будинку',
	flat = 'Введіть номер квартири',
}

export enum REG_FIELD_TYPES {
	hostId = 'number',
	personalBill = 'number',
}
