import { createSlice } from '@reduxjs/toolkit';
import { IToastifyReducer } from 'utils/types';
import { closeToastOperation, showToastOperation } from './operations';

const toastifyInitialState: IToastifyReducer = {
	toastList: [],
};

const toastifyStore = createSlice({
	name: 'userSlice',
	initialState: toastifyInitialState,
	reducers: {
		showToast: showToastOperation,
		closeToast: closeToastOperation,
	},
});

export const { showToast, closeToast } = toastifyStore.actions;

export default toastifyStore.reducer;
