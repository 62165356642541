export enum USER_PROFILE_FIELD_NAME {
	NAME = 'firstName',
	SURNAME = 'lastName',
	EMAIL = 'email',
	CHANGE = 'change-pin-code',
}

export const EDIT_USER_FIRST_NAME = { title: 'Вкажіть ваше ім’я', label: 'Ім’я', name: USER_PROFILE_FIELD_NAME.NAME };

export const EDIT_USER_LAST_NAME = {
	title: 'Вкажіть ваше прізвище',
	label: 'Прізвище',
	name: USER_PROFILE_FIELD_NAME.SURNAME,
};

export const EDIT_USER_EMAIL = {
	title: 'Вкажіть ваш email',
	label: 'Email',
	name: USER_PROFILE_FIELD_NAME.EMAIL,
};
