import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { getServicesCategoriesSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { ArrowBackButton, SearchInput } from 'components/shared';
import { ServicesRequest } from 'utils/types';
import styles from './index.module.scss';

interface ServicesHeaderProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	onChange: (data: ServicesRequest) => void;
	data: ServicesRequest;
	categoryId: string;
	onClickBack: () => void;
}

export const ServicesHeader: FC<ServicesHeaderProps> = ({
	onChange,
	data,
	categoryId,
	onClickBack,
	className,
	...props
}) => {
	const categories = useAppSelector(getServicesCategoriesSelector);

	const [title, setTitle] = useState('');

	useEffect(() => {
		const payload = categories.data.tableData.find((item) => item.id === categoryId)?.title;
		if (payload) {
			setTitle(payload);
		}
	}, [categories.data.tableData]);

	const handleInputSearch = (value: string): void => {
		if (!value && !data.options?.serviceTitle) return;
		onChange({ ...data, offset: 0, options: { ...data.options, serviceTitle: value } });
	};

	return (
		<header {...props} className={classNames('service-header', styles.wrapper, className)}>
			<div>
				<ArrowBackButton onClick={onClickBack} />
				<Typography className={styles.title} variant="h6">
					{title}
				</Typography>
			</div>
			<SearchInput onChange={handleInputSearch} placeholder="Пошук послуг" />
		</header>
	);
};
