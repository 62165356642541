import { StoreType } from 'store/root';
import { PAYMENT_PROVIDER, RADABANK_BOOLEAN_VALUE, RADABANK_CARD_BLOCK_LEVEL } from 'utils/enums';
import { IRadabankInternalClientPhoneResponse, IRadabankInternalCurrentCard } from 'utils/types';

export const getRadabanlInternalClientSelector = (store: StoreType): IRadabankInternalClientPhoneResponse | null => {
	return store.radabankCardStore.radabankInternalClient.data;
};

export const getRadabankCardExistsSelector = (store: StoreType): boolean => {
	const internalClient = getRadabanlInternalClientSelector(store);
	return (
		(!!internalClient &&
			internalClient?.cardexists !== RADABANK_BOOLEAN_VALUE.FALSE &&
			internalClient.activate === RADABANK_BOOLEAN_VALUE.FALSE) ||
		store.cardsStore.list.some((card) => card.provider === PAYMENT_PROVIDER.RADABANK)
	);
};

export const getRadabankInternalClientLoading = (store: StoreType): boolean => {
	return store.radabankCardStore.radabankInternalClient.isLoading;
};

export const getRadabanlInternalCardsListSelector = (store: StoreType): IRadabankInternalCurrentCard[] => {
	return store.radabankCardStore.cards.list;
};

export const getRadabanlInternalIsLoadingCardsListSelector = (store: StoreType): boolean => {
	return store.radabankCardStore.cards.isLoading;
};

export const getRadabankCurrentCardSelector = (store: StoreType): IRadabankInternalCurrentCard | null =>
	store.radabankCardStore.cards.list.find((item) => store.radabankCardStore.currentCard.id === item.id) ?? null;

export const getIsCurrentCardBlockedSelector = (store: StoreType): boolean => {
	const currentCard = getRadabankCurrentCardSelector(store);

	return !!currentCard && currentCard?.blocklevel !== RADABANK_CARD_BLOCK_LEVEL.ACTIVE;
};

export const getRadabankOperationsSelector = (store: StoreType) => store.radabankCardStore.currentCard.operations;

export const getRadabankOperationsPaymentsIdListSelector = (store: StoreType) =>
	store.radabankCardStore.currentCard.paymentsRequestIdList;

export const getRadabankCurrentCardOverdraftDataSelector = (store: StoreType) =>
	store.radabankCardStore.currentCard.overdraftData;

export const getRadabankCurrentCardRulesSelector = (store: StoreType) =>
	store.radabankCardStore.currentCard.currentRules;

export const getRadabankCurrentCardSmsInfoSelector = (store: StoreType) => store.radabankCardStore.currentCard.smsInfo;

export const getRadabankInfoStatusSelector = (store: StoreType) => store.radabankCardStore.infoStatus?.statusCode;

export const getRadabankInfoStatusClientMessageSelector = (store: StoreType) =>
	store.radabankCardStore.infoStatus?.clientMessage || 'Заявка обробляється співробітниками банку';

export const getRadabankInfoSprDataSelector = (store: StoreType) => store.radabankCardStore.infoSprData.data;

export const getRadabankInfoSprDataLoadingSelector = (store: StoreType) =>
	store.radabankCardStore.infoSprData.isLoading;

export const getRadabankCardIdSelector = (store: StoreType) => {
	return store.radabankCardStore.currentCard.id;
};

export const getRadabankStatementsByPeriodSelector = (store: StoreType) => {
	return store.radabankCardStore.currentCard.statement;
};

export const getRadabankTransactionByIdSelector = (store: StoreType, transactionId: string) => {
	const transactionsByPeriod = getRadabankStatementsByPeriodSelector(store);

	const transactionsByPeriodValues = Object.values(transactionsByPeriod)?.flat();

	if (transactionsByPeriodValues.length) {
		return transactionsByPeriodValues.find(({ id }) => id === transactionId);
	}

	const transactions = getRadabankOperationsSelector(store).list?.flat();

	return transactions.find(({ id }) => id === transactionId);
};

export const getRadabankPlasticCardOrderStatusSelector = (store: StoreType) => {
	return store.radabankCardStore.currentCard.plasticCardStatus;
};

export const getRadabankCanUserOrderPlasticCardSelector = (store: StoreType) => {
	const currentCard = getRadabankCurrentCardSelector(store);

	return currentCard && currentCard.isreqnewcard !== RADABANK_BOOLEAN_VALUE.FALSE;
};
