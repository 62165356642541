import { FC } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as DocumentIcon } from 'assets/icons/radabank/card-documents.svg';
import { getRadabankCurrentCardSelector, useAppSelector } from 'store';
import { RadabankCardActionButton } from 'components/RadabankCard';
import { RADABANK_BOOLEAN_VALUE } from 'utils/enums';
import styles from './index.module.scss';

export const RadabankCardAccountDetailsAgreements: FC = () => {
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);

	// TODO: add request
	const handleClickAgreementAccount = async () => {
		// eslint-disable-next-line no-console
		console.log('click');
	};

	// TODO: add request
	// const handleClickAgreementOverdraft = async () => {
	// 	// eslint-disable-next-line no-console
	// 	console.log('click');
	// };

	return (
		!!currentCard && (
			<div className={styles.content}>
				<Typography variant="subtitle1" textAlign="center">
					Договори
				</Typography>
				<div className={styles.content__list}>
					{currentCard.add_printdogopen === RADABANK_BOOLEAN_VALUE.TRUE && (
						<RadabankCardActionButton
							labelDefault="Договір про відкриття рахунку"
							onClick={handleClickAgreementAccount}
							icon={<DocumentIcon />}
						/>
					)}
					{/* TODO: should find answer with radabank developers */}
					{/* {currentCard.add_printdogover === RADABANK_BOOLEAN_VALUE.TRUE && (
						<RadabankCardActionButton
							labelDefault="Договір овердрафту"
							onClick={handleClickAgreementOverdraft}
							icon={<DocumentIcon />}
						/>
					)} */}
				</div>
			</div>
		)
	);
};
