import { StoreType } from 'store/root';
import { IMetersReducer } from 'utils/types';

export const getMetersStateSelector = (store: StoreType): IMetersReducer => {
	return store.metersStore;
};

export const getMetersIsLoadingSelector = (store: StoreType): boolean => {
	return store.metersStore.isLoading;
};
