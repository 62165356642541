import { FC } from 'react';
import { CommomGeneralQuestionsForm } from 'components/RadabankCard/CommonGeneralQuestionsForm';
import { FormTextField } from 'components/shared';

export const SurveyLetterGeneralStep: FC = () => {
	return (
		<>
			<FormTextField name="secret" label="Кодове слово" />
			<CommomGeneralQuestionsForm />
		</>
	);
};
