import { FC } from 'react';
import { getRadabankInfoSprDataLoadingSelector, getRadabankInfoSprDataSelector, useAppSelector } from 'store';
import { SurveyContactPerson } from 'components/RadabankCard/SurveyContactPerson';
import { SurveyContactPersonPhone } from 'components/RadabankCard/SurveyContactPersonPhone';
import { SurveyContactPersonRelations } from 'components/RadabankCard/SurveyContactRelations';
import { SurveyCriminal } from 'components/RadabankCard/SurveyCriminal';
import { SurveyEducationMartialStatus } from 'components/RadabankCard/SurveyEducationMartialStatus';

export const OverdraftSocialStatusStep: FC = () => {
	const isLoading = useAppSelector(getRadabankInfoSprDataLoadingSelector);
	const infoSprData = useAppSelector(getRadabankInfoSprDataSelector);

	return (
		<>
			<SurveyEducationMartialStatus
				isLoading={isLoading}
				martialStatusOptions={infoSprData?.martialStatus}
				educationOptions={infoSprData?.education}
                educationFieldName='education'
                martialStatusFieldName='maritial_status'
			/>
			<SurveyCriminal />
			<SurveyContactPerson />
			<SurveyContactPersonPhone />
			<SurveyContactPersonRelations contactPersonOptions={infoSprData?.contactPerson} isLoading={isLoading} />
		</>
	);
};
