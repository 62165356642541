import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
// import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
// import { useAppDispatch } from 'store/hooks';
// import { showToast } from 'store/toastify/reducer';
import { ROUTER_URL } from 'utils/enums';

// TODO: should improve to exit app 'cause it's not working
export const useIonicBackButton = (): void => {
	const history = useHistory();
	const { pathname } = useLocation();

	const [isShowExitToast] = useState(false);

	const handleClickExit = useCallback(async () => {
		try {
			if (Capacitor.isNativePlatform() && pathname === ROUTER_URL.PROFILE) {
				// const toastDuration = 3000;

				// if (!isShowExitToast) {
				history.block((nextLocation) => {
					const isShouldShowToast = nextLocation.pathname.includes(ROUTER_URL.AUTH);

					// if (isShouldShowToast) {
					// 	setIsShowExitToast(true);
					// 	dispatch(
					// 		showToast({
					// 			message: 'Щоб вийти з додатку натисність кнопку ще раз',
					// 			duration: toastDuration,
					// 			color: 'light',
					// 		})
					// 	);
					// } else {
					// 	App.exitApp();
					// }

					return !isShouldShowToast as any;
				});
				// }

				// setTimeout(() => {
				// 	setIsShowExitToast(false);
				// 	history.block(false);
				// }, toastDuration);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('🚀 ~ file: use-ionic-back-button.ts:49 ~ handleClickExit ~ error:', error);
		}
	}, [isShowExitToast]);

	// const handleGoBack = useCallback(
	// 	(event: Event) => {
	// 		(event as any).detail.register(-1, () => {
	// 			console.log('🚀 ~ file: use-ionic-back-button.ts:57 ~ isShowExitToast:', isShowExitToast);
	// 			if (isShowExitToast) {
	// 				App.exitApp();
	// 			}
	// 		});
	// 	},
	// 	[isShowExitToast]
	// );

	useEffect(() => {
		// document.addEventListener('ionBackButton', handleGoBack);

		return () => {
			handleClickExit();
		};
	}, []);
};
