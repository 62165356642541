// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Services_wrapper__1yOmQ {
  overflow: hidden;
}
.Services_wrapper__1yOmQ .Services_content__ZolwY {
  padding: 0 var(--spacing-2) var(--spacing-3);
}`, "",{"version":3,"sources":["webpack://./src/components/Receipt/AddPortmoneServiceModal/Services/index.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;AACC;EAEC,4CAAA;AAAF","sourcesContent":[".wrapper {\n\toverflow: hidden;\n\n\t.content {\n\t\t// height: 100%;\n\t\tpadding: 0 var(--spacing-2) var(--spacing-3);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Services_wrapper__1yOmQ`,
	"content": `Services_content__ZolwY`
};
export default ___CSS_LOADER_EXPORT___;
