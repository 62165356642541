import {
	AuthenticateOptions,
	BiometricAuth,
	BiometryType,
	CheckBiometryResult,
} from '@aparajita/capacitor-biometric-auth';
import { Capacitor } from '@capacitor/core';
import { BIOMETRIC_AUTH_OPTIONS } from 'utils/constants';
import { STORAGE_KEY } from 'utils/enums';
import { StorageService } from './storage.service';

class BiometryService {
	static primaryBiometryType: BiometryType = BiometryType.none;

	static biometryType: BiometryType = BiometryType.none;

	static isAvailable = false;

	static setBiometryType = async (value: BiometryType): Promise<void> => {
		this.biometryType = value;
		await StorageService.set(STORAGE_KEY.BIOMETRIC_TYPE, value);
	};

	static checkBiometry = async (): Promise<void> => {
		const storageType = await StorageService.get<BiometryType>(STORAGE_KEY.BIOMETRIC_TYPE);
		const { biometryType, isAvailable }: CheckBiometryResult = await BiometricAuth.checkBiometry();
		if (storageType) {
			this.setBiometryType(storageType);
		}
		this.isAvailable = !!storageType || isAvailable;
		this.primaryBiometryType = biometryType;
		BiometricAuth.addResumeListener((result: CheckBiometryResult) => {
			if (!result.isAvailable) {
				this.setBiometryType(BiometryType.none);
				this.isAvailable = false;
			}
		});
	};

	static toggleBiometryEnabled = async (): Promise<void> => {
		const payload = this.biometryType === BiometryType.none ? this.primaryBiometryType : BiometryType.none;
		this.setBiometryType(payload);
	};

	static auth = async (config?: Partial<AuthenticateOptions>): Promise<void> => {
		const options = { ...BIOMETRIC_AUTH_OPTIONS, ...config };

		if (
			Capacitor.getPlatform() === 'ios' &&
			(this.primaryBiometryType === BiometryType.touchId || this.biometryType === BiometryType.touchId)
		) {
			options.reason = 'Проскануйте відбиток пальця';
		}

		await BiometricAuth.authenticate(options);
	};
}

export { BiometryService };
