// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesCategories_wrapper__b7gUE {
  overflow: hidden;
}
.ServicesCategories_wrapper__b7gUE .ServicesCategories_content__Vy355 {
  padding: 0 var(--spacing-2) var(--spacing-3);
}
.ServicesCategories_wrapper__b7gUE .ServicesCategories_content__header__4AhzF {
  width: 100%;
  align-items: flex-start;
}
.ServicesCategories_wrapper__b7gUE .ServicesCategories_content__header__4AhzF button {
  margin-bottom: var(--spacing-3);
}`, "",{"version":3,"sources":["webpack://./src/components/Receipt/AddPortmoneServiceModal/ServicesCategories/index.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;AACC;EAEC,4CAAA;AAAF;AAEE;EACC,WAAA;EACA,uBAAA;AAAH;AAEG;EACC,+BAAA;AAAJ","sourcesContent":[".wrapper {\n\toverflow: hidden;\n\n\t.content {\n\t\t// height: 100%;\n\t\tpadding: 0 var(--spacing-2) var(--spacing-3);\n\n\t\t&__header {\n\t\t\twidth: 100%;\n\t\t\talign-items: flex-start;\n\n\t\t\tbutton {\n\t\t\t\tmargin-bottom: var(--spacing-3);\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ServicesCategories_wrapper__b7gUE`,
	"content": `ServicesCategories_content__Vy355`,
	"content__header": `ServicesCategories_content__header__4AhzF`
};
export default ___CSS_LOADER_EXPORT___;
