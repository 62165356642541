import { FC } from 'react';
import { RadabankFormSwitch } from 'components/RadabankCard/FormSwitch';

export const OverdraftTogglersStep: FC = () => {
	return (
		<>
			<RadabankFormSwitch name="isfop" label="Я зареєстрований як самозайнята особа та/або ФОП" />
			<RadabankFormSwitch
				name="isnotar"
				label="Я є нотаріусом, адвокатом, судовим експертом або займаюсь іншою професійною незалежною діяльністю"
			/>
			<RadabankFormSwitch
				name="nalog_resident_other"
				label="Я маю статус податкового резидента іншої юрисдикції, крім України та США"
			/>
			<RadabankFormSwitch
				name="connect_agressor"
				label="Я маю зв’язки з державою, що здійснює збройну агресію проти України"
			/>
		</>
	);
};
