// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReceiptBillsReport_header__description__Nr6CG {
  color: var(--color-neutral-500);
}`, "",{"version":3,"sources":["webpack://./src/pages/ReceiptBillsReport/index.module.scss"],"names":[],"mappings":"AAIC;EACC,+BAAA;AAHF","sourcesContent":["@import 'assets/styles/mixins';\n@import 'assets/styles/media.scss';\n\n.header {\n\t&__description {\n\t\tcolor: var(--color-neutral-500);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header__description": `ReceiptBillsReport_header__description__Nr6CG`
};
export default ___CSS_LOADER_EXPORT___;
