export enum PAYMENT_TYPE {
	OTHER = 'OTHER',
	EK = 'EK',
}

export enum PAY_TOKEN_TYPE {
	APPLE = 'APPLE',
	GOOGLE = 'GOOGLE',
	RADABANK = 'RADA'
}

export enum PAYMENT_PROVIDER {
	RADABANK = 'RADABANK'
}
