import { FC } from 'react';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { CloseButton, CustomButton } from 'components/shared';
import styles from './index.module.scss';

interface PrimaryBannerLayoutProps {
	onClick: () => void;
	onExit: () => void;
	title?: string;
	description?: string | string[];
	buttonLabel: string;
}

export const PrimaryBannerLayout: FC<PrimaryBannerLayoutProps> = ({
	onClick,
	onExit,
	title,
	description,
	buttonLabel,
}) => {
	return (
		<div className="host-card-page-color-primary">
			<CloseButton className={styles.button} onClick={onExit} />
			<RadabankOnboardingContent title={title} description={description} />
			<CustomButton label={buttonLabel} color="secondary" onClick={onClick} />
		</div>
	);
};
