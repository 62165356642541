import { FC } from 'react';
import { FormCustomAutocomplete } from 'components/shared/FormAutocomplete';
import { ISelectOption } from 'utils/types';

interface SurveyEducationMartialStatusProps {
	isLoading: boolean;
	martialStatusOptions?: ISelectOption[];
	educationOptions?: ISelectOption[];
	educationFieldName?: string;
	martialStatusFieldName?: string;
}

export const SurveyEducationMartialStatus: FC<SurveyEducationMartialStatusProps> = ({
	isLoading,
	martialStatusOptions = [],
	educationOptions = [],
	educationFieldName = 'edication',
	martialStatusFieldName = 'marital_status'
}) => {
	return (
		<>
			<FormCustomAutocomplete
				name={martialStatusFieldName}
				label="Сімейний стан"
				options={martialStatusOptions}
				loading={isLoading}
			/>
			<FormCustomAutocomplete name={educationFieldName} label="Освіта" options={educationOptions} loading={isLoading} />
		</>
	);
};
