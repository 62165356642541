import { FC } from 'react';
import { Typography } from '@mui/material';
import { getAmountTitle } from 'utils/helpers';
import styles from './index.module.scss';

interface ProviderServiceBalancePreviewProps {
	title: string;
	amount: string | number;
}

export const ProviderServiceBalancePreview: FC<ProviderServiceBalancePreviewProps> = ({ title, amount }) => {
	return (
		<div className={styles.content}>
			<div className={styles.content__info}>
				<Typography variant="body2">{title}</Typography>
			</div>
			<Typography variant="subtitle1" whiteSpace="nowrap" textAlign="right" marginLeft="auto">
				{getAmountTitle(amount)}
			</Typography>
		</div>
	);
};
