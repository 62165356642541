import { FC, ReactNode } from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

interface RadabankCardActionButtonProps extends Omit<ButtonProps, 'onClick'> {
	labelDefault: string | ReactNode;
	icon?: ReactNode;
	labelMain?: string;
	labelSecondary?: string;
	onClick: () => void;
	disabled?: boolean;
}

export const RadabankCardActionButton: FC<RadabankCardActionButtonProps> = ({
	labelDefault,
	labelMain = '',
	labelSecondary = '',
	onClick,
	disabled,
	icon = null,
	...rest
}) => (
	<Button
		{...rest}
		className={classNames(styles.content, {
			[styles.disabled]: disabled,
		})}
		disabled={disabled}
		onClick={onClick}
		disableTouchRipple={disabled}
		variant="outline"
	>
		{/* eslint-disable-next-line no-nested-ternary */}
		{!labelMain && !labelSecondary ? (
			typeof labelDefault === 'string' ? (
				<Typography variant="subtitle2" textAlign="center" width="100%">
					{labelDefault}
				</Typography>
			) : (
				labelDefault
			)
		) : (
			<div className={styles.content__info}>
				{!!labelMain && (
					<Typography variant="subtitle2" textAlign="left">
						{labelMain}
					</Typography>
				)}
				{!!labelSecondary && (
					<Typography variant="caption" textAlign="left" color="var(--color-neutral-500)">
						{labelSecondary}
					</Typography>
				)}
			</div>
		)}
		{icon}
	</Button>
);
