import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { getRadabankCurrentCardOverdraftDataSelector, useAppSelector } from 'store';
import * as Yup from 'yup';
import { SurveyStepControls } from 'components/RadabankCard/SurveyLetterForm/StepControls';
import { RadabankOverdraftStep } from 'utils/enums';
import { useStepper } from 'utils/hooks';
import { useAndroidBackButton } from 'utils/hooks/use-close-on-back-button';
import { IRadabankClientRequestOverRequest } from 'utils/types';
import { createOverdraftValidateionSchema } from 'utils/validation';
import { CardOverdraftLayout } from '../../Layout';
import { getRadabankOverdraftStep, RADABANK_OVERDRAFT_STEPS_COUNT } from '../data';
import styles from './index.module.scss';

interface RadabankCardOverdraftCreateFormProps {
	initialValues: IRadabankClientRequestOverRequest;
	onSubmit: (values: IRadabankClientRequestOverRequest) => void;
	onGoBack?: () => void;
}

export const RadabankCardOverdraftCreateForm: FC<RadabankCardOverdraftCreateFormProps> = ({
	initialValues,
	onSubmit,
	onGoBack,
}) => {
	const overdraftData = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);

	const { currentStep, handleNextStep, handlePreviousStep } = useStepper(RADABANK_OVERDRAFT_STEPS_COUNT);

	const { validationSchema, Component } = useMemo(() => getRadabankOverdraftStep(currentStep), [currentStep]);

	const modifiedValidationSchema = useMemo(() => {
		if (currentStep !== RadabankOverdraftStep.Initial) return validationSchema;

		return Yup.object()
			.shape({
				amount: createOverdraftValidateionSchema(overdraftData.data?.overamountmin, overdraftData.data?.overamountmax),
			})
			.concat(validationSchema);
	}, [currentStep, overdraftData.data]);

	const methods = useForm({
		defaultValues: initialValues,
		context: { schema: modifiedValidationSchema },
		mode: 'onTouched',
		resolver: async (values, context) => {
			try {
				await context?.schema?.validate(values, { abortEarly: false });

				return {
					values,
					errors: {},
				};
			} catch (validationErrors) {
				const formattedErrors = validationErrors.inner.reduce(
					(allErrors: Record<string, { type: string; message: string }>, currentError: Yup.ValidationError) => {
						if (!currentError.path) return allErrors;

						return {
							...allErrors,
							[currentError.path]: {
								type: currentError.type ?? 'validation',
								message: currentError.message,
							},
						};
					},
					{}
				);

				return {
					values,
					errors: formattedErrors,
				};
			}
		},
	});

	const { reset, handleSubmit } = methods;

	useEffect(() => {
		reset(initialValues);
	}, [initialValues]);

	const formRef = useCallback(
		(node: HTMLDivElement) => {
			if (node) {
				node.scrollIntoView({ block: 'start', behavior: 'instant' });
			}
		},
		[currentStep]
	);

	const isInitialStep = currentStep === RadabankOverdraftStep.Initial;

	const handleGoBack = () => {
		if (!isInitialStep) {
			handlePreviousStep();
			return;
		}
		onGoBack?.();
	};

    useAndroidBackButton(handleGoBack, isInitialStep);

	return (
		<CardOverdraftLayout isShowProgress={isInitialStep} wrapperRef={formRef} title="Встановлення ліміту" onGoBack={handleGoBack}>
			<div className={styles.wrapper}>
				<FormProvider {...methods}>
					<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
						<div className={classNames({ [styles.form__content]: !isInitialStep })}>
							<Component />
						</div>
						<SurveyStepControls
							stepsCount={RADABANK_OVERDRAFT_STEPS_COUNT}
							currentStep={currentStep}
							onNextStep={handleNextStep}
							onPreviousStep={handlePreviousStep}
							className={styles.controls}
						/>
					</form>
				</FormProvider>
			</div>
		</CardOverdraftLayout>
	);
};
