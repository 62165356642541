import { FC } from 'react';
import { MenuList } from '@mui/material';
import { RadabankOperationsDayItem } from 'components/RadabankCard/OperationsDayItem';
import { IRadabankInternalOperation } from 'utils/types';
import styles from './styles.module.scss';

interface RadabanklOperationStatementsListProps {
	statements: Record<string, IRadabankInternalOperation[]>;
}

export const RadabankOperationStatementsList: FC<RadabanklOperationStatementsListProps> = ({ statements }) => {
	return (
		<MenuList className={styles.list}>
			{Object.entries(statements).map(([date, operations]) => (
				<RadabankOperationsDayItem key={date} date={date} operations={operations} />
			))}
		</MenuList>
	);
};
