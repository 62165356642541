import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_CONFIRM_MODAL, MODAL_DATA } from 'utils/constants';
import { ISystemStore } from 'utils/types';
import {
	closeConfirmModalOperation,
	closeDocumentViewerOperation,
	closeModalOperation,
	openConfirmModalOperation,
	openDocumentViewerOperation,
	openModalOperation,
	resetDocumentViewerOperation,
	setAppLoadingOperation,
	updateIsAppUrlListenerInProgressOperation,
	updateIsNetworkConnectedOperation,
	updateModalIsPresentedOperation,
	updateUserPlatformOperation,
} from './operations';

const systemInitialState: ISystemStore = {
	isLoading: false,
	isAppUrlListenerInProgress: true,
	isNetworkConnected: true,
	platform: '',
	confirmModal: EMPTY_CONFIRM_MODAL,
	modal: MODAL_DATA,
	documentViewer: {
		isOpen: false,
		fileName: '',
		openAsUrl: false,
	},
};

const systemStore = createSlice({
	name: 'system',
	initialState: systemInitialState,
	reducers: {
		setAppLoading: setAppLoadingOperation,
		updatePlatform: updateUserPlatformOperation,
		updateIsAppUrlListenerInProgress: updateIsAppUrlListenerInProgressOperation,
		updateIsNetworkConnected: updateIsNetworkConnectedOperation,
		openConfirmModal: openConfirmModalOperation,
		closeConfirmModal: closeConfirmModalOperation,
		updateModalIsPresented: updateModalIsPresentedOperation,
		openModal: openModalOperation,
		closeModal: closeModalOperation,
		openDocumentViewer: openDocumentViewerOperation,
		closeDocumentViewer: closeDocumentViewerOperation,
		resetDocumentViewer: resetDocumentViewerOperation,
	},
});

export const {
	setAppLoading,
	updateIsAppUrlListenerInProgress,
	updatePlatform,
	updateIsNetworkConnected,
	closeConfirmModal,
	openConfirmModal,
	openModal,
	closeModal,
	openDocumentViewer,
	closeDocumentViewer,
	resetDocumentViewer,
	updateModalIsPresented,
} = systemStore.actions;

export default systemStore.reducer;
