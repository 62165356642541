import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ReactComponent as HomeSvg } from 'assets/icons/host-icon.svg';
import { ReactComponent as Water } from 'assets/icons/welcome/drop.svg';
import { ReactComponent as House } from 'assets/icons/welcome/house.svg';
import { ReactComponent as Thermometer } from 'assets/icons/welcome/thermometer.svg';
import { ReactComponent as Bin } from 'assets/icons/welcome/trash.svg';
import { PrivacyLink } from 'components/Auth';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

// eslint-disable-next-line react/jsx-key
const SERVICES_IMAGES_LIST: ReactNode[] = [<House />, <Water />, <Thermometer />, <Bin />];

export const StartScreen: FC = () => (
	<section className={styles.wrapper}>
		<div className={styles.header}>
			<div className={styles.header__img}>
				<HomeSvg />
			</div>
			<div className={styles.header__list}>
				{SERVICES_IMAGES_LIST.map((icon, index) => (
					<Box key={index} width="48px" height="48px">
						{icon}
					</Box>
				))}
			</div>
		</div>

		<div className={styles.description}>
			<Typography variant="h5" color="--color-neutral-white" textAlign="center">
				Єдина квитанція
			</Typography>

			<Typography variant="body1" color="--color-neutral-white" textAlign="center">
				Для оплати всіх комунальних платежів
			</Typography>

			<Link
				to={ROUTER_URL.AUTH_CHECK_PHONE}
				onClick={() =>
					FirebaseAnalytics.logEvent(
						FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.ONBOARDING,
						FIREBASE_EVENT_ANALYTICS_BUTTON.LOGIN
					)
				}
			>
				<CustomButton label="Увійти" color="secondary" />
			</Link>
		</div>

		<PrivacyLink
			color="var(--color-meutral-white)"
			textAlign="center"
			pageName={FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.ONBOARDING}
		/>
	</section>
);
