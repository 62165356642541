import { configureStore, Middleware } from '@reduxjs/toolkit';
import { ERROR_MESSAGE } from 'utils/enums';
import billStore from './bill/reducer';
import cardsStore from './cards/reducer';
import metersStore from './meters/reducer';
import radabankCardStore from './radabank-card/reducer';
import reportStore from './report/reducer';
import systemStore from './system/reducer';
import toastifyStore from './toastify/reducer';
import userStore from './user-service/reducer';

const ignoreAxiosSignalAbortMiddleware: Middleware = () => (next) => (action) => {
	// return if axios cancel all requests
	if (
		action.payload?.message === ERROR_MESSAGE.ABORT_MESSAGE ||
		action.payload?.message === ERROR_MESSAGE.RADABANK_SHOULD_LOGIN_IN
	) {
		// Cancel the action.
		return;
	}

	// Dispatch the action normally.
	return next(action);
};

const store = configureStore({
	reducer: {
		userStore,
		billStore,
		toastifyStore,
		systemStore,
		cardsStore,
		metersStore,
		reportStore,
		radabankCardStore,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(ignoreAxiosSignalAbortMiddleware),
});

// Global store type
export type StoreType = ReturnType<typeof store.getState>;

// Dispatch type
export type DispatchType = typeof store.dispatch;

export default store;
