// eslint-disable-next-line object-curly-newline
import {
	ChangeEvent,
	ChangeEventHandler,
	ClipboardEvent,
	FC,
	forwardRef,
	KeyboardEvent,
	SyntheticEvent,
	useEffect,
	useRef,
	useState,
} from 'react';
import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format';
import { TextField } from '@mui/material';

interface IBANInputProps extends Omit<NumberFormatBaseProps, 'size' | 'color'> {
	onChange: ChangeEventHandler<HTMLInputElement>;
}

const IBANInputDef: FC<IBANInputProps> = ({ onChange, ...props }) => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		if (inputRef.current && isFocused) {
			setTimeout(() => {
				if (inputRef.current && inputRef.current.value.length <= 2) {
					inputRef.current.selectionStart = inputRef.current.value.length;
				}
			});
		}
	}, [inputRef.current, isFocused]);

	const handleChangeValue = (value: string, event?: SyntheticEvent<HTMLInputElement>) => {
		const indexOfUA = value.indexOf('UA');

		const lastIndexOfUA = value.lastIndexOf('UA');

		const ibanDigits = value.replace(/\D/g, '');

		const inputValue = indexOfUA === lastIndexOfUA ? value : `UA${ibanDigits}`;
		onChange(
			Object.assign({} as ChangeEvent<HTMLInputElement>, event, {
				target: { name: props.name, value: inputValue.toLocaleUpperCase() },
			})
		);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (
			// eslint-disable-next-line max-len
			!/^(?:[0-9]|Backspace|Delete|Home|End|ArrowLeft|ArrowRight|Shift|CapsLock|Control|NumLock|Tab|Paste|Redo|Undo)$/i.test(
				event.key
			)
		) {
			event.preventDefault();
		}
	};

	const handlePaste = (event: ClipboardEvent) => {
		event.preventDefault();
		const payloadString =
			event.clipboardData &&
			event.clipboardData
				.getData('text/plain')
				.replace(/[^\dA-Za-z]+/g, '')
				.slice(0, 29);
		handleChangeValue(payloadString);
	};

	return (
		<NumberFormatBase
			{...props}
			type="text"
			inputRef={inputRef}
			onFocus={(event) => {
				setIsFocused(true);
				if (props.onFocus) {
					props.onFocus(event);
				}
			}}
			onBlur={(event) => {
				setIsFocused(false);
				if (props.onBlur) {
					props.onBlur(event);
				}
			}}
			format={(value) => {
				if (!value && !isFocused) return '';
				return `UA${value.slice(2).replace(/\s+/g, '').trim().toLocaleUpperCase()}`;
			}}
			removeFormatting={(value) => value.replace(/\s+/gi, '')}
			getCaretBoundary={(value) => {
				return Array(value.length + 1)
					.fill(0)
					.map(() => true);
			}}
			onValueChange={(values, { event }) => handleChangeValue(values.value, event)}
			customInput={TextField}
			onKeyDown={handleKeyDown}
			onPaste={handlePaste}
			inputProps={{
				maxLength: 29,
			}}
		/>
	);
};

export const IBANInput = forwardRef<HTMLInputElement, IBANInputProps>((props, ref) => (
	<IBANInputDef {...props} getInputRef={ref} />
));
