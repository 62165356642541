import { FC } from 'react';
import { useHistory } from 'react-router';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { ROUTER_URL } from 'utils/enums';
import styles from './index.module.scss';

interface SuccessPasswordUpdateBannerProps {
	onGoBack: () => void;
}

export const SuccessPasswordUpdateBanner: FC<SuccessPasswordUpdateBannerProps> = ({ onGoBack }) => {
	const history = useHistory();

	const handleGoHome = () => {
		history.replace(ROUTER_URL.PROFILE);
	};

	return (
		<div className="host-card-page-color-primary" id={styles.wrapper}>
			<ArrowBackButton onClick={onGoBack} />
			<RadabankOnboardingContent title="Пароль успішно змінено!" />
			<CustomButton label="На головну" color="secondary" onClick={handleGoHome} />
		</div>
	);
};
