import { FC } from 'react';
import { Form, Formik } from 'formik';
import { FormContentDateRow, RadabankOperationDetailInfo } from 'components/RadabankCard';
import { CustomButton } from 'components/shared';
import { IRadabankInternalAddRuleRequest } from 'utils/types';
import { radabankAddInternetRuleValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

interface RadabankCardInternetRuleFormProps {
	onSubmit: (values: IRadabankInternalAddRuleRequest) => void;
	initialValues: IRadabankInternalAddRuleRequest;
}

export const RadabankCardInternetRuleForm: FC<RadabankCardInternetRuleFormProps> = ({ onSubmit, initialValues }) => (
	<Formik
		enableReinitialize
		initialValues={initialValues}
		onSubmit={onSubmit}
		validateOnMount
		validationSchema={radabankAddInternetRuleValidationSchema}
	>
		{({ isValid }) => (
			<Form className={styles.form}>
				<FormContentDateRow />
				<RadabankOperationDetailInfo
					className={styles.info}
					// eslint-disable-next-line max-len
					description="Встановлюючи доступ на виконання платежів в інтернеті, ви можете розраховуватись цією карткою за товари та послуги в інтернеті"
				/>
				<CustomButton className={styles.form__button} label="Додати дозвіл" type="submit" disabled={!isValid} />
			</Form>
		)}
	</Formik>
);
