import { FC } from 'react';
import { IonModalCustomEvent } from '@ionic/core';
import { closeModal, getModalSelector, updateModalIsPresented, useAppDispatch, useAppSelector } from 'store';
import { CustomModal } from 'components/shared';

export const DefaultModal: FC = () => {
	const dispatch = useAppDispatch();

	const { isOpen, content, onDidPresent, ...props } = useAppSelector(getModalSelector);

	const handleDidPresent = (event: IonModalCustomEvent<void>) => {
		dispatch(updateModalIsPresented(true));
		if (onDidPresent) {
			onDidPresent(event);
		}
	};

	const handleDidDismiss = () => {
		dispatch(updateModalIsPresented(false));
	};

	return (
		<CustomModal
			{...props}
			isOpen={isOpen}
			onDidPresent={handleDidPresent}
			onDidDismiss={handleDidDismiss}
			onClose={() => dispatch(closeModal())}
		>
			{content}
		</CustomModal>
	);
};
