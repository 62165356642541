import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem, ReadFileResult } from '@capacitor/filesystem';
import axios from 'axios';

class FilesystemService {
	static extension = {
		pdf: 'pdf',
	};

	static contentType = {
		pdf: 'application/pdf',
	};

	static getBase64Prefix = (extension: keyof typeof this.extension): string => {
		return `data:${this.getContentType(extension)};base64,`;
	};

	static getBase64MimeType = (base64: string): string | null => {
		const mimeType = base64.split(';')[0].split(':')[1];
		return mimeType || null;
	};

	static getContentType = (extension: keyof typeof this.extension): string => {
		switch (extension) {
			case this.extension.pdf:
				return this.contentType.pdf;

			default:
				return '';
		}
	};

	static converBlobToBase64 = async (blob: Blob, extension: keyof typeof this.extension): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onload = () => resolve(`${this.getBase64Prefix(extension)}${(reader.result as string).split(',')[1]}`);
			reader.onerror = (error) => reject(error);
		});
	};

	static convertBase64ToBlob = (base64: string, contentType: string): Blob => {
		const base64Data = base64.includes('base64,') ? base64.split('base64,')[1] : base64;
		const binaryString = atob(base64Data);

		// Create a Uint8Array from the binary string
		const byteArray = new Uint8Array(binaryString.length);
		for (let i = 0; i < binaryString.length; i++) {
			byteArray[i] = binaryString.charCodeAt(i);
		}

		// Create a Blob from the Uint8Array
		const blob = new Blob([byteArray], { type: contentType });
		return blob;
	};

	static convertBase64ToFile = (base64: string): File => {
		const blob = this.convertBase64ToBlob(base64, this.getBase64MimeType(base64) ?? '');
		return new File([blob], Date.now().toString(), { type: blob.type });
	};

	static getFilePath = (name: string, extension: keyof typeof this.extension): string => `HOST/${name}.${extension}`;

	static writeFile = async (name: string, url: string, extension: keyof typeof this.extension): Promise<string> => {
		try {
			const result = await Filesystem.writeFile({
				path: this.getFilePath(name, extension),
				data: url,
				directory: Directory.Documents,
				recursive: true,
			});
			return result.uri;
		} catch (error) {
			throw new Error(error);
		}
	};

	static readFile = async (path: string, extension = this.extension.pdf): Promise<string> => {
		try {
			const result: ReadFileResult = await Filesystem.readFile({
				path,
				directory: Directory.Documents,
			});
			return `${this.getBase64Prefix(extension as keyof typeof this.extension)}${result.data}`;
		} catch (error) {
			throw new Error(error);
		}
	};

	static saveFile = async (name: string, blob: Blob, extension = this.extension.pdf): Promise<void | string> => {
		try {
			const base64: string = await this.converBlobToBase64(blob, extension as keyof typeof this.extension);
			// NATIVE
			if (Capacitor.isNativePlatform()) {
				await this.writeFile(name, base64, extension as keyof typeof this.extension);
				return this.getFilePath(name, extension as keyof typeof this.extension);
			}
			// BROWSER
			const downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);
			downloadLink.href = URL.createObjectURL(blob);
			downloadLink.download = `${name}.${extension}`;
			downloadLink.click();
			document.body.removeChild(downloadLink);
		} catch (error) {
			throw new Error(error);
		}
	};

	static saveWeb = async (name: string, url: string, extension = this.extension.pdf) => {
		const responsePDF = await axios.get(url, {
			responseType: 'blob',
		});
		const blob = new Blob([responsePDF.data], { type: this.getContentType(extension as keyof typeof this.extension) });
		const downloadLink = document.createElement('a');
		document.body.appendChild(downloadLink);
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = `${name}.${extension}`;
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	static convertPdfContentToArrayBuffer = (contentString: string): ArrayBuffer | null => {
		// Decode the Base64 string
		const binaryString = atob(contentString);

		// Get the length of the binary string
		const length = binaryString.length;

		// Create a new Uint8Array with the same length as the binary string
		const bytes = new Uint8Array(length);

		// Iterate over each character in the binary string and assign its ASCII value to the Uint8Array
		for (let i = 0; i < length; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}

		// Convert the Uint8Array to an ArrayBuffer
		return bytes.buffer;
	};
}

export { FilesystemService };
