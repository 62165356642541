import { FC } from 'react';
import classNames from 'classnames';
import { ReceiptPublicBanner, ReceiptPublicStatusContent } from 'components/ReceiptPublic';
import styles from './index.module.scss';

export const PaymentStatusPublicPage: FC = () => {
	return (
		<div className={classNames('page', styles.wrapper)}>
			<ReceiptPublicBanner className={styles.banner} />
			<ReceiptPublicStatusContent />
		</div>
	);
};
