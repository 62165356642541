import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import classNames from 'classnames';
import { getRadabankCurrentCardSelector, useAppSelector } from 'store';
import { useMeasureNonRenderedElement } from 'utils/hooks';
import { RadabankCardFlip } from '../CardFlip';
import { RadabankOperationsMenu } from '../OperationsMenu';
import styles from './index.module.scss';

interface RadabankCardWithOperationsProps {
	isPaymentAllowed: boolean;
	isFrozen: boolean;
	onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
}

const measureContainer = document.getElementById('measure-layer');

export const RadabankCardWithOperations: FC<RadabankCardWithOperationsProps> = ({
	isPaymentAllowed,
	isFrozen,
	onRefresh,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const { dimensions, elementRef } = useMeasureNonRenderedElement<HTMLDivElement>([cardData]);

	return (
		<>
			{measureContainer &&
				createPortal(
					<div
						className={classNames(styles.content, {
							[styles.content_blocked]: isFrozen,
						})}
						ref={elementRef}
					>
						<RadabankCardFlip />
						<RadabankOperationsMenu isDisabled={!isPaymentAllowed} />
					</div>,
					measureContainer
				)}
			{!!dimensions.height && (
				<IonContent style={{ minHeight: dimensions.height, height: '100%' }}>
					<IonRefresher pullMin={80} slot="fixed" onIonRefresh={onRefresh}>
						<IonRefresherContent />
					</IonRefresher>
					<div
						className={classNames(styles.content, {
							[styles.content_blocked]: isFrozen,
						})}
						ref={ref}
					>
						<RadabankCardFlip />
						<RadabankOperationsMenu isDisabled={!isPaymentAllowed} />
					</div>
				</IonContent>
			)}
		</>
	);
};
