import { FC } from 'react';
import { useHistory } from 'react-router';
import {
	createRadabankPayment,
	getRadabankCurrentCardSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankOperationDetailRow, RadabankSectionHeader } from 'components/RadabankCard';
import { RadabankOperationFeeDetails } from 'components/RadabankCard/OperationFeeDetails';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { convertCoinsToUAH, getAmountTitle, getErrorMessage } from 'utils/helpers';
import { useAndroidBackButton } from 'utils/hooks/use-close-on-back-button';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankCreatePaymentRequest, IRadabankFeeResponse } from 'utils/types';
import styles from './index.module.scss';

interface OperationDetailsProps {
	feeData: IRadabankFeeResponse;
	cardPaymentData: IRadabankCreatePaymentRequest;
	onClickBack: () => void;
}

export const OperationDetails: FC<OperationDetailsProps> = ({ feeData, cardPaymentData, onClickBack }) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const handleCreatePayment = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_CARD_DETAILS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE_PAYMENT
			);
			dispatch(setAppLoading(true));
			await dispatch(createRadabankPayment(cardPaymentData)).unwrap();
			history.push(ROUTER_URL.RADABANK_CARD_OPERATION_BANNER);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_CARD_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		onClickBack();
	};

	useAndroidBackButton(handleGoBack);

	return (
		<div className="host-card-page-color-white">
			<RadabankSectionHeader title="На інші картки або рахунки" onClickBack={handleGoBack} />
			<div className={styles.content}>
				{feeData.message1 && <RadabankOperationDetailRow title="Отримувач" description={feeData.message1} />}
				<RadabankOperationDetailRow
					title="Картка отримувача"
					description={cardPaymentData.card.replace(/(\d{4})(?=\d)/g, '$1 ')}
				/>
				{!!cardPaymentData.comment && (
					<RadabankOperationDetailRow title="Коментар" description={cardPaymentData.comment} />
				)}
				{cardData && (
					<>
						<RadabankOperationDetailRow
							title="Сума"
							description={getAmountTitle(convertCoinsToUAH(cardPaymentData.amount), cardData.curriso)}
						/>
						<RadabankOperationFeeDetails
							fee={feeData.clientfee}
							currency={cardData.curriso}
							isShowInfo={!!feeData.message2}
						/>
					</>
				)}
			</div>
			<CustomButton label="Сплатити" onClick={handleCreatePayment} />
		</div>
	);
};
