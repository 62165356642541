import { FC } from 'react';
import { useHistory } from 'react-router';
import { Camera } from '@capacitor/camera';
import { PrimaryBannerLayout } from 'layouts';
import { setAppLoading, showToast, useAppDispatch } from 'store';
import { FIREBASE_EVENT_ANALYTICS_BUTTON,FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ERROR_MESSAGE } from 'utils/enums';
import { FirebaseAnalytics } from 'utils/services';

export const CameraAccessBannerPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleExitPage = (isConfirm = false) => {
		if (!isConfirm) {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PERMISSIONS.CAMERA,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CLOSE
			);
		}
		history.goBack();
	};

	const handleRequestBiometryPermissions = async () => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PERMISSIONS.CAMERA,
				FIREBASE_EVENT_ANALYTICS_BUTTON.ALLOW
			);
			dispatch(setAppLoading(true));

			await Camera.requestPermissions();

			handleExitPage(true);
		} catch (error) {
			dispatch(showToast({ message: ERROR_MESSAGE.CAMERA_ACCESS_ERROR }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	return (
		<PrimaryBannerLayout
			title="Надайте доступ до камери"
			description="Доступ до камери потрібен для проходження перевірки, встановлення фото профілю та сканування QR-кодів квитанцій"
			onClick={handleRequestBiometryPermissions}
			onExit={handleExitPage}
			buttonLabel="Надати доступ"
		/>
	);
};
