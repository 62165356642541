import { KeyboardEvent } from 'react';

export const handleKeyDownServiceAmount = (event: KeyboardEvent<HTMLInputElement>, value: string | number) => {
	const target = (event as any).target;
	const { key } = event;
	const { selectionStart } = target;
	const nextSymbolCaretPos = selectionStart + 1;

	const setNextSymbolCaret = () => {
		target.setSelectionRange(nextSymbolCaretPos, nextSymbolCaretPos);
	};

	if (key === 'Backspace') {
		if ((!+value && selectionStart === 1) || selectionStart === 0) {
			event.preventDefault();
		}
	}

	if(key === '.' && !value.toString().length){
		event.preventDefault();
	}

	if (key === '0' && +value === 0) {
		event.preventDefault();
		if (nextSymbolCaretPos !== target.value.length) {
			setNextSymbolCaret();
		}
	} else if (selectionStart === 0 && !+value && key !== 'Backspace') {
		setNextSymbolCaret();
	}
};

export const handleKeyDownEmail = (event: KeyboardEvent): void => {
	if (event.key.replace(/[^\w\-.@]/g, '') === '') {
		event.preventDefault();
	}
};
