import { AxiosResponse } from 'axios';
import { USER_API } from 'core/api';
import { SESSION_DEFAULT_REQUEST_BODY } from 'utils/constants';
import { USER_API_URL } from 'utils/enums';
import { ISession, ISessionResponse } from 'utils/types';

class SessionsService {
	static getSession = async (): Promise<AxiosResponse<Pick<ISession, 'sessionId'>>> =>
		USER_API.post(USER_API_URL.SESSION_GET, SESSION_DEFAULT_REQUEST_BODY);

	static checkSession = async (): Promise<
		AxiosResponse<Required<Pick<ISessionResponse, 'isAuthorized' | 'result' | 'rights' | 'roles' | 'profile'>>>
	> => USER_API.post(USER_API_URL.SESSION_CHECK, SESSION_DEFAULT_REQUEST_BODY);
}

export { SessionsService };
