import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeConfirmModal } from 'store/system/reducer';
import { getConfirmModalSelector } from 'store/system/selectors';
import { CustomButton, CustomModal } from 'components/shared';
import { ActionModalContent } from 'components/shared/ActionModalContent';

export const ConfirmModal: FC = () => {
	const dispatch = useAppDispatch();

	const confirmModal = useAppSelector(getConfirmModalSelector);

	return (
		<CustomModal isOpen={confirmModal.isOpen} onClose={() => dispatch(closeConfirmModal())} isMobileView={false}>
			<>
				<ActionModalContent icon={confirmModal.icon} title={confirmModal.title} subTitle={confirmModal.subTitle} />
				<div className="modal-actions-column">
					<CustomButton
						label={confirmModal.submitButtonLabel}
						onClick={confirmModal.onSubmit}
						fill="outline"
						color={confirmModal.color}
					/>
					<CustomButton
						label={confirmModal.cancelButtonLabel}
						onClick={() => dispatch(closeConfirmModal())}
						color="danger"
					/>
				</div>
			</>
		</CustomModal>
	);
};
