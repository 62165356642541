import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRadabankCurrentCardSelector, getUserIdSelector } from 'store';
import { RADABANK_FEE_TYPE } from 'utils/enums';
import { convertCoinsToUAH } from 'utils/helpers';
import { useLoading } from 'utils/hooks';
import { RadabankService } from 'utils/services';
import { IRadabankInternalCurrentCard } from 'utils/types';
import { RadabankFeePreview } from '../FeePreview';

interface RadabankFeeOtherPreviewProps {
	feeType: RADABANK_FEE_TYPE;
}

export const RadabankFeeOtherPreview: FC<RadabankFeeOtherPreviewProps> = ({ feeType }) => {
	const userId = useSelector(getUserIdSelector);
	const currentCard = useSelector(getRadabankCurrentCardSelector);

	const { isLoading, handleStartLoading } = useLoading();

	const [fee, setFee] = useState(0);

	const handleGetFeeByType = async (feeType: RADABANK_FEE_TYPE, userId: string, card: IRadabankInternalCurrentCard) => {
		const { data } = await RadabankService.getFeeByType({
			currency: card.currnbu,
			typefee: feeType,
			userId,
			cardid: card.id,
		});

		setFee(convertCoinsToUAH(data.fee));
	};

	useEffect(() => {
		if (userId && currentCard?.id) {
			handleStartLoading(() => handleGetFeeByType(feeType, userId, currentCard));
		}
	}, [feeType, currentCard?.id, userId]);

	return <RadabankFeePreview isLoading={isLoading} amount={fee} currency={currentCard?.curriso ?? ''} />;
};
