import { FieldPath, FieldValues, useController, useFormContext } from 'react-hook-form';
import { getReactHookFormError } from 'utils/helpers';
import { ISelectOption } from 'utils/types';
import { CustomAutocomplete, CustomAutocompleteProps } from '../Autocomplete';

interface FormCustomAutocompleteProps<T extends FieldValues = FieldValues, S = string>
	extends CustomAutocompleteProps<S> {
	name: FieldPath<T>;
	options?: ISelectOption<S>[];
}

export const FormCustomAutocomplete = <T extends FieldValues, S>({
	name,
	...props
}: FormCustomAutocompleteProps<T, S>) => {
	const { control } = useFormContext<T>();

	const {
		field: { onBlur, ...field },
		fieldState: { isTouched, error: fieldError },
	} = useController<T>({ control, name });

	const { isErrorShown, error } = getReactHookFormError(isTouched, fieldError);

	const handleBlurField = () => {
		onBlur();
	};

	return (
		<CustomAutocomplete
			{...field}
			helperText={isErrorShown ? error : ''}
			error={isErrorShown}
			{...props}
			onFieldBlur={handleBlurField}
		/>
	);
};
