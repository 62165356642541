// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProviderService_wrapper__1Qgv2 {
  height: 100%;
}
.ProviderService_wrapper__1Qgv2 ion-button {
  margin-top: var(--spacing-4);
  position: sticky;
  bottom: 0;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/Receipt/AddPortmoneServiceModal/ProviderService/index.module.scss"],"names":[],"mappings":"AAGA;EACC,YAAA;AAFD;AAIC;EACC,4BAAA;EACA,gBAAA;EACA,SAAA;EACA,WAAA;AAFF","sourcesContent":["@import 'assets/styles/mixins';\n@import 'assets/styles/media.scss';\n\n.wrapper {\n\theight: 100%;\n\n\tion-button {\n\t\tmargin-top: var(--spacing-4);\n\t\tposition: sticky;\n\t\tbottom: 0;\n\t\tz-index: 10;\n\t}\n\n\t// @include mobile {\n\t// \t&_withKeyboard {\n\t// \t\tion-button {\n\t// \t\t\tposition: sticky;\n\t// \t\t\tbottom: 0;\n\t// \t\t\tz-index: 10;\n\t// \t\t}\n\t// \t}\n\t// }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ProviderService_wrapper__1Qgv2`
};
export default ___CSS_LOADER_EXPORT___;
