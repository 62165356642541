import { FC } from 'react';
import { Typography } from '@mui/material';
import { InfoAnchor } from 'components/shared/InfoAnchor';

export const RadabankCardOverdraftInfoModal: FC = () => {
	return (
		<InfoAnchor title="Сума">
			<Typography textAlign="justify">Сума, яку необхідно внести, щоб залишитись в пільговому періоді</Typography>
		</InfoAnchor>
	);
};
