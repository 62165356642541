import { PaymentNetwork, PaymentSummaryItem } from '@fresha/capacitor-plugin-applepay';

export const BASE_APPLE_PAY_PAYMENT_REQUEST = {
	countryCode: 'UA',
	currencyCode: 'UAH',
	supportedNetworks: ['visa', 'masterCard'] as PaymentNetwork[],
};

export const APPLE_PAY_PAYMENT_SUMMARY_ITEM: Omit<PaymentSummaryItem, 'amount'> = {
	label: 'Оплата послуг',
	type: 'final',
};
