// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppSettings_switch__yqW9D {
  width: 100%;
}
.AppSettings_switch__content__3fCoI > div:last-child {
  overflow: visible;
}
.AppSettings_switch__yqW9D ion-toggle {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Settings/AppSettings/index.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;AACC;EACC,iBAAA;AACF;AAEC;EACC,oBAAA;AAAF","sourcesContent":[".switch {\n\twidth: 100%;\n\n\t&__content > div:last-child {\n\t\toverflow: visible;\n\t}\n\n\tion-toggle {\n\t\tpointer-events: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `AppSettings_switch__yqW9D`,
	"switch__content": `AppSettings_switch__content__3fCoI`
};
export default ___CSS_LOADER_EXPORT___;
