import { RefObject, useEffect, useState } from 'react';

export const useScrollToTop = (elementRef: RefObject<HTMLElement>, minOffset = 200) => {
	const [isShowButton, setIsShowButton] = useState(false);

	useEffect(() => {
		const handleChangeButtonVisibility = () => {
			if (elementRef.current) {
				setIsShowButton(minOffset < elementRef.current.scrollTop);
			}
		};
		elementRef.current?.addEventListener('scroll', handleChangeButtonVisibility);

		return () => {
			elementRef.current?.removeEventListener('scroll', handleChangeButtonVisibility);
		};
	}, [elementRef]);

	const handleScrollTop = () => {
		elementRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

	return {
		isShowButton,
		handleScrollTop,
	};
};
