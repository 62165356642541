import { FC, MouseEvent, useState } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as CheckSVG } from 'assets/icons/success.svg';
import classNames from 'classnames';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { ApartmentMap } from 'components/Home/ApartmentsList/ApartmentCard/ApartmentMap';
import { ArrowBackButton, CustomButton, CustomModal } from 'components/shared';
import { getApartmentTitle } from 'utils/helpers';
import { useDevice } from "utils/hooks";
import { IDefaultModalProps, ReceiptApartment } from 'utils/types';
import styles from './index.module.scss';

interface SelectApartmentModalProps extends IDefaultModalProps {
	onSubmit: (apartmentAccountId: number) => void;
	apartmentAccountId: number;
}

export const SelectApartmentModal: FC<SelectApartmentModalProps> = ({
	onSubmit,
	onClose,
	apartmentAccountId,
	...props
}) => {
	const { isMobile } = useDevice()
	const apartments = useAppSelector(getApartmentsSelector);

	const [activeApartmentAccountId, setActiveApartmentAccountId] = useState(apartmentAccountId);

	const handleSelectApartment = (event: MouseEvent<HTMLDivElement>, apartmentAccountId: number): void => {
		event.preventDefault();
		setActiveApartmentAccountId(apartmentAccountId);
	};

	const handleSubmit = (): void => {
		onSubmit(activeApartmentAccountId);
		onClose();
	};

	return (
		<CustomModal {...props} className={styles.modal} isCloseHeader={false} onClose={onClose} isFullHeight>
			<div className={styles.content}>
				<ArrowBackButton onClick={onClose} />
				<div className={styles.list}>
					{apartments.list.map((item) => (
						<ApartmentMap
							key={item.apartmentAccountId}
							className={classNames(styles.list__item, {
								[styles.list__item_active]: item.apartmentAccountId === activeApartmentAccountId,
							})}
							data={item}
							onClick={(event: MouseEvent<HTMLDivElement>) => handleSelectApartment(event, item.apartmentAccountId)}
						>
							<div className={styles.apartment}>
								<div
									className={classNames(styles.apartment__icon, {
										[styles.apartment__icon_active]: item.apartmentAccountId === activeApartmentAccountId,
									})}
								>
									<CheckSVG />
								</div>
								<Typography variant="body2" color="var(--color-neutral-white)">
									{getApartmentTitle(
										apartments.list.find(
											(apartmentListItem) => apartmentListItem.apartmentAccountId === item.apartmentAccountId
										) as ReceiptApartment
									)}
								</Typography>
							</div>
						</ApartmentMap>
					))}
				</div>
			</div>
			<div className={classNames(styles.action, { [styles.action__mobile]: isMobile })}>
				<CustomButton onClick={handleSubmit} label="Застосувати" />
			</div>
		</CustomModal>
	);
};
