import { FC, useState } from 'react';
import FifthSlide from 'assets/images/welcome-slide-fifth.png';
import FirstSlide from 'assets/images/welcome-slide-first.png';
import FourthSlide from 'assets/images/welcome-slide-fourth.png';
import SecondSlide from 'assets/images/welcome-slide-second.png';
import ThirdSlide from 'assets/images/welcome-slide-third.png';
import classNames from 'classnames';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { FirebaseAnalytics } from 'utils/services';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './index.module.scss';

interface WelcomeSliderProps {
	onContinue: () => void;
}

const pagination = {
	bulletClass: styles.slider__bullet,
	bulletActiveClass: styles.slider__bullet_active,
	horizontalClass: styles.slider__horizontal,
	renderBullet(_index: number, className: string) {
		return `<span class="${className}"></span>`;
	},
};
const slidesList = [
	{
		text: 'Повертаємо	харків’янам	єдину	квитанцію!',
		src: FirstSlide,
	},
	{
		text: 'Платіть картками будь-яких банків!',
		src: SecondSlide,
	},
	{
		text: 'Легко додавайте адреси квартир та будинків!',
		src: ThirdSlide,
	},
	{
		text: 'Оплачуйте телефон, інтернет та інші послуги!',
		src: FourthSlide,
	},
	{
		text: 'Робимо Харків зручнішим для кожного!',
		src: FifthSlide,
	},
];

export const OnboardingSlider: FC<WelcomeSliderProps> = ({ onContinue }) => {
	const [activeSlide, setActiveSlide] = useState(0);
	const [swiper, setSwiper] = useState<SwiperClass | null>(null);

	const handleClickContinue = (): void => {
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.ONBOARDING, FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE);
		if (swiper) {
			if (activeSlide !== slidesList.length - 1) {
				swiper.slideTo(activeSlide + 1);
			} else {
				onContinue();
			}
		} else {
			onContinue();
		}
	};

	const handleClickSkip = (): void => {
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.ONBOARDING, FIREBASE_EVENT_ANALYTICS_BUTTON.SKIP);
		onContinue();
	};

	return (
		<div
			className={classNames(styles.wrapper, {
				[styles.wrapper_secondBg]: activeSlide === 1,
				[styles.wrapper_thirdBg]: activeSlide === 2 || activeSlide === 3,
				[styles.wrapper_fourthBg]: activeSlide === 4,
			})}
		>
			<Swiper
				pagination={pagination}
				modules={[Pagination, Autoplay]}
				className={styles.slider}
				onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
				onSwiper={(swiper) => setSwiper(swiper)}
			>
				{slidesList.map((item) => (
					<SwiperSlide className={styles.slider__slide} key={item.src}>
						<div className={styles.slider__image}>
							<img src={item.src} alt="slide" />
						</div>
						{/* <Typography marginTop="auto" fontSize="32px" fontWeight={800} color="var(--color-neutral-white)">
							{item.text}
						</Typography> */}
					</SwiperSlide>
				))}
			</Swiper>

			<div className={styles.actions}>
				<CustomButton
					className={styles.button_skip}
					fill="clear"
					label="Пропустити"
					onClick={handleClickSkip}
					color="secondary"
					mode="ios"
				/>
				<CustomButton label="Далі" onClick={handleClickContinue} color="secondary" />
			</div>
		</div>
	);
};
