import { FC, useState } from 'react';
import { MenuList } from '@mui/material';
import dayjs from 'dayjs';
import {
	getRadabankCardIdSelector,
	getUserIdSelector,
	openDocumentViewer,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { CustomMenuItem, CustomModal } from 'components/shared';
import { DATE_FORMAT, ERROR_MESSAGE } from 'utils/enums';
import { getErrorMessage, getRadabankDocumentUrl } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import { IDateRange } from 'utils/types';
import { DownloadStatementEmailForm } from '../DownloadStatementEmailForm';

interface RadabankDownloadStatementModalProps {
	isOpen: boolean;
	onClose: () => void;
	dateRange: IDateRange;
}

export const RadabankDownloadStatementModal: FC<RadabankDownloadStatementModalProps> = ({
	isOpen,
	onClose,
	dateRange,
}) => {
	const { datefinish, datestart } = dateRange;

	const dispatch = useAppDispatch();
	const cardid = useAppSelector(getRadabankCardIdSelector);
	const userId = useAppSelector(getUserIdSelector);

	const [isShowEmailInput, setIsShowEmailInput] = useState(false);

	const handleCloseModal = () => {
		onClose();
		setIsShowEmailInput(false);
	};

	const handleExportStatementAsPdf = async () => {
		if (!userId) return;

		try {
			dispatch(setAppLoading(true));
			const { data } = await RadabankService.printStatementDocData({
				datestart: dayjs(datestart).format(DATE_FORMAT.RADABANK_REQUEST),
				datefinish: dayjs(datefinish).format(DATE_FORMAT.RADABANK_REQUEST),
				cardid,
				userId,
			});
			if (data.name) {
				dispatch(
					openDocumentViewer({
						url: getRadabankDocumentUrl(data.name),
						fileName: data.name,
						openAsUrl: true,
						isShareEnabled: true,
					})
				);
			} else {
				throw new Error(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
			}
			handleCloseModal();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleOpenEmailForm = () => {
		setIsShowEmailInput(true);
	};

	const handleSendStatementToEmail = async (email: string) => {
		if (!userId) return;
		try {
			await RadabankService.printEmailStatementDocData({
				datestart: dayjs(datestart).format(DATE_FORMAT.RADABANK_REQUEST),
				datefinish: dayjs(datefinish).format(DATE_FORMAT.RADABANK_REQUEST),
				cardid,
				userId,
				emailrec: email,
			});
			handleCloseModal();
			dispatch(showToast({ message: 'Виписку було надіслано на вказану поштову скриньку', color: 'success' }));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return (
		<CustomModal title="Отримати виписку" isOpen={isOpen} onClose={handleCloseModal}>
			{isShowEmailInput ? (
				<DownloadStatementEmailForm onSubmit={handleSendStatementToEmail} />
			) : (
				<MenuList>
					<CustomMenuItem title="Завантажити документ (.PDF)" onClick={handleExportStatementAsPdf} />
					<CustomMenuItem title="Відправити документ на email" onClick={handleOpenEmailForm} />
				</MenuList>
			)}
		</CustomModal>
	);
};
