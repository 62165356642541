import { useEffect, useState } from 'react';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';

const useKeyboardMobile = () => {
	const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
	const [keyboardHeight, setKeyboardHeight] = useState(0);

	useEffect(() => {
		const keyboardOpenListener = Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
			setIsKeyboardOpen(true);
			setKeyboardHeight(info.keyboardHeight);
		});

		const keyboardCloseListener = Keyboard.addListener('keyboardWillHide', () => {
			setIsKeyboardOpen(false);
			setKeyboardHeight(0);
		});

		return () => {
			keyboardOpenListener.remove();
			keyboardCloseListener.remove();
		};
	}, []);

	return { isKeyboardOpen, keyboardHeight };
};

export default useKeyboardMobile;
