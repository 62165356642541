import { FC, HTMLAttributes, useEffect, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { ApplePay } from '@fresha/capacitor-plugin-applepay';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { APPLE_PAY_PAYMENT_SUMMARY_ITEM, BASE_APPLE_PAY_PAYMENT_REQUEST } from 'utils/constants';
import { ERROR_MESSAGE, PAY_TOKEN_TYPE } from 'utils/enums';
import { getApplePaySession, getErrorMessage } from 'utils/helpers';
import { IAppleSession } from 'utils/types';
import './index.scss';

interface ApplePayButtonProps extends Omit<HTMLAttributes<HTMLElement>, 'onSubmit'> {
	onSubmit: (token: string, payTokenType: PAY_TOKEN_TYPE) => Promise<void>;
	amount: string;
}

export const ApplePayButton: FC<ApplePayButtonProps> = ({ onSubmit, amount, ...props }) => {
	const dispatch = useAppDispatch();

	const applePayButtonRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (applePayButtonRef.current) {
			applePayButtonRef.current.onclick = handleClickApplePay;
		}
	}, [applePayButtonRef.current]);

	const handlePayWeb = async () => {
		if (window.ApplePaySession) {
			const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
				...BASE_APPLE_PAY_PAYMENT_REQUEST,
				merchantCapabilities: ['supports3DS'],
				total: {
					...APPLE_PAY_PAYMENT_SUMMARY_ITEM,
					amount,
				},
			};

			const applePaySession: ApplePaySession = new window.ApplePaySession(3, paymentRequest);

			applePaySession.oncancel = () => {
				throw new Error(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
			};

			applePaySession.onvalidatemerchant = async () => {
				try {
					const response: IAppleSession = await getApplePaySession();
					applePaySession.completeMerchantValidation(response);
				} catch (error) {
					throw new Error(getErrorMessage(error));
				}
			};

			applePaySession.onpaymentauthorized = async (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
				try {
					await onSubmit(btoa(JSON.stringify(event.payment)), PAY_TOKEN_TYPE.APPLE);
				} catch (error) {
					throw new Error(getErrorMessage(error));
				}
			};

			// Start the Apple Pay payment session
			applePaySession.begin();
		} else {
			dispatch(showToast({ message: 'not apple pay in window' }));
		}
	};

	const handlePayNative = async () => {
		try {
			const isCanMakePayments = await ApplePay.canMakePayments();
			if (isCanMakePayments.canMakePayments) {
				const initiatePaymentResponse = await ApplePay.initiatePayment({
					...BASE_APPLE_PAY_PAYMENT_REQUEST,
					merchantIdentifier: process.env.REACT_APP_APPLE_PAY_MERCHANT_ID as string,
					merchantCapabilities: ['capability3DS'],
					summaryItems: [
						{
							...APPLE_PAY_PAYMENT_SUMMARY_ITEM,
							amount,
						},
					],
				});

				const { paymentData = '' } = initiatePaymentResponse.token;

				if (paymentData) {
					const decodedPaymentData = JSON.parse(atob(paymentData));

					const dto = { paymentData: decodedPaymentData };

					await onSubmit(btoa(JSON.stringify(dto)), PAY_TOKEN_TYPE.APPLE);
					await ApplePay.completeLastPayment({ status: 'success' });
				}
			}
		} catch (error) {
			if (getErrorMessage(error) !== 'User dismissed the sheet with X button.') {
				throw error;
			}
		}
	};

	const handleClickApplePay = async () => {
		try {
			if (Capacitor.isNativePlatform()) {
				await handlePayNative();
			} else {
				await handlePayWeb();
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	if (!window.ApplePaySession && Capacitor.getPlatform() !== 'ios') return null;
	return (
		<apple-pay-button
			{...props}
			id="apple-pay-button"
			ref={applePayButtonRef}
			onClick={handleClickApplePay}
			buttonstyle="black"
			type="plain"
			locale="uk-UA"
		/>
	);
};
