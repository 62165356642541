import { ChangeEvent, FC, useEffect, useState } from 'react';
import { BaseTextFieldProps, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as ClearIcon } from 'assets/icons/close.svg';
import { ReactComponent as Magnifier } from 'assets/icons/magnifier.svg';
import { useDebounce } from 'utils/hooks';
import s from './styles.module.scss';

interface SearchInputProps extends BaseTextFieldProps {
	onChange: (value: string) => void;
}

export const SearchInput: FC<SearchInputProps> = ({ onChange, placeholder, ...rest }) => {
	const [value, setValue] = useState('');

	const debouncedValue = useDebounce(value, 700);

	useEffect(() => {
		onChange(debouncedValue);
	}, [debouncedValue]);

	const handleClearInput = () => {
		setValue('');
		onChange('');
	}

	return (
		<TextField
			{...rest}
			value={value}
			onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
			placeholder={placeholder || 'Пошук'}
			sx={{
				borderRadius: 'var(--spacing-2)',
				backgroundColor: 'var(--color-primary-50)',
				'& .MuiInputBase-root': {
					padding: '4px 16px',
					height: 'auto',
					'& input': {
						padding: '4px 12px',
						'::placeholder': {
							fontWeight: 400,
							fontSize: '18px',
						},
					},
					'& fieldset': {
						borderRadius: '8px',
						border: 'none',
						top: '0px',
					},
				},
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Magnifier />
					</InputAdornment>
				),
				endAdornment: value && (
					<InputAdornment onClick={handleClearInput} className={s.clear} position="end">
						<ClearIcon />
					</InputAdornment>
				),
			}}
		/>
	);
};
