import { FC } from 'react';
import { RestorePasswordProps } from 'pages/Auth/RestorePassword';
import { ConfirmPasswordWeb, SetPasswordWeb } from 'components/Auth';
import { FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { SETUP_PASSWORD_STEP } from 'utils/enums';

interface RestorePasswordWebProps extends RestorePasswordProps {
	onClickBack: () => void;
}

export const RestorePasswordWeb: FC<RestorePasswordWebProps> = ({
	passwordData,
	step,
	onChangeStep,
	onClickBack,
	isLoading,
	onSubmit,
	onChangePassword,
}) => {
	return step === SETUP_PASSWORD_STEP.SET ? (
		<SetPasswordWeb
			label="Встановіть PIN код"
			description="Додайте PIN код для захисту свого облікового запису"
			value={passwordData.password}
			onChange={onChangePassword}
			onSubmit={() => onChangeStep(SETUP_PASSWORD_STEP.CONFIRM)}
			isButtonBackVisible={false}
			pageName={FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.PASSWORD_RESTORE_VERIFY}
		/>
	) : (
		<ConfirmPasswordWeb
			onClickBack={onClickBack}
			value={passwordData.confirmPassword}
			onChange={onChangePassword}
			onSubmit={onSubmit}
			password={passwordData.password}
			isLoading={isLoading}
		/>
	);
};
