import { FC } from 'react';
import { Capacitor } from '@capacitor/core';
import { setupIonicReact } from '@ionic/react';
import { NativeRouter, WebRouter } from 'router';
import { getAppLoadingSelector, useAppSelector } from 'store';
import { ConfirmModal } from 'components/ConfirmModal';
import { DefaultModal } from 'components/DefaultModal';
import { DocumentViewer, Loader } from 'components/shared';
import { useInitialize, useToast } from 'utils/hooks';
import './main.scss';
import 'assets/fonts/fonts.css';

setupIonicReact();

const App: FC = () => {
	const isLoading = useAppSelector(getAppLoadingSelector);
	const toasts = useToast();

	useInitialize();

	return (
		<>
			{Capacitor.isNativePlatform() ? <NativeRouter /> : <WebRouter />}
			<ConfirmModal />
			<DefaultModal />
			<DocumentViewer />
			{toasts}
			{isLoading && <Loader />}
		</>
	);
};

export default App;
