import { FC } from 'react';
import { Typography } from '@mui/material';
import HostSVG from 'assets/icons/host-icon.svg';
import classNames from 'classnames';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { IServicesItemResponse } from 'utils/types';
import styles from './index.module.scss';

interface ServiceCardProps {
	data: IServicesItemResponse;
	onClick: () => void;
	isDisabled?: boolean;
}

export const ServiceCard: FC<ServiceCardProps> = ({ data, onClick, isDisabled }) => {
	const dispatch = useAppDispatch();

	const handleClick = () => {
		if (isDisabled) {
			dispatch(showToast({ message: 'Ця послуга вже додана' }));
		} else {
			onClick();
		}
	};

	return (
		<div
			className={classNames('service-card', styles.content, {
				[styles.content_disabled]: isDisabled,
			})}
			onClick={handleClick}
		>
			<img src={data.imageUrl || HostSVG} className={styles.content__icon} alt="service icon" />
			<Typography variant="body2" className={styles.content__title}>
				{data?.title || data.name}
			</Typography>
		</div>
	);
};
