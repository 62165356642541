import { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as CalendarBlank } from 'assets/icons/calendar-blank.svg';
import { ReactComponent as ClipboardText } from 'assets/icons/clipboard-text.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { AxiosResponse } from 'axios';
import { removeApartment } from 'store/bill/actions';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetMetersReducer } from 'store/meters/reducer';
import { getMetersStateSelector } from 'store/meters/selectors';
import { closeConfirmModal, openConfirmModal, openDocumentViewer, setAppLoading } from 'store/system/reducer';
import { getDocumentViewerSelector } from 'store/system/selectors';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { CustomMenuItem } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import {
	encryptData,
	getErrorMessage,
	getErrorResponse,
	getReceiptBillsListRoute,
	getReportDownloadFileName,
} from 'utils/helpers';
import { FirebaseAnalytics, ReportService } from 'utils/services';
import { IReceiptResponse, IReportSingleReceiptResponse, IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

interface ReceiptMenuListProps {
	onClick?: () => void;
	onClickChangePeriod?: (event: MouseEvent<HTMLLIElement>) => void;
	apartmentAccountId: string | number;
}

export const ReceiptMenuList: FC<ReceiptMenuListProps> = ({ onClick, onClickChangePeriod, apartmentAccountId }) => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const profile = useAppSelector(getUserProfileSelector);
	const receipt = useAppSelector(getBillReceiptSelector);
	const meters = useAppSelector(getMetersStateSelector);
	const documentViewer = useAppSelector(getDocumentViewerSelector);

	const downloadReceipt = async (): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.DOWNLOAD
			);
			if (documentViewer.url && documentViewer.fileName) {
				dispatch(openDocumentViewer());
			} else {
				const response: AxiosResponse<IReportSingleReceiptResponse> = await ReportService.getSingleReceipt({
					apartmentAccountId: +apartmentAccountId,
					year: receipt.data?.year,
					month: receipt.data?.month,
				});
				dispatch(
					openDocumentViewer({
						url: response.data.s3URL,
						isShareEnabled: true,
						fileName: getReportDownloadFileName(receipt.data as IReceiptResponse),
					})
				);
			}
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			dispatch(
				showToast({
					message: errorResponse?.statusCode === 400 ? 'Єдину квитанцію не знайдено' : getErrorMessage(error),
				})
			);
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleDeleteApartment = async (): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.SR_DELETE_ADRESS
			);
			await dispatch(
				removeApartment({
					apartmentAccountId: +apartmentAccountId,
					userId: (profile as IUserProfileResponse).userId,
				})
			).unwrap();
			if (meters.apartmentAccountId === +apartmentAccountId) {
				dispatch(resetMetersReducer());
			}
			history.replace(ROUTER_URL.PROFILE);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(closeConfirmModal());
			dispatch(setAppLoading(false));
		}
	};

	const handleClickChangePeriod = (event: MouseEvent<HTMLLIElement>): void => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.CHANGE_PERIOD
		);
		if (onClick && onClickChangePeriod) {
			onClick();
		}
		if (onClickChangePeriod) {
			onClickChangePeriod(event);
		}
	};

	const openRemoveModal = (event: MouseEvent<HTMLLIElement>): void => {
		event.stopPropagation();
		dispatch(
			openConfirmModal({
				title: 'Видалити адресу',
				subTitle: 'Ви впевнені, що бажаєте видалити адресу зі збережених?',
				icon: <Trash />,
				submitButtonLabel: 'Так, видалити',
				onSubmit: handleDeleteApartment,
			})
		);
	};

	const handleClickGetBillsList = (event: MouseEvent<HTMLLIElement>): void => {
		event.stopPropagation();
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.SR_CHECK_PAYMENT_ARCHIVE
		);
		history.push(getReceiptBillsListRoute(encryptData(apartmentAccountId.toString())));
	};

	return (
		<>
			{!!onClickChangePeriod && (
				<CustomMenuItem
					className={styles.menu__item}
					icon={<CalendarBlank />}
					title="Змінити період"
					onClick={handleClickChangePeriod}
				/>
			)}
			<CustomMenuItem
				className={styles.menu__item}
				icon={<ClipboardText />}
				title="Архів платежів"
				onClick={handleClickGetBillsList}
			/>
			{!!receipt.data && (
				<CustomMenuItem
					className={styles.menu__item}
					icon={<Download />}
					title="Завантажити ЄК"
					onClick={downloadReceipt}
				/>
			)}
			<CustomMenuItem icon={<Trash />} title="Видалити адресу" onClick={openRemoveModal} />
		</>
	);
};
