import { ChangeEvent, FC, useEffect, useLayoutEffect, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material';
import { ReactComponent as Clear } from 'assets/icons/clear.svg';
import { useDebounce, useVariableVirtualList } from 'utils/hooks';
import { useAutofocus } from 'utils/hooks/use-autofocus';
import { ISelectOption } from 'utils/types';
import { SearchModalOption } from './Option';
import styles from './index.module.scss';

interface SearchModalContentProps<S> {
	options: ISelectOption<S>[];
	onChangeInput?: (value: string) => void;
	onSelect: (value: S) => void;
	helperText?: string;
	isLoading?: boolean;
	isOpenModal?: boolean;
	placeholder?: string;
	searchValue?: string;
}

export const SearchModalContent: FC<SearchModalContentProps<any>> = ({
	onChangeInput,
	onSelect,
	options,
	isLoading,
	helperText,
	isOpenModal,
	searchValue = '',
	placeholder = 'Пошук',
	...rest
}) => {
	const inputRef = useAutofocus(isOpenModal);

	const { listRef, handleGetElementSize, handleUpdateSizeMap } = useVariableVirtualList(80);

	const [inputValue, setInputValue] = useState(searchValue);

	const debouncedValue = useDebounce(inputValue);

	useLayoutEffect(() => {
		if (inputValue && !options.find((item) => item.label.toLowerCase() === inputValue.toLowerCase()) && onChangeInput) {
			onChangeInput(inputValue);
		}
	}, [debouncedValue]);

	useEffect(() => {
		setInputValue(searchValue);
		inputRef.current?.focus();
	}, [searchValue]);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);

		if (!event.target.value) {
			handleClearInput();
		}
	};

	const handleSelectItem = (option: ISelectOption<any>) => {
		onSelect(option.value);
		setInputValue('');
	};

	const handleClearInput = () => {
		setInputValue('');
		inputRef.current?.focus();
	};

	return (
		<div className={styles.wrapper}>
			{!!onChangeInput && (
				<div className={styles.header}>
					<TextField
						{...rest}
						inputRef={inputRef}
						type="text"
						size="small"
						value={inputValue}
						placeholder={placeholder}
						onChange={handleInputChange}
						helperText={helperText || ''}
						InputProps={{
							endAdornment: isLoading ? (
								<InputAdornment position="end">
									<CircularProgress color="inherit" size={20} />
								</InputAdornment>
							) : (
								inputValue && <Clear onClick={handleClearInput} />
							),
						}}
						sx={{
							'& .MuiFormHelperText-root': {
								position: 'absolute',
								bottom: '-20px',
							},
						}}
					/>
				</div>
			)}

			<AutoSizer className={styles.autosizer}>
				{({ width, height }) => (
					<VariableSizeList
						ref={listRef}
						itemSize={handleGetElementSize}
						itemCount={options.length}
						width={width}
						height={height}
					>
						{({ index, style }) => (
							<SearchModalOption
								onUpdateSizeMap={handleUpdateSizeMap}
								onClick={handleSelectItem}
								index={index}
								style={style}
								item={options[index]}
							/>
						)}
					</VariableSizeList>
				)}
			</AutoSizer>

			{!options.length && !isLoading && !!debouncedValue && !!inputValue && (
				<MenuItem className={styles.empty_state} disableRipple>
					Результатів не знайдено
				</MenuItem>
			)}
		</div>
	);
};
