import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { RestorePasswordNative } from 'pages/Auth/RestorePassword/RestorePasswordNative';
import { RestorePasswordWeb } from 'pages/Auth/RestorePassword/RestorePasswordWeb';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { userLogin } from 'store/user-service/actions';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROLE, ROUTER_URL, SETUP_PASSWORD_STEP, STORAGE_KEY } from 'utils/enums';
import { checkDeviceIsWeb, encryptData, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, StorageService, UserService } from 'utils/services';

export interface IPasswordData {
	password: string;
	confirmPassword: string;
}

export interface RestorePasswordProps {
	passwordData: IPasswordData;
	step: SETUP_PASSWORD_STEP;
	onChangeStep: (step: SETUP_PASSWORD_STEP) => void;
	onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
	onSubmit: () => Promise<void>;
	isLoading: boolean;
}

export const RestorePasswordPage: FC = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const [step, setStep] = useState(SETUP_PASSWORD_STEP.SET);
	const [passwordData, setPasswordData] = useState<IPasswordData>({
		password: '',
		confirmPassword: '',
	});
	const [phone, setPhone] = useState('');
	const [code, setCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		StorageService.get<string>(STORAGE_KEY.VERIFICATION_CODE).then((value: string | null) => {
			if (value) {
				setCode(value);
			} else {
				history.push(ROUTER_URL.AUTH_RESTORE_VERIFY);
			}
		});

		StorageService.get<string>(STORAGE_KEY.PHONE).then((value: string | null) => {
			if (value) {
				setPhone(value);
			}
		});
	}, []);

	const onSubmitRestorePassword = async () => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.PASSWORD_RESTORE,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONFIRM_PASSWORD
			);
			setIsLoading(true);
			await UserService.restorePassword({
				newPassword: passwordData.password,
				code,
			});
			StorageService.remove(STORAGE_KEY.VERIFICATION_CODE);
			if (Capacitor.isNativePlatform()) {
				await StorageService.set(STORAGE_KEY.USER_PASSWORD, encryptData(passwordData.password));
			}
			const resData = await dispatch(userLogin({ phone, password: passwordData.confirmPassword })).unwrap();
			if (resData.roles?.some((item) => item.name === ROLE.PUMB)) {
				history.replace(ROUTER_URL.SERVICES_CATEGORIES);
			} else {
				history.push(ROUTER_URL.PROFILE);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		if (!/^[0-9]+$|^$/.test(event.target.value)) return;
		setPasswordData({ ...passwordData, [event.target.name]: event.target.value });
	};

	const handleClickBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.PASSWORD_RESTORE,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		setStep(SETUP_PASSWORD_STEP.SET);
		setPasswordData({ ...passwordData, confirmPassword: '' });
	};

	const handleChangeStep = (step: SETUP_PASSWORD_STEP): void => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.PASSWORD_RESTORE,
			FIREBASE_EVENT_ANALYTICS_BUTTON.SET_PASSWORD
		);
		setStep(step);
	};

	return checkDeviceIsWeb() ? (
		<RestorePasswordWeb
			step={step}
			onChangeStep={handleChangeStep}
			passwordData={passwordData}
			onSubmit={onSubmitRestorePassword}
			onChangePassword={handleChangePassword}
			isLoading={isLoading}
			onClickBack={handleClickBack}
		/>
	) : (
		<RestorePasswordNative
			step={step}
			onChangeStep={handleChangeStep}
			onChangePassword={handleChangePassword}
			passwordData={passwordData}
			onSubmit={onSubmitRestorePassword}
			isLoading={isLoading}
		/>
	);
};
