// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Progress_modal__xFske {
  text-align: justify;
}
.Progress_modal__xFske .Progress_button__0nuDe {
  margin: var(--spacing-4) 0;
}
.Progress_modal__xFske .Progress_list__W-zgz {
  list-style-type: "- ";
  padding-left: var(--spacing-3);
}`, "",{"version":3,"sources":["webpack://./src/components/RadabankCard/OperationCard/Progress/index.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACI,0BAAA;AACR;AAEI;EACI,qBAAA;EACA,8BAAA;AAAR","sourcesContent":[".modal {\n    text-align: justify;\n    \n    .button {\n        margin: var(--spacing-4) 0;\n    }\n\n    .list {\n        list-style-type: '- ';\n        padding-left: var(--spacing-3);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Progress_modal__xFske`,
	"button": `Progress_button__0nuDe`,
	"list": `Progress_list__W-zgz`
};
export default ___CSS_LOADER_EXPORT___;
