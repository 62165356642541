import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { getIsPossibleApartmentsStepSelector } from 'store';
import { Header } from 'components/Header';
import { ROUTER_URL } from 'utils/enums';
import styles from './index.module.scss';

interface AuthLayoutProps {
	children: ReactNode;
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
	const { pathname } = useLocation();
	const isApartmentListStep = useSelector(getIsPossibleApartmentsStepSelector);

	return (
		<div className={classNames('layout', styles.wrapper)}>
			<Header isAuth />
			<div
				className={classNames('page', 'auth-content', styles.content, {
					[styles.content_webLogin]: !Capacitor.isNativePlatform() && pathname === ROUTER_URL.AUTH_LOGIN,
					[styles['content-lg']]: isApartmentListStep,
				})}
			>
				{children}
			</div>
		</div>
	);
};
