import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { getAppLoadingSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { Loader, SearchInput } from 'components/shared';
import { getContacts, getErrorMessage, prepareCompareLocaleString } from 'utils/helpers';
import { IContacts } from 'utils/types';
import { ContactItem } from './ContactItem';
import styles from './index.module.scss';

interface RadabankSmsInfoPhoneAddProps {
	onAddPhone: (phone: string) => Promise<void>;
}

export const RadabankSmsInfoPhoneAdd: FC<RadabankSmsInfoPhoneAddProps> = ({ onAddPhone }) => {
	const dispatch = useAppDispatch();

	const isAppLoading = useAppSelector(getAppLoadingSelector);

	const [isLoading, setIsLoading] = useState(false);
	const [contacts, setContacts] = useState<IContacts[]>([]);
	const [filteredContacts, setFilteredContacts] = useState<IContacts[]>([]);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		getContactsList();
	}, []);

	useEffect(() => {
		let payload = [...contacts];
		if (searchValue) {
			payload = contacts.reduce((acc, item) => {
				const filteredList = item.list.filter((contact) => {
					const contactName = prepareCompareLocaleString(contact.name);
					const contactPhone = prepareCompareLocaleString(contact.phone);
					const convertedSearchValue = prepareCompareLocaleString(searchValue);
					return contactName.includes(convertedSearchValue) || contactPhone.includes(convertedSearchValue);
				});
				return filteredList.length ? [...acc, { ...item, list: filteredList }] : acc;
			}, [] as IContacts[]);
		}
		setFilteredContacts(payload);
	}, [searchValue, contacts.length]);

	const getContactsList = async () => {
		try {
			setIsLoading(true);
			const contactsPayload = await getContacts();
			setContacts(contactsPayload);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div className={styles.content}>
				<SearchInput
					size="small"
					value={searchValue}
					onChange={(value) => setSearchValue(value)}
					placeholder="Номер телефону або ім'я"
				/>
				<ul className={styles.list}>
					{filteredContacts.map((item) => (
						<li key={item.firstChar} className={styles.contacts}>
							<Typography color="var(--color-neutral-300)">{item.firstChar}</Typography>
							<ul className={styles.contacts}>
								{item.list.map((contact) => (
									<ContactItem key={contact.phone} onAddPhone={onAddPhone} data={contact} />
								))}
							</ul>
						</li>
					))}
				</ul>
			</div>
			{isLoading && !isAppLoading && <Loader />}
		</>
	);
};
