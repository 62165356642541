import { FC } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { getRadabankCurrentCardSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { RADABANK_BOOLEAN_VALUE } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { RadabankCardAccountDetailsRow } from './AccountDetailsRow';
import styles from './index.module.scss';

export const RadabankCardAccountDetails: FC = () => {
	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);

	const handleClickDownload = async () => {
		if (!currentCard) {
			return;
		}

		try {
			// eslint-disable-next-line max-len
			const data = `Найменування банку: ${currentCard.namefirm}\nМФО банку: ${currentCard.mfofirm}\nОтримувач: ${currentCard.cardholder}\nIBAN: ${currentCard.iban}\nРНОКПП/ЄДРПОУ: ${currentCard.inn}`;
			const isCanShare = await Share.canShare();

			if (!isCanShare.value) {
				await Clipboard.write({ string: data });
				dispatch(
					showToast({ message: "Ваш девайс не підтримує функцію 'Поділитись'. Реквізити було скопійовано до буферу" })
				);
				return;
			}

			await Share.share({
				text: data,
			});
		} catch (error) {
			const message = getErrorMessage(error);
			if (message === 'Share canceled') return;
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return (
		!!currentCard && (
			<div className={styles.content}>
				{currentCard.add_rekv === RADABANK_BOOLEAN_VALUE.TRUE && (
					<div className={styles.content__download}>
						<Typography variant="body2" color="var(--color-primary-500)">
							Поточний рахунок
						</Typography>
						<IconButton onClick={handleClickDownload}>
							<ShareIcon />
						</IconButton>
					</div>
				)}
				<RadabankCardAccountDetailsRow title="Найменування банку" value={currentCard.namefirm} />
				<RadabankCardAccountDetailsRow title="МФО банку" value={currentCard.mfofirm} />
				<RadabankCardAccountDetailsRow title="Отримувач" value={currentCard.cardholder} />
				<RadabankCardAccountDetailsRow title="IBAN" value={currentCard.iban} />
				<RadabankCardAccountDetailsRow title="РНОКПП/ЄДРПОУ" value={currentCard.inn} />
			</div>
		)
	);
};
