import { FC } from 'react';
import { Browser } from '@capacitor/browser';
import { MenuList } from '@mui/material';
import { getRadabankCurrentCardOverdraftDataSelector, showToast, useAppDispatch, useAppSelector } from 'store';
import { FormRadabankCardOverdarftAmountInput } from 'components/RadabankCard/CardOverdraft/CardOverdraftAmountInput/FormInput';
import { RadabankDocumentViewButton } from 'components/RadabankCard/DocumentViewButton';
import { RadabankFormCheckbox } from 'components/RadabankCard/FormCheckbox';
import { getErrorMessage } from 'utils/helpers';
import styles from './index.module.scss';

export const OverdraftCreateInitialStep: FC = () => {
	const dispatch = useAppDispatch();
	const overdraftData = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);

	const handleClickCreditLimitPassport = async (): Promise<void> => {
		try {
			await Browser.open({ url: process.env.REACT_APP_RADABANK_PASSPORT_OF_CREDIT_LIMIT_URL as string });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickCreditLimitTerms = async (): Promise<void> => {
		try {
			await Browser.open({ url: process.env.REACT_APP_RADABANK_CREDIT_LIMIT_TERMS_URL as string });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	return (
		<>
			{!!overdraftData.data && (
				<FormRadabankCardOverdarftAmountInput
					name="amount"
					min={+overdraftData.data.overamountmin}
					max={+overdraftData.data.overamountmax}
				/>
			)}
			<MenuList className={styles.content__documents}>
				<RadabankDocumentViewButton onClick={handleClickCreditLimitPassport} label="Паспорт кредитного ліміту" />
				<RadabankDocumentViewButton onClick={handleClickCreditLimitTerms} label="Умови продукту" />
			</MenuList>
			<RadabankFormCheckbox
				name="confirmSetLimit"
				className={styles.content__submission}
				// eslint-disable-next-line max-len
				label="Натискаючи кнопки «Далі» та «Встановити», я прошу встановити ліміт овердрафту згідно з умовами ДКБО ФО та відповідно до встановлених Банком правил. Підтверджую, що ознайомлений з паспортом споживчого кредиту та умовами, що є невід’ємною частиною договору"
			/>
		</>
	);
};
