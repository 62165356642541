import { FC } from 'react';
import { IonButton } from '@ionic/react';
import { ReactComponent as Backspace } from 'assets/icons/backspace.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

interface NumKeyboardPropsType {
	onButtonClick: (num: number) => void;
	color?: 'primary' | 'secondary';
}

export const NumKeyboard: FC<NumKeyboardPropsType> = ({ onButtonClick, color = 'secondary' }) => {
	const numClickHandler = (num: number) => {
		onButtonClick(num);
	};

	return (
		<div
			className={classNames(styles.wrapper, {
				[styles.color_primary]: color === 'primary',
			})}
		>
			{Array(9)
				.fill(1)
				.map((item, index) => {
					const value = item + index;
					return (
						<IonButton key={value} onClick={() => numClickHandler(value)}>
							{value}
						</IonButton>
					);
				})}
			<div />
			<IonButton onClick={() => numClickHandler(0)}>0</IonButton>
			<IonButton onClick={() => numClickHandler(-1)}>
				<Backspace className={styles.color_primary} />
			</IonButton>
		</div>
	);
};
