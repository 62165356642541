import { ChangeEvent, FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { deleteUserAvatar, updateUserAvatar } from 'store/user-service/actions';
import { getUserAvatarSelector, getUserReducerSelector } from 'store/user-service/selectors';
import { CustomModal, Loader } from 'components/shared';
import { ERROR_MESSAGE, ROUTER_URL } from 'utils/enums';
import { openCamera } from 'utils/helpers';
import { useLoading } from 'utils/hooks';
import { FilesystemService } from 'utils/services';
import { AvatarNativeContent } from './AvatarNativeContent';
import { AvatarWebContent } from './AvatarWebContent';
import styles from './index.module.scss';

interface AvatarUpdateModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AvatarUpdateModal: FC<AvatarUpdateModalProps> = ({ isOpen, onClose }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const userReducer = useAppSelector(getUserReducerSelector);
	const userAvatar = useAppSelector(getUserAvatarSelector);

	const { isLoading, handleStartLoading } = useLoading();

	const handleEditPhotoMobile = async (typePhoto: CameraSource) => {
		const permissions = await Camera.checkPermissions();

		if (!['granted', 'denied', 'limited'].includes(permissions.camera)) {
			history.push(ROUTER_URL.CAMERA_ACCESS_BANNER);
			return;
		}

		const photo = await openCamera(typePhoto, CameraResultType.DataUrl);
		if (photo && photo.dataUrl) {
			const file = FilesystemService.convertBase64ToFile(photo.dataUrl);
			await handleStartLoading(async () => handleUpdateAvatar(file), onClose);
		}
	};

	const handleEditPhotoWeb = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		handleStartLoading(async () => handleUpdateAvatar(file), onClose);
	};

	const handleUpdateAvatar = async (file?: File) => {
		try {
			if (file && userReducer.profile) {
				await dispatch(updateUserAvatar({ userId: userReducer.profile.userId, file })).unwrap();
				dispatch(showToast({ message: 'Фото змінено успішно', color: 'success' }));
			}
		} catch (error) {
			throw new Error(ERROR_MESSAGE.UPLOAD_AVATAR);
		}
	};

	const handleDeletePhoto = async () => {
		await handleStartLoading(deleteUserPhoto, onClose);
	};

	const deleteUserPhoto = async () => {
		if (userAvatar && userReducer.profile) {
			const userUrl = userAvatar.split('/');
			const fileName = userUrl[userUrl.length - 1];
			await dispatch(deleteUserAvatar({ userId: userReducer.profile.userId, fileName })).unwrap();
			dispatch(showToast({ message: 'Фото успішно видалено', color: 'success' }));
		}
	};

	const content = useMemo(() => {
		if (Capacitor.isNativePlatform()) {
			return (
				<AvatarNativeContent
					isUserAvatar={!!userAvatar}
					handleEditPhotoMobile={handleEditPhotoMobile}
					handleDeletePhoto={handleDeletePhoto}
				/>
			);
		}

		return (
			<AvatarWebContent
				isUserAvatar={!!userAvatar}
				onClose={onClose}
				handleEditPhotoWeb={handleEditPhotoWeb}
				handleDeletePhoto={handleDeletePhoto}
			/>
		);
	}, [userAvatar]);

	return (
		<>
			{isLoading && <Loader />}
			<CustomModal
				title="Змінити фото профілю"
				isOpen={isOpen}
				onClose={onClose}
				classNameHeader={classNames(!Capacitor.isNativePlatform() && styles.modal__header)}
			>
				{content}
			</CustomModal>
		</>
	);
};
