import { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import { AutocompleteChangeReason, AutocompleteInputChangeReason, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { ValidationError } from 'yup';
import { CustomModal } from 'components/shared';
import {
	APARTMENT_ADDRESS_INITIAL_OPTIONS,
	APARTMENT_ADDRESS_TOUCHED,
	APARTMENT_ADDRESS_VALIDATION_ERRORS,
	APARTMENT_FIELD_CONFIG,
} from 'utils/constants';
import { ADD_ADDRESS_FIELD_NAME } from 'utils/enums';
import { getErrorMessage, sortApartmentArrayStringResponse } from 'utils/helpers';
import { useDebounce, useDevice, useOpen } from 'utils/hooks';
import { StreetsService } from 'utils/services';
import { IAddApartmentRequest, IStreetsHousesRequest } from 'utils/types';
import { createApartmentValidationSchema, createApartmentWithFlatValidationSchema } from 'utils/validation';
import { AddressAutocomplete } from './AddressAutocomplete';
import { ApartmentNativeAutocomplete } from './ApartmentNativeAutocomplete';

interface AddApartmentByAddressProps {
	data: IAddApartmentRequest;
	onChange: (data: IAddApartmentRequest) => void;
	setIsValid: (value: boolean) => void;
}

export const AddApartmentByAddress: FC<AddApartmentByAddressProps> = ({ data, onChange, setIsValid }) => {
	const dispatch = useAppDispatch();

	const { isMobile } = useDevice();

	const { isOpen, handleOpen, handleClose, handleDidPresent, isPresented } = useOpen();

	const [isLoading, setIsLoading] = useState({
		city: false,
		street: false,
		house: false,
		flat: false,
	});

	const [options, setOptions] = useState<{
		city: string[];
		street: string[];
		house: string[];
		flat: string[];
	}>(APARTMENT_ADDRESS_INITIAL_OPTIONS);
	const [validationError, setValidationError] = useState(APARTMENT_ADDRESS_VALIDATION_ERRORS);
	const [touched, setTouched] = useState(APARTMENT_ADDRESS_TOUCHED);
	const [focusedFieldName, setFocusedFieldName] = useState<ADD_ADDRESS_FIELD_NAME | null>(null);
	const [addressInputValue, setAddressInputValue] = useState<string>('');
	const [cityValue, setCityValue] = useState<string>('');
	const [isDisabledField, setIsDisabledField] = useState({
		city: true,
		street: true,
		house: true,
		flat: true,
	});

	const debouncedAddressValue = useDebounce(addressInputValue, 1200);

	useEffect(() => {
		setIsDisabledField({
			city: !data.city && isLoading.city,
			street: !data.city,
			house: !data.street || !options.house.length,
			flat: (!data.house && !data.street) || !options.flat.length,
		});
	}, [data, options]);

	useEffect(() => {
		if (debouncedAddressValue) {
			handleSearchStreet(debouncedAddressValue);
		}
	}, [debouncedAddressValue]);

	useEffect(() => {
		handleSearchCity();
	}, []);

	useEffect(() => {
		validateData(data);
	}, [data, options]);

	const handleInputChange = (
		searchValue: string,
		name: ADD_ADDRESS_FIELD_NAME,
		reason?: AutocompleteInputChangeReason
	) => {
		if (reason === 'reset') return;
		handleChangeDebouncedState(name, searchValue);
	};

	const handleSearchCity = async (): Promise<void> => {
		try {
			setIsLoading((prev) => ({ ...prev, city: true }));
			const response: AxiosResponse<string[]> = await StreetsService.searchCities({});
			setOptions((prev) => ({
				...prev,
				city: response.data,
			}));
			onChange({ ...data, city: response.data[0] ?? '' })
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({
				...prev,
				city: false,
			}));
		}
	};

	const handleSearchStreet = async (searchValue: string): Promise<void> => {
		try {
			setIsLoading({ ...isLoading, street: true });
			const response: AxiosResponse<string[]> = await StreetsService.searchStreets({
				city: data.city,
				street: searchValue,
			});
			setOptions({ ...options, street: response.data });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading({ ...isLoading, street: false });
		}
	};

	const handleSearchHouse = async (value: string): Promise<void> => {
		try {
			setIsLoading({ ...isLoading, house: true });
			const reqBody: IStreetsHousesRequest = {
				city: data.city,
				street: value,
			};
			const response: AxiosResponse<string[]> = await StreetsService.searchHouses(reqBody);
			setOptions({ ...options, house: response.data.sort(sortApartmentArrayStringResponse) });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading({ ...isLoading, house: false });
		}
	};

	const handleSearchFlat = async (street: string, house: string): Promise<void> => {
		try {
			setIsLoading({ ...isLoading, flat: true });
			const response: AxiosResponse<string[]> = await StreetsService.searchFlats({
				city: data.city,
				street,
				house,
			});
			setOptions({ ...options, flat: response.data.filter((item) => !!item).sort(sortApartmentArrayStringResponse) });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading({ ...isLoading, flat: false });
		}
	};

	const validateData = async (value: IAddApartmentRequest): Promise<void> => {
		try {
			let validatonSchema = createApartmentValidationSchema;
			if (options.flat.length) {
				validatonSchema = createApartmentWithFlatValidationSchema;
			}
			await validatonSchema.validate(value, { abortEarly: false });
			setValidationError({
				city: '',
				street: '',
				house: '',
				flat: '',
			});
			setIsValid(true);
		} catch (error) {
			const errors: any = {};
			error.inner.forEach((err: ValidationError) => {
				errors[err.path as string] = err.message || '';
			});
			setIsValid(false);
			setValidationError(errors);
		}
	};

	const handleBlurAutocomplete = (event: FocusEvent<HTMLInputElement>): void => {
		if (!isMobile) {
			setTouched({ ...touched, [event.target.name]: true });
		}
	};

	const handleTouchEndMobile = (fieldName: ADD_ADDRESS_FIELD_NAME): void => {
		if (isMobile && !isDisabledField[fieldName as keyof typeof isDisabledField]) {
			setFocusedFieldName(fieldName);
			handleOpen();
		}
	};

	const handleChangeDebouncedState = (name: string, value: string) => {
		if (name === ADD_ADDRESS_FIELD_NAME.STREET) {
			setAddressInputValue(value);
		} else if (name === ADD_ADDRESS_FIELD_NAME.CITY) {
			setCityValue(value);
		}
	};

	const handleChangeNativeInput = (event: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		handleChangeDebouncedState(name, value);
	};

	const handleChangeApartmentData = async (value: string, name: string, isShouldCloseModal = false) => {
		const payload = { ...data };
		switch (name) {
			case ADD_ADDRESS_FIELD_NAME.CITY:
				payload.city = value;
				payload.street = '';
				payload.house = '';
				payload.flat = null;
				setOptions((prev) => ({ ...APARTMENT_ADDRESS_INITIAL_OPTIONS, city: prev.city }));
				setAddressInputValue('');
				break;
			case ADD_ADDRESS_FIELD_NAME.STREET:
				payload.street = value;
				payload.house = '';
				payload.flat = null;
				if (payload.street) {
					await handleSearchHouse(payload.street);
				} else {
					setOptions((prev) => ({ ...APARTMENT_ADDRESS_INITIAL_OPTIONS, city: prev.city }));
				}
				break;

			case ADD_ADDRESS_FIELD_NAME.HOUSE:
				payload.house = value;
				payload.flat = null;
				if (payload.house) {
					await handleSearchFlat(payload.street as string, payload.house);
				} else {
					setOptions({
						...options,
						flat: APARTMENT_ADDRESS_INITIAL_OPTIONS.flat,
					});
				}
				break;

			case ADD_ADDRESS_FIELD_NAME.FLAT:
				payload.flat = value;
				break;

			default:
				break;
		}
		if (isShouldCloseModal) {
			handleModalClose();
		}
		onChange(payload);
		handleChangeTouched(name);
	};

	const handleChangeAutocomplete = async (
		name: ADD_ADDRESS_FIELD_NAME,
		value: string,
		reason: AutocompleteChangeReason
	): Promise<void> => {
		switch (reason) {
			case 'selectOption':
				handleChangeApartmentData(value, name);
				break;
			case 'clear':
				handleChangeApartmentData('', name);
				break;

			default:
				break;
		}
	};

	const handleChangeTouched = (name: string): void => {
		let touchedPayload = { ...touched };
		switch (name) {
			case ADD_ADDRESS_FIELD_NAME.STREET:
				touchedPayload = { ...APARTMENT_ADDRESS_TOUCHED, street: true };
				break;

			case ADD_ADDRESS_FIELD_NAME.HOUSE:
				touchedPayload = { ...touchedPayload, house: true, flat: false };
				break;

			default:
				touchedPayload = { ...touchedPayload, [name]: true };
				break;
		}

		setTimeout(() => {
			setTouched(touchedPayload);
		});
	};

	const handleModalClose = (name?: string): void => {
		handleClose();
		if (name) {
			handleChangeTouched(name);
		}
	};

	return (
		<>
			<Typography variant="h5" textAlign="center" width="100%">
				Вкажіть вашу адресу
			</Typography>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.city}
				id="add-address-city"
				options={!isOpen ? options.city : []}
				disabled={isDisabledField.city}
				name={ADD_ADDRESS_FIELD_NAME.CITY}
				value={data.city ?? ''}
				loading={isLoading.city}
				isClear={!isMobile && (!!data.city || !!cityValue)}
				getOptionLabel={(option: string) => option}
				onInputChange={
					!isMobile ? (_, value, reason) => handleInputChange(value, ADD_ADDRESS_FIELD_NAME.CITY, reason) : undefined
				}
				onChange={(_, value, reason) => handleChangeAutocomplete(ADD_ADDRESS_FIELD_NAME.CITY, value, reason)}
				onBlur={handleBlurAutocomplete}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.CITY)}
				filterOptions={(options) => options}
				isError={touched.city && !!validationError.city}
				helperText={touched.city ? validationError.city : ''}
				readOnly={isMobile}
			/>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.street}
				id="add-address-street"
				disabled={isDisabledField.street}
				options={!isOpen ? options.street : []}
				name={ADD_ADDRESS_FIELD_NAME.STREET}
				value={data.street ?? ''}
				loading={isLoading.street}
				isClear={!isMobile && (!!data.street || !!addressInputValue)}
				getOptionLabel={(option: string) => option}
				onInputChange={
					!isMobile ? (_, value, reason) => handleInputChange(value, ADD_ADDRESS_FIELD_NAME.STREET, reason) : undefined
				}
				onChange={(_, value, reason) => handleChangeAutocomplete(ADD_ADDRESS_FIELD_NAME.STREET, value, reason)}
				onBlur={handleBlurAutocomplete}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.STREET)}
				filterOptions={(options) => options}
				isError={touched.street && !!validationError.street}
				helperText={touched.street ? validationError.street : ''}
				readOnly={isMobile}
			/>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.house}
				id="add-address-house"
				name={ADD_ADDRESS_FIELD_NAME.HOUSE}
				options={options.house}
				disabled={isDisabledField.house}
				isClear={!isMobile && !!data.house}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.HOUSE)}
				getOptionLabel={(option: string) => option}
				onChange={(_, value, reason) => handleChangeAutocomplete(ADD_ADDRESS_FIELD_NAME.HOUSE, value, reason)}
				value={data.house ?? ''}
				onBlur={handleBlurAutocomplete}
				loading={isLoading.house}
				isError={touched.house && !!validationError.house}
				helperText={touched.house ? validationError.house : ''}
				readOnly={isMobile}
			/>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.flat}

				id="add-address-flat"
				name={ADD_ADDRESS_FIELD_NAME.FLAT}
				options={options.flat}
				disabled={isDisabledField.flat}
				value={data.flat ?? ''}
				isClear={!isMobile && !!data.flat}
				getOptionLabel={(option: string) => option}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.FLAT)}
				onChange={(_, value, reason) => handleChangeAutocomplete(ADD_ADDRESS_FIELD_NAME.FLAT, value, reason)}
				onBlur={handleBlurAutocomplete}
				loading={isLoading.flat}
				isError={touched.flat && !!validationError.flat}
				helperText={touched.flat ? validationError.flat : ''}
				readOnly={isMobile}
			/>
			<CustomModal
				isOpen={isOpen}
				onClose={() => handleModalClose(focusedFieldName as ADD_ADDRESS_FIELD_NAME)}
				onDidPresent={handleDidPresent}
				isFullHeight
			>
				{!!focusedFieldName && (
					<ApartmentNativeAutocomplete
						{...APARTMENT_FIELD_CONFIG[focusedFieldName as keyof typeof APARTMENT_FIELD_CONFIG]}
						onChange={(value: string, name: string) => handleChangeApartmentData(value, name, true)}
						value={(data[focusedFieldName] as string) ?? ''}
						name={focusedFieldName}
						options={options[focusedFieldName as ADD_ADDRESS_FIELD_NAME]}
						isLoading={isLoading[focusedFieldName]}
						isError={touched[focusedFieldName] && !!validationError[focusedFieldName]}
						helperText={touched[focusedFieldName] ? validationError[focusedFieldName] : ''}
						onInputChange={handleChangeNativeInput}
						isOpenModal={isPresented}
						onClear={() => handleChangeApartmentData('', focusedFieldName, false)}
					/>
				)}
			</CustomModal>
		</>
	);
};
