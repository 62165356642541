import { FC, useEffect, useRef, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { TextField, TextFieldProps, Typography } from '@mui/material';
import { getMeterNumberTitle } from 'utils/helpers';
import { IMeterDataResponse } from 'utils/types';
import styles from './index.module.scss';

interface MeterItemProps {
	data: IMeterDataResponse;
	onChange: (indexFromProvider: string, value: number | null) => void;
	onBlur: (indexFromProvider: string) => void;
	getValidationError: (indexFromProvider: string) => Pick<TextFieldProps, 'error' | 'helperText'>;
}

export const MeterItem: FC<MeterItemProps> = ({ data, onChange, onBlur, getValidationError }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const [meterValue, setMeterValue] = useState<number | null>(data.currentValue);

	useEffect(() => {
		onChange(data.indexFromProvider, meterValue);
	}, [meterValue]);

	useEffect(() => {
		setMeterValue(data.currentValue);
	}, [data]);

	const handleChangeValue = ({ value }: NumberFormatValues): void => {
		setMeterValue(value ? +value : null);
		if (inputRef.current && +value === 0) {
			inputRef.current.setSelectionRange(2, 1);
		}
	};

	return (
		<div className={styles.content}>
			<div className={styles.header}>
				<div className={styles.header__item}>
					<Typography color="var(--color-neutral-500)" variant="caption">
						Особовий рахунок
					</Typography>
					<Typography variant="body1">{data.providerToApartment.providerAccountId}</Typography>
				</div>
				<div className={styles.header__item}>
					<Typography variant="body1">{getMeterNumberTitle(data.indexFromProvider)}</Typography>
				</div>
			</div>
			<div className={styles.meters}>
				<NumericFormat
					customInput={TextField}
					label="Попередні"
					value={data.previousValue}
					disabled
					decimalScale={0}
					inputProps={{ inputMode: 'numeric', autoComplete: 'off' }}
				/>
				<NumericFormat
					{...getValidationError(data.indexFromProvider)}
					customInput={TextField}
					label="Поточні"
					inputRef={inputRef}
					defaultValue=""
					value={meterValue ?? ''}
					onValueChange={handleChangeValue}
					onBlur={() => onBlur(data.indexFromProvider)}
					allowNegative={false}
					allowLeadingZeros
					decimalScale={0}
					inputProps={{ inputMode: 'numeric', autoComplete: 'off' }}
				/>
			</div>
		</div>
	);
};
