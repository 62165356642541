interface MuiComponentSettings {
	[key: string]: string | number | object;
}

export const MuiInputLabel: MuiComponentSettings = {
	styleOverrides: {
		root: {
			fontSize: '16px',
			lineHeight: '22px',
			color: 'var(--color-neutral-300)',
			textAlign: 'center',
			marginBottom: '8px',

			'&.MuiInputLabel-animated': {
				fontSize: '16px',
			},

			'&.Mui-error': {
				color: 'var(--color-neutral-300)',
			},
		},
		sizeSmall: {
			marginBottom: 0,

			'&.Mui-focused': {
				top: '-6px',
			},
		},
	},
};

export const MuiInputBase = {
	styleOverrides: {
		root: {
			fontSize: '20px',
			fontWeight: 700,
			lineHeight: '160%',
			letterSpacing: '0.15px',
			color: 'var(--color-primary-800)',
			height: '58px',
			width: '100%',
			paddingRight: 'var(--spacing-3) !important',

			'& input, & textarea': {
				padding: 'var(--spacing-1) ',
				paddingLeft: 'var(--spacing-3)',
			},
			'& textarea': {
				paddingTop: '12px',
			},
			'& fieldset': {
				height: 'inherit',
				borderRadius: '8px',
				border: '1px solid  var(--color-neutral-300)',
				legend: {
					display: 'none',
				},
			},
			'&:hover': {
				'& fieldset.MuiOutlinedInput-notchedOutline': {
					borderColor: 'var(--color-primary-300)',
				},
			},
			'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
				border: '1px solid var(--color-primary-500)',
			},
			'&.Mui-error': {
				'& fieldset, &.Mui-focused fieldset': { borderColor: ` var(--color-primary-500)` },
			},
			'&.Mui-disabled': {
				fieldset: {
					borderColor: 'var(--color-primary-200) !important',
				},
			},
		},
		sizeSmall: {
			height: '44px',
			fontWeight: 'normal',
			fontSize: '16px',
		},

		multiline: {
			minHeight: '58px',
			height: 'auto',
			padding: '0 !important',
		},
	},

	defaultProps: {
		autoComplete: 'off',
	},
};

export const MuiFormControl = {
	styleOverrides: {
		root: {
			width: '100%',

			'& .MuiInputLabel-animated': {
				fontWeight: 700,
				lineHeight: 'normal',

				'&.Mui-disabled': {
					color: ' var(--color-primary-200)',
				},

				'&.Mui-focused, &.MuiFormLabel-filled': {
					color: 'var(--color-neutral-500)',
					fontSize: '12px',
					transform: 'translate(12px, 4px) scale(0.75)',
					letterSpacing: '0.4px',
					fontWeight: 400,
				},

				'&.Mui-focused': {
					color: ' var(--color-primary-500)',
				},
			},
		},
	},
};
