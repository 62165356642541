import { FC, useEffect, useRef, useState } from 'react';
import { StaticGoogleMap } from 'components/shared';
import { IApartmentResponse } from 'utils/types';
import { ApartmentMapContent } from '../ApartmentMapContent';
import styles from './index.module.scss';

interface BasicMapPropsType {
	data: IApartmentResponse;
}

export const SingleApartmentMap: FC<BasicMapPropsType> = ({ data }) => {
	const mapRef = useRef<HTMLDivElement>(null);
	const [isShowMap, setIsShowMap] = useState(false);

	useEffect(() => {
		if (mapRef.current) {
			setIsShowMap(true);
		}
	}, [mapRef.current]);

	return (
		<div ref={mapRef} className={styles.wrapper}>
			{!!mapRef.current && isShowMap && (
				<StaticGoogleMap
					width={mapRef.current.offsetWidth}
					height={mapRef.current.offsetHeight}
					lat={+data.lat}
					lng={+data.lon}
				/>
			)}

			<ApartmentMapContent data={data} />
		</div>
	);
};
