import { FC, HTMLProps } from 'react';
import { ReactComponent as MapMarkerIcon } from 'assets/icons/map-marker.svg';
import { ReactComponent as MapMarkerCircleIcon } from 'assets/icons/map-marker-circle.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

interface MapMarkerProps extends Omit<HTMLProps<HTMLDivElement>, 'size' | 'onClick'> {
	index: number;
	size?: 'small' | 'medium' | 'big';
	onClick?: () => void;
	isOnlyIcon?: boolean;
}

export const MapMarker: FC<MapMarkerProps> = ({
	className,
	size = 'medium',
	index,
	onClick,
	isOnlyIcon = false,
	...props
}) => (
	<div
		{...props}
		className={classNames(styles.marker, className, {
			[styles.marker_second]: index % 3 === 1,
			[styles.marker_third]: index % 3 === 2,
			[styles.marker_small]: size === 'small',
			[styles.marker_big]: size === 'big',
			[styles.icon]: isOnlyIcon,
		})}
	>
		{isOnlyIcon ? <MapMarkerCircleIcon /> : <MapMarkerIcon onClick={onClick} />}
	</div>
);
