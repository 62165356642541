import { FC } from 'react';
import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import { CustomDatePicker } from 'components/shared';
import { DATE_FORMAT } from 'utils/enums';
import { getTextFieldValidationProps } from 'utils/helpers';
import { IRadabankInternalAddRuleRequest } from 'utils/types';
import 'dayjs/locale/uk';
import styles from './index.module.scss';

export const FormContentDateRow: FC = () => {
	const { setFieldValue, values, errors, touched } = useFormikContext<IRadabankInternalAddRuleRequest>();

	return (
		<div className={styles.content}>
			<Field
				label="Дата початку"
				name="datestart"
				disablePast
				as={CustomDatePicker}
				format={DATE_FORMAT.RADABANK_DATE_PICKER}
				onChange={(value: string) => setFieldValue('datestart', value)}
				maxDate={values.datefinish ? dayjs(values.datefinish, DATE_FORMAT.RADABANK_REQUEST) : undefined}
				{...getTextFieldValidationProps(errors, touched, 'datestart')}
			/>
			<Field
				label="Дата кінця"
				name="datefinish"
				as={CustomDatePicker}
				disablePast
				format={DATE_FORMAT.RADABANK_DATE_PICKER}
				minDate={values.datestart ? dayjs(values.datestart, DATE_FORMAT.RADABANK_REQUEST) : undefined}
				onChange={(value: string) => setFieldValue('datefinish', value)}
				{...getTextFieldValidationProps(errors, touched, 'datefinish')}
			/>
		</div>
	);
};
