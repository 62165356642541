import { FC, HTMLProps, UIEvent, useState } from 'react';
import classNames from 'classnames';
import { CustomButton } from 'components/shared';
import { getAmountTitle } from 'utils/helpers';
import { ReceiptApartment } from 'utils/types';
import { ReceiptMap } from '../ReceiptMap';
import styles from './index.module.scss';

interface ReceiptPreview extends HTMLProps<HTMLDivElement> {
	isDisabled?: boolean;
	apartmentData: ReceiptApartment | null;
	amountToPay: number;
	onClickPay: () => void;
	isDisabledActions?: boolean;
	isDisabledMap?: boolean;
}

const APARTMENT_MAP_MIN_HEIGHT = 116;

export const ReceiptPreview: FC<ReceiptPreview> = ({
	children,
	apartmentData,
	isDisabled,
	amountToPay,
	onClickPay,
	isDisabledActions,
	className,
	isDisabledMap,
}) => {
	const [isMapCollapsed, setMapCollapsed] = useState(false);

	const handleScroll = (event: UIEvent<HTMLDivElement>): void => {
		const { currentTarget } = event;
		const { scrollTop, scrollHeight, clientHeight } = currentTarget;

		if (scrollTop <= 0) {
			setMapCollapsed(false);
		} else if (!isMapCollapsed && scrollHeight - clientHeight > APARTMENT_MAP_MIN_HEIGHT + 20) {
			setMapCollapsed(true);
		}
	};

	return (
		<div
			className={classNames(styles.content, className, {
				[styles.content_collapsed]: isMapCollapsed,
				[styles.content_mapDisabled]: isDisabledMap,
			})}
		>
			{!isDisabledMap && (
				<ReceiptMap apartment={apartmentData} isDisabledActions={isDisabledActions} isCollapsed={isMapCollapsed} />
			)}
			<div className={styles.receipt}>
				<div className={styles.receipt__list} onScroll={isDisabledMap ? undefined : handleScroll}>
					{children}
				</div>
				<div className={styles.button}>
					<CustomButton
						label={`Сплатити ${getAmountTitle(amountToPay)}`}
						type="button"
						onClick={onClickPay}
						disabled={isDisabled}
						size="large"
					/>
				</div>
			</div>
		</div>
	);
};
