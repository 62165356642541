import { ChangeEvent, FC, useState } from 'react';
import { Schema } from 'yup';
import { AuthContentHeader } from 'components/Auth';
import { CustomButton } from 'components/shared';
import Input from 'components/shared/Input';
import { USER_PROFILE_FIELD_NAME } from 'utils/enums';
import { useAutofocus } from 'utils/hooks/use-autofocus';
import { IUserUpdateProfileRequest } from 'utils/types';
import { stringMinLength } from 'utils/validation';

interface EditModalContentProps {
	defaultValue: string;
	title: string;
	label: string;
	name: USER_PROFILE_FIELD_NAME;
	onSubmit: (data: IUserUpdateProfileRequest) => void;
	validationSchema?: Schema;
	isLoading?: boolean;
	isConfirmed?: boolean;
	isOpenModal?: boolean;
	regex?: RegExp;
}

export const EditModalContent: FC<EditModalContentProps> = ({
	title,
	defaultValue = '',
	label,
	name,
	onSubmit,
	validationSchema,
	isLoading,
	isConfirmed = true,
	regex,
	isOpenModal,
}) => {
	const inputRef = useAutofocus(isOpenModal);

	const [value, setValue] = useState<string>(defaultValue as string);
	const [touched, setTouched] = useState(false);
	const [validationError, setValidationError] = useState('');

	const isFormDisabled = !!validationError || (isConfirmed && value === defaultValue) || !value;

	const validateValue = async (value: string): Promise<void> => {
		try {
			if (validationSchema) {
				await validationSchema.validate(value, { abortEarly: false });
			} else {
				await stringMinLength.validate(value, { abortEarly: false });
			}
			setValidationError('');
		} catch (error) {
			setValidationError(error.inner[0].message);
		}
	};

	const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		if (/\d/.test(e.target.value) && !validationSchema) return;

		if (regex?.test(e.target.value)) return;

		setValue(e.target.value);
		validateValue(e.target.value);
	};

	const handleOnBlur = () => {
		setTouched(true);
		validateValue(value);
	};

	const handleSubmitForm = () => {
		if (!isFormDisabled) {
			onSubmit({ [name]: value });
		}
	};

	return (
		<>
			<AuthContentHeader title={title} />
			<Input
				onSubmit={handleSubmitForm}
				inputRef={inputRef}
				value={value}
				label={label}
				onChange={onChangeValue}
				isError={!!validationError && touched}
				helperText={touched && validationError}
				onBlur={handleOnBlur}
				name={name}
			/>
			<CustomButton label="Зберегти зміни" disabled={isFormDisabled} onClick={handleSubmitForm} isLoading={isLoading} />
		</>
	);
};
