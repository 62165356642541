import { FC, PropsWithChildren, Ref } from 'react';
import { RadabankSectionHeader } from 'components/RadabankCard/SectionHeader';
import { RadabankCardOverdarftProgress } from '../CardOverdraftProgress';
import styles from './index.module.scss';

interface CardOverdraftLayoutProps {
	onGoBack: () => void;
	title: string;
	wrapperRef?: Ref<HTMLDivElement>;
	isShowProgress?: boolean;
}

export const CardOverdraftLayout: FC<PropsWithChildren<CardOverdraftLayoutProps>> = ({
	onGoBack,
	children,
	title,
	wrapperRef,
	isShowProgress = false,
}) => {
	return (
		<div className="page" ref={wrapperRef}>
			<RadabankSectionHeader title={title} onClickBack={onGoBack} />
			<div className={styles.content}>
				{isShowProgress && <RadabankCardOverdarftProgress />}
				{children}
			</div>
		</div>
	);
};
