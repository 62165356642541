import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { updateSingleReceiptOtherServiceFieldsValues } from 'store/bill/actions';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAppLoading } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { ProviderServiceFieldsList, ProviderServiceHeader } from 'components/OtherServices';
import { CustomButton, CustomModal } from 'components/shared';
import { BILL_TYPE, ERROR_CODE } from 'utils/enums';
import {
	findServiceProviderAccountIdValue,
	getCreateBillRequestServiceFieldsData,
	getErrorMessage,
	getErrorResponse,
} from 'utils/helpers';
import { BillService } from 'utils/services';
import {
	IAddServiceFieldValueRequest,
	IBillReceipt,
	ICreateBillRequest,
	IDefaultModalProps,
	IReceiptOtherServiceResponse,
	IReceiptResponse,
	IUpdateServiceFieldValueRequest,
	IUserProfileResponse,
} from 'utils/types';
import styles from './index.module.scss';

interface EditServiceFieldValuesModal extends IDefaultModalProps {
	data: IReceiptOtherServiceResponse;
}

export const EditServiceFieldValuesModal: FC<EditServiceFieldValuesModal> = ({ data, ...modalProps }) => {
	const dispatch = useAppDispatch();

	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [isValid, setIsValid] = useState(false);
	const [fieldsValues, setFieldsValues] = useState<IUpdateServiceFieldValueRequest[]>(data.serviceFieldsValues);
	const [isDirty, setIsDirty] = useState(false);

	const isFormDisabled = !isValid || !isDirty;

	useEffect(() => {
		setFieldsValues(data.serviceFieldsValues);
	}, [data.serviceFieldsValues]);

	useEffect(() => {
		const isDirty = fieldsValues.some((item) => {
			const serviceFieldAmount = data.serviceFieldsValues.find(
				(serviceFieldValue) => serviceFieldValue.name === item.name
			)?.value;
			return serviceFieldAmount !== item.value;
		});
		setIsDirty(isDirty);
	}, [fieldsValues, data.serviceFieldsValues]);

	const handleUpdateFieldsValues = async (): Promise<void> => {
		const reqBody = {
			fieldsValues,
			serviceRequestData: {
				serviceId: data.id,
				apartmentAccountId: (receipt.data as IReceiptResponse).apartmentAccountId,
				userId: (profile as IUserProfileResponse).userId,
			},
		};
		await dispatch(updateSingleReceiptOtherServiceFieldsValues(reqBody)).unwrap();
		dispatch(showToast({ message: 'Сервіс успішно оновлено', color: 'success' }));
		modalProps.onClose();
	};

	const handleSubmit = async (): Promise<void> => {
		if(isFormDisabled) return;

		try {
			dispatch(setAppLoading(true));
			const contractNumber = findServiceProviderAccountIdValue(fieldsValues, data);
			if (contractNumber) {
				const billRequestReqBody: ICreateBillRequest = {
					payeeId: data.dataObject.payeeId,
					serviceFieldsData: getCreateBillRequestServiceFieldsData(data.dataObject, fieldsValues),
					billType: data.billType,
				};
				await BillService.createBillRequest(billRequestReqBody);

				await handleUpdateFieldsValues();
			}
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			if (errorResponse?.errorData?.code === ERROR_CODE.SERVICE_PAID_ALREADY && data.billType !== BILL_TYPE.FIRST) {
				await handleUpdateFieldsValues();
			} else {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleChangeFieldsValues = (
		values: IAddServiceFieldValueRequest[] | IUpdateServiceFieldValueRequest[]
	): void => {
		const payload: (IUpdateServiceFieldValueRequest | IAddServiceFieldValueRequest)[] = values.map((item) => ({
			...item,
			apartmentAccountId: (receipt.data as IReceiptResponse).apartmentAccountId,
		}));
		setFieldsValues(payload as IUpdateServiceFieldValueRequest[]);
	};

	return (
		<CustomModal isOpen={modalProps.isOpen} onClose={modalProps.onClose} isFullHeight>
			<div className={styles.content}>
				<ProviderServiceHeader
					className={classNames(styles.content__header, 'receipt-service-modal-header')}
					title={data.title}
					icon={data.imageUrl ?? ''}
				/>
				<ProviderServiceFieldsList
					onSubmit={handleSubmit}
					values={fieldsValues}
					fieldsList={data.serviceFields}
					setIsValid={(value: boolean) => setIsValid(value)}
					onChange={handleChangeFieldsValues}
				/>
				<CustomButton
					disabled={isFormDisabled}
					className={styles.button__submit}
					label="Оновити"
					onClick={handleSubmit}
				/>
			</div>
		</CustomModal>
	);
};
