import DocumentSVG from 'assets/icons/document-text.svg';
import HostLogoSVG from 'assets/icons/host-icon.svg';
import HouseSVG from 'assets/icons/House.svg';
import { HostIdInfoModalContent } from 'components/Apartment/HostIdInfoModalContent';
import { ADD_APARTMENT_VARIANT } from 'utils/enums';

export const ADD_APARTMENT_VARIANT_LIST = [
	{
		title: 'За допомогою адреси',
		value: ADD_APARTMENT_VARIANT.ADDRESS,
		icon: HouseSVG,
	},
	{
		title: 'За допомогою HOST ID',
		value: ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID,
		icon: HostLogoSVG,
		children: <HostIdInfoModalContent />,
	},
	{
		title: 'За допомогою особового рахунку (o/p)',
		value: ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID,
		icon: DocumentSVG,
	},
];

export const APARTMENT_ADDRESS_INITIAL_OPTIONS = {
	city: [],
	street: [],
	house: [],
	flat: [],
};

export const APARTMENT_ADDRESS_VALIDATION_ERRORS = {
	city: '',
	street: '',
	house: '',
	flat: '',
};

export const APARTMENT_ADDRESS_TOUCHED = {
	city: false,
	street: false,
	house: false,
	flat: false,
};

export const APARTMENT_ACCOUNT_ID_CHARS_LENGTH = 10;

export const APARTMENTS_MAX_COUNT = 9999;
