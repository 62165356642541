// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScrollToLayoutTopButtonContainer_wrapper__GaBz4 {
  display: flex;
  width: 100%;
  position: relative;
}
.ScrollToLayoutTopButtonContainer_wrapper__GaBz4 .ScrollToLayoutTopButtonContainer_scroll__j4JZb {
  position: absolute;
  height: 100%;
  left: calc(100% + 50px);
}
.ScrollToLayoutTopButtonContainer_wrapper__GaBz4 .ScrollToLayoutTopButtonContainer_scroll__button__AHmL8 {
  position: sticky;
  top: 85vh;
  top: 85dvh;
  z-index: 1000;
}
.ScrollToLayoutTopButtonContainer_wrapper__GaBz4 .ScrollToLayoutTopButtonContainer_scroll__button__AHmL8.ScrollToLayoutTopButtonContainer_native__BxI2A {
  height: 40px;
  position: fixed;
}

@media (max-width: 880px) {
  .ScrollToLayoutTopButtonContainer_wrapper__GaBz4 .ScrollToLayoutTopButtonContainer_scroll__j4JZb {
    left: calc(100% + 10px);
  }
}
@media (max-width: 790px) {
  .ScrollToLayoutTopButtonContainer_wrapper__GaBz4 .ScrollToLayoutTopButtonContainer_scroll__j4JZb {
    left: unset;
    right: 10px;
  }
  .ScrollToLayoutTopButtonContainer_wrapper__GaBz4 .ScrollToLayoutTopButtonContainer_scroll__button__AHmL8 {
    top: 81vh;
    top: 81dvh;
    right: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/shared/ScrollToLayoutTopButtonContainer/index.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,WAAA;EACA,kBAAA;AACD;AACC;EACC,kBAAA;EACA,YAAA;EACA,uBAAA;AACF;AACE;EACC,gBAAA;EACA,SAAA;EACA,UAAA;EACS,aAAA;AACZ;AACY;EACI,YAAA;EACA,eAAA;AAChB;;AAKA;EAEE;IACC,uBAAA;EAHD;AACF;AAOA;EAEE;IACC,WAAA;IACA,WAAA;EAND;EAQU;IACI,SAAA;IACA,UAAA;IACA,WAAA;EANd;AACF","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\twidth: 100%;\n\tposition: relative;\n\n\t.scroll {\n\t\tposition: absolute;\n\t\theight: 100%;\n\t\tleft: calc(100% + 50px);\n\n\t\t&__button {\n\t\t\tposition: sticky;\n\t\t\ttop: 85vh;\n\t\t\ttop: 85dvh;\n            z-index: 1000;\n            \n            &.native {\n                height: 40px;\n                position: fixed;\n            }\n\t\t}\n\t}\n}\n\n@media (max-width: 880px) {\n\t.wrapper {\n\t\t.scroll {\n\t\t\tleft: calc(100% + 10px);\n\t\t}\n\t}\n}\n\n@media (max-width: 790px) {\n\t.wrapper {\n\t\t.scroll {\n\t\t\tleft: unset;\n\t\t\tright: 10px;\n\n            &__button {\n                top: 81vh;\n                top: 81dvh;\n                right: 20px;\n            }\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ScrollToLayoutTopButtonContainer_wrapper__GaBz4`,
	"scroll": `ScrollToLayoutTopButtonContainer_scroll__j4JZb`,
	"scroll__button": `ScrollToLayoutTopButtonContainer_scroll__button__AHmL8`,
	"native": `ScrollToLayoutTopButtonContainer_native__BxI2A`
};
export default ___CSS_LOADER_EXPORT___;
