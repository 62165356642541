import { useState } from 'react';

export const useMatchMedia = (maxWidth = 726) => {
	const media = matchMedia(`(max-width: ${maxWidth}px)`);

	media.onchange = (e) => {
		setMatches(e.matches);
	};

	const [matches, setMatches] = useState(media.matches);

	return matches;
};
