import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { ArrowBackButton, SearchInput } from 'components/shared';
import { IServicesCategoriesRequest } from 'utils/types';

interface ServicesCategoriesHeaderProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	onChange: (data: IServicesCategoriesRequest) => void;
	data: IServicesCategoriesRequest;
	onClickBack?: () => void;
}

export const ServicesCategoriesHeader: FC<ServicesCategoriesHeaderProps> = ({
	onChange,
	data,
	className,
	onClickBack,
	children,
	title,
	...props
}) => {
	const handleInputSearch = (value: string): void => {
		if (!value && !data.options?.serviceCategoryTitle) return;
		onChange({ ...data, offset: 0, options: { ...data.options, serviceCategoryTitle: value } });
	};

	return (
		<header {...props} className={classNames('service-header', className)}>
			{!!onClickBack && <ArrowBackButton onClick={onClickBack} />}
			<SearchInput onChange={handleInputSearch} placeholder="Пошук категорій" />
			{children}
		</header>
	);
};
