import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Typography } from '@mui/material';
import { addApartment } from 'store/bill/actions';
import { getApartmentsSelector, getPossibleApartmentsLoadingSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { AddApartmentByAddress, AddApartmentById, AddApartmentByProviderId } from 'components/Apartment';
import ApartmentsChoiceStep from 'components/Apartment/AddApartmentByAddress/ApartmentsChoiceStep';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ADD_APARTMENT_VARIANT, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { getApartmentInitialValues, getAppartmentErrorMessage } from 'utils/helpers';
import { useKeyPress } from 'utils/hooks';
import { BillService, FirebaseAnalytics, StorageService } from 'utils/services';
import { IAddApartmentRequest } from 'utils/types';

interface AuthAddApartmentProps {
	variant: ADD_APARTMENT_VARIANT | null;
}

export const AuthAddApartment: FC<AuthAddApartmentProps> = ({ variant }) => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const { isLoading } = useAppSelector(getApartmentsSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const isReceiptLoading = useAppSelector(getPossibleApartmentsLoadingSelector);

	const [data, setData] = useState<IAddApartmentRequest>({
		userId: profile?.userId ?? '',
	});
	const [isValid, setIsValid] = useState(false);
	const [apartmentIds, setApartmentIds] = useState<number[]>([]);

	useEffect(() => {
		if (profile) {
			setData(getApartmentInitialValues(profile.userId, variant));
		}
	}, [variant, profile]);

	const handleAddAddress = async () => {
		try {
			await dispatch(addApartment(data)).unwrap();
			history.push(ROUTER_URL.RECEIPT_MAIN);
		} catch (error) {
			dispatch(showToast({ message: getAppartmentErrorMessage(error) }));
		}
	};

	const handleSubmit = async (): Promise<void> => {
		if(!isValid) return;
		try {
			let buttonName = '';
			switch (variant) {
				case ADD_APARTMENT_VARIANT.ADDRESS:
					buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_ADDRESS_SUBMIT;
					break;
				case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
					buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_HOST_ID_SUBMIT;
					break;
				case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
					buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_PROVIDER_ACCOUNT_ID_SUBMIT;
					break;

				default:
					break;
			}
			FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.UPDATE_PROFILE, buttonName);
			await StorageService.set(STORAGE_KEY.IS_WAS_LOGGED_IN, true);

			if (variant === ADD_APARTMENT_VARIANT.ADDRESS && !apartmentIds.length) {
				const dto = {
					city: data.city,
					street: data.street,
					house: data.house,
					flat: data.flat,
				};
				const { data: response } = await BillService.getApartmentAccountIdsList(dto);

				if (response.length > 1) {
					setApartmentIds(response);
				} else {
					await handleAddAddress();
				}
				return;
			}
			await handleAddAddress();
		} catch (error) {
			dispatch(showToast({ message: getAppartmentErrorMessage(error) }));
		}
	};

	const handleSubmitOnEnter = useKeyPress(handleSubmit);

	const content = useMemo(() => {
		const props = {
			data,
			onChange: (value: IAddApartmentRequest) => setData(value),
			setIsValid: (value: boolean) => setIsValid(value),
			onSubmit: handleSubmitOnEnter,
		};
		switch (variant) {
			case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
				return <AddApartmentById {...props} />;

			case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
				return <AddApartmentByProviderId {...props} />;

			default:
				if (apartmentIds.length) {
					const onChangeApartmentId = (apartmentAccountId?: number) =>
						setData((prev) => ({ ...prev, apartmentAccountId }));
					return (
						<ApartmentsChoiceStep
							onChange={onChangeApartmentId}
							apartmentAccountId={data.apartmentAccountId}
							apartmentIds={apartmentIds}
						/>
					);
				}
				return <AddApartmentByAddress {...props} />;
		}
	}, [variant, data, apartmentIds, isValid]);

	return (
		<>
			{content}
			{!isReceiptLoading && (
				<Box width="100%">
					{!!apartmentIds.length && variant === ADD_APARTMENT_VARIANT.ADDRESS && (
						<Typography mb="8px" textAlign="center" variant="subtitle2" color="var(--color-neutral-300)">
							Також ви можете звернутися до нашого центру клієнтської підтримки для керування цими даними
						</Typography>
					)}
					<CustomButton
						className="auth-button-submit"
						size="large"
						disabled={!isValid}
						label="Продовжити"
						onClick={handleSubmit}
						isLoading={isLoading}
					/>
				</Box>
			)}
		</>
	);
};
