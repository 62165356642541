import { FC, ReactNode } from 'react';
import styles from './index.module.scss';

interface CardProps {
	title?: string;
	children: ReactNode;
}

export const Card: FC<CardProps> = ({ title, children }) => (
	<div className={styles.wrapper}>
		{!!title && <p className={styles.wrapper__title}>{title}</p>}
		{children}
	</div>
);
