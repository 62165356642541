// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StaticGoogleMap_wrapper__8qAvF::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-primary-500);
  opacity: 0.5;
  z-index: 1;
}
.StaticGoogleMap_wrapper__8qAvF .StaticGoogleMap_map__GiOf9 {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.StaticGoogleMap_wrapper__8qAvF .StaticGoogleMap_map_invisible__JJzXU {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/StaticGoogleMap/index.module.scss"],"names":[],"mappings":"AACC;EACC,WAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,YAAA;EACA,UAAA;AAAF;AAGC;EACC,UAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,iBAAA;EACA,UAAA;EAEA,WAAA;EACA,YAAA;AAFF;AAIE;EACC,UAAA;AAFH","sourcesContent":[".wrapper {\n\t&::after {\n\t\tcontent: '';\n\t\ttop: 0;\n\t\tleft: 0;\n\t\tposition: absolute;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tbackground: var(--color-primary-500);\n\t\topacity: 0.5;\n\t\tz-index: 1;\n\t}\n\n\t.map {\n\t\topacity: 1;\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\tobject-fit: cover;\n\t\tz-index: 0;\n\t\t// HBILL-343\n\t\twidth: 100%;\n\t\theight: 100%;\n\n\t\t&_invisible {\n\t\t\topacity: 0;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `StaticGoogleMap_wrapper__8qAvF`,
	"map": `StaticGoogleMap_map__GiOf9`,
	"map_invisible": `StaticGoogleMap_map_invisible__JJzXU`
};
export default ___CSS_LOADER_EXPORT___;
