import { FC, useState } from 'react';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { confirmUserEmail } from 'store/user-service/actions';
import { AuthContentHeader } from 'components/Auth';
import { OtpInput } from 'components/Auth/ConfirmOTPCode/OtpInput';
import { CountdownTimer } from 'components/Auth/ConfirmOTPCode/Timer';
import { CustomButton } from 'components/shared';
import { ERROR_CODE, STORAGE_KEY } from 'utils/enums';
import { getErrorMessage, getErrorResponse } from 'utils/helpers';
import { StorageService, UserService } from 'utils/services';
import { IErrorResponse } from 'utils/types';
import styles from '../index.module.scss';

interface ConfirmEmailModalContentProps {
	email: string;
	onClose: () => void;
}

export const ConfirmEmailModalContent: FC<ConfirmEmailModalContentProps> = ({ email, onClose }) => {
	const dispatch = useAppDispatch();

	const [isValid, setIsValid] = useState(true);
	const [code, setCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const isFormDisabled = code.length < 4 || !isValid;

	const onSubmit = async () => {
		try {
			setIsLoading(true);
			await dispatch(confirmUserEmail({ code })).unwrap();
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);
			onClose();
		} catch (error) {
			const errorResponse: IErrorResponse | undefined = getErrorResponse(error);

			if (errorResponse?.errorData?.code === ERROR_CODE.INVALID_CONFIRM_CODE) {
				setIsValid(false);
			} else {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const onResendConfirmCode = async () => {
		try {
			setIsLoading(true);
			await UserService.resendEmailUpdateConfirmCode();
			await StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const onChangeConfirmCode = (value: string) => {
		setIsValid(true);
		setCode(value);
	};

	const handleConfirmCode = () => {
		if (!isFormDisabled) {
			onSubmit();
		}
	};

	return (
		<>
			<AuthContentHeader
				title="Підтвердіть ваш email"
				description={
					<span className={styles.description}>
						Вкажіть код, який було вислано на ваш email <span>{email}</span>
					</span>
				}
			/>
			<OtpInput onEnterPress={handleConfirmCode} value={code} onChange={onChangeConfirmCode} isValid={isValid} />
			<div>
				<CountdownTimer onResendCode={onResendConfirmCode} />
				<CustomButton label="Підтвердити" disabled={isFormDisabled} onClick={onSubmit} isLoading={isLoading} />
			</div>
		</>
	);
};
