import { ChangeEvent, FC } from 'react';
import { BaseTextFieldProps } from '@mui/material';
import Input from 'components/shared/Input';
import { useKeyPress } from 'utils/hooks';

interface PasswordInputProps extends Omit<BaseTextFieldProps, 'value' | 'defaultValue'> {
	isError?: boolean;
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	errorText?: string;
	touched?: boolean;
	nameInput?: string;
	onSubmit?: () => void;
}

export const PasswordInput: FC<PasswordInputProps> = ({
	value,
	nameInput = 'password',
	errorText,
	touched,
	onChange,
	onSubmit,
	...props
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (!/^[0-9]+$|^$/.test(event.target.value)) return;
		onChange(event);
	};

	const handlePressEnter = useKeyPress(onSubmit);

	return (
		<Input
			name={nameInput}
			{...props}
			onChange={handleChange}
			onKeyDown={handlePressEnter}
			value={value}
			slotProps={{ input: { maxLength: 6 } }}
			type="password"
			placeholder="******"
			autoComplete="new-password"
			inputProps={{ inputMode: 'numeric', autoComplete: 'new-password' }}
			helperText={!!errorText && touched ? errorText : `${value?.length} / 6`}
		/>
	);
};
