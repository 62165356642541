import { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import { CustomButton, PhoneInputFormik } from 'components/shared';
import { RADABANK_ADD_PHONE_MANUAL_INITIAL_VALUES } from 'utils/constants';
import { useAutofocus } from 'utils/hooks/use-autofocus';
import { radabankSmsInfoValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

interface RadabankManualAddPhoneProps {
	onAddPhone: (phone: string) => Promise<void>;
	isModalClosed: boolean;
}

export const RadabankManualAddPhone: FC<RadabankManualAddPhoneProps> = ({ onAddPhone, isModalClosed }) => {
	const inputRef = useAutofocus(isModalClosed);

	const handleSubmit = (values: { phone: string }) => {
		onAddPhone(values.phone);
	};

	return (
		<Formik
			onSubmit={handleSubmit}
			initialValues={RADABANK_ADD_PHONE_MANUAL_INITIAL_VALUES}
			validationSchema={radabankSmsInfoValidationSchema}
			isInitialValid={false}
			validateOnBlur
		>
			{({ isValid }) => (
				<Form className={styles.form}>
					<Field inputRef={inputRef} name="phone" component={PhoneInputFormik} />
					<CustomButton disabled={!isValid} label="Додати номер" type="submit" />
				</Form>
			)}
		</Formik>
	);
};
