import { ChangeEvent, FC } from 'react';
import { TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { CustomAutocomplete, CustomButton, IBANInput, NumberInputFormik } from 'components/shared';
import { RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT } from 'utils/constants';
import { getTextFieldValidationProps } from 'utils/helpers';
import { IRadabankBudgetCreatePaymentRequest, ISelectOption } from 'utils/types';
import styles from './index.module.scss';

interface OperationBudgetFormContentProps {
	codeOptions: ISelectOption[];
	isCodeOptionsLoading: boolean;
}

export const FormContent: FC<OperationBudgetFormContentProps> = ({ isCodeOptionsLoading, codeOptions }) => {
	const { setFieldValue, errors, touched, isValid } = useFormikContext<IRadabankBudgetCreatePaymentRequest>();

	const handleChangeInnRecValue = (event: ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		if (/^\d*$/.test(value)) {
			setFieldValue('innrec', value);
		}
	};

	return (
		<>
			<div className={styles.content}>
				<Field
					name="ibanrec"
					as={IBANInput}
					label="IBAN отримувача"
					{...getTextFieldValidationProps(errors, touched, 'ibanrec')}
				/>
				<Field
					name="innrec"
					as={TextField}
					label="Введіть ЄДРПОУ"
					inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
					onChange={handleChangeInnRecValue}
					{...getTextFieldValidationProps(errors, touched, 'innrec')}
				/>
				<Field
					name="budgetcode"
					label="Код виду сплати"
					component={CustomAutocomplete}
					options={codeOptions}
					loading={isCodeOptionsLoading}
					{...getTextFieldValidationProps(errors, touched, 'budgetcode')}
				/>
				<Field
					name="budgetadd"
					as={TextField}
					label="Додаткова інформація запису"
					{...getTextFieldValidationProps(errors, touched, 'budgetadd')}
				/>
				<Field
					name="namerec"
					as={TextField}
					label="Отримувач"
					{...getTextFieldValidationProps(errors, touched, 'namerec')}
				/>
				<Field
					name="amount"
					label="Сума переказу"
					component={NumberInputFormik}
					max={RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT}
				/>
			</div>
			<CustomButton type="submit" label="Далі" disabled={!isValid} />
		</>
	);
};
