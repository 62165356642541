export const focusToNextInput = (target: HTMLElement) => {
  const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

  if (nextElementSibling) {
    nextElementSibling.focus();
  }
};

export const focusToPrevInput = (target: HTMLElement) => {
  const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

  if (previousElementSibling) {
    previousElementSibling.focus();
  }
};
