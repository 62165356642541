import { FC, HTMLProps } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import classNames from 'classnames';
import { getIsCurrentCardBlockedSelector, getRadabankCurrentCardSelector, useAppSelector } from 'store';
import { ROUTER_URL } from 'utils/enums';
import { getAmountTitle } from 'utils/helpers';
import styles from './index.module.scss';

interface RadabankCardDetailsProps extends HTMLProps<HTMLDivElement> {
	isShowCreditDetails?: boolean;
}

export const RadabankCardDetails: FC<RadabankCardDetailsProps> = ({ className, isShowCreditDetails = false }) => {
	const history = useHistory();

	const cardData = useAppSelector(getRadabankCurrentCardSelector);
	const isCardBlocked = useAppSelector(getIsCurrentCardBlockedSelector);

	const handleNavigateToCreditDetails = () => {
		if (isShowCreditDetails) {
			history.push(ROUTER_URL.RADABANK_CARD_OVERDRAFT_DETAILS);
		}
	};

	const cardCreditLimit = Number(cardData?.overdraft);

	const cardAmountOther = isCardBlocked ? 0 : Number(cardData?.amountother);

	const cardCreditAmount = cardCreditLimit - cardAmountOther;

	return (
		cardData && (
			<div className={classNames(styles.content, className)}>
				<Typography variant="h5">{getAmountTitle(cardData.availamount)}</Typography>
				<div className={classNames(styles.row_amount, { [styles.own_credit]: isShowCreditDetails })}>
					<Typography variant="body2" whiteSpace="nowrap">
						Власні кошти
					</Typography>
					<Typography variant="body2" whiteSpace="nowrap">
						{getAmountTitle(cardData.cardrestmine)}
					</Typography>
				</div>
				<div
					className={classNames(styles.row_amount, { [styles.credit]: isShowCreditDetails })}
					onClick={handleNavigateToCreditDetails}
				>
					<Typography fontWeight="700" variant="body2" whiteSpace="nowrap">
						{isCardBlocked ? 'Кредитний ліміт' : 'Кредитні кошти'}
					</Typography>
					<Typography fontWeight="700" variant="body2" whiteSpace="nowrap">
						{getAmountTitle(cardCreditAmount)}
					</Typography>
					{isShowCreditDetails && <ArrowIcon className={styles.arrow} />}
				</div>
				<Typography variant="subtitle2" marginTop="auto">
					{cardData.cardholder}
				</Typography>
			</div>
		)
	);
};
