// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_wrapper__tiJeR {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}
.Loader_wrapper__tiJeR ion-spinner {
  --color: #fff;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Loader/index.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EAEA,eAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;AAAD;AAEC;EACC,aAAA;EAEA,WAAA;EACA,YAAA;EAEA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;AAFF","sourcesContent":[".wrapper {\n\twidth: 100%;\n\theight: 100%;\n\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\tz-index: 99999;\n\n\tion-spinner {\n\t\t--color: #fff;\n\n\t\twidth: 50px;\n\t\theight: 50px;\n\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\tleft: 50%;\n\t\ttransform: translate(-50%, -50%);\n\t\tz-index: 2;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Loader_wrapper__tiJeR`
};
export default ___CSS_LOADER_EXPORT___;
