// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginNative_wrapper__QHzS\\+ > button {
  position: absolute;
  top: calc(env(safe-area-inset-top) + 10px);
  left: 25px;
  z-index: 1;
}
.LoginNative_wrapper__QHzS\\+ > button svg path {
  stroke: var(--color-neutral-white);
}
.LoginNative_wrapper__QHzS\\+ .LoginNative_content__50S1Z {
  padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Login/LoginNative/index.module.scss"],"names":[],"mappings":"AACI;EACE,kBAAA;EACA,0CAAA;EACA,UAAA;EACA,UAAA;AAAN;AAEM;EACE,kCAAA;AAAR;AAII;EACE,iBAAA;AAFN","sourcesContent":[".wrapper {\n    & > button {\n      position: absolute;\n      top: calc(env(safe-area-inset-top) + 10px);\n      left: 25px;\n      z-index: 1;\n  \n      & svg path {\n        stroke: var(--color-neutral-white);\n      }\n    }\n  \n    .content {\n      padding-top: 20px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LoginNative_wrapper__QHzS+`,
	"content": `LoginNative_content__50S1Z`
};
export default ___CSS_LOADER_EXPORT___;
