import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { STORAGE_KEY } from 'utils/enums';
import { StorageService } from 'utils/services';

export const useSaveFormValuesToStorageOnUnmount = <T>(storageKey: STORAGE_KEY) => {
	const { values } = useFormikContext<T>();
	const actualValues = useRef(values);

	const handleStoreData = async () => {
		try {
			await StorageService.set(storageKey, actualValues.current);
		} catch (error) {
			console.error('erorr during set form values to storage', error);
		}
	};

	useEffect(() => {
		actualValues.current = values;
	}, [values]);

	useEffect(() => {
		return () => {
			handleStoreData();
		};
	}, []);
};
