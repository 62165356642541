import { FC } from 'react';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';

export const RadabankInternalCreateCardSubmissionPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CREATE_CARD_SUBMIT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} />
			<RadabankOnboardingContent
				description={['Ми перевіряємо отримані документи.', 'Ви отримаєте повідомлення, коли все буде готово']}
			/>
			<CustomButton label="Зрозуміло" color="secondary" onClick={handleGoBack} />
		</div>
	);
};
