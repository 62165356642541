import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as Close } from 'assets/icons/simple-close.svg';
import styles from './index.module.scss';

interface ActionModalContentProps {
	icon: ReactNode;
	title: string;
	subTitle: string;
	onClose?: () => void;
}

export const ActionModalContent: FC<ActionModalContentProps> = ({ icon, title, subTitle, onClose }) => {
	return (
		<div className={styles.content}>
			{onClose && (
				<button onClick={onClose} className={styles.content__button}>
					<Close className={styles.content__button__icon} />
				</button>
			)}
			<div className={styles.content__icon}>{icon}</div>
			<div className={styles.content__description}>
				<Typography variant="subtitle1">{title}</Typography>

				<Typography variant="body2" color="--color-neutral-500">
					{subTitle}
				</Typography>
			</div>
		</div>
	);
};
