import { Rights } from "utils/enums/rights.enum";
import { ROUTER_URL } from "../enums";

type RoutePermissionsMap = {
    [key in ROUTER_URL]?: Rights;
}

export const ROUTES_PERMISSIONS: RoutePermissionsMap = {
    [ROUTER_URL.PROFILE]: Rights.PROFILE_READ,
    [ROUTER_URL.METERS]: Rights.METERS_READ,
    [ROUTER_URL.SERVICE]: Rights.SERVICES_READ,
    [ROUTER_URL.SETTINGS]: Rights.SETTINGS_READ,
};

export const ROUTES_PERMISSIONS_NAV_BAR_PUMB =[ROUTER_URL.SERVICES_CATEGORIES, ROUTER_URL.SETTINGS];
