import { FC } from 'react';
import { Typography } from '@mui/material';
import { FIREBASE_EVENT_ANALYTICS_BUTTON } from 'utils/constants';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

interface AccountDeleteModalContentProps {
	pageName: string;
}

export const AccountDeleteModalContent: FC<AccountDeleteModalContentProps> = ({ pageName }) => {
	const handleClickAccountDelete = (): void => {
		FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.DELETE_ACCOUNT);
	};

	return (
		<div className={styles.content}>
			<Typography variant="h5" marginBottom="var(--spacing-5)" textAlign="center">
				Шкода, що ви хочете видалити акаунт
			</Typography>
			<a
				href={process.env.REACT_APP_ACCOUNT_DELETE_URL}
				target="_blank"
				rel="noreferrer"
				onClick={handleClickAccountDelete}
			>
				Подати запит на видалення
			</a>
		</div>
	);
};
