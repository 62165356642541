// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input-wrapper__WzRqH {
  width: 100%;
  z-index: 0;
  height: 57px;
}
.Input_input-wrapper__WzRqH .Input_MuiTextField-root__4Sn\\+k {
  z-index: 0 !important;
}
.Input_input-wrapper__WzRqH input::-webkit-outer-spin-button, .Input_input-wrapper__WzRqH input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Input/index.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,UAAA;EACA,YAAA;AACD;AACC;EACC,qBAAA;AACF;AAGE;EAEC,wBAAA;AAFH","sourcesContent":[".input-wrapper {\n\twidth: 100%;\n\tz-index: 0;\n\theight: 57px;\n\n\t& .MuiTextField-root {\n\t\tz-index: 0 !important;\n\t}\n\n\tinput {\n\t\t&::-webkit-outer-spin-button,\n\t\t&::-webkit-inner-spin-button {\n\t\t\t-webkit-appearance: none;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-wrapper": `Input_input-wrapper__WzRqH`,
	"MuiTextField-root": `Input_MuiTextField-root__4Sn+k`
};
export default ___CSS_LOADER_EXPORT___;
