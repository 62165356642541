import { FC, FocusEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { BiometryErrorType } from '@aparajita/capacitor-biometric-auth';
import { Capacitor } from '@capacitor/core';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { userLogin } from 'store/user-service/actions';
import { ValidationError } from 'yup';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { CHECK_RESPONSE, ERROR_CODE, ERROR_MESSAGE, ROLE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import {
	encryptData,
	getErrorMessage,
	getErrorResponse,
	parseQueryString,
	redirectNotAuthorizedUser,
} from 'utils/helpers';
import { useDevice } from 'utils/hooks';
import { BiometryService, FirebaseAnalytics, PushNotificationsService, StorageService } from 'utils/services';
import { IErrorResponse, IUserResponse, UserLoginRequest } from 'utils/types';
import { loginValidationSchema } from 'utils/validation';
import { VerifyDevicePage } from '../VerifyDevice';
import { LoginNative } from './LoginNative';
import { LoginWeb } from './LoginWeb';

const FORM_ERRORS = {
	phone: '',
	password: '',
};

export const LoginPage: FC = () => {
	const { isMobile } = useDevice();

	const history = useHistory();

	const { search } = useLocation();

	const dispatch = useAppDispatch();

	const [data, setData] = useState<UserLoginRequest>({
		phone: '',
		password: '',
	});

	const [formErrors, setFormErrors] = useState(FORM_ERRORS);
	const [touched, setTouched] = useState({
		phone: false,
		password: false,
	});
	const [isVerificationRequired, setIsVerificationRequired] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		StorageService.get<string>(STORAGE_KEY.PHONE).then((phoneValue: string | null) => {
			if (phoneValue) {
				setData({ ...data, phone: phoneValue ? phoneValue.toString() : '' });
			} else {
				redirectNotAuthorizedUser();
			}
		});
	}, []);

	const validateData = async (value: UserLoginRequest): Promise<void> => {
		try {
			await loginValidationSchema.validate(value, { abortEarly: false });
			setFormErrors(FORM_ERRORS);
		} catch (error) {
			const errors: any = {};
			error.inner.forEach((err: ValidationError) => {
				errors[err.path as string] = err.message ? err.message : [];
			});
			setFormErrors(errors);
		}
	};

	const handleChangeData = (data: UserLoginRequest): void => {
		setData(data);
		validateData(data);
	};

	const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
		setTouched({ ...touched, [event.target.name]: true });
		validateData(data);
	};

	const handleLogin = async (): Promise<void> => {
		if (!isMobile && (Object.values(formErrors).some(Boolean) || !data.password || !data.phone)) return;

		const redirectUrl = parseQueryString<{ redirectTo: string }>(search)?.redirectTo || ROUTER_URL.PROFILE;
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.LOGIN, FIREBASE_EVENT_ANALYTICS_BUTTON.LOGIN);
		try {
			setIsLoading(true);
			await StorageService.set(STORAGE_KEY.PHONE, data.phone);
			const response: IUserResponse = await dispatch(userLogin(data)).unwrap();

			if (Capacitor.isNativePlatform()) {
				await StorageService.set(STORAGE_KEY.USER_PASSWORD, encryptData(data.password));
				const lastLoggedInUserId = await StorageService.get(STORAGE_KEY.LAST_LOGGED_IN_USER_ID);

				if (lastLoggedInUserId && response.profile?.userId && response.profile?.userId !== lastLoggedInUserId) {
					await StorageService.remove(STORAGE_KEY.RADABANK_AUTH_INFO);
					await StorageService.set(STORAGE_KEY.LAST_LOGGED_IN_USER_ID, response.profile.userId);
				}
			}

			if (response.preLoginData?.deviceVerificationRequired === CHECK_RESPONSE.YES) {
				const verificationId: string | undefined = response.preLoginData?.deviceVerificationId;
				const storageVerificationId: string | null = await StorageService.get<string>(STORAGE_KEY.VERIFICATION_ID);
				if (verificationId !== storageVerificationId) {
					StorageService.set(STORAGE_KEY.VERIFICATION_ID, response.preLoginData?.deviceVerificationId);
					StorageService.set(STORAGE_KEY.VERIFICATION_ID_DATE, dayjs().toISOString());
				}
				setIsVerificationRequired(true);
			} else if (response.profile) {
				const isWasLoggedIn = await StorageService.get<boolean>(STORAGE_KEY.IS_WAS_LOGGED_IN);
				if (Capacitor.isNativePlatform()) {
					await PushNotificationsService.subscribeNewUserInsteadOfPrevious(response.profile.userId);
				}
				if (Capacitor.isNativePlatform() && BiometryService.isAvailable && !isWasLoggedIn) {
					await BiometryService.auth({
						androidTitle: 'Використовувати біометричну автентифікацію?',
					});
					await BiometryService.setBiometryType(BiometryService.primaryBiometryType);
				}
				await StorageService.set(STORAGE_KEY.IS_WAS_LOGGED_IN, true);
				if (response.roles?.some((item) => item.name === ROLE.PUMB)) {
					history.replace(ROUTER_URL.SERVICES_CATEGORIES);
				} else {
					history.replace(redirectUrl);
				}
			}
		} catch (error) {
			const errorResponse: IErrorResponse | undefined = getErrorResponse(error);
			if (errorResponse?.errorData?.code === ERROR_CODE.INVALID_PASSWORD_OR_NOT_FOUND) {
				dispatch(showToast({ message: ERROR_MESSAGE.INVALID_PASSWORD_OR_NOT_FOUND }));
				if (Capacitor.isNativePlatform()) {
					setData({ ...data, password: '' });
				}
			} else if (error?.code === BiometryErrorType.userCancel) {
				await StorageService.set(STORAGE_KEY.IS_WAS_LOGGED_IN, true);
				history.replace(redirectUrl);
			} else {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleClickRestorePassword = async (): Promise<void> => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.LOGIN,
			FIREBASE_EVENT_ANALYTICS_BUTTON.RESTORE_PASSWORD
		);
		history.push(ROUTER_URL.AUTH_RESTORE_VERIFY);
	};

	const handleClickBack = (): void => {
		setIsVerificationRequired(false);
		if (Capacitor.isNativePlatform()) {
			setData({ ...data, password: '' });
		}
	};

	if (isVerificationRequired) {
		return <VerifyDevicePage data={data} onClickBack={handleClickBack} />;
	}

	return isMobile && Capacitor.isNativePlatform() ? (
		<LoginNative
			data={data}
			onClickRestorePassword={handleClickRestorePassword}
			onChange={handleChangeData}
			onSubmit={handleLogin}
			onBlur={handleBlur}
			errors={formErrors}
			touched={touched}
			isLoading={isLoading}
		/>
	) : (
		<LoginWeb
			data={data}
			onClickRestorePassword={handleClickRestorePassword}
			onChange={handleChangeData}
			onSubmit={handleLogin}
			onBlur={handleBlur}
			errors={formErrors}
			touched={touched}
		/>
	);
};
