// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/images/host-card-bg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/images/host-logo-card.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/icons/mastercard.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.CardPreview_wrapper__6a6tr {
  background: linear-gradient(to right, rgba(var(--color-primary-500-rgb), 0.4) 20%, rgba(var(--color-primary-500-rgb), 0.4)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  padding-top: 56px;
  position: relative;
}
div.CardPreview_wrapper__6a6tr.CardPreview_blocked__CIPun {
  opacity: 0.7;
}
div.CardPreview_wrapper__6a6tr::before {
  content: "";
  position: absolute;
  top: var(--spacing-4);
  left: var(--spacing-4);
  max-width: 105px;
  width: 100%;
  aspect-ratio: 96/29;
  background: no-repeat center/contain url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
div.CardPreview_wrapper__6a6tr::after {
  content: "";
  position: absolute;
  bottom: var(--spacing-4);
  right: var(--spacing-4);
  max-width: 65px;
  width: 100%;
  aspect-ratio: 247/150;
  background: no-repeat center/contain url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}`, "",{"version":3,"sources":["webpack://./src/components/RadabankCard/CardPreview/index.module.scss"],"names":[],"mappings":"AAEA;EACC,oKAAA;EAMA,sBAAA;EACA,iBAAA;EACA,kBAAA;AAND;AAQC;EACC,YAAA;AANF;AASC;EACC,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,4EAAA;AAPF;AAUC;EACC,WAAA;EACA,kBAAA;EACA,wBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,qBAAA;EACA,4EAAA;AARF","sourcesContent":["@import 'assets/styles/mixins';\n\ndiv.wrapper {\n\tbackground: linear-gradient(\n\t\t\tto right,\n\t\t\trgba(var(--color-primary-500-rgb), 0.4) 20%,\n\t\t\trgba(var(--color-primary-500-rgb), 0.4)\n\t\t),\n\t\turl('~assets/images/host-card-bg.png');\n\tbackground-size: cover;\n\tpadding-top: 56px;\n\tposition: relative;\n\n\t&.blocked {\n\t\topacity: 0.7;\n\t}\n\n\t&::before {\n\t\tcontent: '';\n\t\tposition: absolute;\n\t\ttop: var(--spacing-4);\n\t\tleft: var(--spacing-4);\n\t\tmax-width: 105px;\n\t\twidth: 100%;\n\t\taspect-ratio: 96/29;\n\t\tbackground: no-repeat center / contain url('~assets/images/host-logo-card.svg');\n\t}\n\n\t&::after {\n\t\tcontent: '';\n\t\tposition: absolute;\n\t\tbottom: var(--spacing-4);\n\t\tright: var(--spacing-4);\n\t\tmax-width: 65px;\n\t\twidth: 100%;\n\t\taspect-ratio: 247/150;\n\t\tbackground: no-repeat center / contain url('~assets/icons/mastercard.svg');\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CardPreview_wrapper__6a6tr`,
	"blocked": `CardPreview_blocked__CIPun`
};
export default ___CSS_LOADER_EXPORT___;
