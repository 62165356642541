import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Failed } from 'assets/icons/failed.svg';
import { getIsPumbUserAccessSelector, useAppSelector } from "store";
import { CustomButton } from 'components/shared';
import { ROUTER_URL } from 'utils/enums';
import styles from './index.module.scss';

export const NotFoundPage: FC = () => {
	const isUserPUMB = useAppSelector(getIsPumbUserAccessSelector)

	return (
		<div className={styles.wrapper}>
			<Failed className={styles.error__icon} />
			<p className={styles.error__info}>Не знайдено!</p>
			<Link to={isUserPUMB ? ROUTER_URL.PROFILE : ROUTER_URL.SERVICES_CATEGORIES}>
				<CustomButton label="Повернутись на Головну" />
			</Link>
		</div>
	);
};
