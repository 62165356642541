import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import {
	closeDocumentViewer,
	getAppLoadingSelector,
	getDocumentViewerSelector,
	getRadabankCardIdSelector,
	getRadabankStatementsByPeriod,
	getRadabankStatementsByPeriodSelector,
	getUserIdSelector,
	resetRadabankStatements,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	RadabankOperationsStatementForm,
	RadabankOperationsStatementHeader,
	RadabankOperationStatementsList,
} from 'components/RadabankCard';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { DATE_FORMAT } from 'utils/enums';
import { formatQueryString, getErrorMessage, parseQueryString } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { useAndroidBackButton } from 'utils/hooks/use-close-on-back-button';
import { FirebaseAnalytics } from 'utils/services';
import { IDateRange } from 'utils/types';
import styles from './index.module.scss';

export const RadabankOperationsStatementPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const dispatch = useAppDispatch();

	const currentCardId = useAppSelector(getRadabankCardIdSelector);
	const userId = useAppSelector(getUserIdSelector);
	const statements = useAppSelector(getRadabankStatementsByPeriodSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);
	const documentViewer = useAppSelector(getDocumentViewerSelector);

	const history = useHistory();

	const { search } = useLocation();

	const queryParams = parseQueryString<{ datestart: string; datefinish: string }>(search);

	const [dateRange, setDateRange] = useState({
		datestart: queryParams?.datestart || dayjs().subtract(7, 'day').format(),
		datefinish: queryParams?.datefinish || dayjs().format(),
	});

	const handleGetStatementByDate = async ({ datestart, datefinish }: IDateRange, cardid: string, userId: string) => {
		try {
			dispatch(setAppLoading(true));
			await dispatch(
				getRadabankStatementsByPeriod({
					cardid,
					datestart: dayjs(datestart).format(DATE_FORMAT.RADABANK_REQUEST),
					datefinish: dayjs(datefinish).format(DATE_FORMAT.RADABANK_REQUEST),
					userId,
				})
			);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	useEffect(() => {
		if (currentCardId && userId) {
			handleGetStatementByDate(dateRange, currentCardId, userId);
		}
	}, [dateRange, currentCardId, userId]);

	const handleResetStatements = () => {
		if (documentViewer.isOpen) {
			dispatch(closeDocumentViewer());
			return;
		}

		handleClickBack();
		dispatch(resetRadabankStatements());
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.TRANSACTION_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleResetStatements();
	};

	const handleChangeDateRange = (newDateRange: IDateRange) => {
		setDateRange(newDateRange);
		history.replace({ search: formatQueryString(newDateRange) });
	};

	useAndroidBackButton(handleResetStatements, documentViewer.isOpen);

	const isStatementsEmpty = !Object.keys(statements).length;

	return (
		<div className="page page-scrollable" id={styles.content}>
			<div className={styles.header}>
				<RadabankOperationsStatementHeader dateRange={dateRange} onGoBack={handleGoBack} />
				<RadabankOperationsStatementForm onChangeDateRange={handleChangeDateRange} dateRange={dateRange} />
			</div>
			{isStatementsEmpty && !isAppLoading && (
				<Typography textAlign="center" padding="var(--spacing-6)">
					За даними параметрами операцій не знайдено
				</Typography>
			)}
			{!isStatementsEmpty && <RadabankOperationStatementsList statements={statements} />}
		</div>
	);
};
