export enum DATE_FORMAT {
	TIME_MINUTES_SECONDS = 'mm:ss',
	DATE_VIEW = 'DD.MM.YY',
	DATE_TIME = 'DD.MM.YY HH:mm',
	RECEIPT_VIEW = 'MM.YYYY',
	CARD = 'MM/YY',
	RADABANK_TOKEN_DATE = 'DD.MM.YYYY HH:mm:ss',
	RADABANK_CARD_EXP_DATE = 'MM/YYYY',
	RADABANK_REQUEST = 'DD.MM.YYYY',
	RADABANK_DATE_PICKER = 'DD.MM.YY',
}
