export const removeEmptyProps = <T>(object: T, isRemoveFalse = false): NonNullable<T> => {
	if (!object) {
		return {} as NonNullable<T>;
	}

	const payload: NonNullable<T> = { ...object };
	Object.entries(payload)
		.filter((item: [string, unknown]) => {
			const value: unknown = item[1];
			return (
				(typeof value === 'boolean' && isRemoveFalse && !value) ||
				// eslint-disable-next-line no-inline-comments
				(typeof value !== 'number' && typeof value !== 'boolean' && !value) || // if empty string/null/undefined
				// eslint-disable-next-line no-inline-comments
				(Array.isArray(value) && value.length === 0) // if empty array
			);
		})
		.forEach((item: [string, unknown]) => {
			delete payload[item[0] as keyof typeof payload];
		});
	return payload;
};
