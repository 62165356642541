import { FC, useEffect } from 'react';
import {
	getAppLoadingSelector,
	getRadabankCurrentCardOverdraftDataSelector,
	getRadabankCurrentCardSelector,
	getRadabankOverdraftData,
	getUserProfileSelector,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankCardOverdraftCreate, RadabankCardOverdraftUpdate } from 'components/RadabankCard';
import { Loader } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_BOOLEAN_VALUE } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';

export const RadabankCardOverdraftPage: FC = () => {
	const handleClickBack = useGoBackRadabank();

	const dispatch = useAppDispatch();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const overdraft = useAppSelector(getRadabankCurrentCardOverdraftDataSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);

	useEffect(() => {
		if (currentCard && profile) {
			handleGetCardCurrentRestData(profile.userId, currentCard.id);
		}
	}, [currentCard, profile]);

	const handleGetCardCurrentRestData = async (userId: string, cardid: string): Promise<void> => {
		try {
			await dispatch(getRadabankOverdraftData({ cardid, userId })).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleClickBackButton = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_OVERDRAFT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	const isUpdateLimit = currentCard?.isupdatelimit === RADABANK_BOOLEAN_VALUE.TRUE;

	return (
		<>
			{!!overdraft.data &&
				!!currentCard &&
				(isUpdateLimit ? (
					<RadabankCardOverdraftUpdate onGoBack={handleClickBackButton} />
				) : (
					<RadabankCardOverdraftCreate onGoBack={handleClickBackButton} />
				))}
			{!isAppLoading && overdraft.isLoading && <Loader />}
		</>
	);
};
