// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Timer_timer__4uu-x {
  font-size: 14px;
  margin: 16px auto 0px;
}
.Timer_timer__4uu-x > * {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/ConfirmOTPCode/Timer/index.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,qBAAA;AACD;AACC;EACC,kBAAA;AACF","sourcesContent":[".timer {\n\tfont-size: 14px;\n\tmargin: 16px auto 0px;\n\n\t& > * {\n\t\ttext-align: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer": `Timer_timer__4uu-x`
};
export default ___CSS_LOADER_EXPORT___;
