/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ICardListItemResponse, ICardsReducer } from 'utils/types';
import { deleteCard, getCardsList } from './actions';
import { resetCardsReducerOperation } from './operations';

export const cardsInitialState: ICardsReducer = {
	isLoading: false,
	list: [],
	error: '',
};

const cardsStore = createSlice({
	name: 'cardsSlice',
	initialState: cardsInitialState,
	reducers: {
		resetCardsReducer: resetCardsReducerOperation,
	},
	extraReducers: (builder) => {
		builder.addCase(getCardsList.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getCardsList.fulfilled, (state, { payload }) => {
			state.list = payload;
			state.isLoading = false;
		});
		builder.addCase(getCardsList.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(deleteCard.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(deleteCard.fulfilled, (state, { payload }) => {
			state.list = state.list.filter((item: ICardListItemResponse) => item.cardId !== payload);
			state.isLoading = false;
		});
		builder.addCase(deleteCard.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

export const { resetCardsReducer } = cardsStore.actions;

export default cardsStore.reducer;
