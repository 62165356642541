import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { DATE_FORMAT } from 'utils/enums';
import { formatUTCDateView, getApartmentTitle } from 'utils/helpers';
import { IBillsListItemResponse } from 'utils/types';
import styles from './index.module.scss';

interface BillsListItemProps {
	data: IBillsListItemResponse;
	onClick: () => void;
	title: string;
	description?: string;
}

export const BillsListItem: FC<BillsListItemProps> = ({ data, onClick, description, title }) => {
	const apartments = useAppSelector(getApartmentsSelector);

	const [apartmentTitle, setApartmentTitle] = useState('');

	useEffect(() => {
		if (apartments.list.length) {
			const currentApartment = apartments.list.find(
				(item) => item.apartmentAccountId === data.payerData.apartmentAccountId
			);
			if (currentApartment) {
				setApartmentTitle(getApartmentTitle(currentApartment));
			}
		}
	}, [data, apartments.list.length]);

	return (
		<div className={styles.card} onClick={onClick}>
			<Typography variant="subtitle1">{title}</Typography>

			{!!description && (
				<Typography className={styles.card__description} variant="body2" color="--color-neutral-500">
					{description}
				</Typography>
			)}

			<Typography variant="body2" color="--color-neutral-500">
				{formatUTCDateView(data.paidAt ?? data.createdAt, DATE_FORMAT.RADABANK_TOKEN_DATE)}
			</Typography>
			{!!apartmentTitle && <Typography variant="body1">{apartmentTitle}</Typography>}
		</div>
	);
};
