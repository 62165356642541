// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProviderAccordion_wrapper__ud-Ih {
  box-shadow: none;
}
.ProviderAccordion_wrapper__ud-Ih::before {
  display: none;
}
.ProviderAccordion_wrapper__ud-Ih .ProviderAccordion_header__1krcC {
  background: var(--color-primary-50);
}
.ProviderAccordion_wrapper__ud-Ih .ProviderAccordion_details__C6U1r {
  border-radius: var(--spacing-2);
  border: 1px solid var(--color-primary-100);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/ProviderAccordion/index.module.scss"],"names":[],"mappings":"AAEA;EACC,gBAAA;AADD;AAGC;EACC,aAAA;AADF;AAIC;EACC,mCAAA;AAFF;AAKC;EACC,+BAAA;EACA,0CAAA;EACA,yBAAA;EACA,0BAAA;EACA,gBAAA;AAHF","sourcesContent":["@import 'assets/styles/mixins.scss';\n\n.wrapper {\n\tbox-shadow: none;\n\n\t&::before {\n\t\tdisplay: none;\n\t}\n\n\t.header {\n\t\tbackground: var(--color-primary-50);\n\t}\n\n\t.details {\n\t\tborder-radius: var(--spacing-2);\n\t\tborder: 1px solid var(--color-primary-100);\n\t\tborder-top-left-radius: 0;\n\t\tborder-top-right-radius: 0;\n\t\tborder-top: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ProviderAccordion_wrapper__ud-Ih`,
	"header": `ProviderAccordion_header__1krcC`,
	"details": `ProviderAccordion_details__C6U1r`
};
export default ___CSS_LOADER_EXPORT___;
