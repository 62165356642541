export enum RADABANK_STEP {
	INTERNAL_CLIENT_PHONE = 'InternalClientPhone',
	INFO_BEGIN = 'InfoBegin',
	INFO_APPROVE_STEP = 'InfoApproveCode',
	INFO_SEND_CODE = 'InfoSendCode',
	INFO_DIYA_BEGIN = 'InfoDiyaBegin',
	INFO_IMAGE_SET = 'InfoImageSet',
	INFO_IMAGE_COMMIT = 'InfoImageCommit',
}

export enum RADABANK_RESULT {
	ERROR = 'ERROR !!!',
	OK = 'OK',
}

export enum RADABANK_IMAGE_TYPE_CODE {
	SELFIE = 'selfie',
	SIGNATURE = 'signself',
}

export enum RADABANK_BOOLEAN_VALUE {
	TRUE = '1',
	FALSE = '0',
}

export enum RADABANK_GET_IMAGE_TYPE {
	CONTRACT = 'dogovirstartpdf',
}

export enum RADABANK_PAYMENT_STATUS {
	IN_PROGRESS = '0',
	SUCCESS = '1',
	REJECTED = '2',
	NEED_CONFIRMATION = '3',
}

export enum RADABANK_NPOSHTA_DIVISION_TYPE {
	DEPARTMENT = '0',
	PARCEL_LOCKER = '1',
}

export enum RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST {
	DEPARTMENT = '20',
	PARCEL_LOCKER = '21',
}

export enum RADABANK_NPOSHTA_CARD_STATUS {
	DISABLED = '0',
	CAN_CREATE_REQUEST = '1',
	IN_PROGRESS = '2',
	DELIVERING = '3',
	CAN_ACTIVATE = '4',
	ACTIVATED = '5',
}

export enum RADABANK_CLIENT_REQUEST_OVER_TYPE {
	OPEN = '0',
	UPDATE = '1',
	CLOSE = '3',
}

export enum RADABANK_SECURE_SETTINGS_TABS {
	LIMITS,
	INTERNET,
	COUNTRY,
	CURRENCY,
}

export enum RADABANK_SMS_TYPE_ID {
	ADD = '7',
	DELETE = '8',
}

export enum RADABANK_CARD_BLOCK_LEVEL {
	ACTIVE = '0',
	FREEZED = '1',
}

export enum RADABANK_FEE_TYPE {
	RECALL_PIN = '2',
	UNBLOCK_CARD = '3',
	UPDATE_PIN = '11',
	CARD_ORDER_TO_DEPARTMENT = '20',
	CARD_ORDER_TO_LOCKER = '21',
}
