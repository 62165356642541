import { FC } from 'react';
import { MenuItem, MenuItemProps } from '@mui/material';
import { ReactComponent as CarretSvg } from 'assets/icons/caret.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

interface RadabankDocumentViewButtonProps extends MenuItemProps {
	label: string;
}

export const RadabankDocumentViewButton: FC<RadabankDocumentViewButtonProps> = ({
	onClick,
	className,
	label,
	...rest
}) => (
	<MenuItem {...rest} className={classNames(styles.content, className)} onClick={onClick}>
		{label}
		<CarretSvg />
	</MenuItem>
);
