import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, BottomNavigationProps } from '@mui/material';
import classNames from 'classnames';
import { useAppSelector } from 'store/hooks';
import { getIsPumbUserAccessSelector, getIsUserHasFullAccessSelector } from 'store/user-service/selectors';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, ROUTES_PERMISSIONS_NAV_BAR_PUMB } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { useKeyboard } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { INavbarItem } from "utils/types";
import { formatPathname, getActiveTab, getNavbarList, NAVBAR_LIST_WEB } from './helpers';
import styles from './index.module.scss';

export const Navbar: FC<BottomNavigationProps> = ({ className, ...props }) => {
	const { pathname } = useLocation();

	const { isKeyboardOpen } = useKeyboard();

	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);
	const isUserPUMB = useAppSelector(getIsPumbUserAccessSelector)

	const [activeTab, setActiveTab] = useState(getActiveTab(pathname));

	useEffect(() => {
		setActiveTab(getActiveTab(pathname));
	}, [pathname]);

	const handleChangeNavigationTab = (_event: SyntheticEvent, value: ROUTER_URL) => {
		const pageName = formatPathname(pathname);
		const nextTab = formatPathname(value);
		FirebaseAnalytics.logEvent(pageName, `${FIREBASE_EVENT_ANALYTICS_BUTTON.CHANGE_TAB}-${nextTab}`);
		setActiveTab(value);
	};

	const filterNavbarItems = (allowedRoutes: string[]): INavbarItem[] => {
		return NAVBAR_LIST_WEB.filter((item) => allowedRoutes.includes(item.path));
	};

	const checkPumbRights = () => {
		if (isUserPUMB) {
			return getNavbarList(isHasFullAccess)
		}
		return filterNavbarItems(ROUTES_PERMISSIONS_NAV_BAR_PUMB)
	}

	return (
		<BottomNavigation
			{...props}
			className={classNames(styles.navbar, className, {
				[styles.navbar_hidden]: isKeyboardOpen,
			})}
			showLabels
			value={activeTab}
			onChange={handleChangeNavigationTab}
		>
			{checkPumbRights().map(({ label, icon, path }) => (
				<BottomNavigationAction
					className={styles.navbar__action}
					classes={{
						label: styles.navbar__label,
						selected: styles.selected,
					}}
					disableRipple
					component={Link}
					to={path}
					key={label}
					label={label}
					icon={icon}
					value={path}
				/>
			))}
		</BottomNavigation>
	);
};
