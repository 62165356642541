import { MouseEvent, TouchEvent, useRef } from 'react';
import { TypeOrNull } from 'utils/types';

export const useLongPress = (onPress: () => void, delay = 1000) => {
	const timerRef = useRef<TypeOrNull<NodeJS.Timeout>>(null);
	const isLongPressedRef = useRef(false);

	const onTouchStart = (e: TouchEvent) => {
		e.stopPropagation();
		timerRef.current = setTimeout(() => {
			isLongPressedRef.current = true;
			onPress();
		}, delay);
	};

	const onTouchEnd = (e: TouchEvent) => {
		e.stopPropagation();
		if (timerRef.current) {
			clearTimeout(timerRef.current);
			timerRef.current = null;
		}
	};

	const onClick = (event: MouseEvent) => {
		if (isLongPressedRef.current) {
			event.stopPropagation();
			isLongPressedRef.current = false;
		}
	};

	return {
		onTouchStart,
		onTouchEnd,
		onClick,
	};
};
