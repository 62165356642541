import { FC } from 'react';
import { useHistory } from 'react-router';
import { PrimaryBannerLayout } from 'layouts';
import { getUserIdSelector, setAppLoading, showToast, useAppDispatch, useAppSelector } from 'store';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ERROR_MESSAGE, ROUTER_URL } from 'utils/enums';
import { FirebaseAnalytics, PushNotificationsService } from 'utils/services';

export const PushNotificationsBannerPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const userId = useAppSelector(getUserIdSelector);

	const handleExitPage = (isConfirm = false) => {
		if (!isConfirm) {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PERMISSIONS.PUSH_NOTIFICATIONS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CLOSE
			);
		}
		history.replace(ROUTER_URL.PROFILE);
	};

	const handleSubscribePushNotifications = async () => {
		try {
			dispatch(setAppLoading(true));
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PERMISSIONS.PUSH_NOTIFICATIONS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.TURN_ON
			);
			if (userId) {
				await PushNotificationsService.subscribe(userId);
			}
			handleExitPage(true);
		} catch (error) {
			dispatch(showToast({ message: ERROR_MESSAGE.PUSH_NOTIFICATIONS_WENT_WRONG }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	return (
		<PrimaryBannerLayout
			title="Увімкніть push-повідомлення"
			description={[
				'Увімкніть пуш повідомлення - і будьте в курсі статусу оновлення комунальних та інших послуг.',
				'Для цього дозвольте на наступному екрані надсилати важливі повідомлення',
			]}
			onClick={handleSubscribePushNotifications}
			onExit={handleExitPage}
			buttonLabel="Увімкнути"
		/>
	);
};
