import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface ProviderServicesGroupTitleProps extends TypographyProps {
	title: string;
}

export const ProviderServicesGroupTitle: FC<ProviderServicesGroupTitleProps> = ({ title, ...props }) => (
	<Typography variant="subtitle1" fontSize="15px" {...props}>
		{title}
	</Typography>
);
