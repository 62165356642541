import { ChangeEvent, FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { getRadabankCardExistsSelector, getUserIdSelector, setAppLoading } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { updateUserProfile } from 'store/user-service/actions';
import { ConfirmPasswordNative, SetPasswordNative } from 'components/Auth';
import { ArrowBackButton, Loader } from 'components/shared';
import { ERROR_CODE, ERROR_MESSAGE, SETUP_PASSWORD_STEP, STORAGE_KEY, USER_PROFILE_FIELD_NAME } from 'utils/enums';
import { decryptData, encryptData, getErrorMessage, getErrorResponse } from 'utils/helpers';
import { RadabankService, StorageService } from 'utils/services';
import { TypeOrNull } from 'utils/types';
import { ConfirmChangeRadabankPasswordNative } from '../ConfirmChangeRadabankPasswordNative';
import { PinScreenMobileVariantsType } from '../helpers';
import { SuccessPasswordUpdateBanner } from '../SuccessPasswordUpdateBanner';
import styles from './index.module.scss';

interface ChangeUserPasswordProps {
	onClose: () => void;
}

export const UpdatePasswordNative: FC<ChangeUserPasswordProps> = ({ onClose }) => {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const isUserHasRadabankCard = useAppSelector(getRadabankCardExistsSelector);
	const userId = useAppSelector(getUserIdSelector);

	const [step, setStep] = useState<TypeOrNull<SETUP_PASSWORD_STEP>>(null);
	const [userPassword, setUserPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [passwordData, setPasswordData] = useState({
		currentPassword: '',
		password: '',
		confirmPassword: '',
	});

	const handleVerifyRadabankTokens = async (userId: string) => {
		try {
			const radabankData = await RadabankService.getInternalReqPayload(
				{ userId },
				`${pathname}?updateProfile=${USER_PROFILE_FIELD_NAME.CHANGE}`
			);
			if (radabankData) {
				setStep(SETUP_PASSWORD_STEP.CURRENT);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleDefineFirstStep = async (userId: string) => {
		try {
			dispatch(setAppLoading(true));
			if (isUserHasRadabankCard) {
				await handleVerifyRadabankTokens(userId);
			} else {
				setStep(SETUP_PASSWORD_STEP.CURRENT);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleShowSuccessChangePasswordMessage = () => {
		dispatch(showToast({ message: 'Пароль змінено успішно', color: 'success' }));
	};

	const handleUpdatePasswordInStorage = async (password: string) => {
		await StorageService.set(STORAGE_KEY.USER_PASSWORD, encryptData(password));
	};

	const handleChangeRadabankPassword = async () => {
		if (!userId) return;

		try {
			const { currentPassword, password, confirmPassword } = passwordData;
			await RadabankService.changeClientPassword({
				userId,
				oldpass: currentPassword,
				pass1: password,
				pass2: confirmPassword,
			});
			setStep(SETUP_PASSWORD_STEP.OTP);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			setPasswordData((prev) => ({ ...prev, confirmPassword: '' }));
		}
	};

	useLayoutEffect(() => {
		if (userId) {
			handleDefineFirstStep(userId);
		}
	}, [isUserHasRadabankCard, userId]);

	useEffect(() => {
		StorageService.get(STORAGE_KEY.USER_PASSWORD).then((value) => {
			if (value) {
				setUserPassword(decryptData(value as string));
			}
		});
	}, []);

	const handleClose = () => {
		if (step === SETUP_PASSWORD_STEP.CONFIRM) {
			setPasswordData({ ...passwordData, password: '' });
			setStep(SETUP_PASSWORD_STEP.SET);
		} else {
			onClose();
		}
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
		setPasswordData({ ...passwordData, [event.target.name]: event.target.value });
	};

	const handleChangeHostPassword = async () => {
		await dispatch(updateUserProfile({ password: userPassword, newPassword: passwordData.password })).unwrap();
		await handleUpdatePasswordInStorage(passwordData.password);
	};

	const onSubmit = async () => {
		try {
			setIsLoading(true);

			if (!isUserHasRadabankCard) {
				await handleChangeHostPassword();
				handleShowSuccessChangePasswordMessage();
				onClose();
			} else {
				await handleChangeRadabankPassword();
			}
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			dispatch(
				showToast({
					message:
						errorResponse?.errorData?.code === ERROR_CODE.INVALID_CURRENT_PASSWORD
							? ERROR_MESSAGE.INVALID_CURRENT_PASSWORD
							: getErrorMessage(error),
				})
			);
			setPasswordData({ ...passwordData, confirmPassword: '' });
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeHostPasswordAfterRadabank = async () => {
		await handleChangeHostPassword();
		setStep(SETUP_PASSWORD_STEP.SUCCESS);
	};

	const content = useMemo(() => {
		switch (step) {
			case SETUP_PASSWORD_STEP.SET:
				return (
					<SetPasswordNative
						contentClasses={styles.wrapper__content}
						isVisibleDescription={false}
						value={passwordData.password}
						onChange={handleChangePassword}
						type={PinScreenMobileVariantsType.SetNewPassword}
						maxLength={6}
						onSubmit={() => setStep(SETUP_PASSWORD_STEP.CONFIRM)}
					/>
				);

			case SETUP_PASSWORD_STEP.CONFIRM:
				return (
					<ConfirmPasswordNative
						contentClasses={styles.wrapper__content}
						isVisibleDescription={false}
						value={passwordData.confirmPassword}
						password={passwordData.password}
						onChange={handleChangePassword}
						maxLength={6}
						onSubmit={onSubmit}
						variant={PinScreenMobileVariantsType.ConfirmNewPassword}
					/>
				);
			case SETUP_PASSWORD_STEP.OTP:
				return (
					<ConfirmChangeRadabankPasswordNative
						onClose={handleClose}
						onPasswordChange={handleChangeHostPasswordAfterRadabank}
						onResendCode={handleChangeRadabankPassword}
					/>
				);
			case SETUP_PASSWORD_STEP.SUCCESS:
				return <SuccessPasswordUpdateBanner onGoBack={handleClose} />;
			default:
				return (
					<ConfirmPasswordNative
						contentClasses={styles.wrapper__content}
						isVisibleDescription={false}
						value={passwordData.currentPassword}
						password={userPassword}
						maxLength={6}
						onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
						onSubmit={() => setStep(SETUP_PASSWORD_STEP.SET)}
						variant={PinScreenMobileVariantsType.CheckCurrentPassword}
					/>
				);
		}
	}, [passwordData, step]);

	const isOtpOrSuccessStep = step && [SETUP_PASSWORD_STEP.OTP, SETUP_PASSWORD_STEP.SUCCESS].includes(step);

	return (
		<>
			{isLoading && <Loader />}
			{step !== null &&
				(isOtpOrSuccessStep ? (
					content
				) : (
					<div className={styles.wrapper}>
						<ArrowBackButton onClick={handleClose} />
						{content}
					</div>
				))}
		</>
	);
};
