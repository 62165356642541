import { FC, KeyboardEvent, useRef } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { CURRENCY_LABEL } from 'utils/enums';
import { checkIsServiceValueAllowed, handleKeyDownServiceAmount } from 'utils/helpers';
import { IServiceFieldResponse } from 'utils/types';

interface OtherServiceAmountInputProps extends Omit<BaseTextFieldProps, 'value' | 'defaultValue' | 'type'> {
	fieldConfig?: IServiceFieldResponse;
	onChange: (value: string) => void;
	value: string | number;
	defaultValue?: string | number;
	onSubmit?: () => void;
}

export const OtherServiceAmountInput: FC<OtherServiceAmountInputProps> = ({
	fieldConfig,
	onChange,
	defaultValue,
	onSubmit,
	...props
}) => {
	const inputRef = useRef<HTMLInputElement>();

	const handleChangeValue = ({ value }: NumberFormatValues): void => {
		onChange(value === '' ? '0' : value);
		if (inputRef.current && +value === 0) {
			inputRef.current.setSelectionRange(2, 1);
		}
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter') {
			onSubmit?.();
		} else {
			handleKeyDownServiceAmount(e, props.value)
		}
	}

	return (
		<NumericFormat
			{...props}
			inputRef={inputRef}
			suffix={CURRENCY_LABEL.UAH}
			customInput={TextField}
			decimalScale={2}
			onValueChange={handleChangeValue}
			allowNegative={false}
			allowLeadingZeros
			fixedDecimalScale
			defaultValue={defaultValue ?? ''}
			thousandSeparator=" "
			inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
			allowedDecimalSeparators={[',']}
			isAllowed={fieldConfig ? ({ value }) => checkIsServiceValueAllowed(value, fieldConfig, true) : undefined}
			onKeyDown={handleKeyDown}
		/>
	);
};
