import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { App } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { StatusBar } from '@capacitor/status-bar';
import { getRadabankInternalClientData } from 'store';
import { getCardsList } from 'store/cards/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIsNetworkConnected, updatePlatform } from 'store/system/reducer';
import { getUserAvatar } from 'store/user-service/actions';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { PLATFORMS, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { FirebaseAnalytics, StorageService } from 'utils/services';
import { BiometryService } from 'utils/services/biometry.service';

interface AppStateChangeEvent {
	isActive: boolean;
}

export const useInitialize = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const networkStatusListener = useRef<PluginListenerHandle | null>(null);
	const profile = useAppSelector(getUserProfileSelector);

	useEffect(() => {
		handleInit();
	}, []);

	useEffect(() => {
		if (profile?.userId) {
			dispatch(getCardsList(profile.userId));
			dispatch(getUserAvatar(profile.userId));
			if (Capacitor.isNativePlatform()) {
				dispatch(getRadabankInternalClientData({ userId: profile.userId, userphone: profile.phone }));
			}
		}
	}, [profile?.userId]);

	const updateNetworkStatus = async (connectionStatus: ConnectionStatus | undefined = undefined): Promise<void> => {
		let status = connectionStatus;

		if (!status) {
			status = await Network.getStatus();
		}

		dispatch(updateIsNetworkConnected(status.connected));
		if (!status.connected) {
			history.replace(ROUTER_URL.ERROR_NETWORK);
		}
	};

	const handleInit = async () => {
		dispatch(updatePlatform(Capacitor.getPlatform() as PLATFORMS));
		await FirebaseAnalytics.init();
		await updateNetworkStatus();

		if (Capacitor.isNativePlatform()) {
			if (Capacitor.getPlatform() === 'android') {
				await StatusBar.setBackgroundColor({ color: '#57486D' });
			}

			await App.addListener('appStateChange', async ({ isActive }: AppStateChangeEvent) => {
				if (!isActive) {
					// const currentTime = new Date().getTime();
					// await StorageService.set(STORAGE_KEY.APP_LAST_BACKGROUND_TIME, currentTime);
					await networkStatusListener.current?.remove();
				} else {
					await updateNetworkStatus();
					networkStatusListener.current = await Network.addListener('networkStatusChange', updateNetworkStatus);

					if (
						!window.location.pathname.includes(ROUTER_URL.RADABANK_CARD_AUTH_INFO_SET_ANKETA) &&
						window.location.pathname !== ROUTER_URL.PROFILE
					) {
						const values = await StorageService.get(STORAGE_KEY.RADABANK_INFO_ANKETA_DATA);

						if (values) {
							await StorageService.remove(STORAGE_KEY.RADABANK_INFO_ANKETA_DATA);
						}
					}

					// const lastBackgroundTime = await StorageService.get<number>(STORAGE_KEY.APP_LAST_BACKGROUND_TIME);
					// if (lastBackgroundTime) {
					// 	console.log('🚀 ~ file: use-initialize.ts:89 ~ App.addListener ~ lastBackgroundTime:', lastBackgroundTime);
					// 	const currentTime = new Date().getTime();
					// 	if (currentTime - +lastBackgroundTime > 5 * 60 * 1000 && !pathname.includes(ROUTER_URL.AUTH)) {
					// 		await userLogout();
					// 		history.push(ROUTER_URL.AUTH_LOGIN);
					// 	}
					// }
				}
			});
			await BiometryService.checkBiometry();
		}
	};

	// .then(async () => {
	// 	if (!networkStatusListener.current) {
	// 		networkStatusListener.current = await Network.addListener('networkStatusChange', onNetworkStatusChange);
	// 	}
	// });

	return { isNativePlatform: Capacitor.isNativePlatform() };
};
