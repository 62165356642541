// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScrollTopButton_button__AEJwd {
  position: absolute;
  width: 40px;
  aspect-ratio: 1;
  bottom: 50px;
  right: 50px;
}
.ScrollTopButton_button__AEJwd, .ScrollTopButton_button__AEJwd:hover {
  background-color: var(--color-primary-500);
}
.ScrollTopButton_button__AEJwd .ScrollTopButton_icon__pV1cT {
  transform: rotate(90deg);
}
.ScrollTopButton_button__AEJwd .ScrollTopButton_icon__pV1cT path {
  stroke: var(--palette-common-white);
}`, "",{"version":3,"sources":["webpack://./src/components/shared/ScrollTopButton/index.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,0CAAA;AACR;AAEC;EACC,wBAAA;AAAF;AAEQ;EACI,mCAAA;AAAZ","sourcesContent":[".button {\n    position: absolute;\n    width: 40px;\n    aspect-ratio: 1;\n    bottom: 50px;\n    right: 50px;\n    \n    &, &:hover {\n        background-color: var(--color-primary-500);\n    }\n\n\t.icon {\n\t\ttransform: rotate(90deg);\n        \n        path {\n            stroke: var(--palette-common-white)\n        }\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ScrollTopButton_button__AEJwd`,
	"icon": `ScrollTopButton_icon__pV1cT`
};
export default ___CSS_LOADER_EXPORT___;
