import { FC } from 'react';
import { ProviderServiceDetails, ProviderServiceHeader } from 'components/OtherServices';
import { getAmountTitle } from 'utils/helpers';
import { IBillsSubBillItem, IProviderServiceResponse, IReceiptOtherServiceResponse } from 'utils/types';

interface ProviderPortmoneInfoModalProps {
	data: IReceiptOtherServiceResponse | IProviderServiceResponse | IBillsSubBillItem;
	amount?: number | string;
	providerAccountId?: string | number;
	title: string;
	icon?: string;
}

export const ProviderPortmoneInfoModal: FC<ProviderPortmoneInfoModalProps> = ({
	data,
	amount,
	providerAccountId,
	title,
	icon,
}) => {
	return (
		<>
			<ProviderServiceHeader className="receipt-service-modal-header" icon={icon} title={title} />
			<ProviderServiceDetails
				data={data}
				amount={amount ? getAmountTitle(amount) : (amount as undefined)}
				providerAccountId={providerAccountId?.toString()}
			/>
		</>
	);
};
