import { FC, MouseEvent, PropsWithChildren } from 'react';
import { ReactComponent as InfoNoteIcon } from 'assets/icons/info-note.svg';
import { CustomButton } from 'components/shared/CustomButton';
import { CustomModal } from 'components/shared/CustomModal';
import styles from './index.module.scss';

interface InfoHintModalProps {
	onClose: () => void;
	isOpen: boolean;
}

export const InfoHintModal: FC<PropsWithChildren<InfoHintModalProps>> = ({ children, onClose, isOpen }) => {
	const handleStopPropagation = (e: MouseEvent) => {
		e.stopPropagation();
	};

	const handleCloseModal = (e: MouseEvent) => {
		handleStopPropagation(e);
		onClose();
	};

	return (
		<CustomModal onClick={handleStopPropagation} isMobileView={false} isOpen={isOpen} onClose={onClose}>
			<div className={styles.container}>
				<InfoNoteIcon className={styles.icon} />
				{children}
				<CustomButton className={styles.button} label="Зрозуміло" onClick={handleCloseModal} />
			</div>
		</CustomModal>
	);
};
