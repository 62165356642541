import { FC } from 'react';
import { ReactComponent as QuestionSVG } from 'assets/icons/question-wavy.svg';
import { ActionModalContent, CustomButton, CustomModal } from 'components/shared';
import { getMaskedPhone } from 'utils/helpers';
import { IDefaultModalProps } from 'utils/types';

interface ConfirmPhoneModalProps extends IDefaultModalProps {
	phone: string;
	onSubmit: () => void;
	isLoading: boolean;
}

export const ConfirmPhoneModal: FC<ConfirmPhoneModalProps> = ({ isOpen, onClose, phone, onSubmit, isLoading }) => {
	const handleConfirmModal = () => {
		onSubmit();
		if (onClose) {
			onClose();
		}
	};

	return (
		<CustomModal isOpen={isOpen} onClose={onClose} isMobileView={false}>
			<ActionModalContent
				icon={<QuestionSVG />}
				title={getMaskedPhone(phone)}
				subTitle="Ви впевнені, що бажаєте використати вказаний номер для входу?"
			/>
			<div className="modal-actions-row">
				<CustomButton label="Змінити номер" onClick={onClose} fill="outline" color="primary" size="large" />
				<CustomButton label="Так, продовжити" onClick={handleConfirmModal} isLoading={isLoading} size="large" />
			</div>
		</CustomModal>
	);
};
