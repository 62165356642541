export enum SETTINGS_NAMES {
  Імя = 'name',
  Прізвище = 'secondName',
  Email = 'email',
  Телефон = 'phone',
  Підтвердити = 'confirm',
}

export enum SETTINGS_SUBMIT_TEXT {
  Email = 'Підтвердіть вашу електронну адресу',
  Телефон = 'Підтвердіть номер телефону',
}

export enum SETTINGS_LABELS {
  Імя = 'Ваше імя',
  Прізвище = 'Ваше прізвище',
  Email = 'Ваша електронна адреса',
  Телефон = 'Ваш номер телефону',
  Підтвердити = 'Код підтвердження',
}

export enum SETTINGS_SUBMIT_BUTTON_TEXT {
  SAVE = 'Зберегти',
  SUBMIT = 'Підтвердити',
}

export type SettingsNamesKeys = keyof typeof SETTINGS_NAMES;
export type InputLabelsKeys = keyof typeof SETTINGS_LABELS;
export type SubmitTextKeys = keyof typeof SETTINGS_SUBMIT_TEXT;
